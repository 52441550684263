/**
 * SVF Field 編集 フィールド定義
 */
export class FieldDefs{
    id: string | undefined;
    name: string | undefined;
    searchName: string | undefined;
    fieldType: string | undefined;
    dateType: string | undefined;
    timezone: string | undefined;
    visualConfirmation:boolean| undefined;
    editRestrictions:boolean| undefined;

    constructor(init?: Partial<FieldDefs>) {
        Object.assign(this, init);
    }
}
