import * as WebConstants from "../constants/WebConstants";
import { AnnotationInfo } from "../models/AnnotationInfo";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * アノテーション API
 */
export const useAnnotationService = () => {
    const serviceState = ServiceContainer.useContainer()

    const getAnnotation = (id: number | string, pageNo: number) => {
        return serviceState.request(
            WebConstants.ANNOTATION_GET_URL(id, pageNo),
            WebConstants.METHOD_GET,
            null
        ).then((response) => {
            return response.data as AnnotationInfo;
        })
    }

    return {
        getAnnotation
    }
}