import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { IOSSwitch } from "../manage/css/Style";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import { ReceiptData } from "../../models/ReceiptData";
import { GetTimeZoneDate, TransformDateToNumber } from "../../common/TransFormTimeZone";
import UserContainer from "../../container/UserContainer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StyledDatePicker, parseDateToMoment } from "../datepicker/StyledDatePicker";
import { DEFAULT_TIMEZONE } from "../../constants/Constants";

type Props = {
    title: string;
    isOpen: boolean;
    data: ReceiptData;
    delDateDttmDateChoose: boolean;
    vouDateIssDttmDateChoose: boolean;
    doClose: () => void;
    doClick: (data: any) => void;
};

// 最大フィールド長
const MAX_LENGTHS = {
    cneePrivateCdId: 13,
    cnsgPrivateCdId: 13,
    shipToPrivateCdId: 13,
    shipFromPrivateCdId: 13,
    ordNumId: 23,
    trspCliPrivateCdId: 13
};

const getStatusMap = {
    "未取得のみ": "0",
    "取得済のみ": "1",
    "すべて取得": "2",
}

export const ReceiptDialog: React.FC<Props> = ({ title, isOpen, data, doClose, doClick, delDateDttmDateChoose, vouDateIssDttmDateChoose }) => {
    const userState = UserContainer.useContainer();

    const [open, setOpen] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<ReceiptData>({});
    const [formDelDateDttmDateChoose, setFormDelDateDttmDateChoose] = React.useState<boolean>(false);
    const [formVouDateIssDttmDateChoose, setFormVouDateIssDttmDateChoose] = React.useState<boolean>(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setOpen(isOpen);
        setFormDelDateDttmDateChoose(delDateDttmDateChoose);
        setFormVouDateIssDttmDateChoose(vouDateIssDttmDateChoose);
        setFormData(data);
    }, [isOpen])

    useEffect(() => {
        setFormData(data)
    }, [data])

    /**
     * 入力ボックスのデータ変更に対応
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any }) => {
        const { target: { value, name } } = e;
        const maxLength = MAX_LENGTHS[name as keyof typeof MAX_LENGTHS];
        let newValue = value.length > maxLength ? value.substring(0, maxLength) : value;
        setFormData({ ...formData, [name]: newValue });
    }

    /**
     * 日付から相対日付への変換を指定します
     * @param event 
     */
    const handleDateChooseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked, name } } = event;
        if (name === "checkDelDateDttmDate") {
            setFormDelDateDttmDateChoose(checked);
            if (checked) {
                let relativeDate = Number.parseInt(formData.delDateDttmRelative ?? "")
                let absoluteDate = formData.delDateDttm
                if (relativeDate === null || Number.isNaN(relativeDate)) {
                    absoluteDate = ""
                } else {
                    absoluteDate = GetTimeZoneDate(new Date(), DEFAULT_TIMEZONE, relativeDate);
                }
                setFormData({ ...formData, delDateDttm: absoluteDate, delDateDttmRelative: "" })
            } else {
                let absoluteDate = formData.delDateDttm
                let relativeDate = ""
                if (!absoluteDate) {
                    relativeDate = ""
                } else {
                    relativeDate = TransformDateToNumber(absoluteDate);
                }
                setFormData({ ...formData, delDateDttm: "", delDateDttmRelative: relativeDate })
            }
        } else if (name === "checkVouDateIssDttmDate") {
            setFormVouDateIssDttmDateChoose(checked);
            if (checked) {
                let relativeDate = Number.parseInt(formData.vouDateIssDttmRelative ?? "")
                let absoluteDate = formData.vouDateIssDttm
                if (relativeDate === null || Number.isNaN(relativeDate)) {
                    absoluteDate = ""
                } else {
                    absoluteDate = GetTimeZoneDate(new Date(), DEFAULT_TIMEZONE, relativeDate);
                }
                setFormData({ ...formData, vouDateIssDttm: absoluteDate, vouDateIssDttmRelative: "" })
            } else {
                let absoluteDate = formData.vouDateIssDttm
                let relativeDate = ""
                if (!absoluteDate) {
                    relativeDate = ""
                } else {
                    relativeDate = TransformDateToNumber(absoluteDate);
                }
                setFormData({ ...formData, vouDateIssDttm: "", vouDateIssDttmRelative: relativeDate })
            }
        }
    }

    /**
     * ラジオボックスのステータスを変更する
     */
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { name } } = event;
        setFormData({ ...formData, getStatus: getStatusMap[name as keyof typeof getStatusMap] });
    }

    /**
     * コントロールスイッチ
     * @param event
     */
    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, fetchFlag: event.target.checked === true ? "1" : "0" });
    }

    /**
     * 日本のタイムゾーン時間への変換とフォーマット
     * @param date 日付型の変数
     * @param timeZone タイムゾーン
     * @param changeDayNum 変更日数
     * @returns フォーマットされた文字列
     */
    const numberToTimeZoneDate = (date: Date, timeZone: string, changeDayNum: number, isEstiDateDttm: boolean): string => {
        if (date === null || Number.isNaN(changeDayNum)) {
            return "";
        }
        const datePartFormat = GetTimeZoneDate(date, timeZone, changeDayNum);
        if (isEstiDateDttm) {
            setFormData({ ...formData, delDateDttm: datePartFormat, delDateDttmRelative: "" });
        } else {
            setFormData({ ...formData, vouDateIssDttm: datePartFormat, vouDateIssDttmRelative: "" });
        }

        return datePartFormat;
    }

    /**
     * 日付型データの差分を数値型に変換
     * @param date 変換が行われる時間
     * @returns 時差
     */
    const dateToNumber = (date: string | undefined, isEstiDateDttm: boolean): string => {
        if (date == undefined || date === "") {
            return "";
        }
        const day = TransformDateToNumber(date);
        if (isEstiDateDttm) {
            setFormData({ ...formData, delDateDttmRelative: day, delDateDttm: "" });
        } else {
            setFormData({ ...formData, vouDateIssDttmRelative: day, vouDateIssDttm: "" });
        }

        return day;
    }

    const handleClick = () => {
        const data = { tenantId: userState.tenantId, ...formData }
        doClick(data);
    }

    /**
     * 新規ダイアログの確認ボタンのdisable状態を制御する
     * @returns コンポーネントのdisable状態を返す
     */
    const disableButton = () => {
        if ((formData.delDateDttm || formData.delDateDttmRelative) && formData.cnsgPrivateCdId) {
            return false;
        }
        return true;
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{title}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* 納入予定日 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '125px' }}>
                            <span style={{ color: 'red', lineHeight: '10px' }}>*</span>
                            {formDelDateDttmDateChoose ? t('Text.SubmitDatetime') : t('Text.DelDateDttmRelative')}
                        </Typography>
                        <div style={{ marginLeft: '120px', display: 'flex', alignItems: 'center' }}>
                            {
                                formDelDateDttmDateChoose ?
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <StyledDatePicker
                                                name="delDateDttm"
                                                value={formData.delDateDttm ? moment(formData.delDateDttm) : null}
                                                sx={{ width: '170px' }}
                                                format={"YYYY-MM-DD"}
                                                onChange={(valueNew) => {
                                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                                        handleTextFieldOnChange({ target: { value: "", name: "delDateDttm" } })
                                                    } else {
                                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DD"), name: "delDateDttm" } })
                                                    }
                                                }} />
                                        </LocalizationProvider>
                                    </> :
                                    <>
                                        <TextField
                                            name="delDateDttmRelative"
                                            type="number"
                                            size="small"
                                            style={{ width: '150px' }}
                                            value={formData.delDateDttmRelative}
                                            onChange={handleTextFieldOnChange}
                                            variant="outlined"
                                        />
                                        <span style={{ marginLeft: '10px' }}>
                                            {formData.delDateDttmRelative?.length === 0 ? "" : (parseInt(formData.delDateDttmRelative!) === 0 ? i18n.t('Text.TheDay') :
                                                (parseInt(formData.delDateDttmRelative!) > 0 ? i18n.t('Text.DaysAfter') : i18n.t('Text.DaysBefore')))}
                                        </span>
                                    </>
                            }

                        </div>
                        <div style={{ marginLeft: '30px' }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkDelDateDttmDate"
                                            color="primary"
                                            checked={formDelDateDttmDateChoose}
                                            onChange={handleDateChooseChange} />
                                    }
                                    label={i18n.t('Text.SpecifiedDate')}
                                />
                            </FormGroup>
                        </div>
                    </div> <br />
                    {/* 荷受人コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '100px' }}>
                            {t('Text.CneePrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name="cneePrivateCdId"
                            size="small"
                            value={formData.cneePrivateCdId}
                            style={{ marginLeft: '145px', width: '385px' }}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 荷送人コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '120px' }}>
                            <span style={{ color: 'red' }}>*</span>
                            {t('Text.CnsgPrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name='cnsgPrivateCdId'
                            size="small"
                            value={formData.cnsgPrivateCdId}
                            style={{ width: '385px', marginLeft: '125px' }}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 荷届先コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '100px' }}>
                            {t('Text.ShipToPrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name='shipToPrivateCdId'
                            size="small"
                            value={formData.shipToPrivateCdId}
                            style={{ marginLeft: '145px', width: '385px' }}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 出荷場所コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>
                            {t('Text.ShipFromPrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name='shipFromPrivateCdId'
                            size="small"
                            value={formData.shipFromPrivateCdId}
                            style={{ width: '385px', marginLeft: '95px' }}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 発注番号 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '100px' }}>{t('Text.OrdNumId')}</Typography>
                        <TextField
                            name='ordNumId'
                            size="small"
                            value={formData.ordNumId}
                            style={{ marginLeft: '145px', width: '385px' }}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 納品伝票データ作成日付 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '180px' }}>
                            {formVouDateIssDttmDateChoose ? t('Text.VouDateIssDttm') : t('Text.VouDateIssDttmRelative')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '65px' }}>
                            {
                                formVouDateIssDttmDateChoose ?
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <StyledDatePicker
                                                name="vouDateIssDttm"
                                                value={formData.vouDateIssDttm ? moment(formData.vouDateIssDttm) : null}
                                                sx={{ width: '170px' }}
                                                format={"YYYY-MM-DD"}
                                                onChange={(valueNew) => {
                                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                                        handleTextFieldOnChange({ target: { value: "", name: "vouDateIssDttm" } })
                                                    } else {
                                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DD"), name: "vouDateIssDttm" } })
                                                    }
                                                }} />
                                        </LocalizationProvider>
                                    </> :
                                    <>
                                        <TextField
                                            name="vouDateIssDttmRelative"
                                            type="number"
                                            size="small"
                                            style={{ width: '150px' }}
                                            value={formData.vouDateIssDttmRelative}
                                            onChange={handleTextFieldOnChange}
                                            variant="outlined"
                                        />
                                        <span style={{ marginLeft: '10px' }}>
                                            {formData.vouDateIssDttmRelative?.length === 0 ? "" : (parseInt(formData.vouDateIssDttmRelative!) === 0 ? i18n.t('Text.TheDay') :
                                                (parseInt(formData.vouDateIssDttmRelative!) > 0 ? i18n.t('Text.DaysAfter') : i18n.t('Text.DaysBefore')))}
                                        </span>
                                    </>
                            }
                        </div>
                        <div style={{ marginLeft: '30px' }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkVouDateIssDttmDate"
                                            color="primary"
                                            checked={formVouDateIssDttmDateChoose}
                                            onChange={handleDateChooseChange} />
                                    }
                                    label={i18n.t('Text.SpecifiedDate')}
                                />
                            </FormGroup>
                        </div>
                    </div><br />
                    {/* 運送事業者コード(自社ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '130px' }}>
                            {t('Text.TrspCliPrivateCdId')}<br />
                            ({t('Text.InternalLocal')})
                        </Typography>
                        <TextField
                            name='trspCliPrivateCdId'
                            size="small"
                            value={formData.trspCliPrivateCdId}
                            style={{ marginLeft: '115px', width: '385px' }}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 受領書取得 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '100px' }}>{t('Text.FetchFlag')}</Typography>
                        <FormControlLabel
                            style={{ width: '78px', marginLeft: '135px' }}
                            control={
                                <IOSSwitch
                                    checked={formData.fetchFlag === "1" ? true : false}
                                    onChange={handleSwitch} />
                            }
                            label=""
                        />
                    </div><br />
                    {/* 取得フラグ */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: 'red', lineHeight: '10px' }}>*</span>
                        <Typography variant="body1" gutterBottom style={{ width: '240px' }}>{t('Text.ReceiptGetStatus')}</Typography>
                        <FormControlLabel
                            control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={formData.getStatus === "0"} onChange={handleCheckboxChange} name="未取得のみ" color="primary" />}
                            label="未取得のみ"
                        />
                        <FormControlLabel
                            control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={formData.getStatus === "1"} onChange={handleCheckboxChange} name="取得済のみ" color="primary" />}
                            label="取得済のみ"
                        />
                        <FormControlLabel
                            control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={formData.getStatus === "2"} onChange={handleCheckboxChange} name="すべて取得" color="primary" />}
                            label="すべて取得"
                        />
                    </div><br />
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" onClick={() => handleClick()} disabled={disableButton()}>
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => doClose()}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
