export const SPA_PATH = (subdomain: string | undefined) => {
    return `https://${subdomain}.spa-cloud.com/`
};

export const BASE_PATH = process.env.REACT_APP_ROUTE_MODE;

// HTTP Method POST
export const METHOD_POST = 'POST';
// HTTP Method GET
export const METHOD_GET = 'GET';
// HTTP Method PUT
export const METHOD_PUT = 'PUT';

/** API のURL */
// ログイン
// export const LOGIN_URL = SPA_PATH + '/service/auth/login';
export const LOGIN_URL = BASE_PATH + '/auth/login';
// ログアウト
export const LOGOUT_URL = BASE_PATH + '/auth/logout';

// Users Authorities (v7)
export const USER_AUTHORITIES_URL = (id: number | undefined) => {
    return `${BASE_PATH}/users_v7/${id}/authorities`
};
// CURRENT_GET_URL
export const CURRENT_GET_URL = BASE_PATH + '/sessions_v2/current';
// Stamp List
export const STAMP_LIST_URL = BASE_PATH + '/stamps/name/list';
// Stamp Preview Get
export const STAMP_PREVIEW_GET_URL =  (id: number | string) => {
    return BASE_PATH + `/stamps/name/preview/${id}`;
}
// Reviewer Stamp Get
export const REVIEWER_STAMP_GET_URL =  (reviewerId: number | string) => {
    return BASE_PATH + `/reviews/stamp/${reviewerId}`;
}
// 検索 V20
export const SEARCH_URL = BASE_PATH + '/search_v20/folder';
// SearchConditions List
export const SEARCH_CONDITION_LIST_URL = BASE_PATH + '/searchconditions';
// SearchValues Master List
export const SEARCH_VALUES_MASTER_LIST_URL = BASE_PATH + '/searchvalues/list';
// UI Review ViewColumns Get
export const PROFILES_LIBRARY_GET_URL = BASE_PATH + '/ui/profiles/library';
// UI ViewColumns Get
export const UI_VIEW_COLUMNS_GET_URL = BASE_PATH + '/ui/viewcolumns';
// Custom Properties List(v2)
export const CUSTOM_PROPERTIES_LIST_URL = BASE_PATH + '/properties_v2';

// Reviews List
export const REVIEWS_LIST_URL = BASE_PATH + '/reviews/list';

export const REVIEWS_LIST_V2_URL = BASE_PATH + '/reviews_v2/list';
// Reviews Get
export const REVIEWS_GET_URL = (id: number | string) => {
    return `${BASE_PATH}/reviews_v2/${id}`;
};
// REVIEWS_ACCEPT_URL
export const REVIEWS_ACCEPT_URL = (id: number | undefined) => {
    return `${BASE_PATH}/reviews/${id}/accept`;
};

// Preview Get (v4)
export const PREVIEW_GET_URL  = (id: number | undefined, page: number) => {  return `${BASE_PATH}/preview_v4/${id}/${page}`
};
// Preview Attributes Get (追跡記録の登録用)
export const PREVIEW_ATTRIBUTE_GET_URL  = (id: number) => {  return `${BASE_PATH}/ui/preview/attribute/${id}`
};
// Annotation Get
export const ANNOTATION_GET_URL  = (id: number | string, page: number) => `${BASE_PATH}/annotation/${id}/${page}`;
// Documents Comments Get
export const DOCUMENT_COMMENTS_GET_URL  = (id: number | undefined) => {  return `${BASE_PATH}/documents_v2/${id}/comments`
};
// Documents Comments Add
export const DOCUMENT_COMMENTS_ADD_URL  = (id: number | undefined) => {  return `${BASE_PATH}/documents/${id}/comments`
};

// SvfField Values Get(最後の/必要)
export const SVFFIELD_VALUES_GET_URL = BASE_PATH + '/svffieldvalues/';
// SvfField Values Update(一括処理)
export const SVFFIELD_VALUES_ALL_UPDATE_URL = BASE_PATH + '/svffieldvalues/all';
// Permission Get For Login User
export const PERMISSION_GET_LOGIN_USER_URL = BASE_PATH + '/permissions/user';

// Send List
export const SEND_FILTER_DATA = BASE_PATH + '/sendrecord/list';

// Doc Map
export const STYLENAME_URL = (id: number | String) => {
    return `${BASE_PATH}/doctype_v10/${id}?field=true`
};

export const DOCUMENT_SAVE_URL = BASE_PATH + '/mappingfile/deliver/update';
export const DOCUMENT_GET_URL = BASE_PATH + '/mappingfile/deliver/get';

export const RECEIVE_DOCUMENT_SAVE_URL = BASE_PATH + '/mappingfile/receive/update';
export const RECEIVE_DOCUMENT_GET_URL = BASE_PATH + '/mappingfile/receive/get';

export const DOCUMENT_DEFINITION_URL = BASE_PATH + '/doctype_v8/list';

// Connect Manage
export const MANAGE_SIP_INFO_GET = BASE_PATH + '/receiveaccount/list';
export const MANAGE_SIP_INFO_ADD = BASE_PATH + '/receiveaccount/insert';
export const MANAGE_SIP_INFO_EDIT = BASE_PATH + '/receiveaccount/update';
export const MANAGE_SIP_INFO_DELETE = BASE_PATH + '/receiveaccount/delete';
export const FOLDERS_LIST = BASE_PATH + '/folders_v4';

// Ia Manage
export const MANAGE_IA_GET = BASE_PATH + '/iaaccount/list';
export const MANAGE_IA_ADD = BASE_PATH + '/iaaccount/insert';
export const MANAGE_IA_EDIT = BASE_PATH + '/iaaccount/update';
export const MANAGE_IA_DELETE = BASE_PATH + '/iaaccount/delete';

// History Manage
export const MANAGE_HISTORY_GET = BASE_PATH + '/historysettings/list';
export const MANAGE_HISTORY_ADD = BASE_PATH + '/historysettings/insert';
export const MANAGE_HISTORY_EDIT = BASE_PATH + '/historysettings/update';

// Receipt Manage
export const MANAGE_RECEIPT_GET = BASE_PATH + '/receipt/list';
export const MANAGE_RECEIPT_ADD = BASE_PATH + '/receipt/insert';
export const MANAGE_RECEIPT_EDIT = BASE_PATH + '/receipt/update';
export const MANAGE_RECEIPT_DELETE = BASE_PATH + '/receipt/delete';

export const RECEIPT_FOLDER_LIST = BASE_PATH + '/folders/list';
export const RECEIPT_FOLDER_INSERT = BASE_PATH + '/folders/insert';
export const RECEIPT_FOLDER_UPDATE = BASE_PATH + '/folders/update';

// Delivery Manage
export const MANAGE_DELIVERY_GET = BASE_PATH + '/delivery/list';
export const MANAGE_DELIVERY_ADD = BASE_PATH + '/delivery/insert';
export const MANAGE_DELIVERY_EDIT = BASE_PATH + '/delivery/update';
export const MANAGE_DELIVERY_DELETE = BASE_PATH + '/delivery/delete';

export const DELIVERY_FOLDER_LIST = BASE_PATH + '/delivery/folders/list';
export const DELIVERY_FOLDER_INSERT = BASE_PATH + '/delivery/folders/insert';
export const DELIVERY_FOLDER_UPDATE = BASE_PATH + '/delivery/folders/update';

// Consignor Manage
export const MANAGE_CONSIGNOR_GET = BASE_PATH + '/consignor/list';
export const MANAGE_CONSIGNOR_ADD = BASE_PATH + '/consignor/insert';
export const MANAGE_CONSIGNOR_EDIT = BASE_PATH + '/consignor/update';
export const MANAGE_CONSIGNOR_DELETE = BASE_PATH + '/consignor/delete';

// HEADER
export const HEADER_ACCEPT_JSON  = {'Accept':'application/json'};
export const HEADER_CONTENTTYPE_JSON  = {'Content-Type': 'application/json'};
export const HEADER_CONTENTTYPE_URLENCODED  = {'Content-Type': 'application/x-www-form-urlencoded'}
export const HEADER_MULTIPART  = {'Content-Type': 'multipart/form-data'};





