import {Box} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";
import ValueCheckBox from "./ValueCheckBox";
import RangeSelect from "./RangeSelect";
import SearchValueTextField from "./SearchValueTextField";

/**
 * 数値型検索条件
 * @param props
 * @constructor
 */
const NumberCondition = (props: { templateIdx: number, detailIdx: number, conditionType: string, options: [] }) => {
    const {templateIdx, detailIdx, conditionType, options} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    //入力非活性フラグ
    const [disable, setDisable] = React.useState(false);
    //値なし・ありのチェックボックスが変わった時
    const valueCheckBoxChanged = (checked: boolean) => {
        setDisable(checked);
    }
    //Boxの表示切り替え
    const isVisible = (disable: boolean) => {
        return disable ? "hidden" : 'visible';
    }
    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey = (pre: string) => {
        return pre + '_' + templateIdx + '_' + detailIdx;
    }


    const renderCondition = () => {
        let ret: JSX.Element[] = [];
        ret.push(
            <>
                {/* */}
                <Box mr={2} display="flex" flexDirection="row" alignItems="center" visibility={isVisible(disable)}>
                    <SearchValueTextField key={getKey('searchValueTextFieldMin')} templateIdx={templateIdx}
                                          detailIdx={detailIdx}
                                          conditionType={conditionType} options={options} attrName={'min'}/>
                    <span>{t('Text.FromToDash')}</span>
                    <SearchValueTextField key={getKey('searchValueTextFieldMax')} templateIdx={templateIdx}
                                          detailIdx={detailIdx}
                                          conditionType={conditionType} options={options} attrName={'max'}/>
                    {/*<!-- 条件ドロップダウン 範囲 -->*/}
                    <RangeSelect key={getKey('rangeSelect')} templateIdx={templateIdx} detailIdx={detailIdx}
                                 conditionType={conditionType}/>
                </Box>
                <ValueCheckBox key={getKey('valueCheck')} templateIdx={templateIdx} detailIdx={detailIdx}
                               conditionType={conditionType}
                               valueTypeChanged={(value) => {
                                   valueCheckBoxChanged(value);
                               }}
                />
            </>
        );
        return ret;
    };

    return (
        // 詳細条件実行時指定 日付型 テンプレート
        <Box display="flex" flexDirection="row" alignItems="center" key={'number' + detailIdx}
             className="conditionsTemplateDetail" mx={2}>
            {renderCondition}
        </Box>

    );
};

export default NumberCondition;
