import React from 'react';
import { createContainer } from "unstated-next";
import { User } from "../models/User";
import { PreviewStampData } from "../models/PreviewStampData";
import * as WebConstants from "../constants/WebConstants";
import * as Constants from "../constants/Constants";
import { MSG_PIPE_STR } from "../constants/Constants";
import { useStampService } from '../service/StampService';
import { useUserAuthoritiesService } from '../service/UserAuthoritiesService';
import { UserAuthorities } from '../models/UserAuthorities';
import DocContainer from './DocContainer';
import { unstable_batchedUpdates } from 'react-dom';
import ServiceContainer from './AxiosServiceContainer';
import CommonContainer from './CommonContainer';
import ErrorContainer from './ErrorContainer';

const TANTOU_NAME_KEY = 'tantouName';

const useUserState = () => {
    const docCotainer = DocContainer.useContainer();
    const commonState = CommonContainer.useContainer();
    const errorState = ErrorContainer.useContainer();
    const { getUserAuthorities } = useUserAuthoritiesService();
    const { listStamp } = useStampService();
    const [user, setUser] = React.useState<User | null>(null);
    const [tenantId, setTenantId] = React.useState<string>();
    const [tantou, _setTantou] = React.useState<string | ''>(
        localStorage.getItem(TANTOU_NAME_KEY) ?? ''
    );
    const [subdomain, setSubdomain] = React.useState<string>();
    const [errorMsg, setErrorMsg] = React.useState<string | ''>('');
    const [stamps, _setStamps] = React.useState<any[]>();
    const [stamp, setStamp] = React.useState<PreviewStampData>();
    const [userAuthorities, setUserAuthorities] = React.useState<number[]>([]);

    const serviceState = ServiceContainer.useContainer();

    /**
     * ログイン
     */
    const signIn = async (domain: string | '', userid: string | '', password: string | '') => {
        let formBody = 'domain=' + encodeURIComponent(domain) + '&';
        formBody += 'user=' + encodeURIComponent(userid) + '&';
        formBody += 'password=' + encodeURIComponent(password);

        serviceState.request(
            WebConstants.LOGIN_URL,
            WebConstants.METHOD_POST,
            formBody,
            null,
            false
        ).then((response) => {
            console.log("****** response is *******", response);
        }).then((response) => {
            currentGet(false)
        }).catch((error) => {
            let msg = '';
            if (error.response.headers['x-spa-error-message']) {
                msg = error.response.headers['x-spa-error-code'] + MSG_PIPE_STR;
                msg += decodeURIComponent(error.response.headers['x-spa-error-message']);

            } else {
                msg = error.response.status + MSG_PIPE_STR;
            }

            setErrorMsg(msg);

            console.log("****** error is *******", error.response);
        }).then(() => {
            commonState.setProgress(false);
        })
    }

    /**
     * CurrentGet
     */
    const currentGet = async (catchError: boolean = true) => {
        serviceState.request(
            WebConstants.CURRENT_GET_URL,
            WebConstants.METHOD_GET,
            null,
            null,
            catchError
        ).then((response) => {
            if (localStorage.getItem("page") == "mainPage") {
                setStamps();
            }

            unstable_batchedUpdates(() => {
                setUser({ ...user, id: response.data.userId, displayName: response.data.fullName });
                docCotainer.setUserId(response.data.userId);
            })
            setTenantId(response.data.tenantId)
            setSubdomain(response.data.subdomain)
            errorState.setError(null);
            console.log("****** currentGet response is *******", response);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        })
    }

    /**
     * ログアウト
     */
    const signOut = async () => {
        serviceState.request(
            WebConstants.LOGOUT_URL,
            WebConstants.METHOD_POST,
            null,
        ).then((response) => {
            console.log("****** logout is *******");
            //   setUser(null); UIでやる
        }
        ).catch((error) => {
            console.log("****** error is *******", error.response);
        })
    }
    const setTantou = (name: string) => {
        _setTantou(name);
        localStorage.setItem(TANTOU_NAME_KEY, name);
    }

    const setStamps = async () => {
        try {
            const stamps = await listStamp();
            _setStamps(stamps);
            docCotainer.setStamps(stamps);
        } catch (error) {
            console.log("****** error is *******", (error as any).response);
        }
        // const stampForAccept = stamps.find((stp) => stp.name === Constants.ACCEPT_STAMP_NAME);
        // // if (stampForAccept?.id) {
        // //     setStamp(await StampService.getPreviewStampData(stampForAccept.id));
        // // }
    }


    const currentAuthorities = async () => {
        try {
            const userAuthor: any = (await getUserAuthorities(user?.id as number));
            setUserAuthorities(userAuthor.authorities);
        } catch (error) {
            console.log("****** error is *******", (error as any).response);
        }
    }

    return { user, setUser, tenantId, setTenantId, tantou, setTantou, subdomain, signIn, signOut, currentGet, errorMsg, setErrorMsg, stamps, currentAuthorities, userAuthorities }
}

const UserContainer = createContainer(useUserState);

export default UserContainer;
