import { Button, TextField} from "@material-ui/core";
import React, {Ref} from "react";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";
import SearchValueDialog from "../dialog/SearchValueDialog";

/**
 * 検索値表示ボタン付きテキストフィールド
 * @param props
 * @constructor
 */
const SearchValueTextField = (props: { templateIdx: number, detailIdx: number, conditionType: string,options:[],attrName:string }) => {
    const {templateIdx, detailIdx, conditionType,options,attrName} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
    const [searchValueDlgOpen, setSearchValueDlgOpen] = React.useState(false);
    const [value, setValue] = React.useState(detailCondition[attrName]);

    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey = (pre: string) => {
        return pre + '_' + templateIdx + '_' + detailIdx;
    }
    const handleInputChange = ( value: any) => {
        let temp = detailCondition;
        // @ts-ignore
        temp[attrName] = value;
        setDetailCondition(temp);
        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
        // @ts-ignore
        setValue(value);
    }
    /**
     *検索値ダイアログOpen
     */
    const handleDialogOpen = () => {
        setSearchValueDlgOpen(true);
    };
    /**
     *検索値ダイアログClose
     */
    const handleDialogClose = () => {
        setSearchValueDlgOpen(false);
    };
    /**
     *検索値ダイアログ：OK
     */
    const handleValueSelected = (value: string) => {
        handleInputChange( value);
        handleDialogClose();
    };
    {/*  render*/}
    return (
        <>
            {/*  テキストフィールド*/}
            <TextField variant="outlined"
                       key={getKey('text'+attrName)}
                       id={getKey('text'+attrName)}
                       value={value ? value : ''}
                /* styles the input component */
                       inputProps={{
                           style: {
                               height : '40px',
                               padding: '0 10px',
                               marginTop:'-2px'
                           },
                       }}
                       onChange={(e)=>handleInputChange(e.currentTarget.value)}
            />
            {/*ダイアログ表示ボタン*/}
            {(options.length > 0)?
                ( <><Button　variant="outlined" style={{maxWidth: '30px', maxHeight: '38px', minWidth: '30px', minHeight: '38px'}}
                            onClick={handleDialogOpen}>
                    {t('Text.FileChooserLabel')}
                </Button>
          {/*検索値ダイアログ*/}
            <SearchValueDialog id='searchValueDialog'
                               title={t('Text.SearchValuesDialogTitle')}
                               isOpen={searchValueDlgOpen}
                               ButtonText={t('Text.Ok')}
                               doSubmit={(value) => {
                                   handleValueSelected(value)
                               }}
                               doClose={() => handleDialogClose()}
                               data={options}/>
           </>     ) :null}
        </>
    );
}

export default SearchValueTextField;
