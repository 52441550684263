import { useState } from "react";
import { ErrorInfo } from "../models/ErrorInfo";
import { createContainer } from "unstated-next";
import Axios, { AxiosInstance, AxiosResponse } from "axios";
import * as Constants from "../constants/Constants";
import ErrorContainer from "./ErrorContainer";
import CommonContainer from "./CommonContainer";
import Cookies from "js-cookie";

const useServiceState = () => {

    const commonState = CommonContainer.useContainer();
    const errorState = ErrorContainer.useContainer()

    const getAuthToken = (): string => {
        return Cookies.get(Constants.X_AUTHORIZATION) ?? ""
    }
    const resetAuthToken = () => {
        Cookies.remove(Constants.X_AUTHORIZATION)
    }

    const handleSuccess = (response: AxiosResponse<any>) => {
        return response;
    }

    const handleError = (error: { response: { status: any; headers: { [key: string]: any } }; }) => {
        console.log("****** error is *******", error.response);
        let msg = '';
        let code = '-1';
        //待機画面終了
        if (error.response) {
            if (error.response.headers['x-spa-error-message']) {
                code = error.response.headers['x-spa-error-code']
                msg += decodeURIComponent(error.response.headers['x-spa-error-message']);

            } else {
                code = error.response.status;
                msg = '';
            }
        }
        errorState.setError(new ErrorInfo(code, msg));
        commonState.setProgress(false);
        return Promise.reject(error)
    }

    const unHandleError = (error: any) => {
        return Promise.reject(error)
    }

    const createService = (responseType?: any) => {
        let service = Axios.create({
            headers: {
                'X-Forwarded-Host': 'wat-ecodx02'
            }
        });
        if (!!responseType) {
            service.defaults.responseType = responseType
        }
        service.defaults.withCredentials = true;
        service.interceptors.response.use(handleSuccess, handleError);
        return service;
    }

    const createNonCatchService = (responseType?: any) => {
        let service = Axios.create({
            headers: {
                'X-Forwarded-Host': 'wat-ecodx02'
            }
        });
        if (!!responseType) {
            service.defaults.responseType = responseType
        }
        service.defaults.withCredentials = true;
        service.interceptors.response.use(handleSuccess, unHandleError);
        return service;
    }

    const catchErrorService: AxiosInstance = createService();
    const nonCatchService: AxiosInstance = createNonCatchService();

    const getXsrfToken = (): string => {
        return Cookies.get(Constants.TOKEN_PREFIX) ?? ""
    }

    const redirectTo = (document: { location: any; }, path: any) => {
        document.location = path
    }

    const get = (path: string, callback: (arg0: number, arg1: any) => any) => {
        return catchErrorService.get(path).then(
            (response) => callback(response.status, response.data)
        );
    }

    const patch = (path: any, payload: any, callback: (arg0: number, arg1: any) => any) => {
        return catchErrorService.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    const post = (path: any, payload: any, callback: (arg0: number, arg1: any) => any) => {
        return catchErrorService.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    const request = (url: any, method: any, data: any, header?: any | null, catchError: boolean = true, responseType?: any) => {
        if (header === undefined || header === null) {
            header = {};
        }

        let dktxToken = getAuthToken()
        if (!!dktxToken) {
            header[Constants.X_AUTHORIZATION] = dktxToken
        }

        let xsrfToken = getXsrfToken()
        if (xsrfToken.length > 0) {
            header[Constants.X_REQUESTED_WITH_KEY] = Constants.X_REQUESTED_WITH_VALUE;
            header[Constants.XSRF_TOKEN_KEY] = xsrfToken;
            header[Constants.X_AUTHORIZATION] = getAuthToken()
        }
        return catchError ?
            catchErrorService({
                method: method,
                url: url,
                data: data,
                headers: header,
                responseType
            })
            :
            nonCatchService({
                method: method,
                url: url,
                data: data,
                headers: header,
                responseType
            })
    }

    return {
        redirectTo,
        get, patch, post, request,
        getAuthToken, resetAuthToken,
    }
}

const ServiceContainer = createContainer(useServiceState);

export default ServiceContainer;