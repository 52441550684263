import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import { ErrorOutline } from "@material-ui/icons";

type Props = {
    title: string;
    isOpen: boolean;
    content: string
    doClose: () => void;
};
export const AleartDialog: React.FC<Props> = ({
    title,
    isOpen,
    content,

    doClose,
}) => {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = () => {

        setOpen(false);
        doClose();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <ErrorOutline style={{ color: 'red' }} fontSize="large" /> {content}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary">
                        {t('Text.Ok')}
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
};

