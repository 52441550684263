import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";

type Props = {
    isOpen: boolean;
    content: string;
    doClose: () => void;
    statusCode?: number;
};

/**
 * SnackBar5秒で消えるインフォメーション
 * @param isOpen
 * @param content
 * @constructor
 */
const CustomInfoSnackBar: React.FC<Props> = ({
    isOpen,
    content,
    doClose,
    statusCode
}) => {

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    const [open, setOpen] = React.useState(false);

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        setOpen(false);
        doClose();
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={statusCode === 200 ? 'success' : 'error'}>
                    {content}
                </Alert>
            </Snackbar>
        </>
    );
}
export default CustomInfoSnackBar;
