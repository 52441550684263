import {
    FormControl,
    MenuItem,
    Select
} from "@material-ui/core";
import React, {Ref} from "react";
import {useTranslation} from "react-i18next";
import SearchOptionService from "../../service/SearchOptionService";
import DocContainer from "../../container/DocContainer";

/**
 * 文字の一致条件コンボボックス
 * @param props
 * @constructor
 */
const TextMatchSelect = (props: { templateIdx: number, detailIdx: number, conditionType: string }) => {
    const {templateIdx, detailIdx, conditionType} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
    const [textMatch, setTextMatch] = React.useState(detailCondition.textMatch);
    const templateOption = SearchOptionService.getTemplateOption();

    const handleChange = (event: object) => {
        let temp = detailCondition;
        // @ts-ignore
        temp.textMatch = event.target.value;
        setDetailCondition(temp);
        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
        // @ts-ignore
        setTextMatch(event.target.value);
    }
    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey = (pre: string) => {
        return pre + '_' + templateIdx + '_' + detailIdx;
    }


    {/*<!--  条件ドロップダウン テキスト一致-->*/
    }
    return (
            <FormControl  key={getKey('textMatchFormControl')} variant="outlined"  margin='dense'>
                <Select key={getKey('textMatchDropDown')}
                        style={{width:'180px'}}
                        value={textMatch}
                        onChange={handleChange}
                        displayEmpty
                >
                    {templateOption.textMatchOptions.map(({label, value}: any) => {
                        return (<MenuItem key={value} value={value}>{t(label)}</MenuItem>);
                    })
                    }

                </Select>
            </FormControl>
    );
};

export default TextMatchSelect;
