import { Divider, IconButton, InputBase } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Word from "../../common/Word";
import { useManageService } from "../../service/ManageService";
import CommonContainer from "../../container/CommonContainer";
import { SipInfo } from "../../models/SipInfo";
import UserContainer from "../../container/UserContainer";
import { table, useStyles } from "./css/Style";
import { ManagementDeleteDialog } from "../dialog/ManagementDeleteDialog";
import { SipDialog } from "../dialog/SipDialog";
import { ConfirmPasswordDialog } from "../dialog/ConfirmPasswordDialog";

export default function SipList(props: { setCallBackMessage: (arg0: { status: number; message: string; open: boolean }) => void; }) {
    // css
    const classes = useStyles();

    const commonState = CommonContainer.useContainer();
    const userState = UserContainer.useContainer();

    const { sipGetDataApi, sipAddDataApi, sipDeleteDataApi, sipEditDataApi } = useManageService();

    const { t, i18n } = useTranslation();

    const [addData, setAddData] = React.useState<SipInfo>({});
    const [editData, setEditData] = React.useState<SipInfo>({});
    const [rowSelected, setRowSelected] = React.useState<any[]>([]);
    const [OpenEdit, setOpenEdit] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = React.useState<any>({});
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState<SipInfo[]>([]);
    const [openPassword, setOpenPassword] = React.useState(false);
    const [password, setPassword] = React.useState<string>("");
    const [openAdd, setOpenAdd] = React.useState(false);
    const [hidePassword, setHidePassword] = React.useState<string>("");
    const [tableColumns, setTableColumns] = React.useState(
        [
            {
                label: i18n.t('Text.Name'),
                name: "name"
            },
            {
                label: i18n.t('Text.UserID'),
                name: "userId"
            },
            {
                label: i18n.t('Text.Password'),
                name: "passWord",
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        return (
                            <InputBase type='password' value={"**********"} inputProps={{ readOnly: true }} />
                        );
                    }
                }
            },
            {
                label: i18n.t('Text.Url'),
                name: "url"
            },
            {
                label: i18n.t('Text.TenantID'),
                name: "sipTenantId"
            },
            {
                label: i18n.t('Text.ClientID'),
                name: "clientId"
            },
            {
                label: i18n.t('Text.RetransmissionsNumber'),
                name: "reSendCount"
            }
        ]
    );

    const options: any = {
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: false,
        search: false,
        sort: true,
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        onRowSelectionChange: (currentRowsSelected: any[] | undefined, allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            if (allRowsSelected !== undefined) {
                setRowSelected(rowsSelected as any[]);
            }
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: '',
                columnHeaderTooltip: '',
            }
        }
    };

    useEffect(() => {
        getListData();
    }, [])

    useEffect(() => {
        if (rowSelected.length === 1) {
            setSelectedRowData({ ...tableData[rowSelected[0]], password: "" });
        }
    }, [rowSelected])

    /**
     * 伝票送受信の接続の新規ダイアログを開く
     */
    const addOpen = () => {
        setOpenAdd(true)
        setHidePassword("");
    }

    /**
     * 伝票送受信の接続の編集ダイアログを開く
     */
    const editOpen = () => {
        setOpenEdit(true);
        setHidePassword("**********");
        setEditData(Object.assign({}, selectedRowData));
    }

    /**
     * 伝票送受信の接続情報取得
     */
    const getListData = () => {
        commonState.setProgress(true);
        sipGetDataApi(userState.tenantId as string).then(responses => {
            setTableData(responses);
            setRowSelected([]);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
            resetData();
        })
    }

    /**
     * 伝票送受信の接続情報新規
     */
    const handleAddButtonOnClick = (addData: SipInfo) => {
        let rule = /^(0|\+?[1-9][0-9]*)$/;
        commonState.setProgress(true);
        if (!rule.test(addData.reSendCount!)) {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.WrongFormat'), open: true });
            commonState.setProgress(false);
            return;
        }
        setOpenAdd(false);
        sipAddDataApi({ tenantId: userState.tenantId, ...addData }).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.AddSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.AddFailed'), open: true });
        }).finally(() => {
            setPassword("");
            setHidePassword("");
            getListData();
        })
    }

    /**
     * 伝票送受信の接続情報編集
     */
    const handleEditButtonOnClick = (editData: SipInfo) => {
        let rule = /^(0|\+?[1-9][0-9]*)$/;
        commonState.setProgress(true);
        if (!rule.test(editData.reSendCount!)) {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.WrongFormat'), open: true });
            commonState.setProgress(false);
            return;
        }
        setOpenEdit(false);
        sipEditDataApi(editData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.EditSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.EditFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * 削除操作の実行
     * @param value タブに対応する番号
     */
    const handleDeleteButtonOnClick = () => {
        sipDeleteDataApi(tableData[rowSelected[0]]).then((data) => {
            setOpenDeleteAlert(false);
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.DeleteSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.DeleteFailed'), open: true });
            console.log("****** error is ******", error.response);
        }).finally(() => {
            getListData();
        });
    }

    /**
     * 追加ダイアログを閉じる
     */
    const handleAddClose = () => {
        setOpenAdd(false);
        setPassword("");
        setHidePassword("");
        resetData();
    }

    const resetData = () => {
        setAddData({
            name: "",
            userId: "",
            password: "",
            url: "",
            sipTenantId: "",
            clientId: "",
            reSendCount: "1"
        })
    }

    /**
     * パスワードの確認
     */
    const confirmPassword = (passwordList: any) => {
        if (passwordList.password !== passwordList.confirmPassword) {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.ConfirmPassword'), open: true });
            return;
        }
        if ((!passwordList.password && !passwordList.confirmPassword) || passwordList.password!.length > 32) {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.CheckPasswordFormat'), open: true });
            return;
        }
        setPassword(passwordList.password);
        setHidePassword("**********");
        setOpenPassword(false);
    }

    return (
        <>
            <div className="TabPanel" style={{ height: 'auto', width: '100%' }}>
                <div className={classes.tabTitle}>{t('Text.ContinuousManagement')}</div>
                <Divider />
                <div className={classes.iconStyle}>
                    <IconButton onClick={() => addOpen()} disabled={tableData.length !== 0}>
                        <AddIcon className={tableData.length === 0 ? classes.addButtonActived : classes.addButtonDisabled} />
                    </IconButton>
                    <IconButton onClick={editOpen} disabled={rowSelected.length === 0}>
                        <EditIcon className={rowSelected.length !== 0 ? classes.editButtonActived : classes.editButtonDisabled} />
                    </IconButton>
                    <IconButton onClick={() => setOpenDeleteAlert(true)} disabled={rowSelected.length === 0}>
                        <CloseIcon className={rowSelected.length !== 0 ? classes.deleteButtonActived : classes.deleteButtonDisabled} />
                    </IconButton>
                </div>
                <Divider />
                <div style={{ textAlign: 'left', padding: '10px 10px 0 20px' }}>{t('Text.ContinuousPagePrompt')}</div>
                <div style={{ width: '100%', backgroundColor: 'white', padding: '20px 10px 20px 10px' }}>
                    <MuiThemeProvider theme={table()}>
                        <MUIDataTable
                            columns={tableColumns}
                            data={tableData}
                            title={undefined}
                            options={options}>
                        </MUIDataTable>
                    </MuiThemeProvider>
                </div>
            </div>

            {/* 伝票送受信の接続管理の新規 */}
            <SipDialog
                title={t('Text.New')}
                action={'add'}
                isOpen={openAdd}
                data={addData}
                password={password}
                hidePassword={hidePassword}
                doClose={() => handleAddClose()}
                doClick={(addData) => handleAddButtonOnClick(addData)}
                openConfirmPassword={() => setOpenPassword(true)}
            />

            {/* 伝票送受信の接続管理の編集 */}
            <SipDialog
                title={t('Text.Edit')}
                action={'edit'}
                isOpen={OpenEdit}
                data={editData}
                password={password}
                hidePassword={hidePassword}
                doClose={() => setOpenEdit(false)}
                doClick={(editData) => handleEditButtonOnClick(editData)}
                openConfirmPassword={() => setOpenPassword(true)}
            />

            {/* 削除のヒント */}
            <ManagementDeleteDialog
                isOpen={openDeleteAlert}
                doClose={() => setOpenDeleteAlert(false)}
                deleteButtonOnClick={() => handleDeleteButtonOnClick()}
            />

            {/* パスワード入力ボックス */}
            <ConfirmPasswordDialog
                title={t('Text.Password')}
                isOpen={openPassword}
                confirmPassword={(passwordList) => confirmPassword(passwordList)}
                doClose={() => setOpenPassword(false)}
            />
        </>
    )
}