export class ErrorInfo {
    get msg(): string {
        return <string>this._msg;
    }

    set msg(value: string) {
        this._msg = value;
    }

    get code(): string {
        return <string>this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    private _code?: string;
    private _msg?: string;

    constructor(code: string | undefined, message: string | undefined) {
        this._code = code;
        this._msg = message;
    }
}
