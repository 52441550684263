import {Box} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";
import AmbiguitySelect from "./AmbiguitySelect";
import TextMatchSelect from "./TextMatchSelect";
import ValueCheckBox from "./ValueCheckBox";
import SearchValueTextField from "./SearchValueTextField";

/**
 * 文字列型検索条件
 * @param props
 * @constructor
 */
const TextCondition = (props: { templateIdx: number, detailIdx: number, conditionType: string, options: [] }) => {
    const {templateIdx, detailIdx, conditionType, options} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    //入力非活性フラグ
    const [disable, setDisable] = React.useState(false);
    //値なし・ありのチェックボックスが変わった時
    const valueCheckBoxChanged = (checked: boolean) => {
        setDisable(checked);
    }
    //Boxの表示切り替え
    const isVisible = (disable: boolean) => {
        return disable ? "hidden" : 'visible';
    }
    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey = (pre: string) => {
        return pre + '_' + templateIdx + '_' + detailIdx;
    }



    const renderCondition = () => {
        let ret: JSX.Element[] = [];
        ret.push(
                /*  <!-- 条件ドロップダウン あいまい -->*/
                <Box key={getKey('textConditionBox')} display="flex" flexDirection="row"  alignItems="center"　 mr={2} visibility={isVisible(disable)}>

                <AmbiguitySelect key={getKey('ambiguitySelect')} templateIdx={templateIdx} detailIdx={detailIdx}
                                 conditionType={conditionType}/>
                <SearchValueTextField key={getKey('searchValueTextField')} templateIdx={templateIdx} detailIdx={detailIdx}
                                      conditionType={conditionType}options={options}attrName={'value'}/>
                {/* <!-- 条件ドロップダウン テキスト一致 -->*/}
                <TextMatchSelect key={getKey('textMatchSelect')} templateIdx={templateIdx} detailIdx={detailIdx}
                                 conditionType={conditionType}/>
                {/* <!-- 値なし  値あり-->*/}
                <ValueCheckBox key={getKey('valueCheck')} templateIdx={templateIdx} detailIdx={detailIdx}
                               conditionType={conditionType}     valueTypeChanged={(value) => {
                    valueCheckBoxChanged(value);
                }}/>
                </Box>
        );
        return ret;
    }

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" key={'date' + detailIdx}
                 className="conditionsTemplateDetail" mx={2}>
                {renderCondition}
            </Box>


        </>
    );
};

export default TextCondition;
