export default function UTCTimeFilter( val: any) {

        var ret = val;
        if (val != null) {
            var date_offset = 0;
            var browser_offset = -new Date().getTimezoneOffset();

            if (typeof val === "string") {
                //カスタムプロパティのタイムゾーンあり
                //yyyyMMddHHmmss
                //yyyyMMddHHmm,
                if ((val.length === 12) || (val.length === 14)) {

                    var yyyy = val.substring(0, 4);
                    var mm = val.substring(4, 6);
                    var dd = val.substring(6, 8);
                    var hh = val.substring(8, 10);
                    var m = val.substring(10, 12);
                    var ss = 0;
                    if (val.length > 13) {
                        // @ts-ignore
                        ss = val.substring(12, 14);
                    }
                    // @ts-ignore
                    var date = new Date(yyyy, mm - 1, dd, hh, m, ss);

                    ret = zeroPadding(date.getUTCFullYear(), 4);
                    ret += zeroPadding(date.getUTCMonth() + 1, 2);
                    ret += zeroPadding(date.getUTCDate(), 2);
                    ret += zeroPadding(date.getUTCHours(), 2);
                    ret += zeroPadding(date.getUTCMinutes(), 2);

                    if (val.length > 13) {
                        ret += zeroPadding(date.getUTCSeconds(), 2);
                    }

                } else {
                    var hasTimezone = false;
                    if (val.indexOf('T') > 0) {
                        hasTimezone = true;
                    }
                    val = Date.parse(val);
                    if (hasTimezone) {
                        date_offset = new Date(val).getTimezoneOffset() * -1;
                    }
                    var offset = (browser_offset - date_offset) * 60000;
                    ret = val - offset;

                }
            }

        }

        return ret;
}
    //指定した桁数でゼロパディングする
    function zeroPadding(val: string | number, digit: number) {
        var zero = '';
        for (var i = 0; i < digit; i++) {
            zero += '0';
        }
        val = val.toString();
        val = (zero + val ).slice( digit * -1 );
        return val;
    }


