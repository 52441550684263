import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";


/**
 * 担当者ダイアログのテキストボックスの部分
 */

type Props = {
    content: string,
    defaultValue: string
    inputRef: any
};

const TantouInputContent: React.FC<Props> = ({
                                                 content,
                                                 defaultValue,
                                                 inputRef
                                             }) => {
    const {t, i18n} = useTranslation();

    return (
        <DialogContent>
                {content}
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label={t('Text.Tantou')}
                    type="string"
                    fullWidth
                    defaultValue={defaultValue}
                    inputRef={inputRef}
                />
        </DialogContent>);
}

export default TantouInputContent;
