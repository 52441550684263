import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MuiThemeProvider, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { textFieldStyle } from "../manage/css/Style";
import { ReceiptData } from "../../models/ReceiptData";
import UserContainer from "../../container/UserContainer";
import { ConsignorData } from "../../models/ConsignorData";

type Props = {
    title: string;
    isOpen: boolean;
    action: string;
    cdList: any;
    data: ReceiptData;
    doClose: () => void;
    doClick: (data: any) => void;
};

// 最大フィールド長
const MAX_LENGTHS = {
    cd: 13,
    name: 80,
    postcode: 7,
    address: 80,
    tel: 13,
    sipConvRuleId: 12,
    sipSrcLocationCode: 13
};

export const ConsignorDialog: React.FC<Props> = ({ title, isOpen, action, data, cdList, doClose, doClick }) => {
    const userState = UserContainer.useContainer();

    const [open, setOpen] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<ConsignorData>({});
    const [cdRepeat, setCdRepeat] = React.useState<boolean>(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setOpen(isOpen);
        setCdRepeat(false);
    }, [isOpen])

    useEffect(() => {
        setFormData(data)
    }, [data])

    /**
     * 入力ボックスのデータ変更に対応
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any }) => {
        const { target: { value, name } } = e;
        const maxLength = MAX_LENGTHS[name as keyof typeof MAX_LENGTHS];
        let newValue = value.length > maxLength ? value.substring(0, maxLength) : value;
        if (name === "cd" && cdList.indexOf(newValue) > -1) {
            setCdRepeat(true);
        } else {
            if (value[0] === " ") {
                return;
            }
            setCdRepeat(false);
        }
        setFormData({ ...formData, [name]: newValue });
    }

    const handleClick = () => {
        const data = { tenantId: userState.tenantId, ...formData }
        doClick(data);
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{title}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <MuiThemeProvider theme={textFieldStyle()}>
                        <div style={{ position: 'relative', left: '60px', width: '70px' }}>
                            {/* 基本情報 */}
                            <Typography variant="body1" gutterBottom={true}>{t('Text.BasicInfo')}</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* 荷送人コード(ローカル) */}
                            <Typography variant="body1" gutterBottom style={{ width: '215px' }}>
                                <span style={{ color: 'red', position: 'relative', top: '2px' }}>*</span>
                                {t('Text.CnsgPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")"}
                            </Typography>
                            <TextField
                                name='cd'
                                size="small"
                                style={{ width: '240px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.cd}
                                variant="outlined"
                                disabled={action === "edit" ? true : false}
                            />
                        </div>
                        {cdRepeat ? <span style={{ marginLeft: "215px", fontSize: '13px', color: 'red' }}>{t('Msg.CdRepeat')}</span> : ""}
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* 荷送人名（漢字） */}
                            <Typography variant="body1" gutterBottom style={{ width: 320 }}>
                                {t('Text.CnsgPrivateName')}
                            </Typography>
                            <TextField
                                name='name'
                                size="small"
                                style={{ width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.name}
                                variant="outlined"
                            />
                        </div><br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* 荷送人郵便番号 */}
                            <Typography variant="body1" gutterBottom style={{ width: '215px' }}>
                                {t('Text.CnsgPstcCd')}
                            </Typography>
                            <TextField
                                name='postcode'
                                size="small"
                                style={{ width: '180px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.postcode}
                                variant="outlined"
                            />
                        </div><br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* 荷送人住所（漢字） */}
                            <Typography variant="body1" gutterBottom style={{ width: '320px' }}>
                                {t('Text.CnsgPstlAdrs')}
                            </Typography>
                            <TextField
                                name='address'
                                size="small"
                                style={{ width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.address}
                                variant="outlined"
                            />
                        </div><br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* 荷送人電話番号 */}
                            <Typography variant="body1" gutterBottom style={{ width: '215px' }}>
                                {t('Text.CnsgTelNum')}
                            </Typography>
                            <TextField
                                name='tel'
                                size="small"
                                style={{ width: '180px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.tel}
                                variant="outlined"
                            />
                        </div><br />
                        <div style={{ position: 'relative', left: '60px', width: '80px' }}>
                            {/* SIP連携用 */}
                            <Typography variant="body1" gutterBottom={true}>{t('Text.SipCooperation')}</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* SIP連携を行う場合は、以下の項目を設定してください。 */}
                            <Typography variant="body1" gutterBottom={true}>{t('Text.SipCooperInfo')}</Typography>
                        </div><br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* SIP基盤変換ルールID */}
                            <Typography variant="body1" gutterBottom style={{ width: '215px' }}>
                                {t('Text.ConvRuleId')}
                            </Typography>
                            <TextField
                                name='sipConvRuleId'
                                size="small"
                                style={{ width: '180px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.sipConvRuleId}
                                variant="outlined"
                            />
                        </div><br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* SIP基盤発送元拠点コード */}
                            <Typography variant="body1" gutterBottom style={{ width: '215px' }}>
                                {t('Text.SrcLocationCd')}
                            </Typography>
                            <TextField
                                name='sipSrcLocationCode'
                                size="small"
                                style={{ width: '180px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.sipSrcLocationCode}
                                variant="outlined"
                            />
                        </div><br />
                    </MuiThemeProvider>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" onClick={() => handleClick()} disabled={cdRepeat || !formData.cd}>
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => doClose()}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
