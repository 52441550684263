
export function GetTimeZoneDate(date: Date, timeZone: string, changeDayNum: number) {
    let tmpDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: timeZone
    };
    tmpDate.setDate(tmpDate.getDate() + (changeDayNum));
    const fixedTimeZoneDate = Intl.DateTimeFormat('ja-JP', options).format(tmpDate);
    const [datePart, timePart] = fixedTimeZoneDate.split(' ');
    const datePartFormat = datePart.replaceAll('/', '-');
    return datePartFormat;
}

export function TransformDateToNumber(date: string | undefined) {
    const localDate: Date = new Date();
    const transformDate = new Date(date as string);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };
    const localDateStringInTimeZone = Intl.DateTimeFormat('ja-JP', options).format(localDate);
    const localDateInTimeZone = new Date(localDateStringInTimeZone);
    var day = (transformDate.getTime() - localDateInTimeZone.getTime()) / (1000 * 3600 * 24);
    if (day > 0) {
        day = Math.ceil(Math.abs(day));
    } else {
        day = Math.floor(Math.abs(day)) * -1;
    }
    return day.toString();
}