import React from 'react';
import {useTranslation} from "react-i18next";

/**
 * リソースをComponet以外から参照したいための関数
 * @param key
 * @constructor
 */
export default function Word(key:string) {
    const {t, i18n} = useTranslation();
    return t(key);
}
