import React, {FC, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";

type Props = {
    id:string,
    title: string;
    isOpen: boolean;
    content: string
    ButtonText: string;
    doClose: (isOK: boolean) => void;
};
export const ConfirmDialog: React.FC<Props> = ({    id,
                                                   title,
                                                   isOpen,
                                                   content,
                                                   ButtonText,
                                                   doClose,
                                               }) => {
    const [open, setOpen] = useState(false);
    const {t, i18n} = useTranslation();
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = (isOK:boolean) => {

        setOpen(false);
        doClose(isOK);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose(true)} color="primary">
                        {ButtonText}
                    </Button>
                    <Button onClick={()=>handleClose(false)} color="primary">
                        {t('Text.Cancel')}
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
};

