import { useState } from "react";
import { createContainer } from "unstated-next";
import { ErrorInfo } from "../models/ErrorInfo";

const useDocState = () => {

    const [error, setError] = useState<ErrorInfo | null>(null);

    return {
        error, setError
    }
}

const ErrorContainer = createContainer(useDocState);

export default ErrorContainer;