import * as WebConstants from "../constants/WebConstants";
import { ReviewInfo } from "../models/ReviewInfo";
import { useStampService } from "./StampService";
import { StampInfo } from "../models/StampInfo";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * レビュー API
 */
export const useReviewService = () => {
    const { getPreviewStampData, stampHeight, stampWidth } = useStampService()
    const serviceState = ServiceContainer.useContainer()

    const reviewAccept = async (id: number, comment: string, stamp?: StampInfo, coordinate?: { x: number; y: number }) => {
        let form = new FormData();
        form.append('comment', comment);
        if (stamp && stamp.id && stamp.uiParams && coordinate) {
            const stampData = await getPreviewStampData(stamp.id);
            if (stampData.data) {
                // base64画像からBlobを作成
                const bin = atob(stampData.data.replace(/^.*,/, ''));
                const buffer = new Uint8Array(bin.length);
                for (var i = 0; i < bin.length; i++) {
                    buffer[i] = bin.charCodeAt(i);
                }
                const blob = new Blob([buffer.buffer], {
                    type: 'image/png'
                });
                const width = stamp.uiParams.userSize.width ?? stampWidth;
                const height = stamp.uiParams.userSize.height ?? stampHeight;
                const stampInfo = JSON.stringify({
                    page: 1,
                    width: Math.round(width * 96 / 25.4), // mm -> px(96dpi)
                    height: Math.round(height * 96 / 25.4),
                    ...coordinate,
                })
                form.append('stampAll', 'true');
                form.append('stampImage', blob);
                form.append('stampInfo', stampInfo);
            }
        }
        return serviceState.request(
            WebConstants.REVIEWS_ACCEPT_URL(id),
            WebConstants.METHOD_POST,
            form,
            { 'Content-type': undefined }
        ).then((response) => {
            console.log("****** reviewsAccept is *******", id, response);
            return response;
            //       }
            //   ).catch((error) => {
            //       handleError(error);
            //       return error;
            //       console.log("****** error is *******", error.response);
        })

    }

    const reviewList = (status: any) => {
        return serviceState.request(
            WebConstants.REVIEWS_LIST_URL,
            WebConstants.METHOD_POST,
            status,
        ).then((response) => {
            console.log("****** reviewsList is *******", response);
            return response.data.reviewList;
        })
    }

    const reviewGet = (id: number | string) => {
        return serviceState.request(
            WebConstants.REVIEWS_GET_URL(id),
            WebConstants.METHOD_GET,
            null
        ).then((response) => {
            return response.data as ReviewInfo
        })
    }

    return {
        reviewAccept, reviewGet, reviewList
    }
}