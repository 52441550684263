import { Button, Checkbox, Divider, Grid, IconButton, InputBase, List, ListItem, ListSubheader, NativeSelect, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography, makeStyles, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DocMapContainer from "../container/DocMapContainer";
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useEffect } from "react";
import DocMapModel, { DocMapType } from "../models/DocMapModel";
import { DialogResult, SipProjectItem, SipStandardMessage } from "../models/SipStandardMessage";
import { Add } from "@material-ui/icons";
import UserContainer from "../container/UserContainer";
import { SelfDialog } from "./dialog/SelfDialog";
import CustomInfoSnackBar from "./dialog/CustomInfoSnackBar";
import CommonContainer from "../container/CommonContainer";

const DocMappingManage = () => {
    const { t, i18n } = useTranslation();
    const docMapState = DocMapContainer.useContainer();
    const userState = UserContainer.useContainer();
    const commonState = CommonContainer.useContainer();

    // data

    const [formNameDialogVisible, setFormNameDialogVisible] = React.useState<boolean>(false);
    const [docNameDialogVisible, setDocNameDialogVisible] = React.useState<boolean>(false);
    const [tempFormNameValue, setTempFormNameValue] = React.useState<string>("");
    const [saveDialogVisible, setSaveDialogVisible] = React.useState<boolean>(false);
    const [dataIntegrityDialogVisible, setDataIntegrityDialogVisible] = React.useState<boolean>(false);
    const [docName, setDocName] = React.useState<string>("");

    const commonTableIndeterminate: boolean = docMapState.commonDocMapList.filter((val) => { return !val.isDefault && val.checked }).length > 0;
    const repeatTableIndeterminate: boolean = docMapState.repeatDocMapList.filter((val) => { return !val.isDefault && val.checked }).length > 0;

    const hideCommonCheckbox = !docMapState.commonDocMapList.some((val) => { return !val.isDefault });
    const hideRepeatCheckbox = !docMapState.repeatDocMapList.some((val) => { return !val.isDefault });

    useEffect(() => {
        commonState.setProgress(true);
        // 定義名情報を取得して
        docMapState.initDocDefList();
        docMapState.initSipProjectDirectory();
        return () => {
            docMapState.setCurrentDocDef(undefined);
            docMapState.setFormNameValue("");
            docMapState.setFormMappingNameValue("");
        }
    }, [])

    useEffect(() => {
        if (!!docMapState.currentDocDef?.id) {
            docMapState.setCommonTableChecked(false);
            docMapState.setRepeatTableChecked(false);
            docMapState.setFormNameValue("");
            docMapState.setFormMappingNameValue("");
            docMapState.initDocMapFieldList(docMapState.currentDocDef?.id, userState.tenantId!);
        }
    }, [docMapState.currentDocDef])

    useEffect(() => {
        // IA文書管理情報を取得して
        docMapState.filterDocMapFieldList(docMapState.formNameValue);
        docMapState.setCommonTableChecked(false);
        docMapState.setRepeatTableChecked(false);
        if (!!!docMapState.formMappingNameValue) {
            resetData();
        }
        if (!!docMapState.formNameValue && !!docMapState.formMappingNameValue) {
            if (docMapState.formNameValue !== docMapState.formMappingNameValue) {
                resetData();
            } else {
                docMapState.initDocMapFieldList(docMapState.currentDocDef?.id, userState.tenantId!);
            }
        }
    }, [docMapState.formNameValue])

    /**
     * 保存ボタンクリック
     */
    const onSave = () => {
        const docCommonMap = docMapState.commonDocMapList.filter(item => item.defaultValue === "");
        const docRepeatMap = docMapState.repeatDocMapList;
        for (let i = 0; i < docCommonMap.length; i++) {
            if (docCommonMap[i].defineName === "" || docCommonMap[i].sipInfrastructure === "" || docCommonMap[i].iaDocumentManagement === "" && docCommonMap[i].sipInfrastructure !== "pdf_base64") {
                setDataIntegrityDialogVisible(true);
                return;
            }
        }
        for (let i = 0; i < docRepeatMap.length; i++) {
            if (docRepeatMap[i].defineName === "" || docRepeatMap[i].sipInfrastructure === "" || docRepeatMap[i].iaDocumentManagement === "") {
                setDataIntegrityDialogVisible(true);
                return;
            }
        }

        setSaveDialogVisible(true);
    }

    /**
     * 保存ロジックの処理
     * @param param 結果：ok cancel
     */
    const handleSave = (param: DialogResult) => {
        switch (param) {
            case 'cancel':
                setSaveDialogVisible(false);
                break;
            case 'ok':
                let json = Object.create({});
                json['formName'] = docMapState.formNameValue;
                json['tenantId'] = userState.tenantId;
                json['docTypeId'] = docMapState.currentDocDef.id;
                let dataArray = [];
                dataArray.push(...docMapState.commonDocMapList.map(docMap => {
                    let obj = Object.create({});
                    obj['toType'] = docMap.defineName;
                    obj['toField'] = docMap.sipInfrastructure;
                    obj['svfField'] = docMap.iaDocumentManagement;
                    obj['defaultValue'] = docMap.defaultValue;
                    return obj;
                }))
                dataArray.push(...docMapState.repeatDocMapList.map(docMap => {
                    let obj = Object.create({});
                    obj['toType'] = docMap.defineName;
                    obj['toField'] = docMap.sipInfrastructure;
                    obj['svfField'] = docMap.iaDocumentManagement;
                    obj['defaultValue'] = docMap.defaultValue;
                    return obj;
                }))
                json['mapData'] = dataArray;
                setSaveDialogVisible(false);
                docMapState.saveDocMap(json);
                break;
            default:
                setSaveDialogVisible(false);
                break;
        }
    }

    const resetData = () => {
        let commonRowList = docMapState.commonDocMapList;
        commonRowList.forEach(row => row.iaDocumentManagement = '');
        commonRowList = commonRowList.filter(row => row.isDefault === true);
        docMapState.setCommonDocMapList(commonRowList);
        let repeatRowList = docMapState.repeatDocMapList;
        repeatRowList.forEach(row => row.iaDocumentManagement = '');
        repeatRowList = repeatRowList.filter(row => row.isDefault === true);
        docMapState.setRepeatDocMapList(repeatRowList);
    }

    /**
     * 処理モード名変換ロジック
     * @param type 結果：ok cancel
     */
    const handleFormNameDialogClose = (type: DialogResult = 'cancel') => {
        switch (type) {
            case 'cancel':
                setFormNameDialogVisible(false);
                setTempFormNameValue("");
                break;
            case 'ok':
                docMapState.setFormNameValue(tempFormNameValue);
                setFormNameDialogVisible(false);
                break;
        }
    }

    const handleDocNameDialogClose = (type: DialogResult = 'cancel') => {
        switch (type) {
            case 'cancel':
                setDocNameDialogVisible(false);
                break;
            case 'ok':
                docMapState.setCurrentDocDef(docName);
                setDocNameDialogVisible(false);
                break;
        }
    }
    /**
     * すべて選択して行を削除
     * @param value 
     */
    const onCommonTableAllChecked = (value: boolean) => {
        docMapState.setCommonTableChecked(value);
        let commonTableData = docMapState.commonDocMapList;
        commonTableData = commonTableData.map(data => {
            return {
                ...data,
                checked: data.isDefault ? false : value
            }
        })
        docMapState.setCommonDocMapList([...commonTableData]);
    }

    /**
     * すべて選択して行を削除
     * @param value 
     */
    const onRepeatTableAllChecked = (value: boolean) => {
        docMapState.setRepeatTableChecked(value);
        let repeatTableData = docMapState.repeatDocMapList;
        repeatTableData = repeatTableData.map(data => {
            return {
                ...data,
                checked: data.isDefault ? false : value
            }
        })
        docMapState.setRepeatDocMapList([...repeatTableData]);
    }

    /**
     * 行を削除するオプション
     * @param index 
     * @param value 
     */
    const onCommonTableSingleChecked = (index: number, value: boolean) => {
        let mapList = docMapState.commonDocMapList;
        mapList[index].checked = value;
        let checked = mapList.every(docMap => docMap.checked || docMap.isDefault);
        docMapState.setCommonTableChecked(checked);
        docMapState.setCommonDocMapList([...mapList]);
    }

    /**
     * 行を削除するオプション
     * @param index 
     * @param value 
     */
    const onRepeatTableSingleChecked = (index: number, value: boolean) => {
        let mapList = docMapState.repeatDocMapList;
        mapList[index].checked = value;
        let checked = mapList.every(docMap => docMap.checked || docMap.isDefault);
        docMapState.setRepeatTableChecked(checked);
        docMapState.setRepeatDocMapList([...mapList]);
    }

    /**
     * 新規行
     * @param type データ区間:common repeat
     */
    const onAddRow = (type: DocMapType = 'common') => {
        switch (type) {
            case 'common':
                let rowsCommon = docMapState.commonDocMapList;
                rowsCommon.push(new DocMapModel("", "", "", false));
                docMapState.setCommonTableChecked(false);
                docMapState.setCommonDocMapList([...rowsCommon]);
                break;
            case 'repeat':
                let rowsRepeat = docMapState.repeatDocMapList;
                rowsRepeat.push(new DocMapModel("", "", "", false));
                docMapState.setRepeatTableChecked(false);
                docMapState.setRepeatDocMapList([...rowsRepeat]);
                break;
        }
    }

    /**
     * 行の削除
     * @param type データ区間:common repeat
     */
    const onRemoveRow = (type: DocMapType = 'common') => {
        switch (type) {
            case 'common':
                let rowsCommon = docMapState.commonDocMapList;
                rowsCommon = rowsCommon.filter(row => !row.checked);
                docMapState.setCommonTableChecked(false);
                docMapState.setCommonDocMapList([...rowsCommon]);
                break
            case 'repeat':
                let rowsRepeat = docMapState.repeatDocMapList;
                rowsRepeat = rowsRepeat.filter(row => !row.checked);
                docMapState.setRepeatTableChecked(false);
                docMapState.setRepeatDocMapList([...rowsRepeat]);
                break;
        }
    }

    /**
     * 一致する文書コンテンツ変換ロジックの処理
     * @param data 
     * @param index 
     * @param type データ区間:common repeat
     */
    const onDocMapChange = (data: DocMapModel, index: number, type: DocMapType = 'common') => {
        switch (type) {
            case 'common':
                let commonRowList = docMapState.commonDocMapList;
                commonRowList[index] = data;
                docMapState.setCommonDocMapList([...commonRowList]);
                break;
            case 'repeat':
                let repeatRowList = docMapState.repeatDocMapList;
                repeatRowList[index] = data;
                docMapState.setRepeatDocMapList([...repeatRowList]);
                break;
        }
    }

    /**
     * マッピングファイルの比較（マッピングファイルが存在する）
     * @param savedMappingFile 保存されたマッピングファイル
     * @param currentMappingFile 現在のマッピングファイル
     * @returns 変更の有無
     */
    const compareDocMapLists = (savedMappingFile: DocMapModel[], currentMappingFile: DocMapModel[]) => {
        if (savedMappingFile.length !== currentMappingFile.length) {
            return true;
        }
        for (let index = 0; index < savedMappingFile.length; index++) {
            const item1 = savedMappingFile[index];
            const item2 = currentMappingFile[index];
            // 「定義名」、「SIP基盤」、「IA文書管理」変更されたかどうかを確認します
            const keyList = ["defineName", "sipInfrastructure", "iaDocumentManagement"];
            const update = keyList.some((key) => {
                if (keyList.indexOf(key) > -1) {
                    return item1[key as keyof DocMapModel] !== item2[key as keyof DocMapModel]
                }
            });
            if (update) {
                return true;
            }
        }
        return false;
    }

    /**
     * マッピングファイルの比較（マッピングファイルが存在しない）
     * @param currentMappingFile 現在のマッピングファイル
     * @param existCheckbox チェックボックスの有無
     * @returns 変更の有無
     */
    const compareDocMapList = (currentMappingFile: DocMapModel[], existCheckbox: boolean) => {
        for (const item of currentMappingFile) {
            if (!!item['iaDocumentManagement'] || existCheckbox) {
                return true;
            }
        }
        return false;
    }

    /**
     * 文書定義名切り替えの処理
     * @param docDef 
     */
    const onDocDefClick = (docDef: any) => {
        setDocName(docDef);
        // 現在のファイルはマップされたファイルである
        if (docMapState.formNameValue === docMapState.formMappingNameValue) {
            if (compareDocMapLists(docMapState.copyCommonDocMapList, docMapState.commonDocMapList) || compareDocMapLists(docMapState.copyRepeatDocMapList, docMapState.repeatDocMapList)) {
                setDocNameDialogVisible(true);
                return;
            }
        } else {
            if (compareDocMapList(docMapState.commonDocMapList, !hideCommonCheckbox) || compareDocMapList(docMapState.repeatDocMapList, !hideRepeatCheckbox)) {
                setDocNameDialogVisible(true);
                return;
            }
        }
        docMapState.setCurrentDocDef(docDef);
    }

    /**
     * 様式名切り替えの処理
     * @param event 
     */
    const onFormNameChange = (event: any) => {
        let formName = event.target.value;

        // 現在のファイルはマップされたファイルである
        if (docMapState.formNameValue === docMapState.formMappingNameValue) {
            if (compareDocMapLists(docMapState.copyCommonDocMapList, docMapState.commonDocMapList) || compareDocMapLists(docMapState.copyRepeatDocMapList, docMapState.repeatDocMapList)) {
                setTempFormNameValue(formName);
                setFormNameDialogVisible(true);
                return;
            }
        } else {
            if (compareDocMapList(docMapState.commonDocMapList, !hideCommonCheckbox) || compareDocMapList(docMapState.repeatDocMapList, !hideRepeatCheckbox)) {
                setTempFormNameValue(formName);
                setFormNameDialogVisible(true);
                return;
            }
        }
        docMapState.setFormNameValue(formName);
    }

    const useStyles = makeStyles((theme) => ({
        container: {
            width: '100%',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        appBarSpacer: theme.mixins.toolbar,
        title: {
            flexGrow: 1,
            textAlign: 'left'
        },
        mainTitle: {
            padding: '0 10px 0 15px',
            height: '50px'
        },
        subTitle: {
            padding: '0 10px 0 25px',
            height: '40px'
        },
        subTitleContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        subTitleContentItemRight: {
            padding: '0 0 0 5px'
        },
        content: {
            width: '100%',
            padding: '0 15px 0 25px',
            height: 'calc(100% - 174px)'
        },
        tableCellInput: {
            width: '100%',
            '& > select': {
                paddingTop: '2px',
                paddingBottom: '2px',
            }
        },
        modelSelect: {
            width: '200px'
        },
        headerBar: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        buttonBox: {
            zIndex: 999,
            width: 'auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        actionBtton: {
            padding: '0 5px',
            margin: '0 5px'
        },
        tableContainer: {
            height: 'calc(100vh - 238px)',
            maxHeight: 'calc(100vh - 238px)',
            overflowY: 'auto'
        },
        actionBox: {
            width: '100%',
            height: '30px'
        },
        tableRow: {
            '& > td': {
                padding: '3px 16px !important'
            }
        },
        docDefBox: {
            padding: '0 10px',
        },
        docDefList: {
            border: '1px solid rgba(224, 224, 224, 1)',
            paddingBottom: '0',
            height: 'calc(100vh - 450px)',
            overflowY: 'auto',
            textWrap: 'nowrap'
        },
        attention: {
            textWrap: 'nowrap',
            width: '100%',
            height: '276px',
            border: '1px solid rgba(224, 224, 224, 1)',
            '& > span': {
                display: 'block'
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: '15px',
            overflowY: 'auto'
        },
        docDefHeader: {
            minWidth: '200px',
            backgroundColor: '#eaeaea',
            color: '#333333',
            fontWeight: 'bold'
        },
        docDefItem: {
            minWidth: '200px',
            '&:nth-child(odd)': {
                backgroundColor: '#f3f3f3',
            },
            '&:nth-child(even)': {
                backgroundColor: '#fdfdfd',
            }
        },
        tableFuncBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        footer: {
            width: '100%',
            height: '20px',
            backgroundColor: '#fff'
        },
        checkboxNonePadding: {
            padding: '0'
        },
        backdrop: {
            color: "#fff",
            zIndex: 35000
        }

    }));

    const classes = useStyles();

    const SelfListItem = withStyles((theme) => ({
        root: {
            '&.Mui-selected': {
                backgroundColor: '#d6e5ec'
            },
            '&.MuiListItem-button:hover': {
                backgroundColor: '#d6e5ec'
            }
        }
    }))(ListItem);

    const SelfNativeSelect = withStyles((theme) => ({
        root: {
            '&.Mui-disabled': {
                cursor: 'not-allowed',
                backgroundColor: '#f5f7fa',
            }
        },

    }))(NativeSelect);

    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }))(InputBase);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        docMapState.setOpen(false);
    };

    const DocMapTableRow = ({ data, type = 'common', onChange, }: { type: DocMapType; data: DocMapModel; onChange: (param: DocMapModel) => any }) => {
        const docMapState = DocMapContainer.useContainer();
        const [defineName, setDefineName] = React.useState<string>(data.defineName ?? '');
        const [sipInfrastructure, setSipInfrastructure] = React.useState<string>(data.sipInfrastructure ?? '');
        const [iaDocumentManagement, setIADocumentManagement] = React.useState<string>(data.iaDocumentManagement ?? '');
        const [sipProjectItem, setSipProjectItem] = React.useState<SipProjectItem[]>([]);
        const [repeatDialogVisible, setRepeatDialogVisible] = React.useState<boolean>(false);

        useEffect(() => {
            initSipProjcetItem(data.defineName);
        }, [])

        /**
         * SIP基盤データの初期化
         * @param defineName 
         */
        const initSipProjcetItem = (defineName: string | undefined) => {
            let index = -1;
            if (defineName) {
                if ((index = SipStandardMessage.findIndex(ssm => ssm.physicalProjectName == defineName)) != -1) {
                    var sipStandardMessageItems = SipStandardMessage[index].items;
                    setSipProjectItem([...(sipStandardMessageItems as SipProjectItem[])]);
                } else {
                    setSipProjectItem([]);
                }
            }
        }

        /**
         * 繰り返すかどうかを判断する
         * @param sipInfrastructure 
         * @returns 
         */
        const judgmentRepetition = (sipInfrastructure: string) => {
            if (docMapState.commonDocMapList.findIndex(docMap => docMap.sipInfrastructure === sipInfrastructure) != -1 || docMapState.repeatDocMapList.findIndex(docMap => docMap.sipInfrastructure === sipInfrastructure) != -1) {
                return true;
            }
            return false;
        }

        /**
         * 定義名選択内容変更
         * @param event 
         */
        const onDefineNameChange = (event: any) => {
            let defineName = event.target.value;
            setDefineName(defineName);
            initSipProjcetItem(defineName);
            onChange({
                iaDocumentManagement: iaDocumentManagement,
                sipInfrastructure: sipInfrastructure,
                defineName: defineName,
                isDefault: data.isDefault,
                checked: data.checked,
                defaultValue: data.defaultValue
            } as DocMapModel)
        }

        /**
         * SIP基盤選択内容変更
         * @param event 
         */
        const onSipInfrastructureChange = (event: any) => {
            let sipInfrastructure = event.target.value;
            if (!judgmentRepetition(sipInfrastructure)) {
                setSipInfrastructure(sipInfrastructure);
                if (sipInfrastructure === 'pdf_base64') {
                    setIADocumentManagement("");
                    onChange({
                        iaDocumentManagement: "",
                        sipInfrastructure: sipInfrastructure,
                        defineName: defineName,
                        isDefault: data.isDefault,
                        checked: data.checked,
                        defaultValue: data.defaultValue
                    } as DocMapModel);
                } else {
                    onChange({
                        iaDocumentManagement: iaDocumentManagement,
                        sipInfrastructure: sipInfrastructure,
                        defineName: defineName,
                        isDefault: data.isDefault,
                        checked: data.checked,
                        defaultValue: data.defaultValue
                    } as DocMapModel);
                }
            } else {
                setRepeatDialogVisible(true);
            }
        }

        /**
         * ポップアップボックスを閉じる
         * @param param 
         */
        const handleRepeat = (param: DialogResult) => {
            switch (param) {
                case 'ok':
                    setRepeatDialogVisible(false);
                    break;
                default:
                    setRepeatDialogVisible(false);
                    break;
            }
        }

        /**
         * IA文書管理
         * @param event 
         */
        const onIaDocumentManageChange = (event: any) => {
            let iaDocumentManage = event.target.value;
            setIADocumentManagement(iaDocumentManage);
            onChange({
                iaDocumentManagement: iaDocumentManage,
                sipInfrastructure: sipInfrastructure,
                defineName: defineName,
                isDefault: data.isDefault,
                checked: data.checked,
                defaultValue: data.defaultValue
            } as DocMapModel);
        }

        return (
            <>
                {/* 定義名 */}
                <TableCell key={'defineName'} component={'td'}>
                    <SelfNativeSelect
                        id="demo-customized-select-native"
                        disabled={data.isDefault}
                        className={classes.tableCellInput}
                        input={<BootstrapInput />}
                        value={defineName}
                        onChange={onDefineNameChange}
                    >
                        <option aria-label="None" value="" />
                        {docMapState.sipProjectDirectory.filter(sip => type == 'common' ? !sip.isRepeat : sip.isRepeat).map(sip => (
                            <option value={sip.physicalProjectName} key={sip.physicalProjectName}>{sip.projectName}</option>
                        ))}
                    </SelfNativeSelect>
                </TableCell>
                {/* SIP基盤 */}
                <TableCell key={'sipInfrastructure'} component={'td'}>
                    <SelfNativeSelect
                        id="demo-customized-select-native"
                        disabled={data.isDefault}
                        className={classes.tableCellInput}
                        input={<BootstrapInput />}
                        value={sipInfrastructure}
                        onChange={onSipInfrastructureChange}
                    >
                        <option aria-label="None" value="" />
                        {sipProjectItem.map(sip => (
                            <option value={sip.physicalProjectName} key={sip.physicalProjectName}>{sip.projectName}</option>
                        ))}
                    </SelfNativeSelect>
                </TableCell>
                {/* IA文書管理 */}
                <TableCell key={'iaDocumentManagement'} component={'td'}>
                    <SelfNativeSelect
                        id="demo-customized-select-native"
                        className={classes.tableCellInput}
                        input={<BootstrapInput />}
                        value={iaDocumentManagement}
                        onChange={onIaDocumentManageChange}
                        disabled={data.sipInfrastructure === "pdf_base64" || !!!docMapState.formNameValue}
                    >
                        <option aria-label="None" value="" />
                        {docMapState.docDefFieldList.map(state => (
                            <option value={state.name} key={state.name}>{state.name}</option>
                        ))}
                    </SelfNativeSelect>
                </TableCell>
                <SelfDialog visible={repeatDialogVisible}
                    type={t('Text.Warning')}
                    title="エラー発生しました"
                    content={t('Msg.RepeatWarning')}
                    onClose={handleRepeat} />
            </>
        )
    }

    return (
        <main className={classes.container}>
            <div className={classes.appBarSpacer} />

            <div className={classes.mainTitle}>
                <Typography component="h1" variant="h4" color="inherit" noWrap className={classes.title}>
                    {t('Text.DeliveryDocumentMappingName')}
                </Typography>
                <Divider variant="fullWidth" />
            </div>

            <div className={classes.subTitle}>
                <div className={classes.subTitleContent}>
                    <Typography component="h1" variant="h6" color="inherit" noWrap>
                        {t('Text.OutputItem')}
                    </Typography>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.subTitleContentItemRight}>
                        {t('Text.SipInfrastructure')}
                    </Typography>
                </div>
                <Divider variant="fullWidth" />
            </div>
            <div className={classes.content}>
                <Grid container>
                    <Grid item xs={3} key={'grid-define'} className={classes.docDefBox}>
                        {/* 文書定義名 */}
                        <List className={classes.docDefList}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader className={classes.docDefHeader} component="div" id="nested-list-subheader">
                                    {t('Text.DocTypeName')}
                                </ListSubheader>
                            }
                        >
                            {docMapState.docDefList.map((dfl, index) => {
                                return (
                                    <SelfListItem key={index} button divider
                                        selected={docMapState.currentDocDef && dfl.id == docMapState.currentDocDef.id}
                                        className={classes.docDefItem}
                                        onClick={() => onDocDefClick(dfl)}>
                                        {dfl.name}
                                    </SelfListItem>
                                )
                            })}

                        </List>
                        {/* 固定値 */}
                        <div className={classes.attention}>
                            <p>{t('Text.SipFixedValue')}</p>
                            <span>{t('Text.MsgInfoClsTypCd')}</span>
                            <span>{t('Text.MsgFnStasCd')}</span>
                            <span>{t('Text.ClsOfVouCd')}</span>
                            <span>{t('Text.CompanyCd')}</span>
                            <span>{t('Text.OfficeCd')}</span>
                            <span>{t('Text.CompanyName')}</span>
                            <span>{t('Text.OfficeName')}</span>
                            <span>{t('Text.VouStasCd')}</span>
                            <span>{t('Text.VouFnStasCd')}</span>
                            <span>{t('Text.VouFormatVersionCd')}</span>
                            <br />
                        </div>
                    </Grid>
                    {/* ドキュメントの照合 */}
                    <Grid item xs={9} key={'grid-map'}>
                        <Toolbar className={classes.headerBar}>
                            {/* 様式名 */}
                            <NativeSelect
                                id="demo-customized-select-native"
                                className={classes.modelSelect}
                                input={<BootstrapInput />}
                                onChange={(event: object) => onFormNameChange(event)}
                                value={docMapState.formNameValue}
                                disabled={!!!docMapState.formNameValue}
                            >
                                {docMapState.docDefFieldContainer.map(docDefField => (
                                    <option value={docDefField.formName} key={docDefField.formName}>{docDefField.formName}</option>
                                ))}
                            </NativeSelect>
                            <div className={classes.buttonBox}>
                                <Button size="large" color="primary" className={classes.actionBtton} onClick={() => onSave()} disabled={!!!docMapState.formNameValue}>
                                    {t('Text.Save')}
                                </Button>
                            </div>
                        </Toolbar>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table aria-label="simple table" stickyHeader >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" padding="checkbox"></TableCell>
                                        {/* No. */}
                                        <TableCell align="center" padding="checkbox">{t('Text.Number')}</TableCell>
                                        {/* 定義名 */}
                                        <TableCell align="center" width='30%'>{t('Text.DefineName')}</TableCell>
                                        {/* SIP基盤 */}
                                        <TableCell align="center" width='30%'>{t('Text.SipInfrastructure')}</TableCell>
                                        {/* IA文書管理 */}
                                        <TableCell align="center" width='30%'>{t('Text.IaDocumentManage')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* 見出し項目 */}
                                    <TableRow>
                                        <TableCell align="center" component="td" colSpan={1}>
                                            {!hideCommonCheckbox ?
                                                <Checkbox
                                                    checked={docMapState.commonTableChecked}
                                                    indeterminate={commonTableIndeterminate && !docMapState.commonTableChecked}
                                                    onChange={(event, checked) => onCommonTableAllChecked(checked)}
                                                    className={classes.checkboxNonePadding}
                                                    color="primary" />
                                                :
                                                null
                                            }
                                        </TableCell>
                                        <TableCell align="justify" component="td" colSpan={4} >
                                            <div className={classes.tableFuncBox}>
                                                <Typography variant="h6" gutterBottom>
                                                    {t('Text.TitleItems')}
                                                </Typography>
                                                <div>
                                                    <IconButton color="primary" onClick={() => onAddRow('common')} disabled={!!!docMapState.formNameValue}>
                                                        <Add />
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => onRemoveRow('common')} disabled={!!!docMapState.formNameValue}>
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {docMapState.commonDocMapList.map((row, index) => (
                                        !row.defaultValue ?
                                            <TableRow key={'table-row' + index} className={classes.tableRow} >
                                                <TableCell component="td" scope="row" align="center">
                                                    {row.isDefault ?
                                                        <span style={{ color: '#f56c6c' }}>
                                                            *
                                                        </span>
                                                        :
                                                        <Checkbox
                                                            checked={row.checked}
                                                            onChange={(event, checked) => onCommonTableSingleChecked(index, checked)}
                                                            className={classes.checkboxNonePadding}
                                                            color="primary" />}
                                                </TableCell>
                                                <TableCell component="td" scope="row" align="center">
                                                    {index + 1}
                                                </TableCell>
                                                <DocMapTableRow type="common" data={row} onChange={(data) => onDocMapChange(data, index, 'common')}></DocMapTableRow>
                                            </TableRow>
                                            :
                                            null
                                    ))}
                                    {/* 繰り返し項目 */}
                                    <TableRow>
                                        <TableCell align="center" component="td" colSpan={1}>
                                            {!hideRepeatCheckbox ?
                                                <Checkbox
                                                    checked={docMapState.repeatTableChecked}
                                                    indeterminate={repeatTableIndeterminate && !docMapState.repeatTableChecked}
                                                    className={classes.checkboxNonePadding}
                                                    onChange={(event, checked) => onRepeatTableAllChecked(checked)}
                                                    color="primary" />
                                                :
                                                null
                                            }
                                        </TableCell>
                                        <TableCell align="left" component="td" colSpan={4}>
                                            <div className={classes.tableFuncBox}>
                                                <Typography variant="h6" gutterBottom>
                                                    {t('Text.RepeatItems')}
                                                </Typography>
                                                <div>
                                                    <IconButton color="primary" onClick={() => onAddRow('repeat')} disabled={!!!docMapState.formNameValue}>
                                                        <Add />
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => onRemoveRow('repeat')} disabled={!!!docMapState.formNameValue}>
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {docMapState.repeatDocMapList.map((row, index) => (
                                        <TableRow key={'table-row' + index} className={classes.tableRow}>
                                            <TableCell component="td" scope="row" align="center">
                                                {row.isDefault ?
                                                    <span style={{ color: '#f56c6c' }}>
                                                        *
                                                    </span>
                                                    :
                                                    <Checkbox
                                                        checked={row.checked}
                                                        className={classes.checkboxNonePadding}
                                                        onChange={(event, checked) => onRepeatTableSingleChecked(index, checked)}
                                                        color="primary" />}
                                            </TableCell>
                                            <TableCell component="td" scope="row" align="center">
                                                {index + 1 + docMapState.commonDocMapList.length}
                                            </TableCell>
                                            <DocMapTableRow type="repeat" data={row} onChange={(data) => onDocMapChange(data, index, 'repeat')}></DocMapTableRow>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.footer}></div>

            {/* 保存確認ボックス */}
            <SelfDialog
                visible={saveDialogVisible}
                type="confirm"
                title={t('Text.Verify')}
                content={t('Msg.SaveConfirm')}
                onClose={handleSave}
            />

            {/* データの完全性の確認 */}
            <SelfDialog
                visible={dataIntegrityDialogVisible}
                type="warn"
                title={t('Text.Warning')}
                content={t('Msg.SaveWarning')}
                onClose={() => setDataIntegrityDialogVisible(false)}
            />

            {/* モード名切替確認ボックス */}
            <SelfDialog
                visible={formNameDialogVisible}
                type="confirm"
                title={t('Text.Verify')}
                content={t('Msg.RevertConfirm')}
                onClose={handleFormNameDialogClose}
            />

            {/* 文書定義名の切り替え確認ボックス */}
            <SelfDialog
                visible={docNameDialogVisible}
                type="confirm"
                title={t('Text.Verify')}
                content={t('Msg.RevertConfirm')}
                onClose={handleDocNameDialogClose}
            />

            <CustomInfoSnackBar
                isOpen={docMapState.open}
                content={docMapState.statusCode === 200 ? i18n.t('Msg.SaveSuccessful') : i18n.t('Msg.SaveFailed')}
                statusCode={docMapState.statusCode}
                doClose={handleClose}
            />
        </main >
    )
}

export default DocMappingManage