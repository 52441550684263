import { Switch, withStyles } from "@material-ui/core";
import DocContainer from "../../container/DocContainer";

export default function CellFullSwitch() {
    const docState = DocContainer.useContainer();
    
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    
    /**
     * トグルスイッチ
     */
    const handleChange = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if(docState.updateFields.length > 0) {
            docState.setChangeConfirmDlgOpen(true);
            docState.setSwitchAll(true);
            return;
        }
        docState.setAllSwitchConfirmDlgOpen(true);
    }
    return (
        <>
            <div style={{position:'relative', paddingRight: '15px',top:'-2px' }}>
                <AntSwitch checked={docState.allCheckStatus} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} onClick={handleChange} />
            </div>
        </>
    );
}


