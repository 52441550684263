import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

export const useListService = () => {
    const serviceState = ServiceContainer.useContainer()

    const doctypeList = (): Promise<any[]> => {
        return serviceState.request(
            WebConstants.DOCUMENT_DEFINITION_URL,
            WebConstants.METHOD_GET,
            null
        ).then((response) => {
            return response.data.docTypeList
        });
    }

    return {
        doctypeList
    }
}