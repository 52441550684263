import {
    REVIEW_PROPERTIES,
    FILE_PROPERTIES,
    DOC_STATUS,
    KIND_TYPE,
    DATE_FORMAT,
    EXTENSION_COLUMNS
} from "../constants/PropertiesConstants";
import {FormControlLabel, Typography, Checkbox, Switch} from "@material-ui/core";
import React from "react";
import CellTranslateText from "../components/table/CellTranslateText";
import LocalTimeFilter from "../common/LocalTimeFilter";
import moment from 'moment';
import CellTranslateDocStatus from "../components/table/CellTranslateDocStatus";
import CellCheckbox from "../components/table/CellCheckbox";
import CellTranslateConvertError from "../components/table/CellTranslateConvertError";
import CellSwitchState from "../components/table/CellSwitchState";

class PropRenderService {
    /**
     * ドキュメントの定義を生成するファクトリー関数です。
     */
    getSystemRenderer = (fieldId: string | undefined, value: any, tableMeta: any, updateValue: any) => {
        // @ts-ignore
        let column: any = FILE_PROPERTIES[fieldId];

        //日付
        if (column.type === 'date') {
            return this.getTextRender(this.getFormattedDate(value,''));
        } else {
            // @ts-ignore
            switch (fieldId) {
                //名称
                case 'name':
                    return this.getTextRender(value);
                /*   // 名称は標準のテンプレートを使用するので要素を削除
                   delete val.cellTemplateUrl;
                   val.cellClass = function (row) {
                       var ret = '';
                       if (row.entity && row.entity.type) {
                           ret = constantService.getIconForList(row.entity.type, row.entity.properties.filetype, row.entity.properties.content_type,
                               row.entity.properties.pagecount);
                       }
                       return ret;
                   };

                   // 名称のソート順をカスタマイズ(フォルダーが先頭の名称順。PDFファイル、PDF以外のファイル、リンク、ページリンクは混在で名称順)
                   val.sortFunction = function (nextRow, currentRow) {
                       var nextProp = nextRow.entity;
                       var currentProp = currentRow.entity;

                       // 名称順の場合は種別がフォルダの時だけまとめて先頭、後尾に表示する
                       var nextPrefix = nextProp.type === 'folder' ? '0' : '1';
                       var currentPrefix = currentProp.type === 'folder' ? '0' : '1';

                       var next = nextPrefix + nextProp.properties.name;
                       var current = currentPrefix + currentProp.properties.name;

                       return _compareSort(next, current);
                   };*/
                //  break;

                // 種類
                case 'filetype':
                    // 種類のソート順をコード値でカスタマイズ
                    // @ts-ignore
                    return this.getTransTextRender(KIND_TYPE[value].display);
                /*                    val.sortFunction = function (nextRow, currentRow) {
                                        var next = (nextRow.entity.type === 'folder') ? -1 : Number(nextRow.entity.properties.filetype);
                                        var current = (currentRow.entity.type === 'folder') ? -1 : Number(currentRow.entity.properties.filetype);
                                        return _compareSort(next, current);
                                    };
                                    val.dataAccessor = function (row) {
                                        var name = '';
                                        if (row.entity && row.entity.type) {

                                            if (row.entity.type === 'folder') {
                                                name = KIND_TYPE['99'].display;
                                            } else {
                                                var fileType = row.entity.properties.filetype;
                                                if (KIND_TYPE[fileType])) {
                                                    name = KIND_TYPE[fileType].display;
                                                }
                                            }
                                        }
                                        return translate(name);
                                    };
                break;*/

                // ファイルサイズ
                case 'size':
                    var ret = '';
                    if (value && value.length > 0) {
                        var fs = Number(value);
                        if (fs === 0) {
                            ret = '-';
                        }
                        fs = (fs + 1023) / 1024;
                        fs = fs < 1 ? 1 : Math.floor(fs);
                        ret = this.numFmt(fs);
                    } else {
                        ret = '';
                    }
                    return this.getNumberRender(ret);

                // タイムスタンプ
                case 'stamp':
                    return this.getTimestampStatusValue(value);

                // 文書ステータス
                case 'parsed_status':
                    return this.getDocStatusValue(value);

                // SVF検索フィールドの有無
                case 'svffield_editable_status':
                    return this.getSvfFieldEditableStatusValue(value);
                    break;

                // ページ数
                case 'pagecount':
                    //数値型
                    if (value && value === '0') {
                        value = '-';
                    }
                    return this.getNumberRender(value);
                    break;

                // レビューステータス
                case 'review_status':
                    return this.getReviewStatusValue(value);
                    break;

                // 文書バージョン
                case 'document_version':
                    return this.getTextRender(value);
                /*                    val.textAlign = 'right';
                                    // ソート順をカスタマイズ
                                    val.sortFunction = function (nextRow, currentRow) {
                                        var nextH;
                                        var nextL;
                                        var currentH;
                                        var currentL;
                                        var cdata;
                                        if (nextRow.entity.type === 'folder') {
                                            nextH = -1;
                                        } else {
                                            if (nextRow.entity.properties.document_version === null || nextRow.entity.properties.document_version === '') {
                                                nextH = 0;
                                            } else {
                                                cdata = nextRow.entity.properties.document_version.split('.');
                                                nextH = Number(cdata[0]);
                                                nextL = Number(cdata[1]);
                                            }
                                        }
                                        if (currentRow.entity.type === 'folder') {
                                            currentH = -1;
                                        } else {
                                            if (currentRow.entity.properties.document_version === null || currentRow.entity.properties.document_version === '') {
                                                currentH = 0;
                                            } else {
                                                cdata = currentRow.entity.properties.document_version.split('.');
                                                currentH = Number(cdata[0]);
                                                currentL = Number(cdata[1]);
                                            }
                                        }
                                        return _versionCompareSort(nextH, currentH, nextL, currentL);
                                    };
                break;*/

                // 確認済み
                case 'svffield_data_checked':
                    return (<CellSwitchState value={JSON.parse(value)} meta={tableMeta} updateValue={updateValue}/>);
                /*                    val.dataAccessor = function (row) {
                                        var value = row.entity.properties.svffield_data_checked;

                                        return {
                                            value: $filter('booleanFilter')(value),
                                            type: val.type,
                                            hasCheckBox: true
                                        };
                                    };
                                    val.sortFunction = function (nextRow, currentRow) {
                                        var next = nextRow.entity.properties.svffield_data_checked;
                                        var current = currentRow.entity.properties.svffield_data_checked;
                                        return _compareSort(next, current);
                                    };
                break;*/

                // 注釈の有無
                case 'annotation_existence':
                    return this.getCheckboxRender(JSON.parse(value));
                /*     val.cellTemplateUrl = 'src/svf/svf-grid/dataTypeListCell.html';
                     val.dataAccessor = function (row) {
                         var value = row.entity.properties.annotation_existence;

                         return {
                             value: $filter('booleanFilter')(value),
                             type: val.type,
                             hasCheckBox: true
                         };
                     };
                     val.sortFunction = function (nextRow, currentRow) {
                         var next = nextRow.entity.properties.annotation_existence;
                         var current = currentRow.entity.properties.annotation_existence;
                         return _compareSort(next, current);
                     };
                break;*/

                // Document Converter変換ステータス
                case 'convert_doc_status':
                    //ファイルのみの想定
                    return this.getDocumentConvertStatusValue(value);

                // Document Converter変換エラー
                case 'doc_convert_error':
                    /*                    val.dataAccessor = function (row) {
                                            if (row.entity.type !== 'folder') {
                                                var ipPortVal;
                                                if (row.entity.properties.doc_convert_connection.length > 0) {
                                                    var ConnData = JSON.parse(row.entity.properties.doc_convert_connection);
                                                    if ($scope.appInfo.systems.cloudMode) {
                                                        ipPortVal = ConnData.host;
                                                    } else {
                                                        ipPortVal = ConnData.host + ':' + ConnData.port;
                                                    }
                                                } else {
                                                    ipPortVal = '';
                                                }
                                                var value = propertyOptionsService.getDocumentConvertErrorValue(row.entity.properties.doc_convert_error, ipPortVal);
                                                return value;
                                            } else {
                                                return '';
                                            }
                                        };*/
                    break;

                // チェックアウト
                case 'checkout':
                    return this.getCheckboxRender(JSON.parse(value));
                /*                    val.cellTemplateUrl = 'src/svf/svf-grid/dataTypeListCell.html';
                                    val.dataAccessor = function (row) {
                                        var value = row.entity.properties.checkout;
                                        var hasCheckBox = true;

                                        if (row.entity.type === 'folder') {
                                            hasCheckBox = false;
                                        }

                                        return {
                                            value: $filter('booleanFilter')(value),
                                            type: val.type,
                                            hasCheckBox: hasCheckBox
                                        };
                                    };
                                    val.sortFunction = function (nextRow, currentRow) {
                                        var next = nextRow.entity.properties.checkout;
                                        var current = currentRow.entity.properties.checkout;
                                        return _compareSort(next, current);
                                    };
                break;*/
                // Loaderの実行結果コード
                case 'loader_result_code':
                    return this.getNumberRender(value);
                /*                    val.sortFunction = function (nextRow, currentRow) {
                                        var next = parseInt(nextRow.entity.properties.loader_result_code);
                                        var current = parseInt(currentRow.entity.properties.loader_result_code);
                                        if (isNaN(next)) {
                                            return -1;
                                        }
                                        return _compareSort(next, current);
                                    };
                break;*/
                //関連文書
                case 'relate_info':
                    ret = '';
                    var relatedDocs = [];
                    var relateInfo = value;
                    if (relateInfo) {
                        relatedDocs = JSON.parse(relateInfo).relatedDocs;
                        if (relatedDocs.length > 1) {
                            ret = 'Text.ExData';
                        }
                    }
                    return this.getTransTextRender(ret);

                /*                    val.cellTemplateUrl = 'src/svf/svf-grid/labelButtonCell.html';
                                    val.dataAccessor = function (row) {
                                        var value = '';
                                        var relatedDocs = [];
                                        var relateInfo = row.entity.properties.relate_info;
                                        if (relateInfo) {
                                            relatedDocs = JSON.parse(relateInfo).relatedDocs;
                                            if (relatedDocs.length > 1) {
                                                value = translate('Text.ExData');
                                            }
                                        }
                                        return {
                                            value: value,
                                            buttonOptions: {
                                                onClick: function () {
                                                    return dialogOptionsService.openRelateDialog($scope, row.entity.id);
                                                }
                                            }
                                        };
                                    };
                                    val.sortFunction = function (nextRow, currentRow) {
                                        var next = nextRow.entity.properties.relate_info;
                                        var current = currentRow.entity.properties.relate_info;
                                        return _compareSort(next, current);
                                    };
                break;*/
                default:
                    return this.getTextRender(value);
                    break;
            }

        }

        /*        //ライブラリのフィルターをセット
                if (isLibrary && !hasFilter) {
                    val.filterFunctionFactory = getLibraryfilterFunction($scope);
                    hasFilter = true;
                    $scope.columnSetting.filterFieldId = val.field;
                }*/
        return;
    }
    /**
     * テキストレンダラー
     * @param value
     */
    getTextRender = (value: string) => {
        return (<Typography variant={'body2'}> {value}
        </Typography>)
    }
    /**
     * 数値レンダラー
     * @param value
     */
    getNumberRender = (value: string) => {
        return (<Typography align={'right'} variant={'body2'}> {value}
        </Typography>)
    }
    /**
     * 翻訳テキストレンダラー
     * @param value
     */
    getTransTextRender = (value: string) => {
        return (<CellTranslateText value={value}/>)
    }
    /**
     * チェックボックスレンダラー
     * @param value
     */
    getCheckboxRender = (value: boolean) => {
        return (<CellCheckbox value={value}/>)
    }
    /**
     *  カンマ区切り数値フォーマット
     * @param n
     */
    numFmt = (n: number) => {
        var parts = n.toString().split('.');
        parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        return parts.join('.');
    }
    /**
     * フォーマット済み日付
     * @param value
     */
    getFormattedDate = (value: string,format:string) => {
        let ret = value;
        // var format = $scope.appInfo.userProfile.dateFormat.default;
        if (format===null || format.length === 0) {
            //日付フォーマット文字列[デフォルト値]
            format = 'yyyy/MM/DD HH:mm:ss';
        }
        if (value) {
            ret = LocalTimeFilter(value);
            ret = moment(value).format(format);
        }
        return ret;
    }
    /**
     * 関連付けの取得
     * @param value
     */
    getRelateInfoValue = (value: string) => {
        var relatedDocs = [];
        if (value) {
            relatedDocs = JSON.parse(value).relatedDocs;
            if (relatedDocs.length > 1) {
                return this.getTransTextRender('Text.ExData');
            }
        }
        return null;
    }
    /**
     * タイムスタンプのステータス文字列の取得
     * @param value
     */
    getTimestampStatusValue = (value: string) => {
        var statusText = '';
        if ((value) && value.length > 0) {
            // ステータス
            switch (value) {
                case '0':
                    statusText = 'Text.TimestampStatus0';
                    break;
                case '1':
                    statusText = 'Text.TimestampStatus1';
                    break;
                case '2':
                    statusText = 'Text.TimestampStatus2';
                    break;
                case '3':
                    statusText = 'Text.TimestampStatus3';
                    break;
                case '6':
                    statusText = 'Text.TimestampStatus6';
                    break;
            }
        }
        return this.getTransTextRender(statusText);
    }

    // 文書ステータスの取得

    getDocStatusValue = (value: string) => {
        let docStatus = [];

        if ((value) && value.length > 0) {
            // 文書ステータス
            switch (value) {
                //解析中
                case DOC_STATUS.PARSE.code:
                    docStatus.push('Text.Parsing');
                    break;
                //解析中（検索インデックス作成中）	-
                case DOC_STATUS.CREATE_INDEX.code:
                    docStatus.push('Text.Parsing');
                    docStatus.push(DOC_STATUS.CREATE_INDEX.caption);
                    break;
                //解析完了	マスク適用失敗)
                case DOC_STATUS.MASK_FAILED.code:
                    docStatus.push('Text.AnalysisComplete');
                    docStatus.push(DOC_STATUS.MASK_FAILED.caption);
                    break;
                //解析完了	解析不能ファイル
                case DOC_STATUS.IMPOSSIBLE_PARSE.code:
                    docStatus.push('Text.AnalysisComplete');
                    docStatus.push(DOC_STATUS.IMPOSSIBLE_PARSE.caption);
                    break;
                //解析完了	暗号化ファイル
                case DOC_STATUS.ENCRYPTED.code:
                    docStatus.push('Text.AnalysisComplete');
                    docStatus.push(DOC_STATUS.ENCRYPTED.caption);
                    break;
                //解析完了	検索可能ファイル（本文検索不可）
                case DOC_STATUS.DO_NOT_CREATE.code:
                    docStatus.push('Text.AnalysisComplete');
                    docStatus.push(DOC_STATUS.DO_NOT_CREATE.caption);
                    break;
                //解析完了	検索可能ファイル（本文検索一部可）
                case DOC_STATUS.SEARCHIABLE.code:
                    docStatus.push('Text.AnalysisComplete');
                    docStatus.push(DOC_STATUS.SEARCHIABLE.caption);
                    break;
                //解析完了 検索可能ファイル
                case DOC_STATUS.CREATED.code:
                    docStatus.push('Text.AnalysisComplete');
                    docStatus.push(DOC_STATUS.CREATED.caption);
                    break;
            }
        }
        return (<CellTranslateDocStatus value={docStatus}/>);
    }


// SVF検索フィールドの編集ステータスの取得
    getSvfFieldEditableStatusValue = (status: string) => {
        var value = '';

        if (status) {
            // ステータス
            switch (status) {
                // 解析中
                case '0':
                    value = 'Text.Analysis';
                    break;
                // 解析不能
                case '1':
                    value = 'Text.Unanalyzable';
                    break;
                // データなし
                case '2':
                    value = 'Text.NoData';
                    break;
                //  データあり
                case '3':
                case '4':
                    value = 'Text.ExData';
                    break;
                // データあり(キャッシュあり)
                case '5':
                    value = 'Text.ExDataAndCache';
                    break;
            }
        }
        return this.getTransTextRender(value);
    }

// レビューステータスの文字列の取得
    getReviewStatusValue = (reviewStatus: string) => {
        var value = '';

        if (reviewStatus) {
            // ステータス
            switch (reviewStatus) {
                case '0':
                    value = 'Text.BeforeIssue';
                    break;
                case '1':
                    value = 'Text.Issue';
                    break;
                case '2':
                    value = 'Text.Processing';
                    break;
                case '3':
                    value = 'Text.IssueCompleted';
                    break;
            }
        }
        return this.getTransTextRender(value);
        ;
    }

// Doc Optionステータスの文字列の取得
    getDocumentConvertStatusValue = (documentConvertStatus: string) => {
        var value = '';

        if (documentConvertStatus) {
            switch (documentConvertStatus) {
                case '0':
                    value = 'Text.NotApplicable';
                    break;
                case '1':
                    value = 'Text.Standby';
                    break;
                case '2':
                    value = 'Text.ConvertStandby';
                    break;
                case '3':
                    value = 'Text.Completed';
                    break;
                case '4':
                    value = 'Text.Failed';
                    break;
            }
        }
        return this.getTransTextRender(value);
        ;
    }
    /**
     *
     * @param documentConvertError
     * @param docConvertConnection
     */
    getDocumentConvertErrorValue = (documentConvertError: any, docConvertConnection: any) => {
        let value = '';
        let args1 = docConvertConnection.length === 0 ? '' : '(' + docConvertConnection + ')';
        let param = {'args1': args1};
        if (documentConvertError) {
            switch (documentConvertError) {
                case '0':
                    value = 'Msg.ConvertTargetFileNotFound';

                    break;
                case '-10':
                    value = 'Msg.DrawingFontNotFound';

                    break;
                case '-20':
                    value = 'Msg.PdfParseError';

                    break;
                case '-30':
                    value = 'Msg.ConvertProcessStartError';

                    break;
                case '-40':
                    value = 'Msg.PdfConvertTimeout';

                    break;
                case '-50':
                case '-60':
                    value = 'Msg.PdfConvertError';

                    break;
                case '-70':
                    value = 'Msg.DefaultPrinterSettingError';

                    break;
                case '-80':
                    value = 'Msg.ConvertApplicationAssociationError';

                    break;
                case '-9999':
                    value = 'Msg.UnexpectedError';

                    break;
                case '-2110':
                    value = 'Msg.FailedDocumentConverterConnection';

                    break;
                case null:
                    break;
                default:
                    value = 'Msg.OtherDocumentConvertError';
                    param.args1 = documentConvertError;
                    break;
            }
        }
        return (<CellTranslateConvertError msg={value} param={param}/>);
    }

    /**
     * レビュー関連のプロパティを取得
     * @param fieldId
     * @param value
     * @param tableMeta
     * @param updateValue
     */
    getReviewRenderer = (fieldId: string | undefined, value: any, tableMeta: any, updateValue: any) => {
        // @ts-ignore
        let column: any = REVIEW_PROPERTIES[fieldId];
        //日付
        if (column.type === 'date') {
            return this.getTextRender(this.getFormattedDate(value,''));
        } else {
            // @ts-ignore
            switch (fieldId) {

                case  'status':
                    return this.getReviewStatusValue(value);
                default:
                    return this.getTextRender(value);
                    break;
            }
        }
    }
    /**
     * カスタムプロパティのレンダラーを取得
     * @param fieldId
     * @param value
     * @param tableMeta
     * @param updateValue
     */
    getCustomRenderer = (columnDef: any, value: any, tableMeta: any, updateValue: any) => {
        switch (columnDef.type) {
            //日付
            case 'date':
                if (value != null) {
                    if (columnDef.dateType < 3) {
                        value = LocalTimeFilter(value);
                    }
                    // @ts-ignore
                    value = moment(value).format(DATE_FORMAT[columnDef.dateType])
                }
                return this.getTextRender(value);
            //boolean
            case 'boolean':
                return this.getCheckboxRender(JSON.parse(value));
            //マスク
            case EXTENSION_COLUMNS.EXT_MASK_TYPE:
                let txt = '';
                if (value === '1') {
                    // 自動的にマスクする
                    txt = 'Text.Auto';
                } else if (value === '2') {
                    // 手動でマスクする
                    txt = 'Text.Manual';
                }
                return this.getTransTextRender(txt);
            //数値
            case 'number':
                return this.getNumberRender(value);
            default:
                return this.getTextRender(value);
        }
    }
}

export default new PropRenderService()

