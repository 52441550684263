import MUIDataTable, { FilterType, SelectableRows } from "mui-datatables";
import React, { useEffect } from "react";
import { Accordion, AccordionSummary, IconButton, MuiThemeProvider, Tooltip, Typography, createMuiTheme, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FilterDataDialog } from "./dialog/FilterDataDialog";
import { useTranslation } from "react-i18next";
import SendContainer from "../container/SendContainer";
import { useSendService } from "../service/SendService";
import Word from "../common/Word";
import UserContainer from "../container/UserContainer";
import { DEFAULT_DATE_FORMAT } from "../constants/Constants";
import moment from "moment";
import CustomInfoSnackBar from "./dialog/CustomInfoSnackBar";
import CommonContainer from "../container/CommonContainer";
import { DATE_FORMAT } from "../constants/PropertiesConstants";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '100px 24px',
        width: '100%',
        margin: 'auto'
    }
}));

const SendTable = () => {
    // コンテナ
    const sendState = SendContainer.useContainer();
    const userState = UserContainer.useContainer();
    const commonState = CommonContainer.useContainer();

    const { getFilterDataApi } = useSendService()

    // 変数
    const { t, i18n } = useTranslation();

    // データ
    const [tableColumns, setTableColumns] = React.useState(
        [
            { label: i18n.t('Text.FileName'), name: "fileName", options: { viewColumns: false } },
            { label: i18n.t('Text.FolderName'), name: "folderName" },
            { label: i18n.t('Text.DataNo'), name: "dataNo", options: { viewColumns: false } },
            { label: i18n.t('Text.SubmitNo'), name: "submitNo" },
            { label: i18n.t('Text.SendDatetime'), name: "sendDatetime" },
            { label: i18n.t('Text.SubmitDatetime'), name: "submitDatetime" },
            { label: i18n.t('Text.SendUser'), name: "userName" },
            { label: i18n.t('Text.SendMethod'), name: "sendMethod" },
            { label: i18n.t('Text.SendResult'), name: "sendResult", options: { viewColumns: false } },
            { label: i18n.t('Text.SendErrorDetail'), name: "sendErrorDetail" }
        ]
    );

    const options: any = {
        filterType: 'checkbox' as FilterType,
        selectableRows: 'single' as SelectableRows,
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: true,
        search: false,
        sort: true,
        filter: false,
        print: false,
        download: false,
        viewColumns: true,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        customToolbar: () => {
            return (
                <>
                    <Tooltip title={i18n.t('Text.Search')!}>
                        <IconButton onClick={() => sendState.setOpen(true)}>
                            <Search />
                        </IconButton>
                    </Tooltip>
                </>
            )
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: '',
                columnHeaderTooltip: '',
            },
            pagination: {
                next: Word('Text.PageDown'),
                previous: Word('Text.PageUp'),
                rowsPerPage: Word('Text.rowsPerPage'),
                displayRows: "/",
            },
            toolbar: {
                viewColumns: Word('Text.ColumnDisplaySetting'),
            },
            viewColumns: {
                title: Word('Text.ColumnDisplaySetting')
            }
        }
    };

    // スタイル
    const classes = useStyles();

    const tableClass = () => createMuiTheme({
        typography: {
            body2: {
                fontSize: "0.875rem"
            }
        },
        overrides: {
            MuiTableCell: {
                head: {
                    height: "69px", padding: "0px", margin: "0px"
                }
            },
            MuiTableRow: {
                root: {
                    height: "20px", padding: "0px", margin: "0px", whiteSpace: "nowrap"
                }
            }
        },
    });

    useEffect(() => {
        if (!!userState.tenantId) {
            getTodayTableList();
        }
    }, [userState.tenantId])

    /**
     * 当日のデータ取得
     */
    const getTodayTableList = () => {
        commonState.setProgress(true);
        const sendDatetimeStart = moment(sendState.searchData.sendDatetimeStart).format("YYYY-MM-DDTHH:mmZ");
        const sendDatetimeEnd = moment(sendState.searchData.sendDatetimeEnd).format("YYYY-MM-DDTHH:mmZ");
        const data = { ...sendState.searchData, tenantId: userState.tenantId, sendDatetimeStart: sendDatetimeStart, sendDatetimeEnd: sendDatetimeEnd };
        getFilterDataApi(data).then(async responseData => {
            if (responseData) {
                responseData.forEach((value: any) => {
                    value.sendDatetime = value.sendDatetime ? moment(value.sendDatetime).format(DEFAULT_DATE_FORMAT) : null;
                    value.submitDatetime = value.submitDatetime ? moment(value.submitDatetime).format(DATE_FORMAT[3]) : null;
                });
            }
            sendState.setFilteData(responseData);
        }).catch((error) => {
            console.log("****** SendTable getTodayTableList error: *******", error);
        }).finally(() => {
            commonState.setProgress(false);
        })
    }

    /**
     * キューボックスを閉じる
     */
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        sendState.setIsOpen(false);
    }

    return (
        <div className={classes.container}>
            <React.Fragment>
                <Accordion expanded={true} >
                    <AccordionSummary style={{ cursor: 'auto' }}>
                        <Typography>{t('Text.SendLog')}</Typography>
                    </AccordionSummary>
                    <MuiThemeProvider theme={tableClass()}>
                        <MUIDataTable
                            columns={tableColumns}
                            data={sendState.filteData}
                            title={""}
                            options={options}>
                        </MUIDataTable>
                    </MuiThemeProvider>
                </Accordion>
            </React.Fragment>

            <FilterDataDialog
                title={t('Text.Search')}
                isOpen={sendState.open}
            />

            <CustomInfoSnackBar
                isOpen={sendState.isOpen}
                content={i18n.t('Msg.SearchSuccessful')}
                statusCode={sendState.statusCode}
                doClose={handleClose}
            />
        </div>
    );
}

export default SendTable;