import React, { useCallback, useEffect, useRef, useState } from 'react';
import dummyImage from '../assets/image.png'
import Toolbar from '@material-ui/core/Toolbar';
import DocContainer from "../container/DocContainer";
import { Circle, Layer, Rect, Stage, Text, Label, Tag, Group } from "react-konva";
import IconButton from "@material-ui/core/IconButton";
import {
    Backdrop, Box,
    Button, CircularProgress, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, makeStyles, Paper,
    Popover,
    Slider, TextField
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Konva from "konva";
import { Comment, Edit, LaptopWindows, Save, Visibility } from "@material-ui/icons";
import { KonvaEventObject } from "konva/types/Node";
import { Alert, Pagination } from '@material-ui/lab';
import TantouDialog from "./dialog/TantouDialog";
import { useTranslation } from "react-i18next";
import DocCommentDialog from "./dialog/DocCommentDialog";
import SvfSearchFieldDialog from "./dialog/SvfSearchFieldDialog";
import { MSG_PIPE_STR } from "../constants/Constants";
import { REVIEWER_STAMP_GET_URL } from '../constants/WebConstants';
import CommonContainer from '../container/CommonContainer';
import { useStampService } from '../service/StampService';

const base64ToUrl = (data: string) => `data:image/png;base64,${data}`;
const initZoomScale: number = 100;
const imageScale: number = 1.33; // dpi ⇆ px
export default function Preview() {
    const { getReviewStamp } = useStampService();
    Konva.hitOnDragEnabled = true;
    const docState = DocContainer.useContainer();
    const commonState = CommonContainer.useContainer();
    type PreviewTouchPoint = {
        distance: number;
        lastX: number;
        lastY: number;
    }
    //Ref
    const stage = React.useRef<Konva.Stage>(null);
    const zoomSlider = React.useRef(null);
    const imageLayer = React.useRef<Konva.Layer>(null);
    const fieldLayer = React.useRef<Konva.Layer>(null);
    const stampLayer = React.useRef<Konva.Layer>(null);
    const editFieldValue = React.useRef(null);
    const editSaveDlg = React.useRef(null);
    //state
    const [fieldVisible, setFieldVisible] = React.useState(true);
    const [editDlgOpen, setEditDlgOpen] = React.useState(false);
    const [saveConfirmDlgOpen, setSaveConfirmDlgOpen] = React.useState(false);
    const [commentDlgOpen, setCommentDlgOpen] = React.useState(false);
    const [lastPoint, setPinchPoint] = React.useState<PreviewTouchPoint | null>(null);
    const [zoomScale, setZoomScale] = React.useState(initZoomScale);
    const [imageHeight, setImageHeight] = React.useState(0);
    //const [currentPage, setCurrentPage] = React.useState(1);
    const { t, i18n } = useTranslation();

    /**
     * スタンプ描画ステータス表示
     */
    const renderStamp = () => {
        const reviewFlowStamps = docState.annotationData?.reviewFlowStamps;
        if (!reviewFlowStamps?.length || !isLoadedData()) {
            if (stampLayer.current) {
                stampLayer.current.removeChildren();
            }
            return null;
        }
        reviewFlowStamps.forEach(async (stamps) => {
            const reviewerId = stamps.reviewerId
            const stampRect = stamps.rect;
            const stampReferencePoint = stamps.referencePoint;
            if (stampRect && reviewerId && stampReferencePoint) {
                await Konva.Image.fromURL(REVIEWER_STAMP_GET_URL(reviewerId), (image: Konva.Image) => {
                    image.setAttrs({
                        x: stampReferencePoint.x,
                        y: stampReferencePoint.y,
                        width: stampRect.width,
                        height: stampRect.height,
                    });
                    image.preventDefault(false);
                    if (stampLayer.current) {
                        //stampLayer.current.removeChildren();
                        stampLayer.current.add(image);
                        stampLayer.current.batchDraw();
                        docState.setPreviewBackdrop(false);
                    }
                });
            }
        });
    }

    /**
     * フィールド生成
     */
    const renderField = () => {
        if (!fieldVisible) {
            return null;
        }
        if (docState.pageFieldList) {
            let ret: any[] = new Array();
            const pageData = docState.pageFieldList[docState.currentPageNo - 1];
            if (pageData) {
                const scale: number | undefined = pageData.scale;
                if (pageData.values && scale) {
                    //単品フィールドの追加
                    ret = createRects(pageData.values.fieldList, ret, scale, '');
                    //レコードのフィールドの追加
                    for (var recordL of pageData.values.recordList) {
                        for (var records of recordL.records) {
                            for (var record of records) {
                                ret = createRects(record.fieldList, ret, scale, recordL.name + '#' + record.row + '#');
                            }
                        }
                    }
                }
            }
            return ret;
        }
    }
    /**
     * 編集されたフィールドかどうか
     * @param item
     */
    const isEdited = (item: { editValue: any, updated: boolean }) => {
        var ret = false;
        if (item) {
            if (item.editValue || item.updated) {
                ret = true;
            }
        }
        return ret;
    }
    /**
     * フィールド矩形に表示する文字列取得
     * @param item
     */
    const getRectValue = (item: { editValue: any, updated: boolean, value: any }) => {
        var ret = '';
        if (item) {
            if (item.editValue) {
                ret = item.editValue;
            } else if (item.updated) {
                ret = item.value;
            }
        }
        return ret;
    }
    /**
     * フィールド矩形の色
     * @param item
     */
    const getRectColor = (item: { editValue: any, updated: boolean }) => {
        //デフォルト（黄色）
        var ret = 'yellow';
        if (item) {
            if (item.updated) {
                //編集済み（ピンク）
                ret = 'pink';
            } else if (item.editValue) {
                //編集中（水色）
                ret = 'skyblue';
            }
        }
        return ret;
    }

    /**
     * フィールド矩形の生成
     * @param fieldList
     * @param ret
     * @param scale
     * @param key
     */
    const createRects = (fieldList: any[], ret: any[], scale: number, key: string) => {
        for (var field of fieldList) {
            var item = field.fields[0];
            // @ts-ignore
            if (docState.confirmFields.indexOf(field.id) < 0) continue;
            var rect = (<Rect key={'f_' + key + field.id}

                width={_calcScale(item.width, scale, 2)}
                height={_calcScale(item.height, scale, 2)}
                fill={getRectColor(item)}
                type="field"
                stroke='red'
                strokeWidth={0}
                opacity={isEdited(item) ? 0.9 : 0.3}
                preventDefault={false}
                onMouseEnter={e => {
                    if (docState.disableEditField() === false && docState.isEditButtonVisibleCheck()) {
                        // @ts-ignore
                        let rect: Rect;
                        rect = e.target;
                        rect.strokeWidth(3);
                        rect.parent.parent.draw();
                        // @ts-ignore
                        const container = rect.getStage().container();
                        container.style.cursor = "pointer";
                    }
                }}
                onMouseLeave={e => {
                    if (docState.disableEditField() === false) {
                        // @ts-ignore
                        let rect: Rect;
                        rect = e.target;
                        rect.strokeWidth(0);
                        rect.parent.parent.draw();
                        // @ts-ignore
                        const container = e.target.getStage().container();
                        container.style.cursor = "default";
                    }
                }}
            />
            );
            var text = null;
            if (isEdited(item)) {
                text = (<Text
                    key={'t_' + key + field.id}
                    text={getRectValue(item)}
                    align={'justify'}
                    fontSize={18}
                    fontFamily={'Calibri'}
                />);
            }
            ret.push(
                <Group
                    key={docState.currentPageNo + '_' + key + field.id}
                    id={docState.currentPageNo + '#' + key + field.id}
                    x={_calcScale(item.x, scale, 2)}
                    y={_calcScale(item.y, scale, 2)}
                    width={_calcScale(item.width, scale, 2)}
                    height={_calcScale(item.height, scale, 2)}
                    draggable={false}
                    onClick={handleFieldClick}
                    onTap={handleFieldClick}
                >{rect}{text}</Group>
            );

        }
        return ret;
    };
    /**
     * 座標、幅、高さをスケール変換する
     * @param num 数値
     * @param n 小数点の桁数
     * @return number 小数点を切り捨てた値
     */
    const _calcScale = (num: number, scale: number, n: number) => {
        var pow = Math.pow(10, n);
        return Math.round(num * scale * pow) / pow;
    }

    /**
     * プレビューイメ-ジ描画
     */
    const previewImage = async () => {
        // @ts-ignore
        if (isLoadedData()) {
            // @ts-ignore
            const { data, height, width } = docState.previewData[docState.currentPageNo - 1];
            const url = base64ToUrl(data);
            const scale = 96 / 72;//画像の大きさがptのため　96/72dpi
            // @ts-ignore
            // eslint-disable-next-line react-hooks/rules-of-hooks
            //return (<Image image={useImage(url)}/>);
            Konva.Image.fromURL(url, async function (image) {
                image.setAttrs({
                    // @ts-ignore
                    width: width * scale,
                    // @ts-ignore
                    height: height * scale,
                    borderSize: 5,
                    borderColor: 'red',
                });
                image.preventDefault(false);
                // Stage用のコンテナのサイズ調整
                setImageHeight(height * scale);
                // append to layer
                if (imageLayer.current) {
                    if (stage.current) {
                        stage.current.size({
                            width: width * scale * zoomScale / 100,
                            height: height * scale * zoomScale / 100
                        });
                    }
                    if (stampLayer.current) {
                        await stampLayer.current.removeChildren();
                        await stampLayer.current.batchDraw();
                    }
                    imageLayer.current.removeChildren();
                    imageLayer.current.add(image);
                    imageLayer.current.batchDraw();
                    console.log("****** previewImage() *******");
                    docState.setPreviewBackdrop(false);
                }
            });

        } else {
            return null;
        }
    }
    /**
     * 拡大率変更された
     */
    const scaleChanged = useCallback(
        (_, value) => {
            setZoomScale(value);
            let newScale: number = value / 100;
            if (stage.current) {
                stage.current.scale({ x: newScale, y: newScale });
                stage.current.batchDraw();
            }
        },
        []
    );
    /**
     * プレビュー画像のピンチ操作
     */
    const handlePreviewDrag = (event: KonvaEventObject<TouchEvent>) => {
        const touch1 = event.evt.touches[0];
        const touch2 = event.evt.touches[1];
        if (touch1 && touch2 && stage.current) {
            event.evt.preventDefault();
            if (stage.current.isDragging()) {
                stage.current.stopDrag();
            }
            const newCenter = {
                distance: Math.sqrt(Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2)),
                lastX: (touch1.clientX + touch2.clientX) / 2,
                lastY: (touch1.clientY + touch2.clientY) / 2
            };
            if (!lastPoint) {
                setPinchPoint(newCenter);
                return;
            }
            let scale = Math.round(100 * stage.current.scaleX() * (newCenter.distance / lastPoint.distance));
            if (scale < 50 || scale > 200) {
                scale = scale < 50 ? 50 : 200;
                scaleChanged(null, scale);
                return;
            }
            scaleChanged(null, scale);
            setPinchPoint(newCenter);
        }
    }
    /**
     * プレビュー画面のピンチ操作終了
     */
    const handlePreviewDragEnd = (event: KonvaEventObject<TouchEvent>) => {
        setPinchPoint(null);
    }
    const disableSaveButton = () => {
        return (docState.disableEditField() === true || docState.updateFields.length === 0);
    }
    /**
     * ページが変更された
     */
    const pageChanged = async (event: any, value: number) => {
        docState.setCurrentPageNo(value);
        scaleChanged(null, initZoomScale);
        docState.pageChanged(value).then(() => {
            previewImage();
        })
    };
    /**
     * フィールドを選択して編集ダイアログ表示
     * @param event
     */
    const handleFieldClick = (event: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
        if (docState.disableEditField() || !docState.isEditButtonVisibleCheck()) return;
        docState.setCurrentField(event.currentTarget.attrs.id);
        setEditDlgOpen(true);
    }
    /**
     *値の編集ダイアログ：閉じる
     */
    const handleClose = () => {
        setEditDlgOpen(false);
    };
    /**
     *フィールド値保存ダイアログ：開く
     */
    const handleEditConfirmOpen = () => {
        setSaveConfirmDlgOpen(true);

    };
    /**
     *フィールド値保存ダイアログ：閉じる
     */
    const handleEditConfirmClose = () => {
        setSaveConfirmDlgOpen(false);
        //docState.saveEditField();
    };
    /**
     *フィールド値保存ダイアログ：OK
     */
    const handleEditConfirmOK = (tantou: string) => {

        docState.saveEditField(t('Text.EditValue') + MSG_PIPE_STR + tantou);
    };
    /**
     *文書コメントダイアログ：開く
     */
    const handleCommentOpen = () => {
        setCommentDlgOpen(true);

    };
    /**
     *文書コメントダイアログ：閉じる
     */
    const handleCommentClose = () => {
        setCommentDlgOpen(false);

    };
    const previewStyle = makeStyles((theme) => ({
        slider: {
            width: 100
        },
    }));
    const classes = previewStyle();

    /**
     * 総ページ数取得する
     */
    const getPageCount = () => {
        if (docState.currentDoc) {
            // @ts-ignore
            let page = docState.currentDoc['properties.pagecount'];
            if (page.length > 0) {
                return Number.parseInt(page);
            }
        }
    };

    /**
     * 現在のページのプレビューデータが取得できたかどうか
     */
    const isLoadedData = () => {
        if (docState.currentDoc != null && docState.previewData != null && docState.previewData.length > 0) {
            if (docState.previewData[docState.currentPageNo - 1] != null) {
                return true;
            }
        }
        return false;
    }
    /**
     * 受け取り完了バーを出す
     */
    const showCompleteBar = () => {
        let cDate = docState.getCompleteDate();
        if (cDate.length === 0) {
            return null;
        } else {
            let msg = t('Text.Receive');
            msg += '　' + cDate;
            return (<><Box style={{ flexGrow: 1 }} /><Alert severity="success" style={{ display: 'flex', justifyContent: "flex-end" }}>{msg}</Alert></>);
        }
    }
    if (docState.currentDoc !== null && docState.previewData && docState.previewData.length > 0) {
        //アクセス権によりツールボタンを生成する
        let buttons = [];
        //フィールド表示切り替えボタン
        // @ts-ignore
        if (docState.currentDoc['properties.svffield_editable_status'] > 2) {
            buttons.push(<ToggleButton
                id={'visibleFieldButton'}
                key={'visibleFieldButton'}
                value="check"
                selected={fieldVisible}
                onChange={() => {
                    setFieldVisible(!fieldVisible);
                }}
            >
                <Visibility />
            </ToggleButton>);
        }
        //フィールド編集ボタン
        if (docState.visibleEditField() && docState.isEditButtonVisibleCheck()) {
            buttons.push(<IconButton key={'fieldEditButton'} disabled={disableSaveButton()}
                onClick={handleEditConfirmOpen}><Save /></IconButton>
            )
        }
        //文書コメントボタン
        if (docState.visibleCommentButton()) {
            buttons.push(<IconButton key={'commentButton'} onClick={handleCommentOpen}><Comment /></IconButton>
            )
        }

        return (
            <React.Fragment>
                <Toolbar>
                    {buttons}

                    <Pagination id="pagenation" count={getPageCount()} size="small" page={docState.currentPageNo}
                        onChange={pageChanged} />
                    <Box style={{ width: 30 }} />
                    <Slider ref={zoomSlider}
                        className={classes.slider}
                        value={zoomScale}
                        defaultValue={100}
                        aria-labelledby="discrete-slider-small-steps"
                        step={10}
                        marks
                        min={50}
                        max={200}
                        valueLabelDisplay="auto"
                        onChange={scaleChanged}
                    />
                    <Box style={{ width: 30 }} />
                    {showCompleteBar()}
                </Toolbar>
                <SvfSearchFieldDialog
                    isOpen={editDlgOpen}
                    doClose={() => handleClose()}
                />
                {/*保存の確認ダイアログ*/}
                <TantouDialog
                    id='fromPreview'
                    title={t('Text.Save')}
                    isOpen={saveConfirmDlgOpen}
                    content={t('Msg.ConfirmSaveFieldValue')}
                    ButtonText={t('Text.Save')}
                    doSubmit={(tantou) => handleEditConfirmOK(tantou)}
                    doClose={() => handleEditConfirmClose()}
                />
                {/*文書コメントダイアログ*/}
                <DocCommentDialog
                    isOpen={commentDlgOpen}
                    docId={''}
                    doClose={() => handleCommentClose()}
                />
                {/*プレビュー*/}
                <Box id="previewContainer" style={{ width: "100%", height: imageHeight, overflow: "auto", backgroundColor: "gray" }}>
                </Box>

                {docState.currentDoc !== null ?
                    <Stage ref={stage} container="previewContainer"
                        onTouchMove={handlePreviewDrag} onTouchEnd={handlePreviewDragEnd}
                        onWheel={e => { }}
                    >
                        <Layer ref={imageLayer} onLoad={previewImage()}>
                        </Layer>
                        <Layer ref={stampLayer} onLoad={renderStamp()}>
                        </Layer>
                        <Layer ref={fieldLayer} visible={fieldVisible}>
                            {renderField()}
                        </Layer>
                    </Stage>

                    : <></>
                }

            </React.Fragment>
        );
    } else {
        return null
    }

}
