import React from "react";
import { createContainer } from "unstated-next";
import DocMapModel, { DocDefField } from "../models/DocMapModel";
import { SipProjectDirectory, SipStandardMessage } from "../models/SipStandardMessage";
import { usePageService } from "../service/PageService";
import { useListService } from "../service/ListService";
import { useSipStandardMessageService } from "../service/SipStandardMessageService";
import CommonContainer from "./CommonContainer";

const useDocMapState = () => {
    const commonState = CommonContainer.useContainer();

    const { fixDataApi, saveDocMapApi } = usePageService()
    const { initDefaultData } = useSipStandardMessageService()
    const { doctypeList } = useListService();

    // 文書定義名
    const [docDefList, setDocDefList] = React.useState<any[]>([]);

    // IA文書管理
    const [docDefFieldContainer, setDocDefFieldContainer] = React.useState<DocDefField[]>([]);
    const [formNameValue, setFormNameValue] = React.useState<string>("");
    const [formMappingNameValue, setFormMappingNameValue] = React.useState<string>("");
    const [currentDocDef, setCurrentDocDef] = React.useState<any>(undefined);
    const [docDefFieldList, setDocDefFieldList] = React.useState<any[]>([]);

    // doc map list
    const [commonDocMapList, setCommonDocMapList] = React.useState<DocMapModel[]>([]);
    const [copyCommonDocMapList, setCopyCommonDocMapList] = React.useState<DocMapModel[]>([]);
    const [repeatDocMapList, setRepeatDocMapList] = React.useState<DocMapModel[]>([]);
    const [copyRepeatDocMapList, setCopyRepeatDocMapList] = React.useState<DocMapModel[]>([]);

    // SIP基盤
    const [sipProjectDirectory, setSipProjectDirectory] = React.useState<SipProjectDirectory[]>([]);

    const [open, setOpen] = React.useState(false);
    const [statusCode, setStatusCode] = React.useState<number>();

    const [commonTableChecked, setCommonTableChecked] = React.useState<boolean>(false);
    const [repeatTableChecked, setRepeatTableChecked] = React.useState<boolean>(false);
    /**
     * 文書定義名の取得
     */
    const initDocDefList = () => {
        doctypeList().then((docDefList) => {
            setDocDefList(docDefList)
            setCurrentDocDef(docDefList[0])
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        })
    }

    /**
     * ファイルの保存
     * @param dataArray 
     */
    const saveDocMap = (dataArray: any) => {
        commonState.setProgress(true)
        saveDocMapApi(dataArray).then(() => {
            initDocMapFieldList(dataArray.docTypeId, dataArray.tenantId, "save");
        }).catch((error) => {
            setOpen(true);
            setStatusCode(500);
            commonState.setProgress(false);
        })
    }

    /**
     * 初期化データ
     * @param docDefId 
     * @param tenantId 
     * @param action 
     */
    const initDocMapFieldList = (docDefId: string, tenantId: string, action?: string) => {
        commonState.setProgress(true);
        fixDataApi(docDefId).then(async responses => {
            const docDefFieldResp: any = responses;
            const formNameList: any[] = [];
            let iaSearchFieldsArray: any[] = [];
            docDefFieldResp.forEach((res: { formName: any; }) => {
                formNameList.push(res.formName);
            })
            if (docDefFieldResp) {
                setDocDefFieldContainer(docDefFieldResp);
            }
            let params = {
                "docTypeId": docDefId,
                "tenantId": tenantId
            }
            const obj = await initDefaultData(params, formNameList);
            if (docDefFieldResp.length > 0) {
                if (obj.formName !== "") {
                    setFormNameValue(obj.formName);
                    setFormMappingNameValue(obj.formName);
                    docDefFieldResp.forEach((res: { formName: string; searchFields: any[]; }) => {
                        if (res.formName === obj.formName) {
                            res.searchFields.forEach(val => {
                                iaSearchFieldsArray.push(val.name);
                            })
                            return;
                        }
                    })
                } else {
                    setFormNameValue([...docDefFieldResp][0].formName);
                    setFormMappingNameValue("");
                }
            } else {
                setFormNameValue("");
            }
            obj.commonList.forEach(value => {
                if (!iaSearchFieldsArray.includes(value.iaDocumentManagement)) {
                    value.iaDocumentManagement = "";
                }
            })
            obj.repeatList.forEach(value => {
                if (!iaSearchFieldsArray.includes(value.iaDocumentManagement)) {
                    value.iaDocumentManagement = "";
                }
            })
            setCommonDocMapList(obj.commonList);
            setCopyCommonDocMapList([...obj.commonList]);
            setRepeatDocMapList(obj.repeatList);
            setCopyRepeatDocMapList([...obj.repeatList]);
            if (action) {
                setOpen(true);
                setStatusCode(200);
                setCommonTableChecked(false);
                setRepeatTableChecked(false);
            }
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
        })
    }

    /**
     * 対応する値を取得する
     * @param formName ファイル名
     */
    const filterDocMapFieldList = (formName: string) => {
        if (!!formName) {
            let index = -1;
            if ((index = docDefFieldContainer.findIndex(fieldContainer => fieldContainer.formName == formName)) == -1) {
                setDocDefFieldList([]);
            } else {
                setDocDefFieldList([...docDefFieldContainer[index].searchFields]);
            }
        } else {
            setDocDefFieldList([]);
        }
    }

    const initSipProjectDirectory = () => {
        setSipProjectDirectory(SipStandardMessage);
    }

    return {
        docDefList, setDocDefList,
        commonDocMapList, setCommonDocMapList,
        copyCommonDocMapList, setCopyCommonDocMapList,
        copyRepeatDocMapList, setCopyRepeatDocMapList,
        repeatDocMapList, setRepeatDocMapList,
        initDocDefList,
        saveDocMap,
        currentDocDef, setCurrentDocDef,
        initDocMapFieldList,
        docDefFieldList, setDocDefFieldList,
        docDefFieldContainer, setDocDefFieldContainer,
        filterDocMapFieldList,
        initSipProjectDirectory,
        sipProjectDirectory,
        setSipProjectDirectory,
        formNameValue, setFormNameValue,
        formMappingNameValue, setFormMappingNameValue,
        open, setOpen,
        statusCode, setStatusCode,
        commonTableChecked, setCommonTableChecked,
        repeatTableChecked, setRepeatTableChecked
    }
}

const DocMapContainer = createContainer(useDocMapState);

export default DocMapContainer;