import { Checkbox } from "@material-ui/core";
import CustomContainer from '../../container/CustomContainer';

export default function CellCustomCheckbox(props: { meta: any }) {
    let realIndex = props.meta.currentTableData[props.meta.rowIndex].index;
    const customState = CustomContainer.useContainer();

    const handleChange = () => {
        customState.setRowClickAction("checkbox")
        var rowCheckStatuslist = customState.rowCheckStatuslist.slice();
        if (customState.checkList.indexOf(realIndex) > -1) {
            customState.setCheckList(customState.checkList.filter(value => value !== realIndex))
        } else {
            customState.setCheckList([...customState.checkList, realIndex]);
        }
        rowCheckStatuslist[realIndex].check = !rowCheckStatuslist[realIndex].check;
        for (let i = 0; i < rowCheckStatuslist.length; i++) {
            if (!rowCheckStatuslist[i].check) {
                customState.setFullCheck(false);
                break;
            }
            customState.setFullCheck(true);
        }
        customState.setRowCheckStatuslist(rowCheckStatuslist);
    }
    return (
        <>
            <Checkbox checked={!!customState.rowCheckStatuslist[realIndex]?.check} onChange={handleChange} color="primary" />
        </>
    );
}


