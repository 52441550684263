import React, {useRef, useState} from "react";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";
import {DEFAULT_DATE_FORMAT, SEARCH_CONDITION_TYPE} from "../../constants/Constants";
import Box from "@material-ui/core/Box";
import DateCondition from "./DateCondition";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import {Grid, Paper} from "@material-ui/core";
import TextCondition from "./TextCondition";
import NumberCondition from "./NumberCondition";
import BoolCondition from "./BoolCondition";
import {FILE_PROPERTIES} from "../../constants/PropertiesConstants";
import FreeWordCondition from "./FreeWordCondition";
import DropDownCondition from "./DropDownCondition";

/**
 * 実行時指定ダイアログのコンテントの部分
 */

type Props = {
    content: string
};

const SearchExecContent: React.FC<Props> = ({
                                                content
                                            }) => {
    const {t, i18n} = useTranslation();
    const docState = DocContainer.useContainer();
    const [condition, setCondition] = useState<any>(docState.selectCondition);
    /**
     * 詳細部分
     * @param row
     * @param detailIdx
     */
    const createDetail = (row: { type: any; id: string | number | null | undefined; conditionType: string; name: string,dateTypeIndex:string }, name: string, templateIdx: number, detailIdx: number) => {
        let ret = null;
        let options = [];
        switch (row.type) {
            case 'date':
                ret = (<DateCondition key={row.id} templateIdx={templateIdx} detailIdx={detailIdx}
                                      conditionType={row.conditionType} dateTypeIndex={row.dateTypeIndex}/>);
                break;
            case'string':
                options = docState.getSearchValues(row);
                ret = (<TextCondition key={row.id} templateIdx={templateIdx} detailIdx={detailIdx}
                                      conditionType={row.conditionType} options={options}/>);
                break;
            case'number':
                options = docState.getSearchValues(row);
                ret = (<NumberCondition key={row.id} templateIdx={templateIdx} detailIdx={detailIdx}
                                        conditionType={row.conditionType} options={options}/>);
                break;
            case'boolean':
                ret = (<BoolCondition key={row.id} name={name} templateIdx={templateIdx} detailIdx={detailIdx}
                                      conditionType={row.conditionType}/>);
                break;
            case'dropdown':
                // @ts-ignore
                options =row.dropdownOptions;
                ret = (<DropDownCondition key={row.id}  templateIdx={templateIdx} detailIdx={detailIdx}
                                      conditionType={row.conditionType}　options={options}/>);
                break;
        }
        return ret;
    }
    /**
     * 条件パネル
     */
    const crateExecForms = () => {
        let execList = [];

        if (condition !== null) {
            //フリーワード
            if (condition.freeword && condition.freeword.execSet) {
                execList.push(<FreeWordCondition condition={condition}/>);
            }
            //フォルダー
            if (condition.folder && condition.folder.execSet) {

            }
            let templates = condition.templates;
            templates.map((row: any, idx: number) => {
                let condition: any = {};
                let options: any = {
                    dateFormat: DEFAULT_DATE_FORMAT
                };
                let execDetail = [];
                if (row.execSet === true　&& row.conditionType !== 'folder') {
                    for (let i = 0; i < row.details.length; i++) {

                        let type = row.conditionType;
                        let tempName = row.dispName;
                        let color = '';
                        switch (type) {
                            // システム
                            case SEARCH_CONDITION_TYPE.SYSTEM:
                                color = '#74B666';
                                // @ts-ignore
                                tempName = t(FILE_PROPERTIES[row.name].display);
                                break;
                            // カスタム
                            case SEARCH_CONDITION_TYPE.CUSTOM:
                                color = '#74B666';
                                let col = docState.customPropertiesList.find((el: any) => 'column.' + el.id === row.name);
                                if (col) {
                                    // @ts-ignore
                                    tempName = col.displayNames.ja;
                                    row.name = tempName;
                                } else {
                                    tempName = row.name;
                                }
                                break;
                            // 管理ポリシー
                            case SEARCH_CONDITION_TYPE.POLICY:
                                color = '#74B666';
                                break;
                            // SVF検索フィールド
                            case SEARCH_CONDITION_TYPE.SVF_FIELD:
                                color = '#76BEDB';
                                break;
                            // 明細
                            case SEARCH_CONDITION_TYPE.DETAIL:
                                color = '#E6B05F';
                                break;
                            // ページ
                            case SEARCH_CONDITION_TYPE.PAGE_CONTENT:
                                color = '#74B666';
                                // @ts-ignore
                                tempName = t(FILE_PROPERTIES[row.name].display);
                                break;
                            // フォルダー
                            case SEARCH_CONDITION_TYPE.FOLDER:
                                color = '#CB87F8';
                                break;
                        }
                        let dispName= tempName;
                        if(i>0){
                            dispName ='';
                        }
                        execDetail.push(
                            <Box key={row.id + "_title"}
                                 display="flex"
                                 flexDirection="row"
                                 justifyContent="flex-start"
                                 alignItems="center"
                                 borderLeft={6}
                                 borderColor={color}
                            >

                                {/*/   {(i==0)?<Brightness1Icon style={{color: color}}/>:<Box/>} */}
                                {(row.type !== 'boolean')?<Box mx={2} style={{maxWidth:'200px',minWidth:'100px'}}>{dispName}</Box>:''}
                                <Box display="flex"
                                     flexDirection="row"
                                     justifyContent="flex-start"
                                     alignItems="center"
                                     flexWrap ='wrap'
                                     flexGrow ="1"
                                >
                                {createDetail(row, tempName, idx, i)}
                                </Box>

                            </Box>
                        );
                    }
                    execList.push(
                        <Paper variant="outlined" key={row.id + '_paper'}>
                            {execDetail}
                        </Paper>
                    );
                }
            });
        }
        return execList;
    }
    return (

        <DialogContent id="conditionsExecContent">
            {crateExecForms()}
        </DialogContent>
    );
}

export default SearchExecContent;
