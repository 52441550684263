import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from '@mui/material/TextField';
import { styled } from "@mui/material/styles";
import { DatePicker, DatePickerProps, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { JSX } from "react/jsx-runtime";

const StyledDatePicker = styled(DatePicker)(() => ({
    width: '100%',
    "&>.MuiInputBase-root": {
        "&>input": {
            "padding": "12px 0 12px 10px",
        }
    }
}))

const DateRangePick = ({ dateRange, columnDef, onChange }: { dateRange: string[], columnDef: any, onChange: (param: string[]) => any }): JSX.Element => {
    const [t] = useTranslation()

    const [fromDate, setFromDate] = useState<Moment | null>(null)
    const [endDate, setEndDate] = useState<Moment | null>(null)

    useEffect(() => {
        initDate()
    }, [])

    useEffect(() => {
        initDate()
    }, [dateRange])

    const initDate = () => {
        const [from, end] = dateRange ?? []
        setFromDate(from ? moment(from) : null)
        setEndDate(end ? moment(end) : null)
    }

    const onFromDateChange = (value: any): void => {
        setFromDate(value)
        let dateRangeNew = [getDateStr(value), dateRange[1] ?? ""]
        onChange(dateRangeNew)
    }

    const onEndDateChange = (value: any): void => {
        setEndDate(value)
        let dateRangeNew = [dateRange[0] ?? "", getDateStr(value)]
        onChange(dateRangeNew)
    }

    const getDateStr = (date: Moment | null): string => {
        if (!date || !date.isValid()) {
            return ""
        } else {
            return date.format("yyyy-MM-DD")
        }
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <label>{t(columnDef.display)}:</label>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    <div style={{ width: '50%' }}>
                        <StyledDatePicker
                            value={fromDate}
                            onChange={(newValue) => {
                                onFromDateChange(newValue)
                            }}
                            maxDate={endDate}
                        />
                    </div>
                    <span style={{ margin: '0 5px 0 5px' }}>{t('Text.FromToDash')}</span>
                    <div style={{ width: '50%' }}>
                        <StyledDatePicker
                            value={endDate}
                            onChange={(newValue) => {
                                onEndDateChange(newValue)
                            }}
                            minDate={fromDate}
                        />
                    </div>
                </div>
            </LocalizationProvider>
        </>
    )
}

export default DateRangePick