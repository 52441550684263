import React from 'react';
import { Switch, withStyles } from "@material-ui/core";
import DocContainer from "../../container/DocContainer";

export default function CellSwitchState(props: { value: boolean; meta: any, updateValue: boolean }) {

    const docState = DocContainer.useContainer();
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    let realIndex = props.meta.currentTableData[props.meta.rowIndex].index;

    // @ts-ignore
    let checked = realIndex <= docState.data.length - 1 ? docState.data[realIndex]["properties.svffield_data_checked"] : "";

    if (docState.isVisibleCheck(realIndex)) {
        return (
            <AntSwitch disabled={docState.isDisableCheck(props.meta.rowIndex)}
                color="primary"
                checked={checked === "true" || checked === true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => docState.switchConfirm(realIndex, checked)}
            />
        );
    } else {
        return null;
    }
}


