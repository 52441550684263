import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CustomTableFilterChip from './CustomTableFilterChip';

const useStyles = makeStyles(
    () => ({
        root: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            margin: '0px 16px 0px 16px',
        },
        chip: {
            margin: '8px 8px 0px 0px',
        },
        'gridListTile': {
            marginTop: '0px'
        }
    }),
    { name: 'CustomMUIDataTableFilterList' },
);

const CustomTableFilterList = ({
    // @ts-ignore
    options,
    // @ts-ignore
    filterList,
    // @ts-ignore
    filterUpdate,
    // @ts-ignore
    filterListRenderers,
    // @ts-ignore
    columnNames,
    // @ts-ignore
    serverSideFilterList,
    // @ts-ignore
    customFilterListUpdate,
    ItemComponent = CustomTableFilterChip,
}) => {
    const classes = useStyles();
    const { serverSide } = options;

    // @ts-ignore
    const removeFilter = (index, filterValue, columnName, filterType, customFilterListUpdate = null) => {
        let removedFilter = filterValue;
        if (Array.isArray(removedFilter) && removedFilter.length === 0) {
            removedFilter = filterList[index];
        }

        filterType = 'multiselect'
        let filterValueNew = filterList[index] as string[]
        filterValueNew = filterValueNew.map(filter => filter === filterValue ? "" : filter)

        // @ts-ignore
        filterUpdate(index, filterValueNew, columnName, filterType, customFilterListUpdate, filterList => {
            if (options.onFilterChipClose) {
                options.onFilterChipClose(index, removedFilter, filterList);
            }
        });
    };

    // @ts-ignore
    const customFilterChip = (customFilterItem, index, customFilterItemIndex, item, isArray) => {
        // @ts-ignore
        let type;

        if (isArray) {
            // @ts-ignore
            type = customFilterListUpdate[index] ? 'custom' : 'chip';
        } else {
            // @ts-ignore
            type = columnNames[index].filterType;
        }

        return (
            // @ts-ignore
            <ItemComponent
                label={customFilterItem}
                key={customFilterItemIndex}
                onDelete={() =>
                    removeFilter(
                        index,
                        item[customFilterItemIndex] || [],
                        // @ts-ignore
                        columnNames[index].name,
                        // @ts-ignore
                        type,
                        // @ts-ignore
                        customFilterListUpdate[index],
                    )
                }
                className={classes.chip}
                // @ts-ignore
                itemKey={customFilterItemIndex}
                index={index}
                data={item}
                columnNames={columnNames}
                filterProps={
                    options.setFilterChipProps
                        // @ts-ignore
                        ? options.setFilterChipProps(index, columnNames[index].name, item[customFilterItemIndex] || [])
                        : {}
                }
            />
        );
    };

    // @ts-ignore
    const filterChip = (index, data, colIndex) => (
        // @ts-ignore
        <ItemComponent
            // @ts-ignore
            label={filterListRenderers[index](data)}
            key={colIndex}
            // @ts-ignore
            onDelete={() => removeFilter(index, data, columnNames[index].name, 'chip')}
            className={classes.chip}
            // @ts-ignore
            itemKey={colIndex}
            index={index}
            data={data}
            columnNames={columnNames}
            // @ts-ignore
            filterProps={options.setFilterChipProps ? options.setFilterChipProps(index, columnNames[index].name, data) : {}}
        />
    );

    // @ts-ignore
    const getFilterList = filterList => {
        // @ts-ignore
        return filterList.map((item, index) => {
            // @ts-ignore
            if (columnNames[index].filterType === 'custom' && filterList[index].length) {
                // @ts-ignore
                const filterListRenderersValue = filterListRenderers[index](item);

                if (Array.isArray(filterListRenderersValue)) {
                    return filterListRenderersValue.map((customFilterItem, customFilterItemIndex) =>
                        customFilterChip(customFilterItem, index, customFilterItemIndex, item, true),
                    );
                } else {
                    return customFilterChip(filterListRenderersValue, index, index, item, false);
                }
            }

            // @ts-ignore
            return item.map((data, colIndex) => filterChip(index, data, colIndex));
        });
    };

    return (
        <div className={classes.root}>
            {serverSide && serverSideFilterList ? getFilterList(serverSideFilterList) : getFilterList(filterList)}
        </div>
    );
};

CustomTableFilterList.propTypes = {
    /** Data used to filter table against */
    filterList: PropTypes.array.isRequired,
    /** Filter List value renderers */
    filterListRenderers: PropTypes.array.isRequired,
    /** Columns used to describe table */
    columnNames: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({ name: PropTypes.string.isRequired, filterType: PropTypes.string }),
        ]),
    ).isRequired,
    /** Callback to trigger filter update */
    onFilterUpdate: PropTypes.func,
    ItemComponent: PropTypes.any,
};

export default CustomTableFilterList;