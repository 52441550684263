import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

export const useUserAuthoritiesService = () => {
    const serviceState = ServiceContainer.useContainer()

    const getUserAuthorities = (userId: number) => {
        if (userId === null || userId === undefined) {
            return { authorities: "" };
        }
        return serviceState.request(
            WebConstants.USER_AUTHORITIES_URL(userId),
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {
            return (response.data);
        })
    }

    return {
        getUserAuthorities
    }
}