import { Checkbox } from "@material-ui/core";
import CustomContainer from '../../container/CustomContainer';

export default function CellFullCheckBox() {
    const customState = CustomContainer.useContainer();

    const handleChange = () => {
        var checkArray = customState.rowCheckStatuslist.slice();
        var checkList: any[] = [];
        var fullCheck = customState.fullCheck;
        fullCheck = !fullCheck;
        if (fullCheck) {
            checkArray.forEach(value => {
                value.check = true;
                checkList.push(value.index)
            })
            customState.setCheckList(checkList);
        } else {
            checkArray.forEach(value => {
                value.check = false;
            })
            customState.setCheckList([]);
        }

        customState.setRowCheckStatuslist(checkArray);
        customState.setFullCheck(fullCheck);
    }
    return (
        <>
            <div style={{padding: '16px'}}>
                {customState.checkList.length === 0 || customState.fullCheck ?
                    <Checkbox checked={customState.fullCheck} onChange={handleChange} color="primary" />
                    :
                    <Checkbox indeterminate checked={customState.fullCheck} onChange={handleChange} color="primary" />
                }
            </div>
        </>
    );
}


