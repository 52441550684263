import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import UserContainer from "../container/UserContainer";
import DocContainer from "../container/DocContainer";
import {
    Box,
    IconButton
} from "@material-ui/core";
import {Bookmark, SpaceBar} from "@material-ui/icons";
import ListIcon from '@material-ui/icons/List';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import {DEFAULT_DATE_FORMAT} from "../constants/Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    button: {
        margin: theme.spacing(1),
        order: -1,
        textAlign: 'left',
        width: 120,
        marginTop:3,
        paddingRight: 12, // keep right padding when drawer closed
    },
}));

export default function SearchBar() {
    const userState = UserContainer.useContainer();
    const docState = DocContainer.useContainer();
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    /**
     * 検索ショートカットボタンをクリック
     * @param id
     */
    const handleClickOpen = (id: number) => {
        docState.searchFromBar(id, null, null);
    };

    return (
        <>
            <Box display="flex" justifyContent="flex-start"  alignItems="flex-start">
                <Box style={{display: "flow", width: "120px"}}>
                    <Button
                        color="default"
                        onClick={() => docState.loadData()}
                        className={classes.button}
                        startIcon={<ListIcon/>}
                    >{t('Text.CheckList')}</Button>
                </Box>
                
                <Box style={{width: 5}}/>
                <Box display="flex" justifyContent="flex-start"  flexWrap ='wrap'>
                {docState.conditionList.map(({id, name}: any) => {
                    return (
                        <li key={id} style={{listStyle: 'none'}}>
                            <Chip
                                key={id}
                                label={name}
                                clickable={true}
                                className={classes.chip}
                                onClick={() => handleClickOpen(id)}
                            />
                        </li>
                    );
                })}
                </Box>
            </Box>
        </>
    );
}
