import React, {FC, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import UserContainer from "../../container/UserContainer";
import TantouInputContent from "./TantouInputContent";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";
import MUIDataTable, {FilterType, SelectableRows} from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import {SpaceBar} from "@material-ui/icons";
import Word from "../../common/Word";
import i18n from "../../i18n";
import PropRenderService from "../../service/PropRenderService";


type Props = {
    id: string,
    title: string;
    isOpen: boolean;
    data: [];
    ButtonText: string;
    doSubmit: (value?: any) => void;
    doClose: () => void;
};
const SearchValueDialog: React.FC<Props> = ({
                                                id,
                                                title,
                                                isOpen,
                                                data,
                                                ButtonText,
                                                doSubmit,
                                                doClose,
                                            }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const {t, i18n} = useTranslation();


    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        doClose();
    };

    const handleSubmit = () => {
        doSubmit(value);
        handleClose();
    };
    const disableOK = () => {
        if (value !== null && value.length > 0) {
            return false;
        }
        return true;
    };
    const columnDefs = [
        {
            label: i18n.t('Text.SearchValue'),
            name: 'key',
            options: {
                filter: false,
                sort: true,
                viewColumns: false
            }
        },
        {
            label: i18n.t('Text.Contents'),
            name: 'display',
            options: {
                filter: false,
                sort: true,
                viewColumns: false
            }
        }];
    /**
     * 選択行の背景色を変える
     * @param index
     * @param clear
     */
    const changeRowColor = (index: number, clear: boolean) => {
        let row = document.getElementById(`MUIDataTableBodyRow-${index}`);
        let color = (clear) ? 'white' : '#EBEBEB';
        // @ts-ignore
        if (row) {
            row.setAttribute('style', 'background:' + color);
        }
    }
    /**
     * テーブルオプション
     */
    const options: any = {
        filterType: 'checkbox' as FilterType,
        selectableRows: 'single' as SelectableRows,
        responsive: 'standard',
        onRowSelectionChange: (currentRowsSelected: any[] | undefined, allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            if (currentRowsSelected && currentRowsSelected.length === 1) {
                let idx = currentRowsSelected[0].dataIndex;
                if (rowsSelected) {
                    //        changeRowColor(rowsSelected[0].dataIndex, true);
                }
                // @ts-ignore
                setValue(data[idx].key);
                //     changeRowColor(idx,false);
            }
        },
        onSearchClose:()=>{
          return;
        },
        customToolbar: () => {
            return (
                <>
                </>
            );
        },
        selectToolbarPlacement: 'none',//選択時のツールバーを非表示
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: false,
        search: false,
        searchOpen: true,
        hideSearchBar: false,
        sort: false,
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        rowHover: false,
        rowsPerPageOptions: [100],
        textLabels: {
            body: {
                noMatch: i18n.t('Text.NoHit'),
                toolTip: '',
                columnHeaderTooltip: '',
            },
            pagination: {
                next: i18n.t('Text.PageDown'),
                previous: i18n.t('Text.PageUp'),
                rowsPerPage: i18n.t('Text.rowsPerPage'),
                displayRows: "/",
            },
            toolbar: {
                search: i18n.t('Text.Search'),
                viewColumns: i18n.t('Text.ColumnDisplaySetting'),
            },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
            },
            viewColumns: {
                title: i18n.t('Text.ColumnDisplaySetting'),
                titleAria: Word('Text.ColumnDisplaySetting'),
            },
            selectedRows: {
                text: i18n.t('Text.Select'),
            },
        }

    };

    return (
        <>
            <Dialog
                id={id}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <Box component="span" m={1}
                     width={400}>
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <MUIDataTable
                        key={'searchValueTable'}
                        title={""}
                        data={data}
                        columns={columnDefs}
                        options={options}
                    />
                    <DialogActions>
                        <Button onClick={handleSubmit} color="primary" disabled={disableOK()}>
                            {ButtonText}
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            {t('Text.Cancel')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
export default SearchValueDialog;
