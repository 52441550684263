import {Checkbox, FormControlLabel} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {SEARCH_CONDITION_TYPE} from "../../constants/Constants";
import DocContainer from "../../container/DocContainer";

/**
 * 値あり、値なしチェックボックスコンポーネント
 * @param props
 * @constructor
 */
const ValueCheckBox = (props: { templateIdx: number, detailIdx: number, conditionType: string ,valueTypeChanged: (check:boolean) => void; }) => {
    const {templateIdx, detailIdx, conditionType,valueTypeChanged} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();

    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
　　//値なし
    const [empty, setEmpty] = React.useState(Boolean(detailCondition.empty));
    //値あり
    const [anyValue, setAnyValue] = React.useState(Boolean(detailCondition.anyValue));

    /**
     * 各チェックボックスの値が変更になった時
     * @param val
     * @param name
     */
    const checkBoxChanged =(val:boolean,name:string)=>{
        let temp = detailCondition;
        temp[name] = val;
        setDetailCondition(temp);
        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
        switch (name){
            case 'empty':
                setEmpty(val);
                break;
            case 'anyValue':
                setAnyValue(val);
                break;
        }
        valueTypeChanged(val);
    }
    {/*   <!-- 値なし --> */}
    return (
        <>
            {(conditionType === SEARCH_CONDITION_TYPE.CUSTOM || conditionType === SEARCH_CONDITION_TYPE.SYSTEM) ?
                <FormControlLabel
                    control={
                        <Checkbox
                            color={'primary'}
                            checked={empty}
                            onChange={(event) => {
                                checkBoxChanged(event.currentTarget.checked,'empty');
                            }}
                        />
                    }
                    label={t('Text.NoValue')}
                /> : null}
            {/*   <!-- 値あり --> */}
            {(conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD) ?
                (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={anyValue}
                                color={'primary'}
                                onChange={(event) => {
                                    checkBoxChanged(event.currentTarget.checked,'anyValue');
                                }}
                            />
                        }
                        label={t('Text.WithValue')}
                    />) : null}
        </>
    );
};

export default ValueCheckBox;
