import * as WebConstants from "../constants/WebConstants";
import { StampInfo } from "../models/StampInfo";
import { StampLocationInfo } from "../models/StampLocationInfo";
import { PreviewPageData } from "../models/PreviewPageData";
import { PreviewStampData } from "../models/PreviewStampData";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * スタンプ関連サービスクラス
 */
export const useStampService = () => {
    const serviceState = ServiceContainer.useContainer()

    const stampWidth = 50;
    const stampHeight = 50;

    /**
     * スタンプ一覧を取得します
     * @returns スタンプ一覧
     */
    const listStamp = () => {
        return serviceState.request(
            WebConstants.STAMP_LIST_URL,
            WebConstants.METHOD_GET,
            null,
        ).then((response) => response.data['stampList'] as StampInfo[]);
    }
    /**
     * 文書に付与するスタンプの座標を取得します。
     * @param preview 文書のプレビュー画像データ
     * @param locationInfo レビューに含まれるスタンプ位置
     * @param unit レビューに含まれるユニット情報
     */
    const getStampCoodinate = (preview: PreviewPageData, locationInfo: StampLocationInfo, stamp: StampInfo, unit: string): { x: number; y: number; } => {
        let widthX = 0;
        let heightY = 0;
        // プレビューデータが存在しない場合は(0, 0)とする
        let pageWidth = preview.width ? preview.width / 72 * 96 : 0;
        let pageHeight = preview.height ? preview.height / 72 * 96 : 0;
        let width = stamp.uiParams?.userSize?.width ?? stampWidth;
        let height = stamp.uiParams?.userSize?.height ?? stampHeight;
        width = Math.round(width * 96 / 25.4); // mm -> px(96dpi)
        height = Math.round(height * 96 / 25.4);
        // ロケーション
        switch (locationInfo.location) {
            // 左上
            case 1:
                break;
            // 中央（上）
            case 2:
                widthX = (pageWidth - width) / 2;
                break;
            // 右上
            case 3:
                widthX = pageWidth - width;
                break;
            // 中央（左）
            case 4:
                heightY = (pageHeight - height) / 2;
                break;
            // 中央（右）
            case 6:
                widthX = pageWidth - width;
                heightY = (pageHeight - height) / 2;
                break;
            // 左下
            case 7:
                heightY = pageHeight - height;
                break;
            // 中央（下）
            case 8:
                widthX = (pageWidth - width) / 2;
                heightY = pageHeight - height;
                break;
            // 右下
            case 9:
                widthX = pageWidth - width;
                heightY = pageHeight - height;
                break;
            // 中央
            default:
                widthX = (pageWidth - width) / 2;
                heightY = (pageHeight - height) / 2;
                break;
        }
        // オフセットの調整
        const locationWidthX = locationInfo.widthX ?? '0';
        const locationHeightY = locationInfo.heightY ?? '0';
        if (unit === 'PX') {
            widthX = widthX + Number(locationWidthX);
            heightY = heightY + Number(locationHeightY);
        } else if (unit === 'MM') {
            widthX = widthX + Number(locationWidthX) * 96 / 25.4;
            heightY = heightY + Number(locationHeightY) * 96 / 25.4;
        }
        return {
            x: widthX,
            y: heightY
        };
    }
    /**
     * スタンプのプレビュー情報を取得する。スタンプに日付情報が入っている場合があるので、使用タイミングを考慮すること。
     * @param id スタンプID
     * @returns スタンププレビューデータ
     */
    const getPreviewStampData = (id: number | string) => {
        return serviceState.request(
            WebConstants.STAMP_PREVIEW_GET_URL(id),
            WebConstants.METHOD_GET,
            null,
            {
                'Accept': 'application/json'
            },
        ).then((response) => {
            return response.data as PreviewStampData;
        });
    }

    const getReviewStamp = (reviewerId: any) => {
        return serviceState.request(
            WebConstants.REVIEWER_STAMP_GET_URL(reviewerId),
            WebConstants.METHOD_GET,
            null,
            {
                'Accept': 'image/png',
            },
            true,
            "arraybuffer"
        ).then(response => {
            return Buffer.from(response.data, 'binary').toString('base64')
        })
    }

    return {
        stampHeight, stampWidth,
        listStamp,
        getStampCoodinate,
        getPreviewStampData,
        getReviewStamp,
    }
}