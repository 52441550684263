import { Divider, IconButton } from "@material-ui/core";
import { SelectableRows } from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Word from "../../common/Word";
import CommonContainer from "../../container/CommonContainer";
import CellConsignorListCheckbox from "../table/CellCustomCheckbox";
import CustomContainer from "../../container/CustomContainer";
import CellFullCheckBox from "../table/CellFullCheckBox";
import UserContainer from "../../container/UserContainer";
import { ConsignorData } from "../../models/ConsignorData";
import { useManageService } from "../../service/ManageService";
import CustomMUIDataTable from "../customMUIDataTable/CustomMUIDataTable";
import { CustomMUIDataTableOptions } from "../customMUIDataTable";
import { table, useStyles } from "./css/Style";
import { ManagementDeleteDialog } from "../dialog/ManagementDeleteDialog";
import { ConsignorDialog } from "../dialog/ConsignorDialog";

export default function ConsignorList(props: { setCallBackMessage: (arg0: { status: number; message: string; open: boolean }) => void; }) {
    //css
    const classes = useStyles();

    const commonState = CommonContainer.useContainer();
    const customState = CustomContainer.useContainer();
    const userState = UserContainer.useContainer();

    const { consignorAddDataApi, consignorDeleteDataApi, consignorGetDataApi, consignorUpdatetDataApi } = useManageService()

    const { t, i18n } = useTranslation();
    const [tableData, setTableData] = React.useState<ConsignorData[]>([]);
    const [tableColumns, setTableColumns] = React.useState(
        [
            {
                name: "",
                options: {
                    sort: false,
                    viewColumns: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <>
                                <CellConsignorListCheckbox meta={tableMeta} />
                            </>
                        )
                    },
                    customHeadLabelRender: (columnMeta: any) => {
                        return (
                            <>
                                <CellFullCheckBox />
                            </>
                        )
                    }
                }
            },
            {
                label: i18n.t('Text.CnsgPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")",
                name: "cd"
            },
            {
                label: i18n.t('Text.CnsgPrivateName'),
                name: "name"
            },
            {
                label: i18n.t('Text.CnsgPstcCd'),
                name: "postcode"
            },
            {
                label: i18n.t('Text.CnsgPstlAdrs'),
                name: "address"
            },
            {
                label: i18n.t('Text.CnsgTelNum'),
                name: "tel"
            },
            {
                label: i18n.t('Text.ConvRuleId'),
                name: "sipConvRuleId"
            },
            {
                label: i18n.t('Text.SrcLocationCd'),
                name: "sipSrcLocationCode"
            }
        ]
    );
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [addData, setAddData] = React.useState<ConsignorData>({});
    const [openAdd, setOpenAdd] = React.useState(false);
    const [editData, setEditData] = React.useState<ConsignorData>({});
    const [openEdit, setOpenEdit] = React.useState(false);
    const [cdList, setCdList] = React.useState<any>([]);

    const options: CustomMUIDataTableOptions = {
        selectableRows: 'single' as SelectableRows,
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        selectableRowsHeader: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: false,
        search: false,
        sort: true,
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            if (allRowsSelected !== undefined) {
                if (currentRowsSelected && currentRowsSelected.length === 1 && customState.rowClickAction !== "checkbox") {
                    let idx = currentRowsSelected[0].dataIndex;
                    tableData.forEach((d, index) => {
                        changeRowColor(index, true);
                    });
                    changeRowColor(idx, false);

                    // 「行コントロール」チェックボックスをクリックします
                    var checkArray = customState.rowCheckStatuslist.slice();
                    checkArray.forEach(value => {
                        if (value.index === idx) {
                            value.check = true;
                            let array = [];
                            array.push(idx);
                            customState.setCheckList(array);
                        } else {
                            value.check = false;
                        }
                    })
                    customState.setRowCheckStatuslist(checkArray);
                    customState.setFullCheck(false);
                }

                customState.setRowClickAction("");
            }
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: ''
            },
            toolbar: {
                viewColumns: Word('Text.ColumnDisplaySetting')
            },
            pagination: {
                next: Word('Text.PageDown'),
                previous: Word('Text.PageUp'),
                rowsPerPage: Word('Text.rowsPerPage'),
                displayRows: "/"
            },
            viewColumns: {
                title: Word('Text.ColumnDisplaySetting')
            }
        },
        onTableChange: (action, tableState) => {
            if (!!tableState.displayData && !!customState.rowCheckStatuslist) {
                customState.rowCheckStatuslist.forEach((val, index) => {
                    if (val.check) {
                        changeRowColor(index, false);
                    } else {
                        changeRowColor(index, true);
                    }
                })
            }
        }
    };

    useEffect(() => {
        getListData();
    }, [])

    /**
     * 選択行の背景色を変える
     * @param index
     * @param clear
     */
    const changeRowColor = (index: number, clear: boolean) => {
        let row = document.getElementById(`MUIDataTableBodyRow-${index}`);
        let color = (clear) ? 'white' : '#EBEBEB';
        // @ts-ignore
        if (row) {
            row.setAttribute('style', 'background:' + color);
        }
    }

    /**
     * 荷送人情報取得
     */
    const getListData = () => {
        commonState.setProgress(true);
        consignorGetDataApi(userState.tenantId as string).then(response => {
            setTableData(response);
            var dataList = [];
            var cdList = [];
            if (response.length > 0) {
                for (let i = 0; i < response.length; i++) {
                    dataList.push({ index: i, check: false })
                    cdList.push(response[i].cd)
                }
            }
            customState.setRowCheckStatuslist(dataList);
            customState.setCheckList([]);
            customState.setFullCheck(false);
            setCdList(cdList);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
            resetData();
        })
    }

    /**
     * 荷送人情報新規
     */
    const handleAddButtonOnClick = (addData: ConsignorData) => {
        commonState.setProgress(true);
        setOpenAdd(false);
        consignorAddDataApi(addData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.AddSuccessful'), open: true });
        }).catch((error) => {
            console.log("****** error is *******", error)
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.AddFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * 荷送人情報編集
     */
    const handleEditButtonOnClick = (editData: ConsignorData) => {
        commonState.setProgress(true);
        setOpenEdit(false);
        consignorUpdatetDataApi(editData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.EditSuccessful'), open: true });
        }).catch((error) => {
            console.log("****** error is *******", error)
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.EditFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * 削除操作の実行
     */
    const handleDeleteButtonOnClick = () => {
        let deleteList: any = [];
        customState.checkList.forEach(element => {
            let params = {
                "tenantId": userState.tenantId,
                "cd": tableData[element].cd
            }
            deleteList.push(params)
        });
        commonState.setProgress(true);
        consignorDeleteDataApi(deleteList).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.DeleteSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.DeleteFailed'), open: true });
            console.log("****** error is ******", error.response);
        }).finally(() => {
            getListData();
            setOpenDeleteAlert(false);
        })
    }

    /**
     * 編集ダイアログを開く
     */
    const editOpen = () => {
        const selectedRowData = { ...tableData[customState.checkList[0]] };
        setOpenEdit(true);
        setEditData(Object.assign({}, selectedRowData));
    }

    /**
     * ダイアログを閉じる
     */
    const handleClose = () => {
        setOpenAdd(false)
        resetData();
    }

    const resetData = () => {
        setAddData({
            cd: "",
            name: "",
            postcode: "",
            address: "",
            tel: "",
            sipConvRuleId: "",
            sipSrcLocationCode: ""
        });
    }

    return (
        <>
            <div className={classes.tabTitle}>{t('Text.CnsgInfo')}</div>
            <Divider />
            <div className={classes.iconStyle}>
                <IconButton onClick={() => setOpenAdd(true)}>
                    <AddIcon className={classes.addButtonActived} />
                </IconButton>
                <IconButton onClick={editOpen} disabled={customState.checkList.length !== 1}>
                    <EditIcon className={customState.checkList.length === 1 ? classes.editButtonActived : classes.editButtonDisabled} />
                </IconButton>
                <IconButton onClick={() => setOpenDeleteAlert(true)} disabled={customState.checkList.length === 0}>
                    <CloseIcon className={customState.checkList.length > 0 ? classes.deleteButtonActived : classes.deleteButtonDisabled} />
                </IconButton>
            </div>
            <Divider />
            <div style={{ width: '100%', backgroundColor: 'white', padding: '10px 10px 20px 10px' }}>
                <MuiThemeProvider theme={table()}>
                    <CustomMUIDataTable
                        columns={tableColumns}
                        data={tableData}
                        title={undefined}
                        options={options}>
                    </CustomMUIDataTable>
                </MuiThemeProvider>
            </div>

            {/* 荷送人情報の情報管理の新規 */}
            <ConsignorDialog
                title={t('Text.New')}
                isOpen={openAdd}
                action="add"
                data={addData}
                cdList={cdList}
                doClose={() => handleClose()}
                doClick={(addData) => handleAddButtonOnClick(addData)}
            />
            
            {/* 荷送人情報の情報管理の編集 */}
            <ConsignorDialog
                title={t('Text.Edit')}
                isOpen={openEdit}
                action="edit"
                data={editData}
                cdList={cdList}
                doClose={() => setOpenEdit(false)}
                doClick={(editData) => handleEditButtonOnClick(editData)}
            />

            {/* 削除のヒント */}
            <ManagementDeleteDialog
                isOpen={openDeleteAlert}
                doClose={() => setOpenDeleteAlert(false)}
                deleteButtonOnClick={() => handleDeleteButtonOnClick()}
            />
        </>
    )
}