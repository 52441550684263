import { Button, Divider, IconButton } from "@material-ui/core";
import { SelectableRows } from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from "react";
import { ReceiptData } from "../../models/ReceiptData";
import { useTranslation } from 'react-i18next';
import Word from "../../common/Word";
import { useManageService } from "../../service/ManageService";
import CommonContainer from "../../container/CommonContainer";
import CellReceiptCheckbox from "../table/CellCustomCheckbox";
import CustomContainer from "../../container/CustomContainer";
import CellFullCheckBox from "../table/CellFullCheckBox";
import UserContainer from "../../container/UserContainer";
import CustomMUIDataTable from "../customMUIDataTable/CustomMUIDataTable";
import { CustomMUIDataTableOptions } from "../customMUIDataTable";
import { dialog, table, useStyles } from "./css/Style";
import { TreeViewDialog } from "../dialog/TreeViewDialog";
import { ManagementDeleteDialog } from "../dialog/ManagementDeleteDialog";
import { ReceiptDialog } from "../dialog/ReceiptDialog";
import { RenderTree } from "../../models/RenderTree";

const getStatusMap = {
    "未取得のみ": "0",
    "取得済のみ": "1",
    "すべて取得": "2",
}

export default function ReceiptList(props: { setCallBackMessage: (arg0: { status: number; message: string; open: boolean }) => void; }) {
    // css
    const classes = useStyles();

    const commonState = CommonContainer.useContainer();
    const customState = CustomContainer.useContainer();
    const userState = UserContainer.useContainer();

    const { folderListApi, insertFolderApi, updateFolderApi,
        receiptAddDataApi, receiptGetDataApi, getFolderNameApi, receiptEditDataApi, receiptDeleteDataApi } = useManageService()

    const { t, i18n } = useTranslation();
    const [tableData, setTableData] = React.useState<ReceiptData[]>([]);
    const [tableColumns, setTableColumns] = React.useState(
        [
            {
                name: "",
                options: {
                    sort: false,
                    viewColumns: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <>
                                <CellReceiptCheckbox meta={tableMeta} />
                            </>
                        )
                    },
                    customHeadLabelRender: (columnMeta: any) => {
                        return (
                            <>
                                <CellFullCheckBox />
                            </>
                        )
                    }
                }
            },
            {
                label: i18n.t('Text.SubmitDatetime'),
                name: "delDateDttm",
                options: {
                    viewColumns: false
                }
            },
            {
                label: i18n.t('Text.DelDateDttmRelative'),
                name: "delDateDttmRelative",
                options: {
                    viewColumns: false
                }
            },
            {
                label: i18n.t('Text.CneePrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")",
                name: "cneePrivateCdId"
            },
            {
                label: i18n.t('Text.CnsgPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")",
                name: "cnsgPrivateCdId",
                options: {
                    viewColumns: false
                }
            },
            {
                label: i18n.t('Text.ShipToPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")",
                name: "shipToPrivateCdId"
            },
            {
                label: i18n.t('Text.ShipFromPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")",
                name: "shipFromPrivateCdId"
            },
            {
                label: i18n.t('Text.OrdNumId'),
                name: "ordNumId"
            },
            {
                label: i18n.t('Text.VouDateIssDttm'),
                name: "vouDateIssDttm"
            },
            {
                label: i18n.t('Text.VouDateIssDttmRelative'),
                name: "vouDateIssDttmRelative"
            },
            {
                label: i18n.t('Text.TrspCliPrivateCdId') + "(" + i18n.t('Text.InternalLocal') + ")",
                name: "trspCliPrivateCdId"
            },
            {
                label: i18n.t('Text.FetchFlag'),
                name: "fetchFlag"
            },
            {
                label: i18n.t('Text.ReceiptGetStatus'),
                name: "getStatus"
            }
        ]
    );
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [addData, setAddData] = React.useState<ReceiptData>({});
    const [openAdd, setOpenAdd] = React.useState(false);
    const [editData, setEditData] = React.useState<ReceiptData>({});
    const [openEdit, setOpenEdit] = React.useState(false);
    const [folderTree, setFolderTree] = React.useState<boolean>(false);
    const [treeView, setTreeView] = React.useState<RenderTree>({ id: "1", name: "/", path: "/" });
    const [folderPath, setFolderPath] = React.useState<string>();
    const [folderIdStatus, setFolderIdStatus] = React.useState<boolean>(true);
    const [delDateDttmDateChoose, setDelDateDttmDateChoose] = React.useState<boolean>(false);
    const [vouDateIssDttmDateChoose, setVouDateIssDttmDateChoose] = React.useState<boolean>(false);
    const [currentRow, setCurrentRow] = React.useState<any>({});

    const options: CustomMUIDataTableOptions = {
        selectableRows: 'single' as SelectableRows,
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        selectableRowsHeader: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: false,
        search: false,
        sort: true,
        filter: false,
        print: false,
        download: false,
        viewColumns: true,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            // 現在の行データを取得する
            getSelectedRowData(currentRowsSelected);

            if (allRowsSelected !== undefined) {
                if (currentRowsSelected && currentRowsSelected.length === 1 && customState.rowClickAction !== "checkbox") {
                    let idx = currentRowsSelected[0].dataIndex;
                    tableData.forEach((d, index) => {
                        changeRowColor(index, true);
                    });
                    changeRowColor(idx, false);

                    // 「行コントロール」チェックボックスをクリックします
                    var checkArray = customState.rowCheckStatuslist.slice();
                    checkArray.forEach(value => {
                        if (value.index === idx) {
                            value.check = true;
                            let array = [];
                            array.push(idx);
                            customState.setCheckList(array);
                        } else {
                            value.check = false;
                        }
                    })
                    customState.setRowCheckStatuslist(checkArray);
                    customState.setFullCheck(false);
                }

                customState.setRowClickAction("");
            }
        },
        onTableChange: (action, tableState) => {
            if (!!tableState.displayData && !!customState.rowCheckStatuslist) {
                customState.rowCheckStatuslist.forEach((val, index) => {
                    if (val.check) {
                        changeRowColor(index, false);
                    } else {
                        changeRowColor(index, true);
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: ''
            },
            toolbar: {
                viewColumns: Word('Text.ColumnDisplaySetting'),
            },
            viewColumns: {
                title: Word('Text.ColumnDisplaySetting')
            },
            pagination: {
                next: Word('Text.PageDown'),
                previous: Word('Text.PageUp'),
                rowsPerPage: Word('Text.rowsPerPage'),
                displayRows: "/",
            }
        }
    };

    useEffect(() => {
        getListData();
    }, [])

    useEffect(() => {
        tableData.forEach((val, index) => {
            if (customState.checkList.indexOf(index) > -1) {
                changeRowColor(index, false)
            } else {
                changeRowColor(index, true)
            }
        })
    }, [customState.checkList])

    /**
     * 選択行の背景色を変える
     * @param index
     * @param clear
     */
    const changeRowColor = (index: number, clear: boolean) => {
        let row = document.getElementById(`MUIDataTableBodyRow-${index}`);
        let color = (clear) ? 'white' : '#EBEBEB';
        // @ts-ignore
        if (row) {
            row.setAttribute('style', 'background:' + color);
        }
    }

    /**
     * 受領書の情報管理情報取得
     */
    const getListData = () => {
        commonState.setProgress(true);
        Promise.all([
            receiptGetDataApi(userState.tenantId as string),
            folderListApi(),
            getFolderNameApi(userState.tenantId as string)
        ]).then(responses => {
            let data = responses[0];
            let dataList = [];
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    dataList.push({ index: i, check: false })
                    data[i].fetchFlag = data[i].fetchFlag === "1" ? i18n.t('Text.Obtain') : i18n.t('Text.NotObtain');
                    data[i].getStatus = data[i].getStatus == "0" ? i18n.t('Text.OnlyUnavailable') : data[i].getStatus == "1" ? i18n.t('Text.OnlyAvailable') : i18n.t('Text.GetAll');
                }
            }
            customState.setRowCheckStatuslist(dataList);
            setTreeView({ ...treeView, children: responses[1].folderList });
            if (responses[2].length > 0) {
                var folderInfo = findFolder(responses[2][0].folderId, responses[1].folderList);
                if (folderInfo) {
                    setFolderPath(folderInfo.path);
                } else {
                    setFolderIdStatus(false)
                    setFolderPath(i18n.t('Text.NotSelect'));
                }
            } else {
                setFolderPath(i18n.t('Text.NotSelect'));
            }
            setTableData(data);
            customState.setCheckList([]);
            customState.setFullCheck(false);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
            resetData();
        })
    }

    /**
     * ファイルパスを見つける
     * @param id フォルダ ID
     * @param data フォルダのリスト
     * @returns フォルダ情報
     */
    const findFolder = (id: string, data: RenderTree[]) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                return data[i];
            }
            if (data[i].children!.length > 0) {
                let folderInfo: any = findFolder(id, data[i].children!);
                if (folderInfo) {
                    return folderInfo;
                }
            }
        }
        return null;
    }

    /**
     * 受領書の情報管理情報新規
     */
    const handleAddButtonOnClick = (addData: ReceiptData) => {
        setOpenAdd(false);
        commonState.setProgress(true);
        receiptAddDataApi({ tenantId: userState.tenantId, ...addData }).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.AddSuccessful'), open: true });
        }).catch((error) => {
            console.log("****** error is *******", error)
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.AddFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * 受領書の情報管理情報編集
     */
    const handleEditButtonOnClick = (editData: ReceiptData) => {
        setOpenEdit(false);
        commonState.setProgress(true);
        receiptEditDataApi(editData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.EditSuccessful'), open: true });
        }).catch((error) => {
            console.log("****** error is *******", error)
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.EditFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * 削除操作の実行
     */
    const handleDeleteButtonOnClick = () => {
        const idArray: any[] = [];
        customState.checkList.forEach(value => {
            idArray.push(tableData[value]?.id)
        })
        let params = {
            id: idArray.join(',')
        }
        setOpenDeleteAlert(false);
        commonState.setProgress(true);
        receiptDeleteDataApi(params).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.DeleteSuccessful'), open: true });
        }).catch((error) => {
            setOpenDeleteAlert(false);
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.DeleteFailed'), open: true });
            console.log("****** error is ******", error.response);
        }).finally(() => {
            getListData();
        });
    }

    /**
     * 現在の行データを取得する
     */
    const getSelectedRowData = (currentRowsSelected: any[]) => {
        const currentRow = { ...tableData[currentRowsSelected[0].dataIndex] };
        currentRow.fetchFlag = currentRow.fetchFlag === i18n.t('Text.Obtain') ? "1" : "0";
        currentRow.getStatus = getStatusMap[currentRow.getStatus as keyof typeof getStatusMap];
        setCurrentRow(currentRow);
    }

    /**
     * 追加ダイアログボックスを開く
     */
    const addOpen = () => {
        setOpenAdd(true);
        setDelDateDttmDateChoose(true);
        setVouDateIssDttmDateChoose(true);
    }

    /**
     * 編集ダイアログを開く
     */
    const editOpen = () => {
        setOpenEdit(true);
        if (!!currentRow.delDateDttm) {
            setDelDateDttmDateChoose(true);
        } else {
            setDelDateDttmDateChoose(false);
        }

        if (!!currentRow.vouDateIssDttm) {
            setVouDateIssDttmDateChoose(true);
        } else {
            if (!!!currentRow.vouDateIssDttmRelative) {
                setVouDateIssDttmDateChoose(true);
            } else {
                setVouDateIssDttmDateChoose(false);
            }
        }
        setEditData(currentRow);
    }

    /**
     * ダイアログを閉じる
     */
    const handleAddClose = () => {
        setOpenAdd(false)
        resetData();
    }

    const resetData = () => {
        setAddData({
            delDateDttm: "",
            delDateDttmRelative: "",
            cneePrivateCdId: "",
            cnsgPrivateCdId: "",
            shipToPrivateCdId: "",
            shipFromPrivateCdId: "",
            ordNumId: "",
            vouDateIssDttm: "",
            vouDateIssDttmRelative: "",
            trspCliPrivateCdId: "",
            fetchFlag: "1",
            getStatus: "0"
        });
    }

    /**
     * 選択フォルダー
     */
    const selectFolder = (folder: RenderTree) => {
        let params = {
            tenantId: userState.tenantId,
            folderId: folder?.id
        }
        commonState.setProgress(true);
        if (folderPath === i18n.t('Text.NotSelect') && folderIdStatus) {
            insertFolderApi(params).then((data) => {
                setFolderPath(folder?.path);
                setFolderTree(false);
            }).catch((error) => {
                console.log("****** error is *******", error.response);
            })
        } else {
            updateFolderApi(params).then((data) => {
                setFolderPath(folder?.path);
                setFolderTree(false);
            }).catch((error) => {
                console.log("****** error is *******", error.response);
            })
        }
        commonState.setProgress(false);
    }

    return (
        <>
            <div className={classes.tabTitle}>{t('Text.ReceiptInformationManagement')}</div>
            <Divider />
            <div className={classes.iconStyle}>
                <IconButton onClick={addOpen}>
                    <AddIcon className={classes.addButtonActived} />
                </IconButton>
                <IconButton onClick={editOpen} disabled={customState.checkList.length !== 1}>
                    <EditIcon className={customState.checkList.length === 1 ? classes.editButtonActived : classes.editButtonDisabled} />
                </IconButton>
                <IconButton onClick={() => setOpenDeleteAlert(true)} disabled={customState.checkList.length === 0}>
                    <CloseIcon className={customState.checkList.length > 0 ? classes.deleteButtonActived : classes.deleteButtonDisabled} />
                </IconButton>
            </div>
            <Divider />
            <div style={{ display: 'flex', margin: ' 5px 15px' }}>
                <span style={{ lineHeight: '35px' }}>{t('Text.ReceiptFolder')}</span>
                <Button style={{ backgroundColor: "#e2e2e2", marginLeft: '20px' }} size='small' onClick={() => { setFolderTree(true) }}>
                    {t('Text.Select')}
                </Button>
                <span style={{ lineHeight: '35px', marginLeft: '20px' }}>{folderPath}</span>
            </div>
            <div style={{ width: '100%', backgroundColor: 'white', padding: '10px 10px 20px 10px' }}>
                <MuiThemeProvider theme={table()}>
                    <CustomMUIDataTable
                        columns={tableColumns}
                        data={tableData}
                        title={undefined}
                        options={options}>
                    </CustomMUIDataTable>
                </MuiThemeProvider>
            </div>

            <MuiThemeProvider theme={dialog()}>
                {/* 受領書の情報管理の新規 */}
                <ReceiptDialog
                    title={t('Text.New')}
                    isOpen={openAdd}
                    data={addData}
                    delDateDttmDateChoose={delDateDttmDateChoose}
                    vouDateIssDttmDateChoose={vouDateIssDttmDateChoose}
                    doClose={() => handleAddClose()}
                    doClick={(addData) => handleAddButtonOnClick(addData)}
                />
                {/* 受領書の情報管理の編集 */}
                <ReceiptDialog
                    title={t('Text.Edit')}
                    isOpen={openEdit}
                    data={editData}
                    delDateDttmDateChoose={delDateDttmDateChoose}
                    vouDateIssDttmDateChoose={vouDateIssDttmDateChoose}
                    doClose={() => setOpenEdit(false)}
                    doClick={(editData) => handleEditButtonOnClick(editData)}
                />
            </MuiThemeProvider>

            {/* 削除のヒント */}
            <ManagementDeleteDialog
                isOpen={openDeleteAlert}
                doClose={() => setOpenDeleteAlert(false)}
                deleteButtonOnClick={() => handleDeleteButtonOnClick()}
            />

            {/* ツリービュー (Tree View) */}
            <TreeViewDialog
                isOpen={folderTree}
                treeView={treeView}
                selectFolder={(folder) => selectFolder(folder)}
                doClose={() => setFolderTree(false)}
            />
        </>
    )
}