import {Box, FormControl, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";
import AmbiguitySelect from "./AmbiguitySelect";
import TextMatchSelect from "./TextMatchSelect";
import ValueCheckBox from "./ValueCheckBox";
import SearchValueTextField from "./SearchValueTextField";

/**
 * ドロップダウン検索条件
 * @param props
 * @constructor
 */
const DropDownCondition = (props: { templateIdx: number, detailIdx: number, conditionType: string, options: [] }) => {
    const {templateIdx, detailIdx, conditionType, options} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
    const [value, setValue] = React.useState(detailCondition.dropdownValue);
    const handleChange = (event: object) => {
        let temp = detailCondition;
        // @ts-ignore
        temp.dropdownValue = event.target.value;
        setDetailCondition(temp);
        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
        // @ts-ignore
        setValue(event.target.value);
    }
    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey = (pre: string) => {
        return pre + '_' + templateIdx + '_' + detailIdx;
    }



    const renderCondition = () => {
        return (
            <FormControl  key={getKey('dropDown')} variant="outlined"  margin='dense'>
                <Select key={getKey('dropDownSelect')}
                        style={{width:'180px'}}
                        value={value}
                        onChange={handleChange}
                        displayEmpty
                >
                    {options.map(({label, value}: any) => {
                        return (<MenuItem key={value} value={value}>{t(label)}</MenuItem>);
                    })
                    }

                </Select>
            </FormControl>
        );
    }

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" key={'date' + detailIdx}
                 className="conditionsTemplateDetail" mx={2}>
                {renderCondition}
            </Box>


        </>
    );
};

export default DropDownCondition;
