type DocMapType = 'common' | 'repeat'

const DefaultFormName: string = '仕様名なし'

class DocMapModel {
    // data detail
    defineName?: string;
    sipInfrastructure?: string;
    iaDocumentManagement?: string;
    defaultValue?: string;

    // html
    checked?: boolean;
    isDefault?: boolean;

    constructor(defineName: string, sipInfrastructure: string, iaDocumentManagement: string, isDefault: boolean = false, defaultValue: string = '') {
        this.defineName = defineName
        this.sipInfrastructure = sipInfrastructure
        this.iaDocumentManagement = iaDocumentManagement
        this.checked = false
        this.isDefault = isDefault
        this.defaultValue = defaultValue
    }
}

class DocDefField {
    formName: string;
    searchFields: any[];

    constructor(formName: string) {
        this.formName = !!formName ? formName : DefaultFormName
        this.searchFields = []
    }
}

export type {
    DocMapType
}

export default DocMapModel

export {
    DocDefField,
    DefaultFormName,
}