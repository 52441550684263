import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DialogResult, DialogType } from "../../models/SipStandardMessage";
import { DialogContentText, makeStyles } from "@material-ui/core";

type Props = {
    visible: boolean
    title: string
    type?: DialogType
    content?: string
    onClose: (param: DialogResult) => void
};
export const SelfDialog: React.FC<Props> = ({ visible,
    title,
    type,
    content,
    onClose,
}) => {
    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false)

    useEffect(() => {
        setDialogVisible(visible)
    }, [visible])

    /**
     * ポップアップボックスを閉じる
     * @param param 
    */
    const handleClose = (param: DialogResult) => {
        setDialogVisible(false)
        onClose(param)
    }

    const useStyles = makeStyles((theme) => ({
        dialogContent: {
            'width': 'auto',
            'min-width': '300px',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
        },
        'dialogSymbol-confirm': {
            'fontSize': '32px',
            marginRight: '10px',
            color: '#409eff'
        },
        'dialogSymbol-warn': {
            'fontSize': '32px',
            marginRight: '10px',
            color: '#f56c6c'
        },
        dialogTextContent: {
            margin: '0',
            wordBreak: 'break-all',
        }
    }))

    const dialogClass = useStyles()

    return (
        <>
            <Dialog
                open={dialogVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent className={dialogClass.dialogContent}>
                    {type == 'warn'
                        ?
                        <ErrorOutlineIcon className={dialogClass["dialogSymbol-warn"]} />
                        :
                        <HelpOutlineIcon className={dialogClass["dialogSymbol-confirm"]} />}
                    <DialogContentText id="alert-dialog-description" className={dialogClass.dialogTextContent}>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose('ok')} color="primary" autoFocus>
                        Ok
                    </Button>
                    {type == 'warn'
                        ?
                        <></>
                        :
                        <Button onClick={() => handleClose('cancel')} color="primary" >
                            Cancel
                        </Button>}
                </DialogActions>
            </Dialog >
        </>
    );
};

