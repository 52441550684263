
import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * Permission Get For Login User
 */
export const usePermission4LoginUserService = () => {
    const axios = ServiceContainer.useContainer();

    const getPermission4LoginUser = (docId: number | undefined) => {
        let formBody = 'docIds=' + docId;
        return axios.request(
            WebConstants.PERMISSION_GET_LOGIN_USER_URL,
            WebConstants.METHOD_POST,
            formBody,
            WebConstants.HEADER_CONTENTTYPE_URLENCODED
        ).then((response) => {
            console.log("****** permissionGet4LoginUser is *******", response);
            return response.data;
            //        }
            //    ).catch((error) => {
            //        console.log("****** error is *******", error.response);
        })
    }

    return {
        getPermission4LoginUser
    }
}