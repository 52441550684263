import * as WebConstants from "../constants/WebConstants";
import { DefaultFormName, DocDefField } from "../models/DocMapModel";
import ServiceContainer from "../container/AxiosServiceContainer";

export const usePageService = () => {
    const axios = ServiceContainer.useContainer()

    const fixDataApi = (id: number | string): Promise<DocDefField[]> => {
        return axios.request(
            WebConstants.STYLENAME_URL(id),
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {
            let searchFieldList: Array<any> = response.data.searchFields;
            let docDefFields: DocDefField[] = []

            searchFieldList.forEach(searchField => {
                let formName = !!searchField.formName ? searchField.formName : DefaultFormName
                let index = -1
                if ((index = docDefFields.findIndex(docDefField => docDefField.formName == formName)) == -1) {
                    let docDefFieldNew = new DocDefField(formName)
                    docDefFieldNew.searchFields.push(searchField)
                    docDefFields.push(docDefFieldNew)
                } else {
                    docDefFields[index].searchFields.push(searchField)
                }
            })

            return docDefFields;
        });
    }

    const saveDocMapApi = (dataArray: any): Promise<any> => {
        return axios.request(
            WebConstants.DOCUMENT_SAVE_URL,
            WebConstants.METHOD_POST,
            dataArray,
            WebConstants.HEADER_ACCEPT_JSON
        )
    }

    const getDocMapApi = (params: any) => {
        return axios.request(
            WebConstants.DOCUMENT_GET_URL,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response
        })
    }

    const saveReceiveDocMapApi = (dataArray: any): Promise<any> => {
        return axios.request(
            WebConstants.RECEIVE_DOCUMENT_SAVE_URL,
            WebConstants.METHOD_POST,
            dataArray,
            WebConstants.HEADER_ACCEPT_JSON
        )
    }

    const getReceiveDocMapApi = (params: any) => {
        return axios.request(
            WebConstants.RECEIVE_DOCUMENT_GET_URL,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response
        })
    }

    return {
        fixDataApi, saveDocMapApi, getDocMapApi, saveReceiveDocMapApi, getReceiveDocMapApi
    }
}