import {Box, TextField} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";

/**
 * フリーワード検索
 * @param props
 * @constructor
 */
const FreeWordCondition = (props: {condition:any }) => {
    const {condition} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    const [value, setValue] = React.useState(condition.freeword.val);

    const handleInputChange = ( value: any) => {
        docState.applySearchExecCondition('freeword',value);
        setValue(value);
    }

    {/*  render*/}
    return (
        <>
            <Box mx={2} display="flex" flexDirection="row"　alignItems="center">
            {/*  テキストフィールド*/}
            <TextField  label={t('Msg.EnterSearchKeyword')}
                        variant="outlined"
                        key={'textExecFreeword'}
                        style={{width:'500px', height:'60px'}}
                       id={'textExecFreeword'}
                       value={value ? value : ''}
                /* styles the input component */
                       onChange={(e)=>handleInputChange(e.currentTarget.value)}
            />
            </Box>
        </>
    );
}

export default FreeWordCondition;
