import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendContainer from '../../container/SendContainer';
import { Divider, Input, Select, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import { useSendService } from '../../service/SendService';
import moment, { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../constants/Constants';
import CommonContainer from '../../container/CommonContainer';
import { DATE_FORMAT } from '../../constants/PropertiesConstants';
import UserContainer from '../../container/UserContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledDatePicker, StyledDateTimePicker } from '../datepicker/StyledDatePicker';

type Props = {
    title: string,
    isOpen: boolean
};

export const FilterDataDialog: React.FC<Props> = ({
    title, isOpen
}) => {

    // コンテナ
    const sendState = SendContainer.useContainer();
    const useState = UserContainer.useContainer();
    const commonState = CommonContainer.useContainer();

    const { getFilterDataApi } = useSendService()

    // 変数
    const { t, i18n } = useTranslation();

    /**
     * 送信データの検索し、ダイアログを閉じる
     */
    const handleClickOpen = () => {
        const sendDateArray = [sendState.searchData.sendDatetimeStart, sendState.searchData.sendDatetimeEnd];
        const submitDateArray = [sendState.searchData.submitDatetimeStart, sendState.searchData.submitDatetimeEnd];
        const sendDateEmptyValues = sendDateArray.filter(value => value === "");
        const submitDateEmptyValues = submitDateArray.filter(value => value === "");
        // 開始時刻と終了時刻の一方がNULLかどうかの判定。
        if (sendDateEmptyValues.length === 1 || submitDateEmptyValues.length === 1) {
            sendState.setWarningDialogStatus(true);
            return;
        }
        // 開始時刻が終了時刻より大きいかどうかを判断する。
        if (sendState.searchData.sendDatetimeStart > sendState.searchData.sendDatetimeEnd || sendState.searchData.submitDatetimeStart! > sendState.searchData.submitDatetimeEnd!) {
            sendState.setWarningDialogStatus(true);
            return;
        }

        commonState.setProgress(true);
        const sendDatetimeStart = !!sendState.searchData.sendDatetimeStart ? moment(sendState.searchData.sendDatetimeStart).format("YYYY-MM-DDTHH:mmZ") : "";
        const sendDatetimeEnd = !!sendState.searchData.sendDatetimeEnd ? moment(sendState.searchData.sendDatetimeEnd).format("YYYY-MM-DDTHH:mmZ") : "";
        const data = { ...sendState.searchData, tenantId: useState.tenantId, sendDatetimeStart: sendDatetimeStart, sendDatetimeEnd: sendDatetimeEnd };
        getFilterDataApi(data).then(async responseData => {
            if (responseData) {
                responseData.forEach((value: any) => {
                    value.sendDatetime = value.sendDatetime ? moment(value.sendDatetime).format(DEFAULT_DATE_FORMAT) : null;
                    value.submitDatetime = value.submitDatetime ? moment(value.submitDatetime).format(DATE_FORMAT[3]) : null;
                });
            }
            sendState.setFilteData(responseData);
            sendState.setIsOpen(true);
            sendState.setStatusCode(200);
        }).catch((e) => {
            sendState.setFilteData([]);
        }).finally(() => {
            sendState.resetData();
            sendState.setOpen(false);
            commonState.setProgress(false);
        })

    };

    /**
     * フィルタダイアログを閉じる
     */
    const handleClose = () => {
        sendState.resetData();
        sendState.setOpen(false);
    };

    /**
     * 入力ボックスに入力されたデータをSearchDataに格納する
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any; }) => {
        let { target: { value, name } } = e;
        switch (name) {
            case "sendDatetimeStart":
                //sendState.setSearchData({ ...sendState.searchData, sendDatetimeStart: moment(value).format("YYYY-MM-DDTHH:mmZ") });
                sendState.setSearchData({ ...sendState.searchData, sendDatetimeStart: value.replace("T", " ") });
                break;
            case "sendDatetimeEnd":
                //sendState.setSearchData({ ...sendState.searchData, sendDatetimeEnd: moment(value).format("YYYY-MM-DDTHH:mmZ") });
                sendState.setSearchData({ ...sendState.searchData, sendDatetimeEnd: value.replace("T", " ") });
                break;
            case "submitDatetimeStart":
                sendState.setSearchData({ ...sendState.searchData, submitDatetimeStart: value.replaceAll("-", "") });
                break;
            case "submitDatetimeEnd":
                sendState.setSearchData({ ...sendState.searchData, submitDatetimeEnd: value.replaceAll("-", "") });
                break;
            default:
                sendState.setSearchData({ ...sendState.searchData, [name]: value });
                break;
        }
    }

    return (
        <div>
            <Dialog
                open={sendState.warningDialogStatus}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{t('Text.Warning')}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ReportProblemRoundedIcon color='error' fontSize='large' style={{ marginRight: "20px" }} />
                        {t('Msg.WrongSpecifiedDate')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" onClick={() => sendState.setWarningDialogStatus(false)} color="primary">
                        {t('Text.Ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <form>
                        {/* ファイル名 */}
                        <label>{t('Text.FileName')}:</label>
                        <Input
                            id="standard"
                            name="fileName"
                            style={{ marginLeft: '62px' }}
                            value={sendState.searchData.fileName}
                            onChange={handleTextFieldOnChange}
                        /><br /><br />
                        {/* フォルダ名 */}
                        <label>{t('Text.FolderName')}:</label>
                        <Input
                            id="standard"
                            name="folderName"
                            style={{ marginLeft: '48px' }}
                            value={sendState.searchData.folderName}
                            onChange={handleTextFieldOnChange}
                        /><br /><br />
                        {/* データ処理No．*/}
                        <label>{t('Text.DataNo')} :</label>
                        <Input
                            id="standard-helperText"
                            name="dataNo"
                            style={{ marginLeft: '36px' }}
                            value={sendState.searchData.dataNo}
                            onChange={handleTextFieldOnChange}
                        /><br /><br />
                        {/* 納品伝票番号 */}
                        <label>{t('Text.SubmitNo')}:</label>
                        <Input
                            id="standard-helperText"
                            name="submitNo"
                            style={{ marginLeft: '48px' }}
                            value={sendState.searchData.submitNo}
                            onChange={handleTextFieldOnChange}
                        /><br /><br />
                        {/* 送信日時分秒 */}
                        <label>{t('Text.SendDatetime')}: </label><br />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StyledDateTimePicker
                                name="sendDatetimeStart"
                                value={sendState.searchData.sendDatetimeStart ? moment(sendState.searchData.sendDatetimeStart) : null}
                                sx={{ width: '190px' }}
                                format={"YYYY-MM-DD HH:mm"}
                                onChange={(valueNew) => {
                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                        handleTextFieldOnChange({ target: { value: "", name: "sendDatetimeStart" } })
                                    } else {
                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DDTHH:mm"), name: "sendDatetimeStart" } })
                                    }
                                }}
                            />
                            <span style={{ margin: '0 5px 0 5px' }}>{t('Text.FromToDash')}</span>
                            <StyledDateTimePicker
                                name="sendDatetimeEnd"
                                value={sendState.searchData.sendDatetimeEnd ? moment(sendState.searchData.sendDatetimeEnd) : null}
                                sx={{ width: '190px' }}
                                format={"YYYY-MM-DD HH:mm"}
                                onChange={(valueNew) => {
                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                        handleTextFieldOnChange({ target: { value: "", name: "sendDatetimeEnd" } })
                                    } else {
                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DDTHH:mm"), name: "sendDatetimeEnd" } })
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        <br />
                        <br />
                        {/* 納入予定日 */}
                        <label>{t('Text.SubmitDatetime')}:</label><br />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StyledDatePicker
                                name="submitDatetimeStart"
                                value={sendState.searchData.submitDatetimeStart ? moment(sendState.searchData.submitDatetimeStart) : null}
                                sx={{ width: '170px' }}
                                format={"YYYY-MM-DD"}
                                onChange={(valueNew) => {
                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                        handleTextFieldOnChange({ target: { value: "", name: "submitDatetimeStart" } })
                                    } else {
                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DD"), name: "submitDatetimeStart" } })
                                    }
                                }} />
                            <span style={{ margin: '0 5px 0 5px' }}>{t('Text.FromToDash')}</span>
                            <StyledDatePicker
                                name="submitDatetimeEnd"
                                value={sendState.searchData.submitDatetimeEnd ? moment(sendState.searchData.submitDatetimeEnd) : null}
                                sx={{ width: '170px' }}
                                format={"YYYY-MM-DD"}
                                onChange={(valueNew) => {
                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                        handleTextFieldOnChange({ target: { value: "", name: "submitDatetimeEnd" } })
                                    } else {
                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DD"), name: "submitDatetimeEnd" } })
                                    }
                                }} />
                        </LocalizationProvider>
                        <br />
                        <br />
                        {/* 接続先ユーザ名 */}
                        <label>{t('Text.SendUser')}:</label>
                        <Input
                            id="standard-number"
                            name="userName"
                            style={{ marginLeft: '34px' }}
                            onChange={handleTextFieldOnChange}
                            value={sendState.searchData.userName}
                        /><br /><br />
                        {/* 伝票送信方法 */}
                        <label>{t('Text.SendMethod')}:</label>
                        <Input
                            id="standard"
                            name="sendMethod"
                            style={{ marginLeft: '48px' }}
                            onChange={handleTextFieldOnChange}
                            value={sendState.searchData.sendMethod}
                        /><br /><br />
                        {/* 伝票送信状態 */}
                        <label>{t('Text.SendResult')}:</label>
                        <div style={{ marginLeft: '48px', display: 'inline-block' }}>
                            <Select
                                name="sendResult"
                                native
                                value={sendState.searchData.sendResult}
                                onChange={handleTextFieldOnChange}
                                input={<Input id="demo-dialog-native" />}
                            >
                                <option aria-label="None" value="" />
                                <option value={'送信完了'}>{t('Text.SendFinish')}</option>
                                <option value={'送信失敗'}>{t('Text.SendError')}</option>
                            </Select>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickOpen} color="primary">
                        {t('Text.Ok')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default FilterDataDialog;