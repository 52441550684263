import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import { useTranslation } from 'react-i18next';
type Props = {
    isOpen: boolean;
    doClose: () => void;
    deleteButtonOnClick: () => void;
};

export const ManagementDeleteDialog: React.FC<Props> = ({ isOpen, doClose, deleteButtonOnClick }) => {

    const { i18n, t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => doClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{t('Text.ConifrmDelete')}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ReportProblemRoundedIcon color='error' fontSize='large' style={{ marginRight: "20px" }} />
                        {t('Msg.ConfirmDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" onClick={() => deleteButtonOnClick()} >
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color="inherit" onClick={() => doClose()} >
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
