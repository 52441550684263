import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";

export default function CellTranslateDocStatus(props: { value: string[]; }) {
    const {t, i18n} = useTranslation();
    const createStatus =()=>{
        let ret=null;
        if(props.value.length === 1){
            ret =t(props.value[0]);
        }else{
            ret = t(props.value[0])+'[' + t(props.value[1]) + ']';
        }
        return (ret);
    }
    return (
        <Typography variant={'body2'} >
            {createStatus}
        </Typography>
    );
}


