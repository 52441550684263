import {Fields} from "./Fields";
/**
 * SVF Field 編集 レコード情報
 */
export class Records {
    private _x: number | undefined;
    private _y: number | undefined;
    private _width: number | undefined;
    private _height: number | undefined;
    private _row: number | undefined;
    private _fieldList: Fields[] | undefined;

    get height(): number {
        return <number>this._height;
    }

    set height(value: number) {
        this._height = value;
    }

    get width(): number {
        return <number>this._width;
    }

    set width(value: number) {
        this._width = value;
    }

    get y(): number {
        return <number>this._y;
    }

    set y(value: number) {
        this._y = value;
    }

    get x(): number {
        return <number>this._x;
    }

    set x(value: number) {
        this._x = value;
    }
    get fieldList(): Fields[] | undefined {
        return this._fieldList;
    }

    set fieldList(value: Fields[] | undefined) {
        this._fieldList = value;
    }
    get row(): number {
        return <number>this._row;
    }

    set row(value: number) {
        this._row = value;
    }
}

