import React, { useEffect } from 'react';
import { MUIDataTableState } from "mui-datatables";
import DocContainer from "../container/DocContainer";
import { createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MuiThemeProvider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { SpaceBar } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import TantouDialog from "./dialog/TantouDialog";
import { useTranslation } from "react-i18next";
import InfoSnackBar from "./dialog/InfoSnackBar";
import { ConfirmDialog } from "./dialog/ConfirmDialog";
import Word from "../common/Word";
import { MSG_PIPE_STR } from "../constants/Constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    Accordion,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import { PreviewDialog } from './dialog/PreviewDialog';
import CommonContainer from '../container/CommonContainer';
import CustomTableFilterList from './customMUIDataTable/CustomTableFilterList';
import CustomMUIDataTable from './customMUIDataTable/CustomMUIDataTable';
import { CustomMUIDataTableOptions } from './customMUIDataTable';
import CustomTableToolBar from './customMUIDataTable/CustomTableToolBar';
import CustomTableFilter from './customMUIDataTable/CustomTableFilter';
import { useSvfFieldValueService } from '../service/SvfFieldValueService';
import UserContainer from '../container/UserContainer';

interface SwitchItem {
    docId: number,
    rowIndex: number,
    value: boolean
};

let currentPageData: any = [];
export default function DocList() {
    const docState = DocContainer.useContainer();
    const commonState = CommonContainer.useContainer();
    const useState = UserContainer.useContainer();

    const { allUpdateSvfFieldValue } = useSvfFieldValueService()
    const { previewFullScreen } = CommonContainer.useContainer();
    const [saveConfirmDlgOpen, setSaveConfirmDlgOpen] = React.useState(false);
    const [changeConfirmDlgOpen, setChangeConfirmDlgOpen] = React.useState(false);
    const [switchConfirmDlgOpen, setSwitchConfirmDlgOpen] = React.useState(false);
    const [tempIndex, setTempIndex] = React.useState(-1);
    const [accordionOpen, setAccordionOpen] = React.useState(true);
    const [tableState, setTableState] = React.useState<MUIDataTableState>();
    const [palletsClick, setPalletsClick] = React.useState(false);
    const [acceptAllOpen, setAcceptAllOpen] = React.useState(false);
    const [timeOut, setTimeOut] = React.useState(false);

    useEffect(() => {
        console.log('==== Switched ====')
        if (docState.switchedItem != null) {
            setSwitchConfirmDlgOpen(true);
            setAccordionOpen(true);
        }
    }, [docState.switchedItem])

    /**
     * リストの変動があった時にアコーディオンを開く
     */
    useEffect(() => {
        console.log('==== List updated ====')
        setAccordionOpen(true);
    }, [docState.data])

    const { t, i18n } = useTranslation();

    /**
     * 一括受取ダイアログ：開く
     */
    const handleAcceptAllOpen = () => {
        if (docState.updateFields.length > 0) {
            setAcceptAllOpen(true);
            setChangeConfirmDlgOpen(true);
            return;
        }
        setSaveConfirmDlgOpen(true);
    };

    /**
     * 一括受取ダイアログ：OK
     */
    const handleAcceptAllOK = (user: string) => {
        const docComment = t('Text.Receive') + MSG_PIPE_STR + user;
        docState.doAccept(docComment, useState.user?.id);
    };

    /**
     * 一括受取ダイアログ：閉じる
     */
    const handleAcceptAllClose = () => {
        setSaveConfirmDlgOpen(false);
    };

    /**
     * 確認ダイアログ
     */
    const handleChangeConfirmClose = (isOK: boolean) => {
        if (isOK) {
            // トレイをクリックすると確認ボックスが表示されます。
            if (palletsClick) {
                docState.setLastSelectId(-1);
                docState.loadCheckedList();
            } else {
                if (tempIndex >= 0) {
                    docState.selectChange(tempIndex);
                    docState.setLastSelectId(tempIndex);
                } else {
                    docState.closePreviewDialog();
                    // 受領確定をタップするとダイアログボックスが表示されます。
                    if (acceptAllOpen) {
                        setSaveConfirmDlgOpen(true);
                    } else if (docState.switchAll) {
                        // すべてのスイッチを変更すると、プロンプトボックスが表示される
                        docState.setAllSwitchConfirmDlgOpen(true);
                    }
                }
                if (docState.previewOpen) {
                    docState.closePreviewDialog();
                }
            }
        }
        setChangeConfirmDlgOpen(false);
        resetTableStatus();
    };

    const resetTableStatus = () => {
        setTempIndex(-1);
        setPalletsClick(false);
        setAcceptAllOpen(false);
        docState.setSwitchAll(false);
        docState.setChangeConfirmDlgOpen(false);
    }

    /**
     *スイッチ変更確認ダイアログ
     */
    const handleSwitchConfirmClose = (isOK: boolean) => {
        if (isOK === true) {
            commonState.setProgress(true);
            docState.switchChecked(docState.switchedItem);
        }
        setSwitchConfirmDlgOpen(false);
    };

    /**
     * 全スイッチ変更確認ダイアログ
     */
    const handleAllSwitchConfirmClose = async (isOK: boolean) => {
        let switchData: any[] = [];
        if (isOK === true) {
            commonState.setProgress(true);
            for (let index = 0; index < currentPageData.length; index++) {
                let val: any = currentPageData[index];
                if (val.status && val["properties.svffield_data_checked"].toString() !== (!docState.allCheckStatus).toString()) {
                    let switchItem: SwitchItem = {
                        docId: 0,
                        rowIndex: 0,
                        value: false
                    };
                    switchItem.docId = val.documentId!;
                    switchItem.rowIndex = val.dataIndex;
                    switchItem.value = !docState.allCheckStatus;
                    // @ts-ignore
                    const docId: Doc = docState.data[val.dataIndex].documentId;
                    let data = {
                        switchItem: switchItem,
                        docId: docId
                    }
                    switchData.push(data);
                }
            }

            // データを25項目からなる1つのコピーに分割する。
            const switchDataArray = splitArrayByLength(switchData, 25);
            await callApis(switchDataArray).then(async (results) => {
                if (results[0].status != 500) {
                    // Apigatewayタイムアウト
                    if (!!results[0].status && results[0].status == 504) {
                        commonState.setProgress(false);
                        setTimeOut(true);
                        return;
                    }

                    // 複数の配列を1つにまとめる
                    const flattenedArray = results.reduce((accumulator, currentValue) => {
                        return accumulator.concat(currentValue);
                    }, []);

                    // 正常に更新されたデータを返す
                    const filterSwitchData = switchData.filter(item => {
                        if (flattenedArray.indexOf(item.docId.toString)) {
                            return item;
                        }
                    })

                    docState.setPreviewData(null);
                    await docState.switchAllChecked(filterSwitchData);
                    // 確認済みの数を計算する
                    if (switchData[0].switchItem.value) {
                        docState.setCheckListLength(docState.checkListLength + filterSwitchData.length);
                    } else {
                        docState.setCheckListLength(docState.checkListLength - filterSwitchData.length);
                    }
                }

            }).catch((error) => {
                console.error('エラーが発生しました:', error);
            });

            docState.setSelectRow([]);

            docState.setAllCheckStatus(!docState.allCheckStatus);
            commonState.setProgress(false);
        }
        docState.setAllSwitchConfirmDlgOpen(false);
        docState.setLastSelectId(-1);
    };

    /**
     * 配列の分割
     */
    const splitArrayByLength = (arr: string | any[], chunkSize: number) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const end = Math.min(i + chunkSize, arr.length);
            chunks.push(arr.slice(i, end));
        }
        return chunks;
    }

    /**
     * バッチコールアプリ
     */
    const callApis = (array: any[]) => {
        const promises = array.map((item, index) => {
            return allUpdateSvfFieldValue(item);
        });

        return Promise.all(promises);
    }

    /**
     * 受領完了のメッセージ
     */
    const getSnackContent = () => {
        let msg = '';
        if (docState.snackMsg !== null && docState.snackMsg.length >= 2) {
            if (docState.snackMsg[0].length > 0) {
                msg = t('Text.ReceiveDone') + docState.snackMsg[0];
            }
            if (docState.snackMsg[1].length > 0) {
                msg += t('Text.Failed') + t('Text.ReviewId') + MSG_PIPE_STR + docState.snackMsg[1];
            }
            if (docState.snackMsg.length >= 3) {
                for (const m of docState.snackMsg.slice(2)) {
                    msg += '\n' + t(m);
                }
            }
        }
        return msg;
    }

    /**
     * スイッチ変更時のメッセージ
     */
    const getSwitchMsg = () => {
        let msg = '';
        if (docState.switchedItem != null) {
            msg = (docState.switchedItem.value === true) ? t('Msg.MoveToTray') : t('Msg.MoveFromTray');
            msg += '(' + t('Text.FileId') + MSG_PIPE_STR + docState.switchedItem.docId + ')';
        }
        return msg;
    }
    /**
     * 全スイッチ変更時のメッセージ
     */
    const getAllSwitchMsg = () => {
        let msg = ""
        if (docState.allSwitchConfirmDlgOpen) {
            msg = docState.allCheckStatus === true ? i18n.t('Msg.AllSwitchClose') : i18n.t('Msg.AllSwitchOpen');
        }
        return msg;
    }

    /**
     * トレイをクリック
     */
    const handlePalletsClick = () => {
        if (docState.updateFields.length > 0) {
            setPalletsClick(true);
            setChangeConfirmDlgOpen(true);
            return;
        }
        docState.setCurrentPage(0);
        docState.setLastSelectId(-1);
        docState.loadCheckedList();
    }

    /**
     * タイムアウトダイアログを閉じる
     */
    const closeTimeout = () => {
        setTimeOut(false);
        docState.loadData();
    }

    /**
     * テーブルオプション
     */
    const options: CustomMUIDataTableOptions = {
        filter: true,
        confirmFilters: true,
        filterType: 'multiselect',
        selectableRows: 'single',
        rowsSelected: docState.selectRow,
        responsive: 'standard',
        onRowSelectionChange: (currentRowsSelected: any[] | undefined, allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            if (currentRowsSelected && currentRowsSelected.length === 1) {
                let idx = currentRowsSelected[0].dataIndex;

                if (docState.updateFields.length > 0) {
                    setChangeConfirmDlgOpen(true);
                    setTempIndex(idx);
                } else {
                    if (idx !== docState.lastSelectId) {
                        docState.selectChange(idx);
                    }
                    docState.setLastSelectId(idx);
                    if (previewFullScreen) {
                        docState.openPreviewDialog();
                    }
                }
            }
        },
        customToolbar: () => {
            return (
                <>
                    <IconButton style={{ order: -1 }} onClick={handlePalletsClick}>
                        <Badge
                            badgeContent={docState.checkListLength} color="error">
                            <SpaceBar />
                        </Badge>
                    </IconButton>
                    <Button disabled={docState.checkListLength === 0}
                        onClick={handleAcceptAllOpen} color="primary">{t('Text.ReceiveAssurance')}</Button>
                </>
            );
        },
        onTableChange: (action, tableState) => {
            let filterData: any = [];
            if (tableState.displayData.length > 0) {
                // 現在のページ
                let page = tableState.page;
                // 1ページに表示されるデータの数
                let rowsPerPage = tableState.rowsPerPage;
                let displayData = tableState.displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
                displayData.forEach((val: any) => {
                    const data: any = docState.data[val.dataIndex];
                    if (data.id && data["properties.svffield_data_checked"] !== null) {
                        data['dataIndex'] = val.dataIndex;
                        filterData.push(data);
                    }
                });
                currentPageData = filterData;
                if (filterData.length > 0) {
                    docState.setAllCheckStatus(true);
                } else {
                    docState.setAllCheckStatus(false);
                    return;
                }
                // 伝票リストの現在のページのいずれかの項目が確認状態で閉じられると、そのバッチの確認ボタンの状態も閉じられる。
                for (let i = 0; i < filterData.length; i++) {
                    let val = filterData[i];
                    if (val["properties.svffield_data_checked"] === false || val["properties.svffield_data_checked"] === "false") {
                        docState.setAllCheckStatus(false);
                        return;
                    }
                }
            }
        },
        customFilterDialogFooter: (filterList: string[][], applyNewFilters) => {
            return (
                <Button color="primary" onClick={applyNewFilters}>
                    {i18n.t('Text.Ok')}
                </Button>
            )
        },
        updateField: () => {
            if (docState.updateFields.length > 0) {
                return true
            } else {
                return false
            }
        },
        resetLastSelect: () => {
            docState.setLastSelectId(-1)
        },
        selectToolbarPlacement: 'none',//選択時のツールバーを非表示
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: true,
        search: true,
        sort: true,
        print: false,
        download: false,
        viewColumns: true,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        page: docState.currentPage,
        onChangePage: (currentPage: number) => {
            docState.setCurrentPage(currentPage);
        },
        judgeTableInnerDataChange: (dataTableState) => {
            setTableState(dataTableState);
            // @ts-ignore
            // 現在のアクション
            let action = dataTableState.tableAction;
            let currentPage = action === "changeRowsPerPage" ? 0 : dataTableState.page;
            let rowsPerPage = dataTableState.rowsPerPage;
            let realDataListAfterChange = dataTableState.displayData.map(data => {
                return docState.data[data.dataIndex]
            }).slice(rowsPerPage * (currentPage), rowsPerPage * (currentPage + 1))
            if (!docState.currentDoc) {
                return true;
            }
            // 現在のページにいるかどうかを判断する
            let currentPageExists = realDataListAfterChange.findIndex((data: any) => data["documentId"] == docState.currentDoc!["documentId"]);

            let actionList = ['sort', 'changeRowsPerPage', 'filterChange', 'search', 'reset', 'changePage'];
            if (actionList.indexOf(action) > -1 && docState.updateFields.length == 0 && currentPageExists == -1) {
                docState.setLastSelectId(-1);
                docState.closePreviewDialog();
            }

            return currentPageExists != -1 || docState.updateFields.length == 0 && currentPageExists == -1;
        },
        getNewTableState: () => {
            return tableState
        },
        closePreview: () => {
            docState.closePreviewDialog();
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: '',
                columnHeaderTooltip: (column) => '',
            },
            pagination: {
                next: Word('Text.PageDown'),
                previous: Word('Text.PageUp'),
                rowsPerPage: Word('Text.rowsPerPage'),
                displayRows: "/",
            },
            toolbar: {
                search: Word('Text.Search'),
                filterTable: Word('Text.FilterTable'),
                viewColumns: Word('Text.ColumnDisplaySetting'),
            },
            filter: {
                all: "All",
                title: t("Text.Filters"),
                reset: t("Text.ResetAllController"),
            },
            viewColumns: {
                title: Word('Text.ColumnDisplaySetting'),
                titleAria: Word('Text.ColumnDisplaySetting'),
            },
            selectedRows: {
                text: Word('Text.Select'),
            }
        }
    };

    const tableClass = () => createMuiTheme({
        typography: {
            body2: {
                fontSize: 11,
            },
        },
        overrides: {
            MuiTableCell: {
                head: {
                    height: "10px", padding: "0px", margin: "0px"
                },
            },
            MuiTableRow: {
                root: {
                    height: "10px", padding: "0px", margin: "0px", whiteSpace: "nowrap",
                    '& .MuiTableRow-root.Mui-selected': {
                        backgroundColor: '#EBEBEB',
                    },
                }
            },
            MuiTableFooter: {
                root: {
                    '& .MuiToolbar-root': {
                        backgroundColor: 'white',
                    },
                },
            },
        },
    });

    if (docState.columns?.length > 0) {
        // @ts-ignore
        return (
            <React.Fragment>
                <Accordion expanded={accordionOpen} >
                    <AccordionSummary onClick={() => { setAccordionOpen(!accordionOpen) }} expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('Text.DocList')}</Typography>
                    </AccordionSummary>
                    <MuiThemeProvider theme={tableClass()}>
                        <CustomMUIDataTable
                            key={'listTable'}
                            title={""}
                            data={docState.data}
                            columns={docState.columns}
                            options={options}
                            components={{
                                TableFilterList: CustomTableFilterList,
                                TableToolbar: CustomTableToolBar,
                                TableFilter: CustomTableFilter
                            }}
                        />
                    </MuiThemeProvider>
                </Accordion>
                {/*保存の確認ダイアログ*/}
                <TantouDialog
                    id={'fromDocList'}
                    title={t('Text.Receive')}
                    isOpen={saveConfirmDlgOpen}
                    content={t('Msg.AllAccept')}
                    ButtonText={t('Text.Ok')}
                    doSubmit={(user) => handleAcceptAllOK(user)}
                    doClose={() => handleAcceptAllClose()}
                />
                {/*文書選択時ダイアログ*/}
                <ConfirmDialog
                    title={t('Text.Confirm')}
                    id='selectConfirm'
                    isOpen={changeConfirmDlgOpen || docState.changeConfirmDlgOpen}
                    content={t('Msg.ConfirmBeingEditedDiscard')}
                    ButtonText={t('Text.Ok')}
                    doClose={(isOK) => handleChangeConfirmClose(isOK)}
                />
                {/*スイッチ変更ダイアログ*/}
                <ConfirmDialog id='switchConfirm'
                    title={t('Text.Confirm')}
                    isOpen={switchConfirmDlgOpen}
                    content={getSwitchMsg()}
                    ButtonText={t('Text.Ok')}
                    doClose={(isOK) => handleSwitchConfirmClose(isOK)}
                />
                {/*全スイッチ変更ダイアログ*/}
                <ConfirmDialog id='switchAllConfirm'
                    title={t('Text.Confirm')}
                    isOpen={docState.allSwitchConfirmDlgOpen}
                    content={getAllSwitchMsg()}
                    ButtonText={t('Text.Ok')}
                    doClose={(isOK) => handleAllSwitchConfirmClose(isOK)}
                />
                {/*プレビューダイアログ*/}
                <PreviewDialog
                    isOpen={docState.previewOpen}
                    doClose={() => {
                        if (docState.updateFields.length > 0) {
                            setChangeConfirmDlgOpen(true);
                        } else {
                            docState.closePreviewDialog();
                            docState.setLastSelectId(-1);
                        }
                    }}
                />
                {/*プロンプトフレーム*/}
                <InfoSnackBar isOpen={docState.showSnack}
                    content={getSnackContent()}
                    doClose={() => docState.closeSnackBar()}></InfoSnackBar>
                {/*Apigatewayタイムアウト*/}
                <Dialog
                    open={timeOut}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <div style={{ display: "flex" }}>
                            <Typography variant="h6" align="center" gutterBottom={true}>{t('Text.Warning')}</Typography>
                        </div>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {t('Msg.ApigatewayTimeout')}
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                        <Button variant="contained" onClick={() => closeTimeout()} color="primary">
                            {t('Text.Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    } else {
        return null;
    }
}
