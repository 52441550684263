import * as WebConstants from "../constants/WebConstants";
import { SpaColumn } from "../models/SpaColumn";
import { ESSENTIAL_SYSTEM_PROPS, REVIEW_COLUMNS, SYSTEM_COLUMNS } from "../constants/Constants";
import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import PropRenderService from "./PropRenderService";
import { FILE_PROPERTIES, REVIEW_PROPERTIES } from "../constants/PropertiesConstants";
import { MUIDataTableColumnDef, MUIDataTableFilterOptions } from "mui-datatables";
import i18n from '../i18n';
import DateRangePick from "../components/customMUIDataTable/DateRangePick";
import CellFullSwitch from "../components/table/CellFullSwitch";
import moment from "moment";
import ServiceContainer from "../container/AxiosServiceContainer";

const splitter = " : "

// カラムのタイプ
const PropType = {
    System: 'system',
    Custom: 'custom',
    Review: 'review',
} as const;
type PropType = typeof PropType[keyof typeof PropType];

export const useColumnService = () => {
    const serviceState = ServiceContainer.useContainer();

    //カラム保持
    let uiColumns: SpaColumn[] = [];
    let customColumns = [];
    let systemColumns: SpaColumn[] = [];
    let reviewColumns = [];

    const FORMAT_PATTERN = 'yyyy/MM/DD HH:mm:ss';

    const clear = () => {
        uiColumns = [];
        customColumns = [];
        systemColumns = [];
        reviewColumns = [];
    }

    /**
     * ユーザーの表示設定カラム取得
     * UI Profiles Get
     */
    const uiProfilesLibraryColumnsGet = async () => {
        return serviceState.request(
            WebConstants.PROFILES_LIBRARY_GET_URL,
            WebConstants.METHOD_GET,
            null,
        ).then((response) => {
            let ret = [];
            if (response.data.library) {
                ret = response.data.library.columns;
                for (let sc of SYSTEM_COLUMNS) {
                    let temp = response.data.library.columns.find((el: { id: string; }) => el.id === sc.id);
                    if (typeof temp === 'undefined') {
                        ret.push(sc);
                    }
                }

            }
            return ret;
            console.log("****** UI Profiles Get is *******", response);
        }
        ).catch((error) => {
            console.log("****** error is *******", error.response);
        })
    }

    /**
     * 存在するすべてのカスタムプロパティの構成情報のリスト
     * Custom Properties List(v2)
     */
    const customPropertiesList = async () => {
        return serviceState.request(
            WebConstants.CUSTOM_PROPERTIES_LIST_URL,
            WebConstants.METHOD_GET,
            null,
        ).then((response) => {
            return response.data.customProperties;
            console.log("****** Custom Properties List is *******", response);
        }
        ).catch((error) => {
            console.log("****** error is *******", error.response);
        })
    }

    /**
     * UIのライブラリリスト・検索結果リストに表示するカラム情報を取得する
     * UI ViewColumns Get
     */
    const viewColumnsGet = async () => {
        return serviceState.request(
            WebConstants.UI_VIEW_COLUMNS_GET_URL,
            WebConstants.METHOD_GET,
            null,
        ).then((response) => {
            return response.data.library.columns;
            console.log("****** viewColumnsGet is *******", response);

        }
        ).catch((error) => {
            console.log("****** error is *******", error.response);
        })
    }

    /**
     * 表示するカラムセットの一覧を取得する
     */
    const getColumnsList = async () => {
        clear();
        let system = ESSENTIAL_SYSTEM_PROPS;
        let custom = [];
        try {

            const [uiColumnsN, systemColumnsN, customColumnsN] = await Promise.all([uiProfilesLibraryColumnsGet(), viewColumnsGet(), customPropertiesList()]);
            uiColumns = uiColumnsN;
            systemColumns = systemColumnsN;
            customColumns = customColumnsN;

            if (!uiColumns) return [];
            let result: any[] = [];
            //UIの設定からカラムリストを作成
            for (let uic of uiColumns) {
                let top = false;
                if (uic.visible) {
                    let dc: MUIDataTableColumnDef = { label: "", name: "", options: undefined };
                    //システムプロパティ
                    if (uic.system === true) {
                        let ret = systemColumns.find((el) => el.id === uic.id);
                        if (ret) {
                            let viewColumn = true;
                            let colName = ret.id;
                            //確認済みフラグかどうか
                            if (colName === 'svffield_data_checked') {
                                top = true;
                                viewColumn = false;
                            }
                            // @ts-ignore
                            let columnDef = FILE_PROPERTIES[colName];
                            if (!columnDef) {
                                continue;
                            }
                            dc = {
                                label: i18n.t(columnDef.display),
                                name: 'properties.' + colName,
                                options: {
                                    filter: true,
                                    filterType: 'custom',
                                    sort: true,
                                    viewColumns: viewColumn,
                                    print: false,
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        // @ts-ignore
                                        return PropRenderService.getSystemRenderer(colName, value, tableMeta, updateValue);
                                    },
                                    customHeadLabelRender: (columnMeta: any) => {
                                        return (
                                            colName === 'svffield_data_checked' ?
                                                <>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ fontSize: '0.875rem', width: '60px' }}>{columnMeta.label}</span>
                                                        <CellFullSwitch />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {columnMeta.label}
                                                </>
                                        )
                                    },
                                    filterOptions: initFilterOptions(columnDef, colName as string),
                                }
                            };
                            if (system.indexOf(ret.id as string) < 0) {
                                if (ret.id != null) {
                                    system.push(ret.id);
                                }
                            }
                        }
                    } else {
                        //カスタムプロパティ
                        let ret = customColumns.find((el: { id: string }) => el.id === uic.id);
                        if (ret) {
                            let columnDef = {
                                // @ts-ignore
                                'display': ret.displayNames.ja,
                                // @ts-ignore
                                'type': ret.type,
                                show: true
                            }
                            // @ts-ignore
                            let colName = ret.id;
                            // @ts-ignore
                            let displayName = ret.displayNames.ja
                            dc = {
                                name: 'customProperties.' + colName,
                                label: displayName,
                                options: {
                                    filter: true,
                                    filterType: 'custom',
                                    sort: true,
                                    viewColumns: true,
                                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                                        // @ts-ignore
                                        return PropRenderService.getCustomRenderer(ret, value, tableMeta, updateValue);
                                    },
                                    filterOptions: initFilterOptions(columnDef, colName as string),
                                }
                            };
                            custom.push(colName);
                        }
                    }
                    //確認済みフラグだけ先頭に配置
                    if (top) {
                        result.unshift(dc);
                    } else {
                        result.push(dc);
                    }
                }
            }
            //追加でレビューで必要な一覧を追加
            for (let rvcol of REVIEW_COLUMNS) {
                // @ts-ignore
                let columnDef = REVIEW_PROPERTIES[rvcol];
                let dc: MUIDataTableColumnDef = {
                    label: i18n.t(columnDef.display),
                    name: rvcol,
                    options: {
                        filter: true,
                        filterType: 'custom',
                        sort: true,
                        viewColumns: false,
                        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                            // @ts-ignore
                            return PropRenderService.getReviewRenderer(rvcol, value, tableMeta, updateValue);
                        },
                        filterOptions: initFilterOptions(columnDef, rvcol as string),
                    }
                };
                result.push(dc);
            }
            console.log('全部終わりました！', result);
            return { list: result, systemProps: system, customProps: custom, customPropList: customColumns };
        } catch (error) {
            return Promise.reject(error);
        }
    }

    const initFilterOptions = (columnDef: any, colName: string): MUIDataTableFilterOptions => {
        let filterOptions = {} as MUIDataTableFilterOptions

        switch (columnDef.type) {
            case 'date':
                filterOptions.display = (filterList, onChange, index, column) => {
                    filterList[index] = filterList[index].length == 0 ? ["", ""] : filterList[index]
                    return (
                        <DateRangePick
                            dateRange={filterList[index].map(filter => getFilterData(filter)[1])}
                            columnDef={columnDef}
                            onChange={event => {
                                filterList[index] = [setFilterData(`${i18n.t(columnDef.display)}の開始時刻`, event[0]), setFilterData(`${i18n.t(columnDef.display)}の終了時刻`, event[1])]
                                onChange(filterList[index], index, column);
                            }}
                        ></DateRangePick>
                    )
                }
                filterOptions.logic = (prop, filterValue) => {
                    const [colNameFrom, dateFrom] = getFilterData(filterValue[0])
                    const [colNameTo, dateTo] = getFilterData(filterValue[1])

                    if (!dateFrom && !dateTo) {
                        return false
                    } else if (!dateFrom) {
                        if (!prop) {
                            return true
                        }
                        let propDate = new Date(moment(prop).format(FORMAT_PATTERN))
                        let endDate = new Date(`${dateTo} 23:59:59`)
                        return !(propDate < endDate)
                    } else if (!dateTo) {
                        if (!prop) {
                            return true
                        }
                        let propDate = new Date(moment(prop).format(FORMAT_PATTERN))
                        let fromDate = new Date(`${dateFrom} 00:00:00`)
                        return !(fromDate <= propDate)
                    } else {
                        if (!prop) {
                            return true
                        }
                        let fromDate = new Date(`${dateFrom} 00:00:00`)
                        let propDate = new Date(moment(prop).format(FORMAT_PATTERN))
                        let endDate = new Date(`${dateTo} 23:59:59`)
                        return !(propDate >= fromDate && propDate <= endDate)
                    }
                }
                filterOptions.fullWidth = true
                break
            case 'boolean':
                filterOptions.display = (filterList, onChange, index, column) => {
                    if (!filterList[index][0]) {
                        filterList[index][0] = setFilterData(i18n.t(columnDef.display), 'All')
                    }
                    return (
                        <>
                            <InputLabel id={colName + '_label'}>{i18n.t(columnDef.display)}</InputLabel>
                            <Select
                                labelId={colName + '_select'}
                                id={colName}
                                defaultValue={'All'}
                                value={getFilterData(filterList[index][0])[1]}
                                onChange={event => {
                                    filterList[index][0] = setFilterData(i18n.t(columnDef.display), event.target.value as string);
                                    onChange(filterList[index], index, column);
                                }}
                            >
                                <MenuItem value="All">{i18n.t('Text.All')}</MenuItem>
                                <MenuItem value={'true'}>{i18n.t('Text.Comfirmed')}</MenuItem>
                                <MenuItem value={'false'}>{i18n.t('Text.Unconfirmed')}</MenuItem>
                            </Select>
                        </>
                    )
                }
                filterOptions.logic = (prop, filterValue) => {
                    const [colName, filterValueDetail] = getFilterData(filterValue[0])
                    if (filterValueDetail == 'All' || !filterValueDetail) {
                        return false
                    } else if (filterValueDetail + '' == prop + '') {
                        return false
                    } else {
                        return true
                    }
                }
                filterOptions.fullWidth = false
                break
            case 'string':
            default:
                filterOptions.display = (filterList, onChange, index, column) => {
                    filterList[index][0] = filterList[index][0] ?? ''
                    return (
                        <TextField
                            id={colName + '_input'}
                            label={i18n.t(columnDef.display)}
                            value={getFilterData(filterList[index][0])[1]}
                            onChange={event => {
                                filterList[index][0] = setFilterData(i18n.t(columnDef.display), event.target.value as string);
                                onChange(filterList[index], index, column);
                            }}
                        />
                    )
                }
                filterOptions.logic = (prop, filterValue) => {
                    const textValueRegex = /^Text\./;
                    const [colName, filterValueDetail] = getFilterData(filterValue[0])
                    let value = textValueRegex.test(prop + '') ? i18n.t(prop + '') : prop + ''
                    if (!filterValueDetail || value.indexOf(filterValueDetail) != -1) {
                        return false
                    } else {
                        return true
                    }
                }
                filterOptions.fullWidth = false
                break
        }

        return filterOptions
    }

    return {
        getColumnsList
    }
}

const getFilterData = (data: string): any[] => {
    if (!data) {
        return ["", ""]
    }
    let filterArrays = data.split(splitter)
    let result: any = [filterArrays[0], filterArrays.slice(1).join(splitter)]
    return result
}

const setFilterData = (columnName: string, data: any): string => {
    return columnName + splitter + data
}

export {
    getFilterData,
    setFilterData
}
