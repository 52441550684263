import { DEFAULT_DATE_FORMAT, SEARCH_CONDITION_TYPE } from "../constants/Constants";
import {
    CONVERT_DATE_TYPE,
    DATE_FORMAT,
    DATE_TYPE,
    EXTENSION_COLUMNS,
    FORMAT_DATE
} from "../constants/PropertiesConstants";
import CustomDateFilter from "../common/CustomDateFilter";
import UTCTimeFilter from "../common/UTCTimeFilter";
import moment from "moment";
import ValidatorService from "./ValidatorService";
import i18n from "i18next";
import DocumentOptionService from "./DocumentOptionService";

class SearchOptionService {
    private browser_offset = -new Date().getTimezoneOffset();
    getConditions = (templates: [], ignoreRowIndexes: any) => {
        let conditions: any[] = [];
        templates.map((row: any, idx) => {
            let condition: any = {};
            let options: any = {
                dateFormat: DEFAULT_DATE_FORMAT
            };
            let ignoreDetailIndexes = [];
            if (ignoreRowIndexes) {
                ignoreDetailIndexes = ignoreRowIndexes[idx];
                if (ignoreDetailIndexes && ignoreDetailIndexes.length === row.details.length) {
                    // 全てのレコードが除外対象であれば、条件に追加せずに抜ける
                    return;
                }
            }
            // @ts-ignore
            switch (row.conditionType) {
                // プロパティ検索
                case SEARCH_CONDITION_TYPE.SYSTEM:
                case SEARCH_CONDITION_TYPE.CUSTOM:
                    Object.assign(condition, this.getCommonCondition(row));
                    Object.assign(condition, this.getSystemCondition(row, options));
                    this._deleteProperty(condition, 'anyValue');
                    break;
                // ページ検索
                case SEARCH_CONDITION_TYPE.PAGE_CONTENT:
                    Object.assign(condition, this.getCommonCondition(row));
                    Object.assign(condition, this.getSystemCondition(row, options));
                    var controlIds = this.getPageContentsControlIds();
                    if (controlIds.indexOf(condition.name) !== -1) {
                        this._deleteProperty(condition, 'empty');
                    }
                    this._deleteProperty(condition, 'anyValue');
                    break;
                // SVFフィールド検索
                case SEARCH_CONDITION_TYPE.SVF_FIELD:
                    Object.assign(condition, this.getCommonCondition(row));
                    // @ts-ignore
                    options.isMulti = false;
                    Object.assign(condition, this.getSvfOrDetailCondition(row, options));
                    this._deleteProperty(condition, 'empty');
                    break;
                // 明細
                case SEARCH_CONDITION_TYPE.DETAIL:
                    // @ts-ignore
                    options.isMulti = true;
                    options.ignoreDetailIndexes = ignoreDetailIndexes;
                    Object.assign(condition, this.getSvfOrDetailCondition(row, options));
                    this._deleteProperty(condition, 'empty');
                    this._deleteProperty(condition, 'anyValue');
                    break;
                // フォルダー検索
                case SEARCH_CONDITION_TYPE.FOLDER:
                    Object.assign(condition, this.getCommonCondition(row));
                    Object.assign(condition, this.getSystemCondition(row, options));
                    this._deleteProperty(condition, 'empty');
                    this._deleteProperty(condition, 'anyValue');
                    break;
            }
            conditions.push(condition);
        });
        return conditions;
    }
    // 指定したオブジェクトのプロパティを削除する
    _deleteProperty = (obj: { [x: string]: any; }, prop: string | number) => {
        if (obj[prop] !== undefined) {
            delete obj[prop];
        }
    }
    /**
     * SVF検索フィールドもしくは明細の条件を取得
     * @param {*} row 行
     * @param {object} params パラメータ
     * @param {boolean} params.isMulti
     * @param {number[]} params.ignoreDetailIndexes 除外する明細行のインデックス配列
     */
    getSvfOrDetailCondition = (row: { conditionType: any; name: any; formName: string | undefined; docTypeId: string | number | undefined; federationId: string | null | undefined; type: any; details: any[]; dateTypeIndex: string | undefined; }, params: { ignoreDetailIndexes: string | any[]; isMulti: any; dateFormat: any; }) => {
        // @ts-ignore
        let condition: { [k: string]: any } = {};

        condition.conditionType = row.conditionType;
        condition.name = row.name;
        condition.formName = row.formName === undefined ? null : row.formName;
        var ignoreDetailIndexesSet = this._makeSet(params.ignoreDetailIndexes);
        // @ts-ignore
        if (row.docTypeId > -1) {
            condition.docTypeId = row.docTypeId;
        }
        if (row.federationId !== null) {
            condition.federationId = row.federationId;
        }
        switch (row.type) {
            // テキスト型
            case 'string':
                condition.dataType = 'text';
                // 明細の場合は条件分データを作成する
                if (params.isMulti) {
                    condition.detailsConditions = [];
                    row.details.map((detail: { value: any; ambiguity: any; textMatch: any; }, idx: number) => {
                        if (ignoreDetailIndexesSet[idx]) {
                            return;
                        }
                        var detailCondition = {
                            value: detail.value,
                            exact: detail.ambiguity,
                            type: detail.textMatch
                        };
                        // @ts-ignore
                        condition.detailsConditions.push(detailCondition);
                    });
                }
                break;
            // 数値型
            case 'number':
                condition.dataType = 'numeric';
                if (params.isMulti) {
                    condition.detailsConditions = [];
                    row.details.map((detail: {
                        inRange: any;
                        max: any;
                        min: any;
                        value: any; ambiguity: any; textMatch: any;
                    }, idx: number) => {
                        if (ignoreDetailIndexesSet[idx]) {
                            return;
                        }
                        var detailCondition = {
                            min: detail.min,
                            max: detail.max,
                            type: detail.inRange === '' ? null : detail.inRange
                        };
                        // @ts-ignore
                        condition.detailsConditions.push(detailCondition);
                    });
                }
                break;
            // 日付型
            case 'date':
                condition.dataType = 'date';
                condition.dateFormat = row.dateTypeIndex;
                let datetimeIndex = Number(row.dateTypeIndex);
                // 年月日時分秒、年月日時分、年月日の場合はタイムゾーンを設定する
                if (datetimeIndex === FORMAT_DATE.YYYYMMDDHHMMSS || datetimeIndex === FORMAT_DATE.YYYYMMDDHHMM || datetimeIndex === FORMAT_DATE.YYYYMMDD) {
                    condition.offset = (this.browser_offset / 60);
                }
                // 明細の場合は条件分データを作成する
                if (params.isMulti) {
                    condition.detailsConditions = [];
                    // angular.forEachを使用するとthisの参照先が変わるので事前に変数に格納。
                    var _this = this;
                    row.details.map((detail: any, idx: number) => {
                        if (ignoreDetailIndexesSet[idx]) {
                            return;
                        }
                        let detailCondition: { [k: string]: any } = {};
                        // @ts-ignore
                        let dateFormatStr = DATE_FORMAT[Number(row.dateTypeIndex)];
                        if (detail.specified) {
                            detailCondition.type = detail.inRange === '' ? null : detail.inRange;
                            if (detail.from) {
                                detailCondition.from = this._formatSVFConditionDate(detail.from, row.dateTypeIndex, dateFormatStr);
                            }
                            if (detail.to) {
                                detailCondition.to = this._formatSVFConditionDate(detail.to, row.dateTypeIndex, dateFormatStr);
                            }
                        } else {
                            detailCondition.type = 'relative';
                            detailCondition.range = detail.range;
                            // 期間が「ON」の場合　「From、To」の両方を設定する
                            if (detail.range) {
                                detailCondition.from = detail.from;
                                detailCondition.to = detail.to;
                            } else {
                                detailCondition.value = detail.day;
                            }
                        }
                        // @ts-ignore
                        condition.detailsConditions.push(detailCondition);
                    });

                } else {
                    var detail = row.details[0];
                    if (detail.specified) {
                        if (detail.from) {
                            condition.from = this._formatSVFConditionDate(detail.from, row.dateTypeIndex, params.dateFormat);
                        }
                        if (detail.to) {
                            condition.to = this._formatSVFConditionDate(detail.to, row.dateTypeIndex, params.dateFormat);
                        }
                    } else {
                        condition.range = detail.range;
                        // 期間が「ON」の場合　「From、To」の両方を設定する
                        if (detail.range) {
                            condition.from = detail.from;
                            condition.to = detail.to;
                        } else {
                            condition.value = detail.day;
                        }
                    }
                }
                break;
        }

        return condition;
    }
    getSystemCondition = (row: any, params: any) => {
        var detail = row.details[0];
        let condition: { [k: string]: any } = {};
        condition.conditionType = row.conditionType

        // カスタムプロパティの場合はnameではなくidになり、日付けの設定方法も変わる。
        if (row.isCustom) {
            condition.id = row.id;
            if (row.type === 'date') {
                if (detail.specified) {
                    if (detail.from) {
                        condition.from = this._formatCustomConditionDate(detail.from, row.dateTypeIndex, params.dateFormat);
                    }
                    if (detail.to) {
                        condition.to = this._formatCustomConditionDate(detail.to, row.dateTypeIndex, params.dateFormat);
                    }
                } else {
                    // 期間が「ON」の場合　「From、To」の両方を設定する
                    if (detail.range) {
                        condition.from = detail.from;
                        condition.to = detail.to;
                    } else {
                        condition.value = detail.day;
                    }
                }
            }
        } else {
            // TODO ここで個別にidを切り替えているのはAPI側の受け取りパラメーターのキーがUIと違う為。
            // 最終的には合わせるのが好ましい
            switch (row.id) {
                // ファイルサイズ(KB)
                case 'sizeKB':
                    condition.name = 'size';
                    break;
                // 文書定義管理ID
                case 'doctype_key':
                    condition.name = 'doctype_id';
                    break;
                // 変換が不要な項目
                default:
                    condition.name = row.id;
                    break;
            }

            if (row.type === 'date') {
                if (detail.specified) {
                    condition.from = this._formatSystemConditionDate(detail.from, params.dateFormat);
                    condition.to = this._formatSystemConditionDate(detail.to, params.dateFormat);
                } else {
                    // 期間が「ON」の場合　「From、To」の両方を設定する
                    if (detail.range) {
                        condition.from = detail.from;
                        condition.to = detail.to;
                    } else {
                        condition.value = detail.day;
                    }
                }
            }
        }

        if (row.type === 'date') {
            // 年月日時分秒、年月日時分、年月日の場合はタイムゾーンを設定する
            let dateTypeIdx = Number(row.dateTypeIndex);
            if (dateTypeIdx === FORMAT_DATE.YYYYMMDDHHMMSS || dateTypeIdx === FORMAT_DATE.YYYYMMDDHHMM || dateTypeIdx === FORMAT_DATE.YYYYMMDD) {
                condition.offset = (this.browser_offset / 60);
            }
        }
        return condition;
    }
    // 各条件の共通の値を取得
    getCommonCondition = (row: { details: any[]; type: any; dropdownType: any; conditionType: string; documentType: string | undefined; }) => {
        var detail = row.details[0];
        let condition: { [k: string]: any } = {};

        switch (row.type) {
            // テキスト型
            case 'string':
            case 'hyperLink':
                condition = {
                    value: detail.value,
                    exact: detail.ambiguity,
                    type: detail.textMatch,
                    empty: detail.empty,
                    anyValue: detail.anyValue
                };
                break;
            // 数値型
            case 'number':
                condition = {
                    min: detail.min,
                    max: detail.max,
                    type: detail.inRange === '' ? null : detail.inRange,
                    empty: detail.empty,
                    anyValue: detail.anyValue
                };
                break;
            // Bool型
            case 'boolean':
                condition = {
                    value: detail.boolVal === undefined ? false : detail.boolVal,
                    empty: detail.empty,
                    anyValue: detail.anyValue
                };
                break;
            // ドロップダウン
            case 'dropdown':
                // 項目によってパラメーターが変わる
                // マスク種別は数値型、マスクIDは文字型、それ以外(現時点ではファイル種類のみ)はvalueのみ
                switch (row.dropdownType) {
                    // マスク種別
                    case EXTENSION_COLUMNS.EXT_MASK_TYPE:
                        condition.min = detail.dropdownValue;
                        condition.max = detail.dropdownValue;
                        // マスクなしの場合は値無しとする
                        if (detail.dropdownValue === '0') {
                            condition.empty = true;
                        }
                        break;
                    // マスクID
                    case EXTENSION_COLUMNS.EXT_MASK:
                        condition = {
                            value: detail.dropdownValue,
                            type: 'equals', // 一致する
                            exact: true, // 大文字／小文字の区別を行わない
                            empty: false // 値なし未チェック
                        };
                        break;
                    // レビューステータス
                    case 'review_status':
                        // 空白の場合は値無しとする
                        if (detail.dropdownValue === '') {
                            condition = {
                                value: '',
                                empty: true
                            };
                        } else {
                            condition = {
                                value: detail.dropdownValue
                            };
                        }
                        break;
                    // 種類、スタンプなど
                    default:
                        condition = {
                            value: detail.dropdownValue
                        };
                        break;
                }
                break;
            // 日付型
            case 'date':
                var type;
                if (!detail.specified) {
                    type = 'relative';
                } else {
                    type = detail.inRange === '' ? null : detail.inRange;
                }
                condition = {
                    empty: detail.empty,
                    anyValue: detail.anyValue,
                    type: type,
                    range: detail.range
                };
        }

        if (row.conditionType === SEARCH_CONDITION_TYPE.CUSTOM) {
            condition.documentType = row.documentType;
        }
        return condition;
    }
    // プロパティ検索の日付項目のフォーマット
    _formatSystemConditionDate = (value: string | undefined, dateFormat: any) => {
        if (value === '' || value === undefined) {
            return null;
        } else {
            //システムプロパティはブラウザのタイムゾーンでオフセット付きで渡す
            // @ts-ignore
            let dataTypeStr: string = DATE_TYPE[FORMAT_DATE.YYYYMMDDHHMMSS];
            //  let temp= CustomDateFilter(value, dateFormat, dataTypeStr);
            let ret = moment(value).format('yyyy-MM-DDTHH:mm:ss.000ZZ');
            return ret;
        }
    }

    // SVFフィールド検索の日付項目のフォーマット
    _formatSVFConditionDate = (value: string | undefined, dateType: any, dateFormat: { [x: string]: any; }) => {
        if (value === '' || value === undefined) {
            return null;
        }
        // @ts-ignore
        var dataTypeStr = DATE_TYPE[dateType];
        // @ts-ignore
        var dateValue = CustomDateFilter(value, dateFormat, dataTypeStr);
        return dateValue;
    }
    // カスタムプロパティ検索の日付項目のフォーマット
    _formatCustomConditionDate = (value: string | undefined, dateType: string | number, dateFormat: string) => {
        if (value === '' || value === undefined) {
            return null;
        }
        // @ts-ignore
        var dataTypeStr = DATE_TYPE[dateType];
        var dateValue = CustomDateFilter(value, dateFormat, dataTypeStr);

        // 年月日時分秒又は年月日時分の場合のみ、タイムゾーン処理を行う
        // @ts-ignore
        if (String(dateType) === FORMAT_DATE.YYYYMMDDHHMMSS || String(dateType) === FORMAT_DATE.YYYYMMDDHHMM) {
            //タイムゾーン処理をする
            dateValue = UTCTimeFilter(dateValue);
        }

        return dateValue;
    }
    /**
     * 集合を作成する
     * @param {any[]} items
     */
    _makeSet = (items: string | any[]) => {
        let data = [];
        if (items && items.length) {
            for (var i = 0; i < items.length; i++) {
                data[items[i]] = true;
            }
        }
        return data;
    }
    // ページコンテンツで入力項目を制御するidの取得
    getPageContentsControlIds = () => {
        var ids = ['annotation_text', 'private_annotation_text'];
        return ids;
    }
    // テンプレート初期値
    getTemplateOption = () => {
        const options = {
            treeData: [],
            // 条件ドロップダウン 範囲
            inRangeOptions: [{
                label: 'Text.InRange',
                value: ''
            }, {
                label: 'Text.OutOfRange',
                value: 'not'
            }],
            // 条件ドロップダウン あいまい
            ambiguityOptions: [{
                label: 'Text.SerchTypeAmbiguity',
                value: 'false'
            }, {
                label: 'Text.SerchTypeExact',
                value: 'true'
            }],
            // 条件ドロップダウン テキスト一致
            textMatchOptions: [{
                label: 'Text.Match',
                value: 'equals'
            }, {
                label: 'Text.Disaccord',
                value: 'notequals'
            }, {
                label: 'Text.Including',
                value: 'contains'
            }, {
                label: 'Text.NotContain',
                value: 'notcontains'
            }, {
                label: 'Text.Beginwith',
                value: 'startswith'
            }, {
                label: 'Text.Endwith',
                value: 'endswith'
            }],
            // 条件ドロップダウン フォルダー
            folderMatchOptions: [{
                label: 'Text.Match',
                value: 'equals'
            }, {
                label: 'Text.Including',
                value: 'contains'
            }, {
                label: 'Text.Beginwith',
                value: 'startswith'
            }, {
                label: 'Text.Endwith',
                value: 'endswith'
            }]
        };
        return options;
    }
    /**
     * 入力チェック
     * @param {*} row チェックする行
     * @param {object} params パラメータ
     * @param {*} params.dateFormat 日付フォーマット
     * @param {boolean} params.ignoreEmpty 空欄条件を除外するかどうか
     */
    validate = (row: any, params: any) => {
        let options = {};
        let emptyRow = [];
        let ignoreRowIndexes: any[] = [];
        let msg = '';
        // ※ループを途中で抜けたいので$.eachを使用
        for (let idx = 0; idx < row.details.length; idx++) {
            let detail = row.details[idx];
            var ignored = false;
            //明細検索でエラ〜メッセージがあれば抜ける(1個ずつエラーを出す)
            if (msg.length > 0) break;

            switch (row.type) {
                // テキスト型
                case 'string':
                case 'hyperLink':
                    if (row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD) {
                        if (!detail.anyValue && (detail.value === undefined || detail.value === '')) {
                            if (params.ignoreEmpty) {
                                ignoreRowIndexes.push(idx);
                                ignored = true;
                            } else {
                                emptyRow.push(detail);
                            }
                        }
                    } else {
                        if (!detail.empty && (detail.value === undefined || detail.value === '')) {
                            if (params.ignoreEmpty) {
                                ignoreRowIndexes.push(idx);
                                ignored = true;
                            } else {
                                emptyRow.push(detail);
                            }
                        }
                    }
                    break;
                // 数値型
                case 'number':
                    if (row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD) {
                        if (!detail.anyValue && (detail.min === undefined || detail.min === '') && (detail.max === undefined || detail.max === '')) {
                            if (params.ignoreEmpty) {
                                ignoreRowIndexes.push(idx);
                                ignored = true;
                            } else {
                                emptyRow.push(detail);
                            }
                        }
                    } else {
                        if (!detail.empty && (detail.min === undefined || detail.min === '') && (detail.max === undefined || detail.max === '')) {
                            if (params.ignoreEmpty) {
                                ignoreRowIndexes.push(idx);
                                ignored = true;
                            } else {
                                emptyRow.push(detail);
                            }
                        }
                    }
                    break;
                // Bool型
                case 'boolean':
                    // 未チェックの場合はfalseになるのでチェック無し
                    break;
                // ドロップダウン
                case 'dropdown':
                    // 未選択状態は無いのでチェック無し
                    break;
                // 日付型
                case 'date':
                    detail.from = detail.from || '';
                    detail.to = detail.to || '';
                    if (row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD) {
                        if ((detail.specified && !detail.anyValue && detail.from === '' && detail.to === '') ||
                            (!detail.specified && !detail.range && !detail.anyValue && (detail.day === undefined || detail.day === '')) ||
                            (!detail.specified && detail.range && !detail.anyValue && detail.from === '' && detail.to === '')) {
                            if (params.ignoreEmpty) {
                                ignoreRowIndexes.push(idx);
                                ignored = true;
                            } else {
                                emptyRow.push(detail);
                            }
                        }
                    } else {
                        if ((detail.specified && !detail.empty && detail.from === '' && detail.to === '') ||
                            (!detail.specified && !detail.range && !detail.empty && (detail.day === undefined || detail.day === '')) ||
                            (!detail.specified && detail.range && !detail.empty && detail.from === '' && detail.to === '')) {
                            if (params.ignoreEmpty) {
                                ignoreRowIndexes.push(idx);
                                ignored = true;
                            } else {
                                emptyRow.push(detail);
                            }
                        }
                    }
                    break;
            }
            // 値無しが未チェックで未入力の項目が1件でも存在する場合はメッセージを表示
            if (emptyRow.length > 0) {
                options = {
                    type: 'Warning',
                    isArgsLocale: false,
                    message: 'RequiredField',
                    messageArgs: {
                        args1: this._getItemName(row)
                    }
                };
                msg = i18n.t('Text.RequiredField');
                //  dialogService().message(options);
                //  return false;
            } if (ignored) {
                // 除外する場合は入力チェックせずに継続
                //return true;
            } else {

                // 値なし、または値ありがチェックの項目は行わない
                if ((row.conditionType !== SEARCH_CONDITION_TYPE.SVF_FIELD && !detail.empty) || (row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD && !detail.anyValue)) {
                    switch (row.type) {
                        // テキスト項目の場合、文字数チェックを行う
                        case 'string':
                        case 'hyperLink':
                            // 文書定義管理IDだけチェックが変わる ※文書定義管理IDとして検索するが受け取りパラメーターがdoctype_idなのでdoctype_idを使用
                            if (row.id === 'doctype_key') {
                                // 半角数字チェック
                                msg = ValidatorService.stringNumeric(detail.value, this._getItemName(row));
                            } else if (row.id === 'id') {
                                // 数値の範囲チェック
                                msg = ValidatorService.rangeNumeric(detail.value, '0', '9223372036854775807', this._getItemName(row), false);
                            } else {
                                // 文字数チェック
                                msg = ValidatorService.stringCount(detail.value, this._getItemName(row), 256);
                            }
                            break;

                        // 数値型項目の場合、数値チェック(範囲チェック)を行う
                        case 'number':
                            // 数値チェック(範囲チェック)
                            var fromNum = '';
                            var toNum = '';
                            var isDecimalPoint = false;
                            var numericCnt;
                            var decimalCnt;
                            if (row.conditionType === SEARCH_CONDITION_TYPE.SYSTEM) {
                                if (row.id === 'loader_result_code') {
                                    fromNum = '-2147483648';
                                    toNum = '2147483647';
                                } else {
                                    fromNum = '0';
                                    toNum = '2147483647';
                                }
                            } else if (row.conditionType === SEARCH_CONDITION_TYPE.CUSTOM) {
                                // 整数
                                if (row.numberType === 2) {
                                    fromNum = '-9999999999999999999.99999999999999999999';
                                    toNum = '9999999999999999999.99999999999999999999';
                                    isDecimalPoint = true;
                                    numericCnt = 19;
                                    decimalCnt = 20;
                                } else {
                                    fromNum = '-9223372036854775808';
                                    toNum = '9223372036854775807';
                                }
                            } else if (row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD || row.conditionType === SEARCH_CONDITION_TYPE.DETAIL) {
                                fromNum = '-9999999999999999999.99999999999999999999';
                                toNum = '9999999999999999999.99999999999999999999';
                                isDecimalPoint = true;
                                numericCnt = 19;
                                decimalCnt = 20;
                            }
                            // 開始数値の範囲チェック
                            if (detail.min !== undefined && detail.min !== '') {
                                msg = ValidatorService.rangeNumeric(detail.min, fromNum, toNum, this._getItemName(row), isDecimalPoint, numericCnt, decimalCnt);
                            }
                            // 終了数値の範囲チェック
                            if (detail.max !== undefined && detail.max !== '') {
                                if (msg.length === 0) {
                                    msg = ValidatorService.rangeNumeric(detail.max, fromNum, toNum, this._getItemName(row), isDecimalPoint, numericCnt, decimalCnt);
                                }
                            }
                            // 開始数値・終了数値の関連チェック
                            if ((detail.min !== undefined && detail.min !== '') && (detail.max !== undefined && detail.max !== '')) {
                                if (msg.length === 0) {
                                    msg = ValidatorService.rangeRelationNumeric(detail.min, detail.max, this._getItemName(row));
                                }
                            }
                            break;

                        // 日付型項目の場合、日付フォーマットチェックを行う
                        case 'date':
                            var dateType = '';
                            var dateFormat = '';
                            if (row.conditionType === SEARCH_CONDITION_TYPE.CUSTOM ||
                                row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD ||
                                row.conditionType === SEARCH_CONDITION_TYPE.DETAIL) {
                                // @ts-ignore
                                dateFormat = DATE_TYPE[Number(row.dateTypeIndex)];
                            } else {
                                // 年・月・日・時・分・秒固定
                                // @ts-ignore
                                dateFormat = DATE_TYPE[Number(FORMAT_DATE.YYYYMMDDHHMMSS)];
                            }
                            dateType = params.dateFormat[dateFormat];
                            if (detail.specified) {
                                // 日付フォーマットチェック（FROM）
                                if (detail.from !== '') {
                                    msg = ValidatorService.formatDate(detail.from, this._getItemName(row), dateType, dateFormat);
                                }
                                // 日付フォーマットチェック（TO）
                                if (detail.to !== '') {
                                    if (msg.length === 0) {
                                        msg = ValidatorService.formatDate(detail.to, this._getItemName(row), dateType, dateFormat);
                                    }
                                }
                                // 日付範囲チェック
                                if (detail.from !== '' && detail.to !== '') {
                                    if (msg.length === 0) {
                                        msg = ValidatorService.rangeDate(detail.from, detail.to, this._getItemName(row), dateType, dateFormat);
                                    }
                                }
                            } else {
                                // 期間が「ON」の場合　「From、To」の両方をチェックする
                                if (detail.range) {
                                    // 日付期間チェック（FROM）
                                    if (detail.from !== '') {
                                        if (msg.length === 0) {
                                            msg = ValidatorService.rangeNumeric(detail.from, '-2147483648', '2147483647', this._getItemName(row), false);
                                        }
                                    }
                                    // 日付期間チェック（TO）
                                    if (detail.to !== '') {
                                        if (msg.length === 0) {
                                            msg = ValidatorService.rangeNumeric(detail.to, '-2147483648', '2147483647', this._getItemName(row), false);
                                        }
                                    }

                                    // 開始数値（FROM）・終了数値（TO）の関連チェック
                                    if ((detail.from !== undefined && detail.from !== '') && (detail.to !== undefined && detail.to !== '')) {
                                        if (msg.length === 0) {
                                            msg = ValidatorService.rangeRelationNumeric(detail.from, detail.to, this._getItemName(row));
                                        }
                                    }
                                } else {
                                    //日時指定の数値チェック
                                    if (detail.day !== '') {
                                        if (msg.length === 0) {
                                            msg = ValidatorService.rangeNumeric(detail.day, '-2147483648', '2147483647', this._getItemName(row), false);
                                        }
                                    }
                                }
                            }
                            break;
                    }
                    if (msg.length > 0) {
                        options = {
                            type: 'Warning',
                            isLocale: false,
                            message: msg
                        };
                        //  dialogService().message(options);
                        emptyRow.push(row);
                    }
                }
            }
        }

        return { msg: msg, emptyRow: emptyRow, ignoreRowIndexes: ignoreRowIndexes };
    }
    // 項目名の取得
    _getItemName = (row: any) => {
        var name = '';
        if (row.conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD || row.conditionType === SEARCH_CONDITION_TYPE.DETAIL || row.conditionType === SEARCH_CONDITION_TYPE.CUSTOM) {
            name = row.name;
        } else if (row.conditionType === SEARCH_CONDITION_TYPE.FOLDER) {
            name = i18n.t(row.name);
        } else {
            name = i18n.t(DocumentOptionService.getDocumentPropertyResource(row.name));
        }
        return name;
    }
}

export default new SearchOptionService()
