import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    Box
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import DocContainer from "../../container/DocContainer";
import { MSG_PIPE_STR } from "../../constants/Constants";
import { CONVERT_DATE_TYPE, DATE_FORMAT_APP_INFO } from "../../constants/PropertiesConstants";
import PropRenderService from "../../service/PropRenderService";


type Props = {
    isOpen: boolean;
    doClose: (event: object) => void;
};
const SvfSearchFieldDialog: FC<Props> = ({
    isOpen,
    doClose
}) => {
    const docState = DocContainer.useContainer();
    const [defaultValue, setDefaultValue] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isDate, setIsDate] = useState(false);
    const [dateType, setDateType] = useState('');
    const [value, setValue] = useState('');
    const { t, i18n } = useTranslation();


    useEffect(() => {
        let currentValue = docState.getSelectFieldInfo(docState.FieldProp.EditValue);
        if (currentValue === undefined) {
            currentValue = docState.getSelectFieldInfo(docState.FieldProp.Value);
        }
        if (docState.getSelectFieldInfo(docState.FieldProp.FieldType) === 'DATE') {
            let type = docState.getSelectFieldInfo(docState.FieldProp.DateType)
            setIsDate(true);
            setDateType(type);
            // @ts-ignore
            let format = DATE_FORMAT_APP_INFO[CONVERT_DATE_TYPE[type]];
            currentValue = getFormattedDate(currentValue, format);
        } else {
            setDateType('');
            setIsDate(false);
        }
        setDefaultValue(currentValue);
        // @ts-ignore
        setValue(currentValue);
        setOpen(isOpen);
    }, [isOpen]);
    /**
     * フォーマット文字列変換
     * @param value
     * @param formatStr
     */
    const getFormattedDate = (value: string, formatStr: string) => {
        return PropRenderService.getFormattedDate(value, formatStr);
    }
    /**
     * OKボタンクリック
     * @param event
     */
    const handleSubmit = (event: any) => {
        // @ts-ignore
        let editValue = value;
        //起動時の値と異なる場合のみ保存
        if (defaultValue !== editValue) {
            //バリデーションチェック
            const msg = docState.fieldValueValidate(editValue);
            if (msg.length === 0) {
                if (isDate) {
                    // @ts-ignore
                    editValue = getFormattedDate(value, CONVERT_DATE_TYPE[dateType]);
                }
                docState.fieldValueUpdate(editValue);
                doClose(event);
            } else {
                setMessage(msg);
            }
        } else {
            doClose(event);
        }
    };

    return (
        <Dialog
            key={'fieldEditDialog'}
            open={open}
            onClose={doClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"> {docState.getSelectFieldInfo(docState.FieldProp.SearchName)}</DialogTitle>
            <DialogContent>
                <Box style={{ width: 400 }}>
                    <DialogContentText>
                        {t('Text.CurrentValue') + MSG_PIPE_STR}{defaultValue}
                    </DialogContentText>
                    <TextField
                        error={message.length > 0}
                        autoFocus
                        margin="dense"
                        id="editValue"
                        label={t('Text.NewValue') + MSG_PIPE_STR}
                        type="text"
                        fullWidth
                        value={value}
                        helperText={message}
                        onChange={(e) => setValue(e.currentTarget.value + '')}
                        onFocus={() => setMessage('')}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} color="primary" autoFocus>
                    {t('Text.Ok')}
                </Button>
                <Button onClick={doClose} onTouchEnd={doClose} color="primary">
                    {t('Text.Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default SvfSearchFieldDialog;
