import { Button, Divider, IconButton, MuiThemeProvider } from "@material-ui/core";
import { SelectableRows } from "mui-datatables";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import CommonContainer from "../../container/CommonContainer";
import CustomContainer from "../../container/CustomContainer";
import UserContainer from "../../container/UserContainer";
import Word from "../../common/Word";
import { ShippingData } from "../../models/ShippingData";
import { useListService } from "../../service/ListService";
import { useManageService } from "../../service/ManageService";
import { CustomMUIDataTableOptions } from "../customMUIDataTable";
import CustomMUIDataTable from "../customMUIDataTable/CustomMUIDataTable";
import CellFullCheckBox from "../table/CellFullCheckBox";
import CellReceiptCheckbox from "../table/CellCustomCheckbox";
import { useStyles, table, dialog } from "./css/Style";
import { DeliveryDialog } from "../dialog/DeliveryDialog";
import { ManagementDeleteDialog } from "../dialog/ManagementDeleteDialog";
import { TreeViewDialog } from "../dialog/TreeViewDialog";
import { DocDefListDialog } from "../dialog/DocDefListDialog";
import { RenderTree } from "../../models/RenderTree";

const getStatusMap = {
    "未取得のみ": "0",
    "取得済のみ": "1",
    "すべて取得": "2",
}

export default function DeliveryList(props: { setCallBackMessage: (arg0: { status: number; message: string; open: boolean }) => void; }) {

    // css
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const commonState = CommonContainer.useContainer();
    const customState = CustomContainer.useContainer();
    const userState = UserContainer.useContainer();

    const { folderListApi, insertDeliveryFolderApi, updateDeliveryFolderApi, getDeliveryFolderNameApi,
        deliveryGetDataApi, deliveryAddDataApi, deliveryUpdatetataApi, deliveryDeleteDataApi } = useManageService()

    const { doctypeList } = useListService()
    const [tableData, setTableData] = React.useState<ShippingData[]>([]);
    const [tableColumns, setTableColumns] = React.useState(
        [
            {
                name: "",
                options: {
                    sort: false,
                    viewColumns: false,
                    customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                        return (
                            <>
                                <CellReceiptCheckbox meta={tableMeta} />
                            </>
                        )
                    },
                    customHeadLabelRender: (columnMeta: any) => {
                        return (
                            <>
                                <CellFullCheckBox />
                            </>
                        )
                    }
                }
            },
            {
                label: i18n.t('Text.SubmitDatetime'), //納入（予定）日
                name: "estiDelDateDttm",
                options: {
                    viewColumns: false
                }
            },
            {
                label: i18n.t('Text.DelDateDttmRelative'), //納入日
                name: "estiDelDateDttmRelative",
                options: {
                    viewColumns: false
                }
            },
            {
                label: i18n.t('Text.ShipToPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")", //荷届先コード（ローカル）
                name: "shipToPrivateCdId",
                options: {
                    viewColumns: false
                }
            },
            {
                label: i18n.t('Text.CnsgPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")", //荷送人コード（ローカル）
                name: "cnsgPrivateCdId"
            },
            {
                label: i18n.t('Text.ShipFromPrivateCdId') + "(" + i18n.t('Text.StorageTypeLocal') + ")", //出荷場所コード（ローカル）
                name: "shipFromPrivateCdId"
            },
            {
                label: i18n.t('Text.TrspCliPrivateCdId') + "(" + i18n.t('Text.InternalLocal') + ")", //運送事業者コード（自社ローカル）
                name: "trspCliPrivateCdId"
            },
            {
                label: i18n.t('Text.CnnPrivateCdId') + "(" + i18n.t('Text.InternalLocal') + ")", //中継拠点コード（ローカル）
                name: "cnnPrivateCdId"
            },
            {
                label: i18n.t('Text.SubmitNo'), //納品伝票番号
                name: "delSlipNumId"
            },
            {
                label: i18n.t('Text.VouDateIssDttm'), //納品伝票データ作成日(日付指定)
                name: "vouDateIssDttm"
            },
            {
                label: i18n.t('Text.VouDateIssDttmRelative'), //納品伝票データ作成日付
                name: "vouDateIssDttmRelative"
            },
            {
                label: i18n.t('Text.DocTypeKey'), //文書定義管理ID
                name: "docTypeKey"
            },
            {
                label: i18n.t('Text.ShippingFetchFlag'),
                name: "shippingFetchFlag"
            },
            {
                label: i18n.t('Text.DeliveryGetStatus'),
                name: "getStatus"
            }
        ]
    );
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [addData, setAddData] = React.useState<ShippingData>({});
    const [openAdd, setOpenAdd] = React.useState(false);
    const [editData, setEditData] = React.useState<ShippingData>({});
    const [openEdit, setOpenEdit] = React.useState(false);
    const [folderTree, setFolderTree] = React.useState<boolean>(false);
    const [treeView, setTreeView] = React.useState<RenderTree>({ id: "1", name: "/", path: "/" });
    const [docListOpen, setDocListOpen] = React.useState(false);
    const [folderPath, setFolderPath] = React.useState<string>();
    const [folderIdStatus, setFolderIdStatus] = React.useState<boolean>(true);
    const [selectRowId, setSelectRowId] = React.useState<string>("");
    const [estiDateDttmDateChoose, setEstiDateDttmDateChoose] = React.useState<boolean>(false);
    const [vouDateIssDttmDateChoose, setVouDateIssDttmDateChoose] = React.useState<boolean>(false);
    const [docDefData, setDocDefData] = React.useState<any>([]);
    const [currentRow, setCurrentRow] = React.useState<any>({});
    const [docTypeKey, setDocTypeKey] = React.useState<string>("");

    const options: CustomMUIDataTableOptions = {
        selectableRows: 'single' as SelectableRows,
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        selectableRowsHeader: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: false,
        search: false,
        sort: true,
        filter: false,
        print: false,
        download: false,
        viewColumns: true,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            // 現在の行データを取得する
            getSelectedRowData(currentRowsSelected);

            if (allRowsSelected !== undefined) {
                if (currentRowsSelected && currentRowsSelected.length === 1 && customState.rowClickAction !== "checkbox") {
                    let idx = currentRowsSelected[0].dataIndex;
                    tableData.forEach((d, index) => {
                        changeRowColor(index, true);
                    });
                    changeRowColor(idx, false);

                    // 「行コントロール」チェックボックスをクリックします
                    var checkArray = customState.rowCheckStatuslist.slice();
                    checkArray.forEach(value => {
                        if (value.index === idx) {
                            value.check = true;
                            let array = [];
                            array.push(idx);
                            customState.setCheckList(array);
                        } else {
                            value.check = false;
                        }
                    })
                    customState.setRowCheckStatuslist(checkArray);
                    customState.setFullCheck(false);
                }

                customState.setRowClickAction("");
            }
        },
        onTableChange: (action, tableState) => {
            if (!!tableState.displayData && !!customState.rowCheckStatuslist) {
                customState.rowCheckStatuslist.forEach((val, index) => {
                    if (val.check) {
                        changeRowColor(index, false);
                    } else {
                        changeRowColor(index, true);
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: ''
            },
            toolbar: {
                viewColumns: Word('Text.ColumnDisplaySetting'),
            },
            viewColumns: {
                title: Word('Text.ColumnDisplaySetting')
            },
            pagination: {
                next: Word('Text.PageDown'),
                previous: Word('Text.PageUp'),
                rowsPerPage: Word('Text.rowsPerPage'),
                displayRows: "/",
            }
        }
    };

    useEffect(() => {
        getListData();
    }, [])

    useEffect(() => {
        tableData.forEach((val, index) => {
            if (customState.checkList.indexOf(index) > -1) {
                changeRowColor(index, false)
            } else {
                changeRowColor(index, true)
            }
        })
    }, [customState.checkList])

    /**
     * 選択行の背景色を変える
     */
    const changeRowColor = (index: number, clear: boolean) => {
        let row = document.getElementById(`MUIDataTableBodyRow-${index}`);
        let color = (clear) ? 'white' : '#EBEBEB';
        // @ts-ignore
        if (row) {
            row.setAttribute('style', 'background:' + color);
        }
    }

    /**
     * 納品書の情報管理情報取得
     */
    const getListData = () => {
        commonState.setProgress(true);
        Promise.all([
            deliveryGetDataApi(userState.tenantId as string),
            folderListApi(),
            getDeliveryFolderNameApi(userState.tenantId as string),
        ]).then(responses => {
            let data = responses[0];
            let dataList = [];
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    dataList.push({ index: i, check: false })
                    data[i].shippingFetchFlag = data[i].shippingFetchFlag == "1" ? i18n.t('Text.Obtain') : i18n.t('Text.NotObtain');
                    data[i].getStatus = data[i].getStatus == "0" ? i18n.t('Text.OnlyUnavailable') : data[i].getStatus == "1" ? i18n.t('Text.OnlyAvailable') : i18n.t('Text.GetAll');
                }
            }
            customState.setRowCheckStatuslist(dataList);
            setTreeView({ ...treeView, children: responses[1].folderList });
            if (responses[2].length > 0) {
                var folderInfo = findFolder(responses[2][0].folderId, responses[1].folderList);
                if (folderInfo) {
                    setFolderPath(folderInfo.path);
                } else {
                    setFolderIdStatus(false)
                    setFolderPath(i18n.t('Text.NotSelect'));
                }
            } else {
                setFolderPath(i18n.t('Text.NotSelect'));
            }
            setTableData(data);
            customState.setCheckList([]);
            customState.setFullCheck(false);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
            resetData();
        })
    }

    /**
     * ファイルパスを見つける
     * @param id フォルダ ID
     * @param data フォルダのリスト
     * @returns フォルダ情報
     */
    const findFolder = (id: string, data: RenderTree[]) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                return data[i];
            }
            if (data[i].children!.length > 0) {
                let folderInfo: any = findFolder(id, data[i].children!);
                if (folderInfo) {
                    return folderInfo;
                }
            }
        }
        return null;
    }

    /**
     * 選択フォルダー
     */
    const selectFolder = (folder: RenderTree) => {
        let params = {
            tenantId: userState.tenantId,
            folderId: folder?.id
        }
        commonState.setProgress(true);
        if (folderPath === i18n.t('Text.NotSelect') && folderIdStatus) {
            insertDeliveryFolderApi(params).then((data) => {
                setFolderPath(folder?.path);
                setFolderTree(false);
            }).catch((error) => {
                console.log("****** error is *******", error.response);
            })
        } else {
            updateDeliveryFolderApi(params).then((data) => {
                setFolderPath(folder?.path);
                setFolderTree(false);
            }).catch((error) => {
                console.log("****** error is *******", error.response);
            })
        }
        commonState.setProgress(false);
    }

    /**
     * 文書定義リストの取得
     */
    const getDocDefList = () => {
        commonState.setProgress(true);
        doctypeList().then((response) => {
            if (openAdd) {
                setSelectRowId(docTypeKey);
            } else if (openEdit) {
                setSelectRowId(editData.docTypeKey!);
            }
            setDocDefData(response);
            setDocListOpen(true);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
        })
    }

    /**
     * 文書定義ポップアップウィンドウを閉じる
     */
    const handleDocClose = (isOk: boolean, currentRowId: string) => {
        if (isOk) {
            if (openAdd) {
                setDocTypeKey(currentRowId);
            } else if (openEdit) {
                setDocTypeKey(currentRowId);
                setEditData({ ...editData, docTypeKey: currentRowId });
            }
        }
        setDocListOpen(false);
    }

    /**
     * 追加ダイアログボックスを開く
     */
    const addOpen = () => {
        setOpenAdd(true);
        setEstiDateDttmDateChoose(true);
        setVouDateIssDttmDateChoose(true);
    }

    /**
     * 納品書の情報管理情報新規
     */
    const handleAddButtonOnClick = (addData: ShippingData) => {
        setOpenAdd(false);
        commonState.setProgress(true);
        deliveryAddDataApi(addData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.AddSuccessful'), open: true });
        }).catch((error) => {
            console.log("****** error is *******", error)
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.AddFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * ダイアログを閉じる
     */
    const handleAddClose = async () => {
        setOpenAdd(false);
        resetData();
    }

    /**
     * リセットデータ
     */
    const resetData = () => {
        setAddData({
            estiDelDateDttm: "",
            estiDelDateDttmRelative: "",
            shipToPrivateCdId: "",
            cnsgPrivateCdId: "",
            shipFromPrivateCdId: "",
            trspCliPrivateCdId: "",
            cnnPrivateCdId: "",
            delSlipNumId: "",
            vouDateIssDttm: "",
            vouDateIssDttmRelative: "",
            docTypeKey: "",
            shippingFetchFlag: "1",
            getStatus: "0"
        });
        setDocTypeKey("")
    }

    /**
     * 現在の行データを取得する
     */
    const getSelectedRowData = (currentRowsSelected: any[]) => {
        const currentRow = { ...tableData[currentRowsSelected[0].dataIndex] };
        currentRow.shippingFetchFlag = currentRow.shippingFetchFlag === i18n.t('Text.Obtain') ? "1" : "0";
        currentRow.getStatus = getStatusMap[currentRow.getStatus as keyof typeof getStatusMap];
        setCurrentRow(currentRow);
    }

    /**
     * 編集ダイアログを開く
     */
    const editOpen = () => {
        setOpenEdit(true);
        if (!!currentRow.estiDelDateDttm) {
            setEstiDateDttmDateChoose(true);
        } else {
            setEstiDateDttmDateChoose(false);
        }

        if (!!currentRow.vouDateIssDttm) {
            setVouDateIssDttmDateChoose(true);
        } else {
            if (!!!currentRow.vouDateIssDttmRelative) {
                setVouDateIssDttmDateChoose(true);
            } else {
                setVouDateIssDttmDateChoose(false);
            }
        }
        setEditData(currentRow);
    }

    /**
     * 納品書の情報管理情報編集
     */
    const handleEditButtonOnClick = (editData: ShippingData) => {
        setOpenEdit(false);
        commonState.setProgress(true);
        deliveryUpdatetataApi(editData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.EditSuccessful'), open: true });
        }).catch((error) => {
            console.log("****** error is *******", error)
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.EditFailed'), open: true });
        }).finally(() => {
            getListData();
        })
    }

    /**
     * 削除操作の実行
     */
    const handleDeleteButtonOnClick = () => {
        const idArray: any[] = [];
        customState.checkList.forEach(value => {
            idArray.push(tableData[value]?.id)
        })
        let params = {
            id: idArray.join(',')
        }
        setOpenDeleteAlert(false);
        commonState.setProgress(true);
        deliveryDeleteDataApi(params).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.DeleteSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.DeleteFailed'), open: true });
            console.log("****** error is ******", error.response);
        }).finally(() => {
            getListData();
        });
    }

    return (
        <>
            <div className={classes.tabTitle}>{t('Text.ShippingInformationManagement')}</div>
            <Divider />
            <div className={classes.iconStyle}>
                <IconButton onClick={addOpen}>
                    <AddIcon className={classes.addButtonActived} />
                </IconButton>
                <IconButton onClick={editOpen} disabled={customState.checkList.length !== 1}>
                    <EditIcon className={customState.checkList.length === 1 ? classes.editButtonActived : classes.editButtonDisabled} />
                </IconButton>
                <IconButton onClick={() => setOpenDeleteAlert(true)} disabled={customState.checkList.length === 0}>
                    <CloseIcon className={customState.checkList.length > 0 ? classes.deleteButtonActived : classes.deleteButtonDisabled} />
                </IconButton>
            </div>
            <Divider />
            <div style={{ display: 'flex', margin: ' 5px 15px' }}>
                <span style={{ lineHeight: '35px' }}>{t('Text.ShippingFolder')}</span>
                <Button style={{ backgroundColor: "#e2e2e2", marginLeft: '20px' }} size='small' onClick={() => { setFolderTree(true) }}>
                    {t('Text.Select')}
                </Button>
                <span style={{ lineHeight: '35px', marginLeft: '20px' }}>{folderPath}</span>
            </div>
            <div style={{ width: '100%', backgroundColor: 'white', padding: '10px 10px 20px 10px' }}>
                <MuiThemeProvider theme={table()}>
                    <CustomMUIDataTable
                        columns={tableColumns}
                        data={tableData}
                        title={undefined}
                        options={options}>
                    </CustomMUIDataTable>
                </MuiThemeProvider>
            </div>

            <MuiThemeProvider theme={dialog()}>
                {/* 納品書の情報管理の新規 */}
                <DeliveryDialog
                    title={t('Text.New')}
                    isOpen={openAdd}
                    data={addData}
                    estiDateDttmDateChoose={estiDateDttmDateChoose}
                    vouDateIssDttmDateChoose={vouDateIssDttmDateChoose}
                    docTypeKey={docTypeKey}
                    doClose={() => handleAddClose()}
                    getDocDefList={() => getDocDefList()}
                    doClick={(addData) => handleAddButtonOnClick(addData)}
                />

                {/* 納品書の情報管理の編集 */}
                <DeliveryDialog
                    title={t('Text.Edit')}
                    isOpen={openEdit}
                    data={editData}
                    estiDateDttmDateChoose={estiDateDttmDateChoose}
                    vouDateIssDttmDateChoose={vouDateIssDttmDateChoose}
                    docTypeKey={docTypeKey}
                    doClose={() => setOpenEdit(false)}
                    getDocDefList={() => getDocDefList()}
                    doClick={(editData) => handleEditButtonOnClick(editData)}
                />
            </MuiThemeProvider>

            {/* 削除のヒント */}
            <ManagementDeleteDialog
                isOpen={openDeleteAlert}
                doClose={() => setOpenDeleteAlert(false)}
                deleteButtonOnClick={() => handleDeleteButtonOnClick()}
            />

            {/* ツリービュー (Tree View) */}
            <TreeViewDialog
                isOpen={folderTree}
                treeView={treeView}
                selectFolder={(folder) => selectFolder(folder)}
                doClose={() => setFolderTree(false)}
            />

            {/* 文書定義リスト */}
            <DocDefListDialog
                isOpen={docListOpen}
                docDefData={docDefData}
                selectRowId={selectRowId}
                doClose={(isOk: boolean, currentRowId: string) => handleDocClose(isOk, currentRowId)}
            />

        </>
    )
}