import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';


// ↓ この一行でnpm run buildの際にconsole.log()を何もしないメソッドにすり替え
process.env.NODE_ENV !== "development" && (console.log = () => {});

ReactDOM.render(
    <App />
  ,
  document.getElementById('root')
);

