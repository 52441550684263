import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * SvfField Values API
 */
export const useSvfFieldValueService = () => {
    const serviceState = ServiceContainer.useContainer()

    const getSvfFieldValue = (docId: number | undefined) => {
        //更新フラグ、確認済みフラグ、バリデーション判定情報、確認対象フラグを取得
        let formBody = 'updated=true&isDataCheck=true&visualConfirmation=true&isValidate=true';
        return serviceState.request(
            WebConstants.SVFFIELD_VALUES_GET_URL + docId,
            WebConstants.METHOD_POST,
            formBody,
            WebConstants.HEADER_CONTENTTYPE_URLENCODED
        ).then((response) => {
            console.log("****** svfFieldValuesGet is *******", response);
            return response.data;
        })
    }

    const updateSvfFieldValue = (docId: number, data: any) => {
        return serviceState.request(
            WebConstants.SVFFIELD_VALUES_GET_URL + docId,
            WebConstants.METHOD_PUT,
            JSON.stringify(data),
            WebConstants.HEADER_CONTENTTYPE_JSON
        ).then((response) => {
            console.log("****** svfFieldValuesUpdate is *******", response);
            return response;
        })
    }

    const allUpdateSvfFieldValue = (data: any) => {
        return serviceState.request(
            WebConstants.SVFFIELD_VALUES_ALL_UPDATE_URL,
            WebConstants.METHOD_POST,
            data
        ).then((response) => {
            console.log("****** svfFieldValuesAllUpdate is *******", response);
            return response.data;
        }).catch((error) => {
            console.log("****** error is *******", error.response);
            return error.response;
        })
    }

    return {
        getSvfFieldValue,
        updateSvfFieldValue,
        allUpdateSvfFieldValue
    }
}