/**
 * ファイルプロパティのリスト
 */
export const FILE_PROPERTIES = {
    'name': { 'display': 'Text.Name', 'type': 'icon', width: 200 },//ファイル名
    'type': { 'display': 'Text.DocType', 'type': 'string', width: 100 },//種類
    'filetype': { 'display': 'Text.Kind', 'type': 'string', width: 100 },//ファイル種類
    'linkpath': { 'display': 'Text.LinkedPath', 'type': 'string', width: 100 },//リンクパス
    'docstatus': { 'display': 'Text.Status', 'type': 'string', width: 100 },//文書ステータス(旧)
    'parsed_status': { 'display': 'Text.Status', 'type': 'string', width: 100 },//文書ステータス
    'adddate': { 'display': 'Text.FirstAddDate', 'type': 'date', width: 200 },//初回作成日時
    'adduser': { 'display': 'Text.FirstAddUser', 'type': 'string', width: 100 },//初回作成ユーザー
    'adduserdomain': { 'display': 'Text.FirstAddDomain', 'type': 'string', width: 100 },//初回作成ユーザードメイン
    'updatedate': { 'display': 'Text.AddFileDate', 'type': 'date', width: 200 },//アーカイブ日時
    'updateuser': { 'display': 'Text.AddFileUser', 'type': 'string', width: 100 },//アーカイブユーザー
    'updateuserdomain': { 'display': 'Text.AddFileDomain', 'type': 'string', width: 100 },//アーカイブユーザードメイン
    'pagecount': { 'display': 'Text.PageCount', 'type': 'number', width: 80 },//ページ数
    'size': { 'display': 'Text.SizeKB', 'type': 'number', width: 100 },//サイズ
    'title': { 'display': 'Text.PDFTitle', 'type': 'string', width: 100 },//タイトル
    'subject': { 'display': 'Text.PDFSubject', 'type': 'string', width: 100 },//サブタイトル
    'keywords': { 'display': 'Text.PDFKeywords', 'type': 'string', width: 100 },//キーワード
    'author': { 'display': 'Text.PDFAuthor', 'type': 'string', width: 100 },//PDF作成者
    'creator': { 'display': 'Text.Creator', 'type': 'string', width: 100 },//作成アプリケーション
    'producer': { 'display': 'Text.PDFProducer', 'type': 'string', width: 100 },//PDF変換
    'createdate': { 'display': 'Text.PDFCreateDate', 'type': 'date', width: 100 },//PDF作成日時
    'modifydate': { 'display': 'Text.PDFModifyDate', 'type': 'date', width: 200 }, //PDF更新日時
    'downloaduser': { 'display': 'Text.LastDLUser', 'type': 'string', width: 100 },//最終ダウンロードユーザー
    'downloaduserdomain': { 'display': 'Text.LastDLDomain', 'type': 'string', width: 100 },//最終ダウンロードユーザードメイン
    'downloaddate': { 'display': 'Text.LastDLDate', 'type': 'date', width: 200 }, //最終ダウンロード日時
    'printuser': { 'display': 'Text.LastPrintUser', 'type': 'string', width: 100 },//最終印刷ユーザー
    'printuserdomain': { 'display': 'Text.LastPrintDomain', 'type': 'string', width: 100 },//最終印刷ユーザードメイン
    'printdate': { 'display': 'Text.LastPrintDate', 'type': 'date', width: 200 },//最終印刷日時
    'viewuser': { 'display': 'Text.LastViewUser', 'type': 'string', width: 100 },//最終閲覧ユーザー
    'viewuserdomain': { 'display': 'Text.LastViewDomain', 'type': 'string', width: 100 },//最終閲覧ユーザードメイン
    'viewdate': { 'display': 'Text.LastViewDate', 'type': 'date', width: 200 },//最終閲覧日時
    'doctype_key': { 'display': 'Text.DocTypeKey', 'type': 'string', width: 100 },  //文書定義管理ID
    'doctype_id': { 'display': 'Text.DocTypeId', 'type': 'string', width: 100 },  //文書定義ID
    'doctype_dispname': { 'display': 'Text.DocTypeName', 'type': 'string', width: 100 },//文書定義名
    'stamp': { 'display': 'Text.Timestamp', 'type': 'number', width: 80 },//タイムスタンプ
    'docpath': { 'display': 'Text.Path', 'type': 'string', width: 200 },//文書パス
    'hitpages': { 'display': 'Text.HitPageCount', 'type': 'number', width: 100 },//ヒットページ
    'direct_url': { 'display': 'Text.UrlLink', 'type': 'string', width: 100 },//URLリンク
    'version': { 'display': 'Text.Version', 'type': 'string', width: 100 },//バージョン
    'stamped_image_info': { 'display': 'Text.ImageInfo', 'type': 'string', width: 150 },//タイムスタンプのイメージ情報
    'stamped_expiration_date': { 'display': 'Text.TimestampExpirationDate', 'type': 'date', width: 200 },//タイムスタンプの有効期限
    'review_status': { 'display': 'Text.Review', 'type': 'string', width: 150 },//レビュー
    'document_version': { 'display': 'Text.Version', 'type': 'string', width: 100 }, //文書バージョン
    'svffield_editable_status': { 'display': 'Text.ExistenceSvfField', 'type': 'string', width: 100 }, //SVF検索フィールドの有無（SVF検索フィールドの編集）
    'svffield_data_checked': { 'display': 'Text.SvffieldDataChecked', 'type': 'boolean', width: 100 }, //確認済み
    'annotation_existence': { 'display': 'Text.AnnotationExistence', 'type': 'boolean', width: 100 }, //注釈の有無
    'convert_doc_status': { 'display': 'Text.DocumentConvertStatus', 'type': 'string', width: 100 }, //Document Converter変換ステータス
    'doc_convert_error': { 'display': 'Text.DocumentConvertError', 'type': 'string', width: 200 }, //Document Converter変換エラー
    'checkout': { 'display': 'Text.Checkout', 'type': 'boolean', width: 100 }, //チェックアウト
    'checkoutdate': { 'display': 'Text.CheckoutDate', 'type': 'date', width: 200 }, //チェックアウト日時
    'checkoutuser': { 'display': 'Text.CheckoutUser', 'type': 'string', width: 100 }, //チェックアウトユーザー
    'checkoutuserdomain': { 'display': 'Text.CheckoutUserDomain', 'type': 'string', width: 100 },//チェックアウトユーザーのドメイン
    'stage_name': { 'display': 'Text.ReviewStageName', 'type': 'string', width: 200 }, //レビューの経路名
    'loader_result_code': { 'display': 'Text.LoaderResultCode', 'type': 'number', width: 200 }, //Loaderの実行結果コード
    'output_svffields_createdate': { 'display': 'Text.OutputSvfFieldsCreateDate', 'type': 'date', width: 200 }, //CSVファイル出力の作成日時
    'output_svffields_downloaddate': { 'display': 'Text.OutputSvfFieldsDownloadDate', 'type': 'date', width: 200 }, //CSVファイル出力のダウンロード日時
    'relate_info': { 'display': 'Text.RelateInfo', 'type': 'string', width: 200 }, //関連文書
    'review_complete_date': { 'display': 'Text.ReviewCompleteDate', 'type': 'date', width: 200 }, //レビュー完了日時
    'adduserfullname': { 'display': 'Text.AddUserFullName', 'type': 'string', width: 200 }, //作成ユーザーのフルネーム
    'updateuserfullname': { 'display': 'Text.UpdateUserFullName', 'type': 'string', width: 200 }, //アーカイブユーザーのフルネーム
    'viewuserfullname': { 'display': 'Text.ViewUserFullName', 'type': 'string', width: 200 }, //最終閲覧ユーザーのフルネーム
    'downloaduserfullname': { 'display': 'Text.DownloadUserFullName', 'type': 'string', width: 200 }, //最終ダウンロードユーザーのフルネーム
    'printuserfullname': { 'display': 'Text.PrintUserFullName', 'type': 'string', width: 200 }, //最終印刷ユーザーのフルネーム
    'convert_pdf_status': { 'display': 'Text.ConvertPdfStatus', 'type': 'string', width: 200 }, //PDF変換ステータス
    'folder_direct_url': { 'display': 'Text.FolderDirectUrl', 'type': 'string', width: 200 }, //フォルダーのURLリンク
    'checkoutuserfullname': { 'display': 'Text.CheckoutUserFullName', 'type': 'string', width: 200 }, //チェックアウトユーザーのフルネーム
    'source_box_file': { 'display': 'Text.SourceBoxFile', 'type': 'string', width: 200 }, //取り込み元Boxファイル
    'target_box_file': { 'display': 'Text.TargetBoxFile', 'type': 'string', width: 200 }, //出力先Boxファイル
    //不足分
    'id': { 'display': 'Text.ID', 'type': 'string', width: 200 },                             // ID
    'annotation': { 'display': 'Text.Annotation', 'type': 'string', width: 200 },           // 注釈
    'annotation_text': { 'display': 'Text.AnnotationText', 'type': 'string', width: 200 },   // 注釈のテキスト
    'comment': { 'display': 'Text.Comment', 'type': 'string', width: 200 },                  // 文書のコメント
    'fileId': { 'display': 'Text.FileId', 'type': 'string', width: 200 },                     // ファイルID
    'private_annotation_text': { 'display': 'Text.PrivateAnnotationText', 'type': 'string', width: 200 },// 注釈(個人)のテキスト
    'pagememo': { 'display': 'Text.PageMemo', 'type': 'string', width: 200 },               // ページメモ
    'formname': { 'display': 'Text.FormFileName', 'type': 'string', width: 200 },        // 様式名
    'pagenumber': { 'display': 'Text.PageNumber', 'type': 'string', width: 200 },             // ページ番号
    'sizeKB': { 'display': 'Text.SizeKB', 'type': 'string', width: 200 },                       // ファイルサイズ(KB)
};
/**
 * レビュープロパティのリスト
 */
export const REVIEW_PROPERTIES = {
    'id': { 'display': 'Text.ReviewId', 'type': 'string', width: 100 }, //レビューID
    'description': { 'display': 'Text.ReviewDescription', 'type': 'string', width: 150 },//レビューの説明
    'userId': { 'display': 'Text.ReviewIssueUserId', 'type': 'string', width: 100 },//起票者のユーザーID
    'userName': { 'display': 'Text.ReviewIssueUser', 'type': 'string', width: 100 },//起票者のユーザー名
    'domainId': { 'display': 'Text.Domain', 'type': 'string', width: 100 },//起票者の所属するドメインID
    'domainName': { 'display': 'Text.DomainName', 'type': 'string', width: 100 },//起票者の所属するドメイン名
    'userFullname': { 'display': 'Text.FullName', 'type': 'string', width: 100 },//起票者のユーザーフルネーム
    'deletedUser': { 'display': 'Text.UserDeleteFlg', 'type': 'boolean', width: 100 },//起票者は削除済み
    'documentId': { 'display': 'Text.FileId', 'type': 'string', width: 100 },	//レビュー対象となる文書のID
    'documentName': { 'display': 'Text.FileName', 'type': 'string', width: 100 },//レビュー対象となる文書名
    'path': { 'display': 'Text.Path', 'type': 'string', width: 150 },//レビュー他対象となる文書のパス
    'status': { 'display': 'Text.Status', 'type': 'string', width: 100 },//ステータス
    'createDate': { 'display': 'Text.CreateDate', 'type': 'date', width: 170 },//作成日時
    'startDate': { 'display': 'Text.StartDate', 'type': 'date', width: 170 },//	開始日時
    'completeDate': { 'display': 'Text.CompletedDate', 'type': 'date', width: 170 },//完了日時
    'userExitPath': { 'display': 'Text.FullPathOfProgram', 'type': 'string', width: 150 },//完了時に起動する外部プログラムのパス
    'progressStatus': { 'display': 'Text.ProgressStatus', 'type': 'string', width: 100 },//レビューの現在の経路名（進行状況）
    'checkout': { 'display': 'Text.Checkout', 'type': 'string', width: 100 },//チェックアウトの状態
    'checkoutuser': { 'display': 'Text.CheckoutUser', 'type': 'string', width: 100 },//チェックアウトユーザ名
    'checkoutuserdomain': { 'display': 'Text.CheckoutUserDomain', 'type': 'string', width: 100 },//チェックアウトユーザーのドメイン名
    'checkoutdate': { 'display': 'Text.CheckoutDate', 'type': 'date', width: 100 }//チェックアウト日時
};
export const FORMAT_DATE = {
    // 年月日時分秒(デフォルト)
    'YYYYMMDDHHMMSS': 1,

    // 年月日時分
    'YYYYMMDDHHMM': 2,

    // 年月日
    'YYYYMMDD': 3,

    // 月日時分
    'MMDDHHMM': 4,

    // 月日
    'MMDD': 5,

    // 時分秒
    'HHMMSS': 6,

    // 時分
    'HHMM': 7
}

export const DATE_TYPE = {
    1: 'yyyyMMDDHHmmss',//年月日時分秒(デフォルト)
    2: 'yyyyMMDDHHmm',//年月日時分
    3: 'yyyyMMDD',//年月日
    4: 'MMDDHHmm',//月日時分
    5: 'MMDD',//月日
    6: 'HHmmss',//時分秒
    7: 'HHmm'// 時分
};
export const DATE_FORMAT = {
    1: 'yyyy/MM/DD HH:mm:ss',//年月日時分秒(デフォルト)
    2: 'yyyy/MM/DD HH:mm',//年月日時分
    3: 'yyyy/MM/DD',//年月日
    4: 'MM/DD HH:mm',//月日時分
    5: 'MM/DD',//月日
    6: 'HH:mm:ss',//時分秒
    7: 'HH:mm'// 時分
};
export const DATE_FORMAT_APP_INFO = {
    default: 'yyyy/MM/DD HH:mm:ss',
    'yyyyMMDDHHmmss': 'yyyy/MM/DD HH:mm:ss',//年月日時分秒(デフォルト)
    'yyyyMMDDHHmm': 'yyyy/MM/DD HH:mm',//年月日時分
    'yyyyMMDD': 'yyyy/MM/DD',//年月日
    'MMDDHHmm': 'MM/DD HH:mm',//月日時分
    'MMDD': 'MM/DD',//月日
    'HHmmss': 'HH:mm:ss',//時分秒
    'HHmm': 'HH:mm'// 時分
}
export const CONVERT_DATE_TYPE = {
    'yyyyMMddHHmmss': 'yyyyMMDDHHmmss',//年月日時分秒(デフォルト)
    'yyyyMMddHHmm': 'yyyyMMDDHHmm',//年月日時分
    'yyyyMMdd': 'yyyyMMDD',//年月日
    'MMddHHmm': 'MMDDHHmm',//月日時分
    'MMdd': 'MMDD',//月日
    'HHmmss': 'HHmmss',//時分秒
    'HHmm': 'HHmm'// 時分
}
export const DATE_FORMAT_HTML = {
    1: 'yyyy-MM-DD HH:mm:ss',//年月日時分秒(デフォルト)
    2: 'yyyy-MM-DD HH:mm',//年月日時分
    3: 'yyyy-MM-DD',//年月日
};
// 種別
export const KIND_TYPE = {
    0: { class: 'document', display: 'Text.File' },
    1: { class: 'link', display: 'Text.Link' },
    2: { class: 'pageLink', display: 'Text.PageLink' },
    3: { class: 'multiLink', display: 'Text.MultiLink' },
    95: { class: 'replacingFile', display: 'Text.File' },
    96: { class: 'ownerPasswordLink', display: 'Text.Link' },
    97: { class: 'ownerPasswordFile', display: 'Text.File' },
    98: { class: 'otherFile', display: 'Text.File' },
    99: { class: 'folder', display: 'Text.Folder' }
};
// dataTypeのリソースキー
export const getDataTypeResource = () => {
    var resource = {
        string: 'Text.String',
        number: 'Text.Integer',
        date: 'Text.Date',
        boolean: 'Text.Boolean',
        hyperLink: 'Text.Hyperlink',
        dropdown: '-'
    };
    return resource;
}

// PDF項目の取得
export const getPdfColumns = () => {
    var columns = ['title', 'subject', 'keywords', 'author', 'creator', 'producer', 'createdate', 'modifydate'];
    return columns;
}
// ページコンテンツ項目の取得
export const getPageContentsColumns = () => {
    var columns = ['annotation', 'loginUserAnnotation', 'pagememo', 'formname', 'pagenumber'];
    return columns;
}
// カスタムプロパティのうちの拡張項目の取得
// カスタムプロパティの拡張項目
export const EXTENSION_COLUMNS = {

    // 運用開始日
    EXT_START_DATE: 'SPA4_startdate',

    // ロック
    EXT_LOCK: 'SPA4_readonly',

    // タイムスタンプ
    EXT_TIMESTAMP: 'SPA4_timestamp',

    // マスク
    EXT_MASK: 'SPA4_mask',

    // マスク種別
    EXT_MASK_TYPE: 'SPA4_mask_type',

    // レビューテンプレート
    EXT_REVIEW_TEMPLATE: 'SPA4_review_template',

    // 上書き禁止
    EXT_OVERWRITE_FORBIDDEN: 'SPA4_overwrite_forbidden',

    //レビュー自動起票ユーザーID
    EXT_REVIEW_ISSUE_USER_ID: 'SPA4_review_user_id'

}

//文書ステータス
export const DOC_STATUS = {
    PARSE: {
        code: '0',	//解析中
        caption: 'Text.Parsing'
    },
    CREATE_INDEX: {
        code: '1',	//解析中（検索インデックス作成中）	-
        caption: 'Text.CreatingIndex'
    },
    MASK_FAILED: {
        code: '2',	//解析完了	マスク適用失敗
        caption: 'Text.FailLedMask'
    },
    IMPOSSIBLE_PARSE: {
        code: '3',	//解析不能ファイル
        caption: 'Text.AnalysisNonPDF'
    },
    ENCRYPTED: {
        code: '4',	//暗号化ファイル
        caption: 'Text.EncryptedPDF'
    },
    DO_NOT_CREATE: {
        code: '5',	//検索可能ファイル（本文検索不可）
        caption: 'Text.BodySearchNot'
    },
    SEARCHIABLE: {
        code: '6',	//検索可能ファイル（本文検索一部可）
        caption: 'Text.BodySearchPart'
    },
    CREATED: {
        code: '7',	//検索可能ファイル
        caption: 'Text.SearchablePDF'
    }
}
