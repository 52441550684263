import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * DocumentCommentService API
 */
export const useDocumentCommentService = () => {
    const serviceState = ServiceContainer.useContainer();
    const getDocumentComment = (docId: number | undefined) => {
        return serviceState.request(
            WebConstants.DOCUMENT_COMMENTS_GET_URL(docId),
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {

            console.log("****** documentCommentsGet is *******", response);
            return (response.data);

            //       }
            //   ).catch((error) => {
            //       console.log("****** error is *******", error.response);
        })
    }

    const addDocumentComment = (docId: number, newComment: string) => {
        let form = new FormData();
        form.append('comment', newComment);
        return serviceState.request(
            WebConstants.DOCUMENT_COMMENTS_ADD_URL(docId),
            WebConstants.METHOD_POST,
            form,
            WebConstants.HEADER_MULTIPART
        ).then((response) => {
            console.log("****** documentCommentsAdd is *******", response);
            return response;
            //         }
            //     ).catch((error) => {
            //         console.log("****** error is *******", error.response);
        })
    }

    return {
        getDocumentComment, addDocumentComment
    }
}