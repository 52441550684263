import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { CssBaseline } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import '../App.css';
import UserContainer from '../container/UserContainer';
import MainPage from "./MainPage";
import CommonContainer from "../container/CommonContainer";
import { useTranslation } from 'react-i18next';
import { MSG_PIPE_STR } from "../constants/Constants";
import DocContainer from '../container/DocContainer';

const LoginPage = () => {
    const commonState = CommonContainer.useContainer();
    const userState = UserContainer.useContainer();
    const docState = DocContainer.useContainer();
    const { t, i18n } = useTranslation();

    const [values, setValues] = React.useState({
        domain: "local",
        userid: "",
        password: ""
    });

    /**
     * ログインエラーメッセージ
     */
    const showError = () => {
        let ret = '';
        if (userState.errorMsg.length > 0) {
            ret = userState.errorMsg;
            if (userState.errorMsg.endsWith(MSG_PIPE_STR)) {
                ret += t('Msg.Error');
            }
        }
        return ret;
    }
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        backdrop: {
            color: "#fff",
            zIndex: 35000
        },
        logo: {
            height: '300px',
            width: '300px'
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        userState.currentGet(false);
        docState.setUserId(userState.user?.id);
    }, []);

    /**
     * ログインボタンを押した
     */
    const onLoginButtonClicked = async () => {
        if (values.domain !== null && values.userid !== null && values.password !== null) {
            commonState.setProgress(true)
            await userState.signIn(values.domain, values.userid, values.password);
            userState.currentAuthorities();
            localStorage.setItem("page", "mainPage");
            docState.setAllCheckStatus(false);
        }
    }

    const onLoginEnter = async (e: any) => {
        do {
            if (disableButton()) {
                break;
            }

            if (e.keyCode !== 13) {
                break;
            }

            onLoginButtonClicked();
        } while (false);

        return false;
    }

    const disableButton = () => {
        let ret = (values.domain.length === 0 || values.userid.length === 0 || values.password.length === 0);
        return ret;
    }

    const handleInputChange = (e: { target: any; }) => {
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        setValues({ ...values, [name]: value });
        userState.setErrorMsg('');
    }

    return (
        <div className="App">
            <Backdrop className={classes.backdrop} open={commonState.progress}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {userState.user
                ?
                <MainPage />
                :
                <div className="rowContent">
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            {/*<img className={'logo'} src={Logo}/>*/}
                            <Typography component="h1" variant="h2">
                                {t('Text.ApplicationName')}
                            </Typography>
                            <Grid container>
                                <Grid item xs>
                                    {commonState.versionStr}
                                </Grid>
                            </Grid>
                            <Box style={{ height: 50 }} />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="domain"
                                label={t('Text.Domain')}
                                name="domain"
                                defaultValue={values.domain}
                                onChange={handleInputChange}
                                onKeyDown={onLoginEnter}
                                autoComplete="domain"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="userid"
                                label={t('Text.UserID')}
                                name="userid"
                                defaultValue={values.userid}
                                onChange={handleInputChange}
                                onKeyDown={onLoginEnter}
                                autoComplete="userid"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('Text.Password')}
                                type="password"
                                id="password"
                                defaultValue={values.password}
                                onChange={handleInputChange}
                                onKeyDown={onLoginEnter}
                                autoComplete="current-password"
                            />
                            {/*                               <FormControlLabel
                                    control={<Checkbox value="remember" color="primary"/>}
                                    label="Remember me"
                                />*/}
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={disableButton()}
                                onClick={onLoginButtonClicked}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        onLoginButtonClicked();
                                    }
                                }}
                            >
                                {t('Text.Login')}
                            </Button>
                            {/*エラー*/}
                            <Typography color={'secondary'} variant="body2">
                                {showError()}
                            </Typography>
                        </div>
                    </Container>
                </div>
            }
        </div>
    );
};

export default LoginPage;
