import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export default function CellTranslateConvertError(props: { msg: string,param:{args1:string} }) {
    const {t, i18n} = useTranslation();
    return (
        <Typography variant={'body2'} >
            {t(props.msg,props.param)}
        </Typography>
    );
}


