import React from 'react';
import { createContainer } from "unstated-next";
import { Doc } from "../models/Doc";
import { FormDefs } from "../models/FormDefs";
import { FieldDefs } from "../models/FieldDefs";
import { PageFieldList } from "../models/PageFieldList";
import { useColumnService } from "../service/ColumnService";
import { MUIDataTableColumnDef } from "mui-datatables";
import SearchOptionService from "../service/SearchOptionService";
import { ACCEPT_STAMP_NAME, DEFAULT_DATE_FORMAT, SEARCH_CONDITION_TYPE } from "../constants/Constants";
import moment from "moment";
import { useSvfFieldValueService } from "../service/SvfFieldValueService";
import { usePreviewService } from "../service/PreviewService";
import { useAnnotationService } from "../service/AnnotationService";
import { useDocumentCommentService } from "../service/DocumentCommentService";
import { usePermission4LoginUserService } from "../service/Permission4LoginUserService";
import { useSearchService } from "../service/SearchService";
import { useReviewService } from "../service/ReviewService";
import { useStampService } from "../service/StampService";
import { useSearchConditionService } from "../service/SearchConditionService";
import { useSearchValuesMasterService } from "../service/SearchValuesMasterService";
import ValidatorService from "../service/ValidatorService";
import i18n from "i18next";
import LocalTimeFilter from "../common/LocalTimeFilter";
import { CONVERT_DATE_TYPE, DATE_FORMAT_APP_INFO } from "../constants/PropertiesConstants";
import { StampInfo } from '../models/StampInfo';
import { AnnotationInfo } from '../models/AnnotationInfo';
import { ReviewInfo } from '../models/ReviewInfo';
import CommonContainer from './CommonContainer';

interface data {
    formDefs: FormDefs[] | [],
    fieldDefs: FieldDefs[],
    pageFieldList: PageFieldList[],
    confirmFields: string[]
};
const useDocState = () => {
    const commonState = CommonContainer.useContainer();

    const { listStamp, getStampCoodinate } = useStampService()
    const { updateSvfFieldValue, getSvfFieldValue } = useSvfFieldValueService()
    const { getSearchConditionApi } = useSearchConditionService();
    const { listSearchValuesMaster } = useSearchValuesMasterService()
    const { searchFolder } = useSearchService()
    const { getPreview, getAttributes } = usePreviewService()
    const { getPermission4LoginUser } = usePermission4LoginUserService()
    const { reviewList, reviewGet, reviewAccept } = useReviewService()
    const { addDocumentComment } = useDocumentCommentService()
    const { getAnnotation } = useAnnotationService()
    const { getColumnsList } = useColumnService()

    const [columns, setColumns] = React.useState<MUIDataTableColumnDef[]>([]);//グリッド表示用
    const [systemProps, setSystemProps] = React.useState<[]>([]);//グリッドに表示するシステムプロパティ
    const [customProps, setCustomProps] = React.useState<[]>([]);//グリッド表示するカスタム
    const [customPropertiesList, setCustomPropertiesList] = React.useState<[]>([]);//全てのカスタムプロパティ

    //検索ショートカットリスト
    const [conditionList, setConditionList] = React.useState<string[]>([]);
    const [selectCondition, setSelectCondition] = React.useState<any>(null);
    //検索値マスターリスト
    const [searchValues, setSearchValues] = React.useState<any[]>([]);


    //リストデータ
    const [list, setList] = React.useState<any[] | null>(null);
    const [data, setData] = React.useState<Doc[]>([]);
    const [selectRow, setSelectRow] = React.useState<Number[]>([]);
    const [previewData, setPreviewData] = React.useState<[] | null>([]);
    const [annotationData, setAnnotationData] = React.useState<AnnotationInfo>();

    //スタンプ
    const [stamps, setStamps] = React.useState<StampInfo[]>([]);
    //確認済みリスト
    const [checkList, setCheckList] = React.useState<any[]>([]);
    const [checkListLength, setCheckListLength] = React.useState<number>(0);
    const [switchedItem, setSwitchedItem] = React.useState<{ docId: number, rowIndex: number, value: boolean } | null>(null);
    //フィールド編集
    const [formDef, setFormDef] = React.useState<FormDefs | []>([]);//　フォーム定義
    const [fieldDef, setFieldDef] = React.useState<FieldDefs[] | []>([]);//フィールド定義
    const [pageFieldList, setPageFieldList,] = React.useState<PageFieldList[] | null>([]);//フィールドリスト
    const [updateFields, setUpdateFields] = React.useState<{ page: number, id: string[] }[]>([]);//更新フィールドのストック
    const [confirmFields, setConfirmFields] = React.useState<string[] | []>([]);//確認対象フイールド
    //currentシリーズ
    //表示ページ
    const [currentDoc, setCurrentDoc] = React.useState<Doc | null>(null);
    const [currentPageNo, setCurrentPageNo] = React.useState<number>(1);
    //権限
    const [currentPermission, setCurrentPermission] = React.useState<any | null>(null);
    //選択したフィールドItem
    const [selectField, setSelectField] = React.useState<any | null>(null);

    //メッセージ
    const [showSnack, setShowSnack] = React.useState<boolean>(false);
    // 受領完了時のメッセージ[成功文書, 失敗文書]
    const [snackMsg, setSnackMsg] = React.useState<string[]>([]);
    //Wait
    const [previewBackdrop, setPreviewBackdrop] = React.useState<boolean>(false);
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);

    const [sendInterval, setSendInterval] = React.useState();
    const [timer, setTimer] = React.useState<NodeJS.Timeout>();
    const [userId, setUserId] = React.useState<any>();
    const [allCheckStatus, setAllCheckStatus] = React.useState<boolean>(false);
    const [allSwitchConfirmDlgOpen, setAllSwitchConfirmDlgOpen] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [lastSelectId, setLastSelectId] = React.useState<number>(-1);
    const [changeConfirmDlgOpen, setChangeConfirmDlgOpen] = React.useState<boolean>(false);
    const [switchAll, setSwitchAll] = React.useState<boolean>(false);
    const [clickSwitch, setClickSwitch] = React.useState<boolean>(false);
    // プロパティ
    const FieldProp = {
        Id: 'id',
        Name: 'name',
        Value: 'value',
        EditValue: 'editValue',
        SearchName: 'searchName',
        FieldType: 'fieldType',
        DateType: 'dateType',
        Timezone: 'timezone',
        EditRestrictions: 'editRestrictions'
    } as const;
    type FieldProp = typeof FieldProp[keyof typeof FieldProp];

    /**
     * 選択文書のdocumentIDを取得
     */
    const getCurrentDocId = () => {
        let ret = -1;
        if (currentDoc) {
            let id = currentDoc.documentId;
            if (id) {
                ret = parseInt(id + '');
            }
        }
        return ret;
    }

    /**
     * カラムを取得して一覧の表示
     */
    const loadList = async () => {
        commonState.setProgress(true)
        try {
            getSearchConditionApi().then(async conditions => {
                setConditionList(conditions);
                //検索値マスターの取得
                listSearchValuesMaster().then(async values => {
                    setSearchValues(values);
                }).catch((error) => {
                    console.log("****** error is *******", error.response);
                });
                //表示カラムリストの取得
                getColumnsList().then(async columns => {
                    storeColumns(columns).then(() => {
                        loadData(columns);
                        //検索バーの最初を検索するにはこちら
                        //searchFromBar(conditions[0].id, conditions, columns);
                    });
                });
            }).catch(error => {
                handleError(error as any);
            });
            // const stamps = await StampService.list();
            // setStamps(stamps);
        } catch (error) {
            handleError(error as any);
        }
    }

    /**
     * カラムを取得して一覧の表示
     */
    const loadData = async (columnData?: any) => {
        commonState.setProgress(true);
        resetSelect();
        const dataList = await getListDefault(columnData, false);
        await storeDatalist(dataList);
        setCurrentPage(0);
    }

    /**
     * 確認済みリストを取得して一覧の表示
     */
    const loadCheckedList = async () => {
        commonState.setProgress(true);
        resetSelect();
        const dataList = await getListDefault(null, true);
        await storeDatalist(dataList);
    }

    /**
     * Data tableにデータをセット
     * 不随の処理も行う
     * @param data
     */
    const storeDatalist = async (data: any) => {
        setData(data);
        await getCheckedDocuments(data);
    }

    /**
     * 画面の初期化
     */
    const resetUI = () => {
        //グリッドのクリア
        setData([]);
        setCheckList([]);
        //選択のクリア
        resetSelect();
        //検索条件のクリア
        setConditionList([]);
        //プレビューダイアログ閉じる
        setPreviewOpen(false);
    }
    /**
     * 選択をクリアする
     */
    const resetSelect = () => {
        setSelectRow([]);
        setCurrentDoc(null);
        resetFieldData();
        setPreviewData([]);
        setAnnotationData(undefined);
        setCurrentPermission(null);
        setCurrentPageNo(1);
        setSwitchedItem(null);
        setLastSelectId(-1);
    }

    /**
     * カラム情報を格納
     * @param columns
     */
    const storeColumns = async (columns: any) => {
        return await Promise.all([setSystemProps(columns.systemProps), setCustomProps(columns.customProps), setColumns(columns.list), setCustomPropertiesList(columns.customPropList)]);
    }
    /**
     *文書の選択が変わった時
     */
    const selectChange = async (index: number | undefined) => {
        if (index !== undefined) {
            setSelectRow([index]);
            commonState.setProgress(true);
            //選択文書をセット
            const doc: Doc = data[index];
            setCurrentDoc(doc);
            //ページを１ページにセット
            setCurrentPageNo(1);
            //検索フィールドデータをリセット
            resetFieldData();
            //プレビューデータの配列をページ数分作成
            // @ts-ignore
            let cnt = parseInt(doc['properties.pagecount']);
            // @ts-ignore
            // setPreviewData(new Array(cnt).fill(null));
            //文書に対するアクセス権、プレビューデータ、検索フィールド値を取得する
            let docId = doc.documentId ?? 0;
            try {
                const [
                    docPermission,
                    previewPageData,
                    annotationsOnPage,
                    fieldValues,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    _previewAttributes // プレビューを開いた時だけ追跡記録用にGet Attributesを実行
                ] = await Promise.all([
                    getPermission4LoginUser(docId),
                    getPreview(docId, 1),
                    getAnnotation(docId, 1),
                    getSvfFieldValue(docId),
                    getAttributes(docId)
                ]);
                //ブレビューデータの保存
                let images = new Array(cnt).fill(null);
                images[0] = previewPageData;
                // @ts-ignore
                setPreviewData(images);
                setAnnotationData(annotationsOnPage);
                //権限の保存
                await setCurrentPermission(docPermission);
                //フィールドデータの保存
                // @ts-ignore
                await setFieldValues(fieldValues);
                commonState.setProgress(false);
                //  return Promise.resolve();
                console.log('== setCurrentDoc ==', docId);
            } catch (error) {
                handleError(error as any);
            }
        } else {
            setPreviewData(null);
            setAnnotationData(undefined);
        }
    }

    /**
     * 文書の選択が変わった時（スライダー一括更新）
     */
    const selectAllChange = async (index: number | undefined) => {
        if (index !== undefined) {
            // 選択文書をセット
            const doc: Doc = data[index];
            // 検索フィールドデータをリセット
            resetFieldData();
            // 文書に対するアクセス権、プレビューデータ、検索フィールド値を取得する
            let docId = doc.documentId ?? 0;
            try {
                const fieldValues = await getSvfFieldValue(docId);
                // @ts-ignore
                setPreviewData(null);
                //フィールドデータの保存
                // @ts-ignore
                const checkData = await setFieldValues(fieldValues);
                console.log('== setCurrentDoc ==', docId);
                return checkData;
            } catch (error) {
                handleError(error as any);
            }
        } else {
            setPreviewData(null);
            setAnnotationData(undefined);
            return null;
        }
    }

    /**
     * プレビューのページが変わった時
     * @param pageNo
     */
    const pageChanged = async (pageNo: number) => {
        return new Promise((resolve, reject) => {
            setPreviewBackdrop(true);
            let idx = pageNo - 1;
            // @ts-ignore
            if (previewData?.length > 0 && typeof previewData[idx] !== 'undefined' && previewData[idx] !== null) {
                resolve(pageNo);
            } else {
                if (currentDoc && currentDoc.documentId !== undefined) {
                    Promise.all([
                        getPreview(currentDoc.documentId, pageNo),
                        getAnnotation(currentDoc.documentId, pageNo)
                    ]).then(([previewPageData, annoData]) => {
                        let images = previewData;
                        // @ts-ignore
                        images[pageNo - 1] = previewPageData;
                        setPreviewData(images);
                        setAnnotationData(annoData);
                        resolve(pageNo);
                    }).catch((e) => {
                        handleError(e);
                    })
                }
            }
        });
    }

    /**
     * 受け取りSwitchの有効無効
     * @param index
     */
    const isDisableCheck = (index: number | undefined) => {
        //SVF検索フィールドの編集ステータスの内容でデータなしは無効にする
        if (index != null) {
            const doc: Doc = data[index];
            if (doc) {
                //フィールドが存在しない場合は
                // @ts-ignore
                let status = doc['properties.svffield_editable_status'];
                if (status === '0' || status === '1' || status === '2') {
                    return true;
                }
            }
        }
        return false;
    }
    /**
     * 受け取りSwitchの表示
     * @param index
     */
    const isVisibleCheck = (index: number | undefined) => {
        //SVF検索フィールドの編集ステータスの内容でデータなしは無効にする
        if (index != null) {
            const doc: Doc = data[index];
            if (doc) {
                //レビューIDがない（検索からのとき）のはスイッチ出さない
                if (doc['id'] !== null) {
                    //フィールドが存在しない場合は
                    // @ts-ignore
                    let status = doc['status'];
                    if (status === 'ISSUE' || status === 'IN_PROCESS') {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * 編集ボタンを表示するかどうかを決定する
     */
    const isEditButtonVisibleCheck = (): boolean => {
        if (currentDoc!['id'] !== null) {
            //フィールドが存在しない場合は
            // @ts-ignore
            let status = currentDoc['status'];
            if (status === 'ISSUE' || status === 'IN_PROCESS') {
                return true;
            }
        }
        return false
    }

    /**
     * 承認完了日取得
     */
    const getCompleteDate = () => {
        let ret = '';
        if (currentDoc != null) {

            let completeDate = currentDoc['completeDate'];
            if (completeDate && completeDate.length > 0) {
                ret = moment(completeDate).format(DEFAULT_DATE_FORMAT);
            }
        }
        return ret;
    }

    /**
     * 検索値リストを取得
     */
    const getSearchValues = (condition: any) => {
        let ret = [];
        switch (condition.conditionType) {
            case SEARCH_CONDITION_TYPE.CUSTOM:
                let cId = condition.id;
                for (let temp of searchValues) {
                    // @ts-ignore
                    if (temp.customProperties.indexOf(Number(cId)) > -1) {
                        // @ts-ignore
                        ret = temp.codeList;
                        break;
                    }
                }
                break;
            case SEARCH_CONDITION_TYPE.SVF_FIELD:
            case SEARCH_CONDITION_TYPE.DETAIL:
                for (let temp of searchValues) {
                    let lists = temp.svfFieldList;
                    if (lists) {
                        for (let tempList of lists) {
                            if (condition.docTypeId === -1 && condition.dispName === tempList.svfField) {
                                ret = temp.codeList;
                                break;
                            } else if (condition.docTypeId === tempList.docTypeId && (condition.formName === null || condition.formName === tempList.formName) && condition.dispName === tempList.svfField) {
                                ret = temp.codeList;
                                break;
                            }
                        }
                    }
                }
                break;
        }
        return ret;
    }
    /**
     * 実行時指定の値変更を反映
     * @param templateIdx
     * @param detailIdx
     * @param newCondition
     */
    const applySearchExecDetailCondition = (templateIdx: number, detailIdx: number, newCondition: any) => {
        let tempCondition = selectCondition;
        tempCondition.templates[templateIdx].details[detailIdx] = newCondition;
        setSelectCondition(tempCondition);
    }
    /**
     * 実行時指定の値変更を反映
     * @param templateIdx
     * @param detailIdx
     * @param newCondition
     */
    const applySearchExecCondition = (attrName: string, value: string) => {
        let tempCondition = selectCondition;
        if (attrName === 'freeword') {
            if (tempCondition.freeword) {
                tempCondition.freeword['val'] = value;
            } else {
                tempCondition['freeword'] = { 'val': value };
            }
        } else {
            tempCondition[attrName] = value;
        }


        setSelectCondition(tempCondition);
    }
    /**
     * 取得したフィールド編集データをstateに保存する
     * @param fieldData
     */
    const setFieldValues = (fieldData: { formDefs: any; fieldDefs: any; pages: any[]; }) => {
        //文書定義
        if (fieldData) {
            let data: data = {
                formDefs: [],
                fieldDefs: [],
                pageFieldList: [],
                confirmFields: []
            };
            if (fieldData.formDefs) {
                let formDefList = [];
                for (var formDef of fieldData.formDefs) {
                    formDefList.push(new FormDefs(formDef));
                }
                // @ts-ignore
                setFormDef(formDefList);
                data.formDefs = formDefList;
            }

            //フイールド定義
            if (fieldData.fieldDefs) {
                let fieldDefList = [];
                let confirm = [];
                for (var fieldDef of fieldData.fieldDefs) {
                    fieldDefList.push(new FieldDefs(fieldDef));
                    //確認対象か
                    if (fieldDef.visualConfirmation === true) {
                        confirm.push(fieldDef.id);
                    }
                }
                setFieldDef(fieldDefList);
                setConfirmFields(confirm);
                data.fieldDefs = fieldDefList;
                data.confirmFields = confirm;
            }
            //ページ内のフィールド情報
            if (fieldData.pages) {
                const pages = new Array();
                fieldData.pages.map(function (page: any, idx: number) {
                    pages.push(new PageFieldList(page));
                });
                setPageFieldList(pages);
                data.pageFieldList = pages;
            }
            return data;
        }
    }
    /**
     * フィールドのプロパティを取得
     * @param attr
     */
    const getSelectFieldInfo = (attr: FieldProp) => {
        let ret = '';
        if (selectField) {
            if (attr === FieldProp.Id) {
                ret = selectField.id;
            } else if (attr === FieldProp.Name || attr === FieldProp.SearchName || attr === FieldProp.DateType || attr === FieldProp.FieldType) {
                if (fieldDef) {
                    // @ts-ignore
                    let item = fieldDef.find((el: { id: string; }) => el.id === selectField.id);
                    if (item) {
                        // @ts-ignore
                        ret = item[attr];
                    }
                }
            } else {
                ret = selectField.fields[0][attr];
            }
        }
        return ret;
    }
    /**
     * 編集後の値をJSONに反映
     * @param editValue
     */
    const fieldValueUpdate = (editValue: any) => {
        if (selectField) {
            selectField.fields[0].editValue = editValue;
            let pages = updateFields.find((el) => el.page === currentPageNo);
            if (pages) {
                pages.id.push(selectField.fields[0].id);
            } else {
                updateFields.push({ page: currentPageNo, id: [selectField.fields[0].id] });
            }
            console.log('更新リスト:  ', updateFields);
        }
    }
    /**
     * 検索フィールド値編集後の値チェック
     * @param fieldId
     * @param editValue
     */
    const fieldValueValidate = (editValue: string) => {
        var msg = '';
        var detailMessages = [];
        let fieldType = '';
        let searchName: string = '';
        let itemName = '';
        let value: any = '';
        var yyyy = 0;
        var isYYYY = true;

        var numeric = {
            options: {
                fromNum: '-9999999999999999999.99999999999999999999',
                toNum: '9999999999999999999.99999999999999999999',
                numericCnt: 19,
                decimalCnt: 20
            }
        };
        // @ts-ignore
        const fieldInfo = fieldDef.find((el: { id: string; }) => el.id === selectField.id);
        if (fieldInfo) {
            // @ts-ignore
            itemName = fieldInfo.name;
            if (editValue.length < 1) {
                if (fieldInfo.editRestrictions) {
                    msg = i18n.t('Msg.RequiredField', { args1: itemName });
                }
            } else {
                // @ts-ignore
                fieldType = fieldInfo.fieldType;
                if ('NUMERIC' === fieldType) {
                    // 境界値チェック
                    msg = ValidatorService.rangeNumeric(editValue, numeric.options.fromNum, numeric.options.toNum, itemName, true, numeric.options.numericCnt, numeric.options.decimalCnt);
                } else if ('DATE' === fieldType) {
                    isYYYY = true;

                    if (fieldInfo.timezone === 'WithTimeZone') {
                        value = LocalTimeFilter(dateFormat.format(new Date(editValue), fieldInfo.dateType, 1), false);
                        yyyy = value.substr(0, 4) - 0;
                        if (isNaN(yyyy)) {
                            isYYYY = false;
                        } else {
                            // 99未満から9901未満範囲チェック
                            isYYYY = (99 < yyyy && yyyy < 9901);
                        }
                    }

                    if (isYYYY) {
                        // @ts-ignore
                        let convertDateType = CONVERT_DATE_TYPE[fieldInfo.dateType];
                        // @ts-ignore
                        msg = ValidatorService.formatDate(editValue, itemName, DATE_FORMAT_APP_INFO[convertDateType], convertDateType);
                    } else {
                        msg = i18n.t('Msg.InvalidFormat', { args1: itemName });
                    }
                }
            }
        }
        return msg;
    }
    /**
     *
     */
    const dateFormat = {
        formatFunction: {
            yyyy: function (date: { getFullYear: () => any; }) { return zeroPadding(date.getFullYear(), 4); },
            MM: function (date: { getMonth: () => any; }, m: any) { return zeroPadding(date.getMonth() + m, 2); },
            dd: function (date: { getDate: () => string | number; }) { return zeroPadding(date.getDate(), 2); },
            HH: function (date: { getHours: () => string | number; }) { return zeroPadding(date.getHours(), 2); },
            mm: function (date: { getMinutes: () => string | number; }) { return zeroPadding(date.getMinutes(), 2); },
            ss: function (date: { getSeconds: () => string | number; }) { return zeroPadding(date.getSeconds(), 2); }
        },
        /**
         * 指定した日付フォーマットに変換する
         * @param date 日付
         * @param format 日付フォーマット
         * @param m
         * @return ret
         */
        format: function dateFormat(date: any, format: any, m: any) {
            var ret = format;
            for (var type in this.formatFunction) {
                // @ts-ignore
                ret = ret.replace(type, (this.formatFunction)[type](date, m));
            }
            return ret;
        }
    };
    /**
     * 指定した桁数でゼロパディングする
     * @param val 数値
     * @param digit 桁数
     * @return ゼロパティングされた値
     */
    const zeroPadding = (val: string | number, digit: number) => {
        var zero = '';
        for (var i = 0; i < digit; i++) {
            zero += '0';
        }
        val = val.toString();
        val = (zero + val).slice(digit * -1);
        return val;
    }
    /**
     * 画面で選択したフィールドElementを特定
     * @param uid
     */
    const setCurrentField = (uid: string) => {
        if (pageFieldList) {
            const ids = uid.split('#');
            let id = '';
            let recordName = '';
            let row = -1;
            //レコードの場合IDの情報を分割
            if (ids.length === 4) {
                recordName = ids[1];
                row = parseInt(ids[2]);
                id = ids[3];
            } else if (ids.length === 2) {
                id = ids[1];
            } else {
                id = uid;
            }
            let ret = null;
            const currentPageField: PageFieldList | undefined = pageFieldList.find(el => el.pageNum === currentPageNo);
            if (currentPageField && currentPageField.values) {
                //レコードの場合
                if (recordName.length > 0) {
                    const recordList = currentPageField.values.recordList.find((el: { name: string; }) => el.name === recordName);
                    if (recordList) {
                        for (let record of recordList.records) {
                            const fieldList = record.find((el: { row: number; }) => el.row === row);
                            if (fieldList) {
                                ret = fieldList.fieldList.find((el: { id: string; }) => el.id === id)
                                setSelectField(ret);
                                break;
                            }
                        }
                    }
                    //フィールドの場合
                } else {
                    ret = currentPageField.values.fieldList.find((el: { id: string; }) => el.id === id)
                    setSelectField(ret);
                }
            }
        }
    }
    /**
     * フィールドの更新情報を取得する（更新ページのみ）
     */
    const getUpdatePageFields = () => {
        let updatePageData = [];
        if (updateFields.length > 0) {
            for (var upage of updateFields) {
                let currentPageField: PageFieldList | undefined = pageFieldList?.find(el => el.pageNum === upage.page);
                if (currentPageField) {
                    updatePageData.push(currentPageField.getEditList())
                }
            }
        }
        return updatePageData;
    }
    /**
     *　フィールドの更新情報を取得する（全ページ）
     * 　確認済みフラグをすべて更新
     * @param checked
     */
    const getUpdatePageFieldsWithChecked = (pageData: any, checked: boolean) => {
        let updatePageData = [];
        if (pageData != null) {
            for (var currentPageField of pageData) {
                if (currentPageField) {
                    updatePageData.push(currentPageField.getEditList({ checked: checked }));
                }
            }
        }
        return updatePageData;
    }
    /**
     * フィールドの値更新
     */
    const saveEditField = async (comment: string) => {
        try {
            const pageData = getUpdatePageFields();
            //JSONを生成する
            const saveData = {
                formDefs: formDef,
                fieldDefs: fieldDef,
                pages: pageData,
                condition: {
                    useSvffieldEntityVersion: false
                }
            };
            let docId = getCurrentDocId();
            await updateFieldValue(docId, saveData, true);
            await addDocumentComment(docId, comment);
        } catch (error) {
            handleError(error as any);
            console.log("****** error is *******", (error as any).response);
        }
    }
    /**
     * フィールドの更新をできるか
     * 受取済み、確認済み、アクセス権なしはfalse
     */
    const visibleEditField = () => {
        let ret = false;
        if (currentDoc && currentPermission) {
            //受領日がなく
            if (currentDoc.completeDate?.length === 0) {
                //フィールド編集権限があり
                if (!currentPermission.allDenied) {
                    if (currentPermission.svfFieldEditable) {

                        ret = true;
                    }
                }
            }
        }
        return ret;
    }
    /**
     * フィールドの更新をできるか
     * 受取済み、確認済み、アクセス権なしはfalse
     */
    const disableEditField = () => {
        let ret = false;
        if (currentDoc) {
            //アクセス権なし
            if (!visibleEditField()) {
                ret = true;
            }
            //確認済みでない文書
            // @ts-ignore
            if (currentDoc['properties.svffield_data_checked'] === 'true' || currentDoc['properties.svffield_data_checked'] === true) {
                ret = true;
            }
        }
        return ret;
    }
    /**
     *文書コメントを書き込めるか
     * アクセス権なしはfalse
     */
    const visibleCommentButton = () => {
        let ret = false;
        if (currentDoc && currentPermission) {
            //フィールド編集権限があり
            if (!currentPermission.allDenied) {
                if (currentPermission.commentWritable) {
                    ret = true;
                }
            }
        }
        return ret;
    }
    /**
     * SVF検索フィールドの保存
     */
    const updateFieldValue = (docId: number, saveData: any, save?: boolean) => {
        return new Promise((resolve, reject) => {
            updateSvfFieldValue(docId, saveData).then(async () => {
                if (save) {
                    //リセット
                    resetFieldData();
                    await setFieldValues(await getSvfFieldValue(docId));
                }
                return resolve(docId);
            }).catch((error) => {
                handleError(error);
            });
        });
    }

    /**
     * 検索フィールド情報のクリア
     */
    const resetFieldData = () => {
        //更新情報
        setUpdateFields([]);
        //文書定義
        setFormDef([]);
        //フイールド定義
        setFieldDef([]);
        //ページ内のフィールド情報
        setPageFieldList([]);
        //確認対象フィールド
        setConfirmFields([]);
    }
    /**
     *レビュー一覧取得
     * Reviews List
     */
    const reviewsList = async (withComplete: boolean | undefined) => {
        let status: { [key: string]: any } = {
            "statuses": ["ISSUE", "IN_PROCESS"]
        };
        if (withComplete) {
            status.statuses.push('FINISH');
        }
        return reviewList(status);
    }
    /**
     * 一括受取ボタンを押した
     * @param name
     */
    const doAccept = async (name: string, userId: number | undefined) => {
        let msg = '受取完了　';
        let successCnt = 0;
        let total = checkList.length;
        let failDoc = '';
        let cause: string[] = [];

        // レビュー承認実行前に確認済みリストのレビューから{レビューID: レビュー情報}のMapを作成する
        const checkedReviewMap = new Map<string, ReviewInfo>();
        const defaultStamp = stamps.find(st => st.name === ACCEPT_STAMP_NAME);
        commonState.setProgress(true);
        for (const rev of checkList) {
            const review = await reviewGet(rev.id);
            checkedReviewMap.set(rev.id, review);
        }
        const promises = checkList.map(async (rev) => {
            try {
                const review = checkedReviewMap.get(rev.id);
                if (!review) {
                    throw new Error("Review info was not found.");
                }
                // 承認に利用するスタンプの取得
                let stampForAccept;
                let coordinate;
                let checkedNum = 0;
                // カレントの受領確認者がスタンプを押したかを確認する
                let repeatAccept = rev.stage[0].reviewers.some((data: any) => {
                    return data.id === userId && data.checked
                });
                if (!repeatAccept) {
                    if (review.stage?.length && review.stage[0].stampEnable) {
                        if (review.stage[0].reviewers?.length) {
                            for (let idx = 0; idx < review.stage[0].reviewers?.length; idx++) {
                                if (review.stage[0].reviewers[idx].checked) {
                                    checkedNum++;
                                }
                            }
                        }
                        if (review.stage[0].stampLocationInfo?.length && review.stage[0].stampLocationInfo[checkedNum].name) {
                            const name = review.stage[0].stampLocationInfo[checkedNum].name;
                            stampForAccept = stamps.find(st => st.name === name) ?? defaultStamp;
                            if (stampForAccept) {
                                const previewData = await getPreview(rev.documentId, 1);
                                const locationInfo = review.stage[0].stampLocationInfo[checkedNum]
                                const unit = review.unit ?? 'PX'; //デフォルトは'PX'
                                coordinate = getStampCoodinate(previewData, locationInfo, stampForAccept, unit);
                            } else {
                                cause.push('Msg.StampNotFound');
                                throw new Error("Stamp to accept was not found.");
                            }
                        }
                    }

                    // 承認処理
                    await reviewAccept(rev.id, name, stampForAccept, coordinate);
                }

                successCnt++;
            } catch (error) {
                if (failDoc.length > 0) failDoc += ',';
                failDoc += rev.id;
                handleError(error as any)
            }
        });
        try {
            await Promise.all(promises);
            //リスト再表示
            await loadData();
            //メッセージ
            msg = '(' + successCnt + '/' + total + ') ';

            showSnackBar(msg, failDoc, [...new Set(cause)]);
        } catch (error) {
            handleError(error as any);
        }
        commonState.setProgress(false);
    }
    /**
     * ログイン直後のリストを取得する
     */
    const getListDefault = async (columnData: any, checkedFilter: boolean) => {
        let sProps = columnData ? columnData.systemProps : systemProps;
        let cProps = columnData ? columnData.customProps : customProps;
        try {
            let condition = {
                "folderIds": [
                    {
                        "id": "1"
                    }],
                "operator": "OR",
                "recursive": true,
                "conditions": [
                    {
                        "conditionType": "system",
                        "name": "review_status",
                        "value": "1",
                        "type": "equals"
                    },
                    {
                        "conditionType": "system",
                        "name": "review_status",
                        "value": "2",
                        "type": "equals"
                    }],
                "properties": sProps,
                "customProperties": cProps
            };
            const [reviewData, searchData] = await Promise.all([reviewsList(false), searchFolder(condition)]);
            //結果をマージする
            if (reviewData && searchData) {
                let res = [];
                // 表の最初のページに戻る
                for (var rev of reviewData) {
                    let ser_res = searchData.find((el: any) => el.id === rev.documentId);
                    let reviewId = rev.id;
                    if (ser_res) {

                        //レビュー完了日を指定
                        ser_res.completeDate = ser_res.properties.review_complete_date;
                        expandProperties(true, ser_res);
                        expandProperties(false, ser_res);

                        let temp = Object.assign(rev, ser_res);
                        temp.id = null;
                        temp.stage[0].reviewers.forEach((user: any) => {
                            if (user!.id === userId) {
                                temp.id = reviewId;//IDはレビューのID
                                return;
                            }
                        })
                        temp['properties.svffield_data_checked'] = !!temp.id ? temp['properties.svffield_data_checked'] : null
                        //確認済みリストの時は、確認済みのみ取得
                        if ((checkedFilter === true && ser_res.properties.svffield_data_checked === "false") || (checkedFilter === true && temp.id === null)) {
                            continue;
                        }
                        res.push(temp);
                    }
                }
                return res;
            }

        } catch (error) {
            handleError(error as any);
        }
    }

    /**
     * テーブルのデータオブジェクトにプロパティを拡張保存する
     * @param isSystem
     * @param el
     */
    const expandProperties = (isSystem: boolean, el: any) => {
        let type = '';
        if (isSystem) {
            type = 'properties';
        } else {
            type = 'customProperties';
        }
        let target = el[type];
        for (var key of Object.keys(target)) {
            el[type + '.' + key] = target[key];
        }
    }
    /**
     * 確認済みスイッチが変更
     * 確認ダイアログを表示する
     * @param rowIndex
     * @param newValue
     */
    const switchConfirm = async (rowIndex: number, newValue: boolean) => {
        if (updateFields.length === 0) {
            // @ts-ignore
            setSwitchedItem({ docId: data[rowIndex].documentId, rowIndex: rowIndex, value: newValue });
        } else {
            setClickSwitch(true)
        }

    }
    /**
     * 確認済みフラグの変更
     * プロパティ変更（フィールド確認済みフラグをすべて変更）
     * @param item
     */
    const switchChecked = async (item: { docId: number, rowIndex: number, value: boolean } | null) => {
        //確認対象がない文書は変更を更新しない（強制戻し）
        // @ts-ignore
        if (item.value === false && confirmFields.length === 0) return;

        try {
            //APIの更新
            // @ts-ignore
            const editData = getUpdatePageFieldsWithChecked(pageFieldList, item.value);
            const saveData = {
                formDefs: formDef,
                fieldDefs: fieldDef,
                pages: editData,
                condition: {
                    useSvffieldEntityVersion: false
                }
            };
            // @ts-ignore
            let docNo = await updateFieldValue(item.docId, saveData);
            console.log('======== 　確認済み =======', docNo);

            //グリッドの更新
            // @ts-ignore
            const oldDoc = data[item.rowIndex];
            // @ts-ignore
            const newDoc: Doc = { ...oldDoc, 'properties.svffield_data_checked': item.value };
            // @ts-ignore
            data[item.rowIndex] = newDoc;
            setData(data);
            //現在の文書も更新
            // @ts-ignore
            if (currentDoc?.documentId === item.docId) {
                // @ts-ignore
                currentDoc['properties.svffield_data_checked'] = item.value + '';
            }
            //ダイアログ用アイテムクリア
            setSwitchedItem(null);
            await getCheckedDocuments(data);
        } catch (error) {
            handleError(error as any);
        }
    }

    /**
     * 確認済みフラグの変更（スライダー一括更新）
     * プロパティ変更（フィールド確認済みフラグをすべて変更）（スライダー一括更新）
     * @param item
     */
    const switchAllChecked = async (switchData: any) => {
        if (switchData.length === 0) {
            return;
        }
        try {
            switchData.forEach((element: any) => {
                const oldDoc = data[element.switchItem.rowIndex];
                // @ts-ignore
                const newDoc: Doc = { ...oldDoc, 'properties.svffield_data_checked': element.switchItem.value };
                data[element.switchItem.rowIndex] = newDoc;
            });
            setData(data);
            setSwitchedItem(null);
        } catch (error) {
            handleError(error as any);
        }
    }

    /**
     * 確認済みリストの取得
     */
    const getCheckedDocuments = async (list: any,) => {
        try {
            //    if (list === null) {
            await getListDefault(null, true).then(async (response) => {
                if (response) {
                    console.log('======== 　確認済みトレイ =======', response.length);
                    setCheckList(response);
                    setCheckListLength(response.length);
                }
            });
            commonState.setProgress(false);
            /*    } else {
                    let result: React.SetStateAction<any[]> = [];
                    for (let l of list) {
                        if (l['properties.svffield_data_checked'] === 'true') {
                            result.push(l);
                        }
                    }
                    console.log('======== 　確認済み2 =======', result.length);
                    setCheckList(result);
                }*/
        } catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * 検索バーからの検索
     * @param id
     */
    const searchFromBar = (id: number, conditions: any, columnData: any) => {
        //初回起動の時useStateが遅い場合に引数を渡している
        let conditionL = conditions ? conditions : conditionList;
        let sProps = columnData ? columnData.systemProps : systemProps;
        let cProps = columnData ? columnData.customProps : customProps;

        // @ts-ignore
        let item = conditionL.find((el: { id: number; }) => el.id === id);
        if (item) {
            let execList = [];
            // @ts-ignore
            let conditionStr = item.condition.toString();
            let conditionList = JSON.parse(conditionStr);
            if (conditionList !== null) {
                //フォルダー
                if (conditionList.freeword) {
                    if (conditionList.freeword.execSet === true) {
                        execList.push(conditionList.freeword);
                    }
                }
                if (conditionList.folder.execSet) {

                }
                let templates = conditionList.templates;
                templates.map((row: { execSet: boolean; conditionType: string }, idx: number) => {
                    let condition: any = {};
                    let options: any = {
                        dateFormat: DEFAULT_DATE_FORMAT
                    };
                    if (row.execSet === true && row.conditionType !== 'folder') {
                        execList.push(row);
                    }
                });
            }
            //ない時はそのまま検索実行
            if (execList.length === 0) {
                searchFromTemplate(conditionList, null, sProps, cProps);
            } else {
                setSelectCondition(conditionList);
            }
        }
    }
    /**
     * 検索バーからの検索
     * @param id
     */
    const searchFromTemplate = async (json: any, ignoreRowIndexes: any, sProps: any, cProps: any) => {
        if (json) {
            resetSelect();
            if (sProps === null) {
                sProps = systemProps;
            }
            if (cProps === null) {
                cProps = customProps;
            }

            // @ts-ignore
            //   let conditionStr = item.condition.toString();
            //   let json = JSON.parse(conditionStr);
            const template = SearchOptionService.getConditions(json.templates, ignoreRowIndexes);
            /* template.push({
                   "conditionType": "system",
                   "name": "review_status",
                   "value": "0",
                   "type": "notequals"
               });*/
            let condition = {
                "searchWord": selectCondition ? selectCondition.freeword.val : json.freeword.val,
                "operator": json.matchSelect,
                "folderIds": [
                    {
                        "id": json.folder.useSelected == "true" ? "1" : json.folder.id
                    }],
                "recursive": json.folder.useSelected == "true" ? true : json.folder.subFolder,
                "conditions": template,
                "properties": sProps,
                "customProperties": cProps
            };
            try {
                const [reviewData, searchData] = await Promise.all([reviewsList(true), searchFolder(condition)]);
                let res = [];
                //結果でレビュー履歴のある物だけを抽出
                if (reviewData && searchData) {
                    for (var ser_res of searchData) {
                        let rev = reviewData.find((el: any) => el.documentId === ser_res.id);
                        //レビュー完了日を指定
                        ser_res.completeDate = ser_res.properties.review_complete_date;
                        if (ser_res.review_status !== '' && ser_res.review_status !== '0') {
                            expandProperties(true, ser_res);
                            expandProperties(false, ser_res);

                            //let temp = Object.assign(rev, ser_res);
                            //temp.id = reviewId;//IDはレビューのID

                            let temp = ser_res;
                            temp.documentId = ser_res.id;//文書IDはドキュメントIDとしてセット
                            // temp.id = null;//レビューIDはなし
                            //レビュー対象のものは完了日を追記
                            if (rev) {
                                let reviewIdList: any[] = [];
                                (rev.stage as any[]).forEach(stage => {
                                    (stage.reviewers as any[]).forEach(reviewer => {
                                        reviewIdList.push(reviewer.id)
                                    })
                                });
                                if (reviewIdList.includes(userId)) {
                                    temp.completeDate = rev.completeDate;
                                    temp.status = rev.status;
                                }
                            }

                            if (temp.status !== 'ISSUE' && temp.status !== 'IN_PROCESS') {
                                temp['properties.svffield_data_checked'] = null
                            }
                            res.push(ser_res);
                        }
                    }
                }
                setData(res);
                setCurrentPage(0);
                getCheckedDocuments(null);
            } catch (error) {
                handleError(error as any);
            }
        }
    }
    /**
     * エラー処理
     * @param flg
     */
    const handleError = (error: { response: { status: any; }; }) => {
        commonState.setProgress(false);
        setPreviewBackdrop(false);
        console.log("****** error is *******", error.response);
    }
    /**
     * 共通：SnackBar表示
     * @param msg
     */
    const showSnackBar = (success: string, fail: string, cause: string[]) => {
        setShowSnack(true);
        setSnackMsg([success, fail, ...cause]);
    }
    /**
     * 共通：SnackBar隠す
     */
    const closeSnackBar = () => {
        setShowSnack(false);
        setSnackMsg([]);
    }

    const openPreviewDialog = () => {
        setPreviewOpen(true);
    }

    const closePreviewDialog = () => {
        setPreviewOpen(false);
        resetSelect();
    }

    return {
        columns,
        data,
        loadList,
        loadData,
        list,
        selectChange,
        selectRow,
        previewData,
        annotationData,
        pageFieldList,
        setCurrentField,
        selectField,
        currentDoc,
        currentPageNo,
        setCurrentPageNo,
        FieldProp,
        getSelectFieldInfo,
        fieldValueUpdate,
        updateFields,
        saveEditField,
        switchChecked,
        isDisableCheck,
        isVisibleCheck,
        checkList,
        doAccept,
        showSnack,
        snackMsg,
        closeSnackBar,
        conditionList,
        searchFromBar,
        getCompleteDate,
        visibleEditField,
        disableEditField,
        pageChanged,
        getCurrentDocId,
        visibleCommentButton,
        loadCheckedList,
        handleError,
        switchConfirm,
        switchedItem,
        confirmFields,
        resetUI,
        selectCondition,
        setSelectCondition,
        searchFromTemplate,
        customPropertiesList,
        getSearchValues,
        applySearchExecCondition,
        applySearchExecDetailCondition,
        fieldValueValidate,
        previewOpen,
        openPreviewDialog,
        closePreviewDialog,
        sendInterval, setSendInterval,
        timer, setTimer,
        setUserId,
        isEditButtonVisibleCheck,
        allCheckStatus, setAllCheckStatus,
        switchAllChecked,
        selectAllChange,
        setSelectRow,
        getCheckedDocuments,
        allSwitchConfirmDlgOpen, setAllSwitchConfirmDlgOpen,
        currentPage, setCurrentPage,
        lastSelectId, setLastSelectId,
        changeConfirmDlgOpen, setChangeConfirmDlgOpen,
        switchAll, setSwitchAll,
        clickSwitch, setClickSwitch,
        setSwitchedItem, setPreviewData,
        setStamps,
        checkListLength, setCheckListLength,
        previewBackdrop, setPreviewBackdrop
    }
}

const DocContainer = createContainer(useDocState);

export default DocContainer;
