//XSRF-TOKEN読み込み時のプレフィックス
export const TOKEN_PREFIX = 'XSRF-TOKEN';
//トークン送信時のキー名
export const XSRF_TOKEN_KEY = 'X-XSRF-TOKEN';
//X-Requested-With送信時のキー名
export const X_REQUESTED_WITH_KEY = 'X-Requested-With';
//X-Requested-With送信時の値（ダミー）
export const X_REQUESTED_WITH_VALUE = 'XMLHttpRequest';

export const X_AUTHORIZATION = 'Authorization';
//レビュー一覧のデフォルト値
export const REVIEW_COLUMNS = [/*'completeDate', 'startDate', 'createDate', 'description', 'documentName', 'path', 'userName', 'domainName', */'id', 'documentId'];
//システムカラムの必須デフォルト値
export const SYSTEM_COLUMNS = [{
    "id": "svffield_data_checked",
    "system": true,
    "visible": true,
    "sort": null,
    "sortIndex": null
}];
//システムカラムの必須デフォルト値[SVF検索フィールドの有無、ページ数、レビュー完了日時]
export const ESSENTIAL_SYSTEM_PROPS = ['svffield_editable_status', 'pagecount', 'review_complete_date'];
//エラーメッセージの繋ぎ
export const MSG_PIPE_STR = ' : ';
//デフォルトの日付フォーマット(moment用)
export const DEFAULT_DATE_FORMAT = 'yyyy/MM/DD HH:mm:ss';
export const SEARCH_CONDITION_TYPE = {
    // システム
    'SYSTEM': 'system',

    // カスタム
    'CUSTOM': 'custom',

    // 管理ポリシー
    'POLICY': 'policy',

    // カスタム
    'SVF_FIELD': 'svfField',

    // 明細
    'DETAIL': 'details',

    // ページ
    'PAGE_CONTENT': 'pageContent',

    // フォルダー
    'FOLDER': 'folder'

}
// データベース種別
export const dbType = {
    // PostgreSQL
    POSTGRESQL: 'PostgreSQL',

    // Oracle
    ORACLE: 'Oracle'
};

// 接続文字列
export const connectionStringPrefix = {
    // PostgreSQL
    POSTGRESQL: 'jdbc:postgresql:',

    // Oracle
    ORACLE: 'jdbc:oracle:'
};

// 承認時に利用するデフォルトスタンプの名称
export const ACCEPT_STAMP_NAME = "DocketX_Stamp";

export const DEFAULT_TIMEZONE = "Asia/Tokyo"
