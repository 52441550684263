import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import { ShippingData } from "../../models/ShippingData";
import UserContainer from "../../container/UserContainer";
import { SipInfo } from "../../models/SipInfo";

type Props = {
    title: string;
    action: string;
    isOpen: boolean;
    data: ShippingData;
    password: string;
    hidePassword: string;
    doClose: () => void;
    doClick: (data: any) => void;
    openConfirmPassword: () => void;
};

export const SipDialog: React.FC<Props> = ({ title, action, isOpen, data, password, hidePassword, doClose, doClick, openConfirmPassword }) => {
    const userState = UserContainer.useContainer();

    const [open, setOpen] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<SipInfo>({});
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    useEffect(() => {
        setFormData(data)
    }, [data])

    /**
     * 入力ボックスのデータ変更に対応
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any }) => {
        const { target: { value, name } } = e;
        setFormData({ ...formData, [name]: value });
    }


    const handleClick = () => {
        const data = { ...formData, password: password, tenantId: userState.tenantId }
        doClick(data);
    }

    /**
     * 新規ダイアログの確認ボタンのdisable状態を制御する
     * @returns コンポーネントのdisable状態を返す
     */
    const disableAddButton = () => {
        let element: keyof SipInfo;
        const newData = { ...formData, password: password }
        for (element in newData) {
            if (newData[element]?.length === 0) {
                return true;
            }
        }
        return false;
    }

    /**
     * 編集ダイアログの確認ボタンのdisable状態を制御する。
     * @returns コンポーネントのdisable状態を返す
     */
    const disableEditButton = () => {
        let element: keyof SipInfo;
        for (element in formData) {
            if (element === "password") {
                continue;
            }
            if (formData[element]?.length === 0) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{title}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div>
                        {/* 名称 */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 100 }}>{t('Text.Name')}</Typography>
                            <TextField
                                name='name'
                                size='small'
                                style={{ marginLeft: '55px', width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.name}
                                variant="outlined"
                            />
                        </div><br />
                        {/* ユーザーID */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 100 }}>{t('Text.UserID')}</Typography>
                            <TextField
                                name='userId'
                                size='small'
                                style={{ marginLeft: '55px', width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.userId}
                                variant="outlined"
                            />
                        </div><br />
                        {/* パスワード */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 100 }}>{t('Text.Password')}</Typography>
                            <TextField
                                disabled
                                type='password'
                                size='small'
                                style={{ marginLeft: '38px', width: '300px', lineHeight: '10px', background: '#eee' }}
                                value={hidePassword}
                                variant="outlined"
                            />
                            <IconButton onClick={() => openConfirmPassword()}>
                                <LockIcon style={{ fontSize: 18 }} />
                            </IconButton>
                        </div><br />
                        {/* URL */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 100 }}>{t('Text.Url')}</Typography>
                            <TextField
                                name='url'
                                size='small'
                                style={{ marginLeft: '55px', width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.url}
                                variant="outlined"
                            />
                        </div><br />
                        {/* テナントID */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 100 }}>{t('Text.TenantID')}</Typography>
                            <TextField
                                name='sipTenantId'
                                size='small'
                                style={{ marginLeft: '55px', width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.sipTenantId}
                                variant="outlined"
                            />
                        </div><br />
                        {/* クライアントID */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 150 }}>{t('Text.ClientID')}</Typography>
                            <TextField
                                name='clientId'
                                size='small'
                                style={{ marginLeft: '13px', width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.clientId}
                                variant="outlined"
                            />
                        </div><br />
                        {/* 再送回数 */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" gutterBottom style={{ width: 100 }}>{t('Text.RetransmissionsNumber')}</Typography>
                            <TextField
                                name='reSendCount'
                                size='small'
                                style={{ marginLeft: '57px', width: '500px' }}
                                onChange={handleTextFieldOnChange}
                                value={formData.reSendCount}
                                variant="outlined"
                            /> {t('Text.Times')}
                        </div><br />
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" onClick={() => handleClick()} disabled={action === 'add' ? disableAddButton() : disableEditButton()}>
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => doClose()}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
