import DocMapModel from "../models/DocMapModel"
import { ReceiptSipStandardMessage, SipStandardMessage } from "../models/SipStandardMessage"
import { usePageService } from "./PageService"

export const useSipStandardMessageService = () => {
    const { getDocMapApi, getReceiveDocMapApi } = usePageService()

    const initDefaultData = async (params: any, formNameList: any[]) => {

        let commonModelList: DocMapModel[] = []
        let repeatModelList: DocMapModel[] = []
        let formName: string = ""

        const response = await getDocMapApi(params)
        var mapData = JSON.parse(response.data.mapData)
        if (response.data.formName !== null && formNameList.indexOf(response.data.formName) > -1) {
            formName = response.data.formName
            mapData.map((item: any, index: any) => {
                const projectDetails = SipStandardMessage.find(sip => sip.physicalProjectName === item.toType)
                const require = projectDetails?.items?.find(obj => {
                    if (obj.physicalProjectName === item.toField) {
                        return obj.isRequired
                    }
                })
                if (item.toType !== "item_line_item") {
                    if (require) {
                        commonModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, true, item.defaultValue))
                    } else {
                        commonModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, false, item.defaultValue))
                    }
                } else {
                    if (require) {
                        repeatModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, true, item.defaultValue))
                    } else {
                        repeatModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, false, item.defaultValue))
                    }
                }
            })
        } else {
            SipStandardMessage.forEach(directory => {
                if (!directory.isRepeat) {
                    directory.items!.forEach(item => {
                        if (item.isRequired) {
                            commonModelList.push(new DocMapModel(directory.physicalProjectName as string, item.physicalProjectName as string, "", true, item.defaultValue))
                        }
                    })
                } else {
                    directory.items!.forEach(item => {
                        if (item.isRequired) {
                            repeatModelList.push(new DocMapModel(directory.physicalProjectName as string, item.physicalProjectName as string, "", true, item.defaultValue))
                        }
                    })
                }
            })
        }

        let obj = { formName: formName, commonList: commonModelList, repeatList: repeatModelList }
        return obj
    }

    const initReceiveDefaultData = async (params: any, formNameList: any[]) => {

        let commonModelList: DocMapModel[] = []
        let repeatModelList: DocMapModel[] = []
        let formName: string = ""

        const response = await getReceiveDocMapApi(params)
        var mapData = JSON.parse(response.data.mapData)
        if (response.data.formName !== null && formNameList.indexOf(response.data.formName) > -1) {
            formName = response.data.formName
            mapData.map((item: any, index: any) => {
                const projectDetails = ReceiptSipStandardMessage.find(sip => sip.physicalProjectName === item.toType)
                const require = projectDetails?.items?.find(obj => {
                    if (obj.physicalProjectName === item.toField) {
                        return obj.isRequired
                    }
                })
                if (item.toType !== "item_line_item") {
                    if (require) {
                        commonModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, true, item.defaultValue))
                    } else {
                        commonModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, false, item.defaultValue))
                    }
                } else {
                    if (require) {
                        repeatModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, true, item.defaultValue))
                    } else {
                        repeatModelList.push(new DocMapModel(item.toType as string, item.toField as string, item.svfField, false, item.defaultValue))
                    }
                }
            })
        } else {
            ReceiptSipStandardMessage.forEach(directory => {
                if (!directory.isRepeat) {
                    directory.items!.forEach(item => {
                        if (item.isRequired) {
                            commonModelList.push(new DocMapModel(directory.physicalProjectName as string, item.physicalProjectName as string, "", true, item.defaultValue))
                        }
                    })
                } else {
                    directory.items!.forEach(item => {
                        if (item.isRequired) {
                            repeatModelList.push(new DocMapModel(directory.physicalProjectName as string, item.physicalProjectName as string, "", true, item.defaultValue))
                        }
                    })
                }
            })
        }

        let obj = { formName: formName, commonList: commonModelList, repeatList: repeatModelList }
        return obj
    }

    return {
        initDefaultData, initReceiveDefaultData
    }
}