import { compose } from "./compose";
import UserContainer from "./UserContainer";
import DocContainer from "./DocContainer";
import CommonContainer from "./CommonContainer";
import DocMapContainer from "./DocMapContainer";
import SendContainer from "./SendContainer";
import CustomContainer from "./CustomContainer";
import ErrorContainer from "./ErrorContainer";
import ServiceContainer from "./AxiosServiceContainer";
import ReceiveDocMapContainer from "./ReceiveDocMapContainer";

const GeneralContainer = (props: { children: JSX.Element; }): JSX.Element => {
    return (
        compose(
            [
                UserContainer,
                DocContainer,
                DocMapContainer,
                ReceiveDocMapContainer,
                SendContainer,
                CustomContainer,
                ServiceContainer,
                ErrorContainer,
                CommonContainer
            ],
            props.children
        )
    )
}

export { GeneralContainer }
