import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

type Props = {
    title: string;
    isOpen: boolean;
    confirmPassword: (passwordList: any) => void;
    doClose: () => void;
};

export const ConfirmPasswordDialog: React.FC<Props> = ({ title, isOpen, confirmPassword, doClose }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [passwordList, setPasswordList] = React.useState<any>({ password: '', confirmPassword: '' });

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    /**
     * 入力ボックスのデータ変更に対応
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any }) => {
        const { target: { value, name } } = e;
        setPasswordList({ ...passwordList, [name]: value });
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{title}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div>
                        <div style={{ marginTop: '20px' }}>
                            <label style={{ lineHeight: '40px' }}>{t('Text.Password')}</label>
                            <TextField
                                name="password"
                                type='password'
                                size="small"
                                style={{ marginLeft: '95px', width: '320px' }}
                                onChange={handleTextFieldOnChange}
                                variant="outlined"
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <label style={{ lineHeight: '40px' }}>{t('Text.CheckPassword')}</label>
                            <TextField
                                name="confirmPassword"
                                type='password'
                                size="small"
                                style={{ marginLeft: '25px', width: '320px' }}
                                onChange={handleTextFieldOnChange}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div style={{ height: '10px' }}></div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#e9eef0' }}>
                    <Button variant="contained" color="primary" onClick={() => confirmPassword(passwordList)}>
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => doClose()}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
