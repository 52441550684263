import {Snackbar} from "@material-ui/core";
import React, {FC, useEffect, useState} from "react";

type Props = {
    isOpen: boolean;
    content: string;
    doClose: () => void;
};
const splitMsg = (msg: string) => {
    const messages = msg.split('\n');
    return (<>{messages.map((m, index) => <span id={`message-id-${index}`} key={index}>{m}<br/></span>)}</>)
}
/**
 * SnackBar5秒で消えるインフォメーション
 * @param isOpen
 * @param content
 * @constructor
 */
const InfoSnackBar: React.FC<Props> = ({
                                           isOpen,
                                           content,
                                           doClose,
                                       }) => {

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };


    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        setOpen(false);
        doClose();
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={<span id="snackbar-messages">{splitMsg(content)}</span>}
            />
        </>
    );
}
export default InfoSnackBar;
