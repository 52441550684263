declare type DialogResult = 'ok' | 'cancel'
declare type DialogType = 'confirm' | 'warn'

class SipProjectDirectory {
    projectName?: string;
    physicalProjectName?: string;
    isRequired?: boolean;
    isRepeat?: boolean;
    items?: SipProjectItem[];
}

class SipProjectItem {
    projectName?: string;
    physicalProjectName?: string;
    isRequired?: boolean;
    defaultValue?: string;
}

const SipStandardMessage: SipProjectDirectory[] = [
    {
        projectName: '車輌情報',
        physicalProjectName: 'car_info',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '輸送ID', physicalProjectName: 'trsp_ctrl_num_id', isRequired: false },
            { projectName: '輸送識別番号', physicalProjectName: 'trsp_formation_num_id', isRequired: false },
            { projectName: '運送事業者コード（本社）', physicalProjectName: 'trsp_cli_prty_head_off_id', isRequired: false },
            { projectName: '運送事業者コード（事業所）', physicalProjectName: 'trsp_cli_prty_brnc_off_id', isRequired: false },
            { projectName: '運送事業者コード（自社ローカル）', physicalProjectName: 'trsp_cli_private_cd_id', isRequired: false },
            { projectName: '運送事業者名（漢字）', physicalProjectName: 'trsp_cli_prty_name_txt', isRequired: false },
            { projectName: '運送日', physicalProjectName: 'trsp_date', isRequired: false },
            { projectName: '自動車登録番号', physicalProjectName: 'car_license_plt_num_id', isRequired: false },
            { projectName: '車輌種類', physicalProjectName: 'cls_of_car_txt', isRequired: false },
            { projectName: '乗務員名', physicalProjectName: 'drv_name_txt', isRequired: false },
            { projectName: '乗務員電話番号', physicalProjectName: 'drv_tel_cmm_cmp_num_txt', isRequired: false },
            { projectName: '乗務員メールアドレス', physicalProjectName: 'drv_mail_adrs_txt', isRequired: false },
            { projectName: '運送ルート', physicalProjectName: 'trsp_route_cd', isRequired: false }
        ]
    }, {
        projectName: '届け先別情報',
        physicalProjectName: 'vou_snd_info',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '配送順番号', physicalProjectName: 'trsp_sequence_cd', isRequired: false },
            { projectName: '納品ケース数', physicalProjectName: 'unt_quan_per_ship_quan', isRequired: false },
            { projectName: '総ユニット数', physicalProjectName: 'totl_unt_quan_quan', isRequired: false }
        ]
    }, {
        projectName: '納品伝票情報',
        physicalProjectName: 'vou_info',
        isRequired: true,
        isRepeat: false,
        items: [
            { projectName: '荷送人コード（本社）', physicalProjectName: 'cnsg_prty_head_off_id', isRequired: false },
            { projectName: '荷送人コード（事業所）', physicalProjectName: 'cnsg_prty_brnc_off_id', isRequired: false },
            { projectName: '荷送人コード（ローカル）', physicalProjectName: 'cnsg_private_cd_id', isRequired: true },
            { projectName: '荷送人コード（商流）', physicalProjectName: 'cnsg_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '荷送人名（漢字）', physicalProjectName: 'cnsg_prty_name_txt', isRequired: true },
            { projectName: '荷送人電話番号', physicalProjectName: 'cnsg_tel_cmm_cmp_num_txt', isRequired: true },
            { projectName: '荷送人住所（漢字）', physicalProjectName: 'cnsg_pstl_adrs_line_one_txt', isRequired: true },
            { projectName: '荷送人郵便番号', physicalProjectName: 'cnsg_pstc_cd', isRequired: false },
            { projectName: '荷受人コード（本社）', physicalProjectName: 'cnee_prty_head_off_id', isRequired: false },
            { projectName: '荷受人コード（事業所）', physicalProjectName: 'cnee_prty_brnc_off_id', isRequired: false },
            { projectName: '荷受人コード（ローカル）', physicalProjectName: 'cnee_private_cd_id', isRequired: true },
            { projectName: '荷受人コード（商流）', physicalProjectName: 'cnee_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '荷受人名（漢字）', physicalProjectName: 'cnee_prty_name_txt', isRequired: true },
            { projectName: '出荷場所コード（本社）', physicalProjectName: 'ship_from_prty_head_off_id', isRequired: false },
            { projectName: '出荷場所コード（事業所）', physicalProjectName: 'ship_from_prty_brnc_off_id', isRequired: false },
            { projectName: '出荷場所コード（ローカル）', physicalProjectName: 'ship_from_private_cd_id', isRequired: true },
            { projectName: '出荷場所コード（商流）', physicalProjectName: 'ship_from_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '出荷場所名（漢字）', physicalProjectName: 'ship_from_prty_name_txt', isRequired: false },
            { projectName: '出荷場所電話番号', physicalProjectName: 'ship_from_tel_cmm_cmp_num_txt', isRequired: false },
            { projectName: '出荷場所住所（漢字）', physicalProjectName: 'ship_from_pstl_adrs_line_one_txt', isRequired: false },
            { projectName: '出荷場所郵便番号', physicalProjectName: 'ship_from_pstc_cd', isRequired: false },
            { projectName: '荷届先コード（本社）', physicalProjectName: 'ship_to_prty_head_off_id', isRequired: false },
            { projectName: '荷届先コード（事業所）', physicalProjectName: 'ship_to_prty_brnc_off_id', isRequired: false },
            { projectName: '荷届先コード（ローカル）', physicalProjectName: 'ship_to_private_cd_id', isRequired: true },
            { projectName: '荷届先コード（商流）', physicalProjectName: 'ship_to_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '荷届先名（漢字）', physicalProjectName: 'ship_to_prty_name_txt', isRequired: true },
            { projectName: '荷届先電話番号', physicalProjectName: 'ship_to_tel_cmm_cmp_num_txt', isRequired: true },
            { projectName: '荷届先住所（漢字）', physicalProjectName: 'ship_to_pstl_adrs_line_one_txt', isRequired: true },
            { projectName: '荷届先郵便番号', physicalProjectName: 'ship_to_pstc_cd', isRequired: false }
        ]
    }, {
        projectName: '中継地点',
        physicalProjectName: 'rly_point',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '中継拠点コード（本社）', physicalProjectName: 'cnn_prty_head_off_cd_id', isRequired: false },
            { projectName: '中継拠点コード（事業所）', physicalProjectName: 'cnn_prty_brnc_off_cd_id', isRequired: false },
            { projectName: '中継拠点コード（ローカル）', physicalProjectName: 'cnn_private_cd_id', isRequired: false },
            { projectName: '中継拠点名', physicalProjectName: 'cnn_prty_cd_for_ordering_id', isRequired: false }
        ]
    }, {
        projectName: '納品伝票個別情報',
        physicalProjectName: 'item_total',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '運送区分', physicalProjectName: 'cls_of_trsp_cd', isRequired: false },
            { projectName: '納品伝票番号', physicalProjectName: 'del_slip_num_id', isRequired: true },
            { projectName: '納入予定日', physicalProjectName: 'esti_del_date_dttm', isRequired: true },
            { projectName: '発注番号', physicalProjectName: 'ord_num_id', isRequired: false },
            { projectName: '合計商品数量', physicalProjectName: 'totl_pcke_quan_quan', isRequired: false },
            { projectName: '合計商品バラ数量', physicalProjectName: 'totl_bulk_quan_quan', isRequired: false },
            { projectName: '合計商品重量', physicalProjectName: 'totl_bulk_weig_meas', isRequired: false },
            { projectName: '確認印情報', physicalProjectName: 'chk_of_inv_print', isRequired: false },
            { projectName: '納品伝票備考', physicalProjectName: 'del_note_txt', isRequired: false },
            { projectName: '納品伝票ＰＤＦファイル', physicalProjectName: 'pdf_base64', isRequired: false }
        ]
    }, {
        projectName: '商品明細',
        physicalProjectName: 'item_line_item',
        isRequired: false,
        isRepeat: true,
        items: [
            { projectName: 'GTIN-13', physicalProjectName: 'gtin13_item_cd', isRequired: true },
            { projectName: 'GTIN-14', physicalProjectName: 'gtin14_item_cd', isRequired: false },
            { projectName: '商品コード（ローカル：メーカーP）', physicalProjectName: 'item_private_cd_cd', isRequired: false },
            { projectName: '商品コード（ローカル：卸P）', physicalProjectName: 'item_wholesale_cd_cd', isRequired: false },
            { projectName: '外装コード', physicalProjectName: 'shpm_item_ctrl_num_id', isRequired: false },
            { projectName: '商品名', physicalProjectName: 'shpm_item_name_txt', isRequired: true },
            { projectName: '規格・容量・荷姿', physicalProjectName: 'pcke_frm_annotation_txt', isRequired: false },
            { projectName: '入数（出荷単位）', physicalProjectName: 'pcke_intn_cp_quan', isRequired: true },
            { projectName: '単位（出荷単位）', physicalProjectName: 'pcke_unt_cd', isRequired: true },
            { projectName: '数量（出荷単位）', physicalProjectName: 'pcke_quan_quan', isRequired: true },
            { projectName: '総バラ数量（出荷単位）', physicalProjectName: 'bulk_num_quan', isRequired: false },
            { projectName: '製造日', physicalProjectName: 'mnf_date_dttm', isRequired: false },
            { projectName: '賞味期限／有効期限', physicalProjectName: 'vld_trm_dttm', isRequired: false },
            { projectName: '消費期限', physicalProjectName: 'expn_trm_dttm', isRequired: false },
            { projectName: 'ロット番号', physicalProjectName: 'lot_num_id', isRequired: false },
            { projectName: '温度帯区分', physicalProjectName: 'trms_of_tmp_cd', isRequired: false },
            { projectName: '納品伝票明細備考', physicalProjectName: 'del_note_line_item_txt', isRequired: false }
        ]
    }
]

const ReceiptSipStandardMessage: SipProjectDirectory[] = [
    {
        projectName: '車輌情報',
        physicalProjectName: 'car_info',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '輸送ID', physicalProjectName: 'trsp_ctrl_num_id', isRequired: false },
            { projectName: '輸送識別番号', physicalProjectName: 'trsp_formation_num_id', isRequired: false },
            { projectName: '運送事業者コード（本社）', physicalProjectName: 'trsp_cli_prty_head_off_id', isRequired: false },
            { projectName: '運送事業者コード（事業所）', physicalProjectName: 'trsp_cli_prty_brnc_off_id', isRequired: false },
            { projectName: '運送事業者コード（自社ローカル）', physicalProjectName: 'trsp_cli_private_cd_id', isRequired: false },
            { projectName: '運送事業者名（漢字）', physicalProjectName: 'trsp_cli_prty_name_txt', isRequired: false },
            { projectName: '運送日', physicalProjectName: 'trsp_date', isRequired: false },
            { projectName: '自動車登録番号', physicalProjectName: 'car_license_plt_num_id', isRequired: false },
            { projectName: '車輌種類', physicalProjectName: 'cls_of_car_txt', isRequired: false },
            { projectName: '乗務員名', physicalProjectName: 'drv_name_txt', isRequired: false },
            { projectName: '乗務員電話番号', physicalProjectName: 'drv_tel_cmm_cmp_num_txt', isRequired: false },
            { projectName: '乗務員メールアドレス', physicalProjectName: 'drv_mail_adrs_txt', isRequired: false },
            { projectName: '運送ルート', physicalProjectName: 'trsp_route_cd', isRequired: false }
        ]
    }, {
        projectName: '納品伝票情報',
        physicalProjectName: 'vou_info',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '発注番号', physicalProjectName: 'ord_num_id', isRequired: false },
            { projectName: '発注日', physicalProjectName: 'ord_date_prfm_dttm', isRequired: false },
            { projectName: '納入日', physicalProjectName: 'del_date_dttm', isRequired: true },
            { projectName: '納品伝票番号', physicalProjectName: 'del_slip_num_id', isRequired: true },
            { projectName: '荷受人コード（本社）', physicalProjectName: 'cnee_prty_head_off_id', isRequired: false },
            { projectName: '荷受人コード（事業所）', physicalProjectName: 'cnee_prty_brnc_off_id', isRequired: false },
            { projectName: '荷受人コード（ローカル）', physicalProjectName: 'cnee_private_cd_id', isRequired: true },
            { projectName: '荷受人コード（商流）', physicalProjectName: 'cnee_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '荷受人名（漢字）', physicalProjectName: 'cnee_prty_name_txt', isRequired: true },
            { projectName: '荷送人コード（本社）', physicalProjectName: 'cnsg_prty_head_off_id', isRequired: false },
            { projectName: '荷送人コード（事業所）', physicalProjectName: 'cnsg_prty_brnc_off_id', isRequired: false },
            { projectName: '荷送人コード（ローカル）', physicalProjectName: 'cnsg_private_cd_id', isRequired: true },
            { projectName: '荷送人コード（商流）', physicalProjectName: 'cnsg_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '荷送人名（漢字）', physicalProjectName: 'cnsg_prty_name_txt', isRequired: true },
            { projectName: '荷送人電話番号', physicalProjectName: 'cnsg_tel_cmm_cmp_num_txt', isRequired: false },
            { projectName: '荷送人住所（漢字）', physicalProjectName: 'cnsg_pstl_adrs_line_one_txt', isRequired: false },
            { projectName: '荷届先コード（本社）', physicalProjectName: 'ship_to_prty_head_off_id', isRequired: false },
            { projectName: '荷届先コード（事業所）', physicalProjectName: 'ship_to_prty_brnc_off_id', isRequired: false },
            { projectName: '荷届先コード（ローカル）', physicalProjectName: 'ship_to_private_cd_id', isRequired: true },
            { projectName: '荷届先コード（商流）', physicalProjectName: 'ship_to_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '荷届先名（漢字）', physicalProjectName: 'ship_to_prty_name_txt', isRequired: true },
            { projectName: '荷届先庭先条件', physicalProjectName: 'trms_of_ship_to_palce_txt', isRequired: false },
            { projectName: '荷届先電話番号', physicalProjectName: 'ship_to_tel_cmm_cmp_num_txt', isRequired: true },
            { projectName: '荷届先住所（漢字）', physicalProjectName: 'ship_to_pstl_adrs_line_one_txt', isRequired: true },
            { projectName: '出荷場所コード（本社）', physicalProjectName: 'ship_from_prty_head_off_id', isRequired: false },
            { projectName: '出荷場所コード（事業所）', physicalProjectName: 'ship_from_prty_brnc_off_id', isRequired: false },
            { projectName: '出荷場所コード（ローカル）', physicalProjectName: 'ship_from_private_cd_id', isRequired: false },
            { projectName: '出荷場所コード（商流）', physicalProjectName: 'ship_from_prty_cd_for_ordering_id', isRequired: false },
            { projectName: '出荷場所名（漢字）', physicalProjectName: 'ship_from_prty_name_txt', isRequired: false },
            { projectName: '出荷場所住所（漢字）', physicalProjectName: 'ship_from_pstl_adrs_line_one_txt', isRequired: false },
            { projectName: '出荷場所電話番号', physicalProjectName: 'ship_from_tel_cmm_cmp_num_txt', isRequired: false }
        ]
    }, {
        projectName: '商品明細',
        physicalProjectName: 'item_line_item',
        isRequired: false,
        isRepeat: true,
        items: [
            { projectName: 'GTIN-13', physicalProjectName: 'gtin13_item_cd', isRequired: true },
            { projectName: '商品名', physicalProjectName: 'shpm_item_name_txt', isRequired: true },
            { projectName: '入数（出荷単位）', physicalProjectName: 'pcke_intn_cp_quan', isRequired: true },
            { projectName: '単位（出荷単位）', physicalProjectName: 'pcke_unt_cd', isRequired: true },
            { projectName: '数量（出荷単位）', physicalProjectName: 'pcke_quan_quan', isRequired: true }
        ]
    },
    {
        projectName: '納品伝票PDFファイル',
        physicalProjectName: 'pdf_data',
        isRequired: false,
        isRepeat: false,
        items: [
            { projectName: '納品伝票PDFファイル', physicalProjectName: 'pdf_base64', isRequired: false }
        ]
    }
]

export {
    SipProjectDirectory,
    SipProjectItem,
    SipStandardMessage,
    ReceiptSipStandardMessage
}

export type {
    DialogResult,
    DialogType,
}