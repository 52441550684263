export default function LocalTimeFilter( value: any,locale?:boolean) {
    var regexIso8601 = /^(\d{4}|\+\d{6})(?:-?(\d{2})(?:-?(\d{2})(?:T(\d{2})(?::?(\d{2})(?::?(\d{2})(?:(?:\.|,)(\d{1,}))?)?)?(Z|([\-+])(\d{2})(?::?(\d{2}))?)?)?)?)?$/;
        let isLocale = locale===false?false:true;
        var ret = value;
        var val = value;
        if (val != null) {
            var date_offset = 0;
            var browser_offset = -new Date().getTimezoneOffset();
            let temp = null;

            if (typeof val === 'string') {
                //カスタムプロパティのタイムゾーンあり
                //yyyyMMddHHmmss
                //yyyyMMddHHmm,
                if ((val.length === 12) || (val.length === 14)) {
                    var yyyy = val.substring(0, 4);
                    var mm = val.substring(4, 6);
                    var dd = val.substring(6, 8);
                    var hh = val.substring(8, 10);
                    var m = val.substring(10, 12);
                    var ss = 0;
                    if (val.length > 13) {
                        // @ts-ignore
                        ss = val.substring(12, 14);
                    }
                    // @ts-ignore
                    let date: Date = new Date(yyyy, mm - 1, dd, hh, m, ss).getTime();
                    // @ts-ignore
                    if (isLocale) {
                        // @ts-ignore
                        date = new Date(date + browser_offset * 60000);
                    } else {
                        // @ts-ignore
                        date = new Date(date - browser_offset * 60000);
                    }
                    ret = zeroPadding(date.getFullYear(), 4);
                    ret += zeroPadding(date.getMonth() + 1, 2);
                    ret += zeroPadding(date.getDate(), 2);
                    ret += zeroPadding(date.getHours(), 2);
                    ret += zeroPadding(date.getMinutes(), 2);

                    if (val.length > 13) {
                        ret += zeroPadding(date.getSeconds(), 2);
                    }
                } else {
                    var hasTimezone = false;
                    //if (val.indexOf('T') > 0) {
                    //
                    //}
                    let tempParse = Date.parse(val);
                    if (isNaN(tempParse)) {
                        let t = val.match(regexIso8601);
                        if (t !== null) {
                            temp = new Date(t[1] + '/' + t[2] + '/' + t[3] + ' ' + t[4] + ':' + t[5] + ':' + t[6]);
                            var tempOffsetHour = Number(t[10]);
                            var tempOffsetMin = Number(t[11]);

                            if (!tempOffsetHour) {
                                tempOffsetHour = 0;
                            }
                            if (!tempOffsetMin) {
                                tempOffsetMin = 0;
                            }
                            date_offset = tempOffsetHour * 60 + tempOffsetMin;
                            if (t[9] === '-') {
                                date_offset = date_offset * -1;
                            }
                        }
                    } else {
                        var dateTemp = new Date(tempParse);
                        date_offset = dateTemp.getTimezoneOffset() * -1;
                        temp = dateTemp;
                    }
                    val = temp;

                    var offset = (browser_offset - date_offset) * 60000;
                    if (offset !== 0) {
                        ret = val.getTime() + offset;
                    }
                }
            } else {
                date_offset = new Date(val).getTimezoneOffset() * -1;
                var off = (browser_offset - date_offset) * 60000;
                if (off !== 0) {
                    ret = val + off;
                }
            }

        }

        return ret;

    };

    //指定した桁数でゼロパディングする
    function zeroPadding(val: any, digit: any) {
        var zero = '';
        for (var i = 0; i < digit; i++) {
            zero += '0';
        }
        val = val.toString();
        val = (zero + val).slice(digit * -1);
        return val;
    }

