import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * 検索条件 API
 */

export const useSearchConditionService = () => {
    const serviceState = ServiceContainer.useContainer()

    const getSearchConditionApi = () => {
        return serviceState.request(
            WebConstants.SEARCH_CONDITION_LIST_URL,
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {
            console.log("****** searchConditionsList is *******", response);
            return response.data.searchConditions;
        }
        );
    }

    return {
        getSearchConditionApi
    }
}