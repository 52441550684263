import {Box, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField} from "@material-ui/core";
import React, {Ref} from "react";
import {useTranslation} from "react-i18next";
import DocContainer from "../../container/DocContainer";

/**
 * Boolean型検索条件
 * @param props
 * @constructor
 */
const BoolCondition = (props: { templateIdx : number,detailIdx: number, conditionType: string,name:string }) => {
    const {templateIdx,detailIdx, conditionType,name} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
    const [value, setValue] = React.useState(Boolean(detailCondition.boolVal));
    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey =(pre:string) =>{
        return pre+'_'+templateIdx+'_'+detailIdx;
    }

    const renderCondition = () => {
        let ret: JSX.Element[] = [];
        ret.push(
            <>
                <FormControlLabel  key = {getKey('boolControlLabel')}
                    control={
                        <Checkbox
                            key = {getKey('boolCheck')}
                            id = {getKey('boolCheck')}
                            checked={value}
                            onChange={(event) => {
                                let temp = detailCondition;
                                temp['boolVal'] = event.currentTarget.checked;
                                setDetailCondition(temp);
                                docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                setValue(event.currentTarget.checked);
                            }}
                        />
                    }
                    label={name}
                />
            </>
        );
        return ret;
    }

    return (
        // 詳細条件実行時指定 日付型 テンプレート
        <Box mx={2} display="flex" flexDirection="row"　alignItems="center">
            {renderCondition()}
        </Box>
    );
};

export default BoolCondition;
