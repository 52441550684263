/**
 * 共通のバリデーションサービス
 *
 * @returns {Object}
 */
import * as Constants from '../constants/Constants'
import CustomDateFilter from "../common/CustomDateFilter";
import i18n from '../i18n';
import {DATE_TYPE} from "../constants/PropertiesConstants";

// パスワード　桁数
var _PASSWORD_LENGTH = '32';
// 定義書　桁数
var _DEFINITION_LENGTH = '31';
// フリーワード用
const _TYPE_WORD = 0;  // 単語
var _TYPE_LPAREN = 1;  // 左括弧
var _TYPE_RPAREN = 2;  // 右括弧
var _TYPE_AND = 3;  // AND
var _TYPE_OR = 4;  // OR
var _TYPE_NOT = 5;  // NOT
var _TYPE_LIST = 6;  // 括弧内のトークン
var _CHAR_SPACE = 0;
var _CHAR_CHAR = 1;
var _CHAR_LPAREN = 2;
var _CHAR_RPAREN = 3;
var _CHAR_LQUOTE = 4;
var _CHAR_RQUOTE = 5;
var _ESCAPE_CHARS_CONDITION = '\\*';

class ValidatorService {

    //必須チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    required = (chkVal: string, itemName: any) => {
        var msg = '';
        if ((String(chkVal).replace(/ /g, '') + '').length === 0 || chkVal === undefined) {
            msg = i18n.t('Msg.RequiredField', {args1: itemName});
        }
        return msg;
    }
// 正規表現を用いた数値チェック
    isRegNumCheck = (chkNum: string) => {
        'use strict';
        if (chkNum.match(/[^0-9]/g)) {
            return false;
        } else {
            return true;
        }
    }
    // 正規表現を用いた数値チェック
    numZeroShift = (value: string) => {
        'use strict';
        var res = '';
        if (this.isRegNumCheck(value)) {
            res = value;
            var rep = new RegExp('^0+0?');
            res.replace(rep, '');
        }
        return parseFloat(res);
    };

    //必須チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    selected = (chkVal: undefined, itemName: any) => {
        var msg = '';
        if ((String(chkVal).replace(/ /g, '') + '').length === 0 || chkVal === undefined) {
            msg = i18n.t('Msg.SelectedField', {args1: itemName});
        }
        return msg;
    }

    //禁則文字列チェック
    //@param chkVal 　   検証する文字列
    //@return　true:禁則文字が使用されている　false:禁則文字が使用されていない
    isProhibitString = (chkVal: any) => {
        if (chkVal.match(/[/|\\\\|:|*|?|\"|<|>||]/)) {
            return true;
        } else {
            return false;
        }
    }

    //禁則文字列チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    prohibitString = (chkVal: any, itemName: any | undefined) => {
        var msg = '';
        if (this.isProhibitString(String(chkVal))) {
            msg = i18n.t('Msg.InvalidID', {args1: itemName, args2: ' / \\ : * ? \" < > |  '});
        }
        return msg;
    }

    //拡張子の禁則文字列チェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    // "."（ドット）が検証対象の文字列に含まれているとエラーになります
    prohibitStringForExtension = (chkVal: string) => {
        var msg = '';
        if (chkVal.match(/[./|\\\\|:|*|?|\"|<|>||]/)) {
            msg = i18n.t('Msg.InvalidID', {args1: i18n.t('Text.FileNameExtension'), args2: ' . / \\ : * ? \" < > |  '});
        }
        return msg;
    }

    //カスタムプロパティの表示名の文字チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    //{}（波括弧）が検証対象の文字列内で2組以上存在する場合エラーになります
    checkCurlyBraceString = (chkVal: string, itemName: any) => {
        var msg = '';
        var leftCurlyBrace = 0;
        var rightCurlyBrace = 0;
        // 文字列を1文字に分割して配列に格納
        var chkValArr = chkVal.split('');

        for (var i = 0; i < chkValArr.length; i++) {
            // 分割した文字が波括弧の始め、または終わりであればカウントする
            if (chkValArr[i] === '{') {
                leftCurlyBrace++;
            } else if (chkValArr[i] === '}') {
                rightCurlyBrace++;
            }
        }

        if (leftCurlyBrace >= 2 && rightCurlyBrace >= 2) {
            msg = i18n.t('Msg.InvalidDisplayName', {args1: itemName});
        }
        return msg;
    }

    //半角文字チェック
    //@param chkVal 　   検証する文字列
    //@param chrCnt 　   文字数
    //@return　true:半角文字のみが使用されている　false:半角文字以外が使用されている
    isHalfstringnumericSingleByte = (chkVal: any, chrCnt: any) => {
        var chkStr = '^[\\x20-\\x7e]{1,' + chrCnt + '}$';
        var reg = new RegExp(chkStr);
        if (reg.test(chkVal)) {
            return true;
        } else {
            return false;
        }
    }

    //半角英数チェック
    //@param chkVal 　   検証する文字列
    //@param chrCnt 　   文字数
    //@return　true:半角英数字のみが使用されている　false:半角英数字以外が使用されている
    isAlphanumericSingleByte = (chkVal: string, chrCnt: string) => {
        var chkStr = '^[a-zA-Z0-9]{1,' + chrCnt + '}$';
        var reg = new RegExp(chkStr);
        if (reg.test(chkVal)) {
            return true;
        } else {
            return false;
        }
    }

    //パスワードチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    password = (chkVal: string | undefined) => {
        var msg = '';
        if (!this.isHalfstringnumericSingleByte(chkVal, _PASSWORD_LENGTH)) {
            // パスワード確認
            msg = i18n.t('Msg.InvalidPassword', {args1: 32});
        }
        return msg;
    }

    //定義名チェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    definition = (chkVal: string, itemName: any) => {
        var msg = '';
        if (!this.isAlphanumericSingleByte(chkVal, _DEFINITION_LENGTH)) {
            // パスワード確認
            msg = i18n.t('Msg.InvalidDefinition', {args1: itemName});
        }
        return msg;
    }

    //文字数取得
    //@param chkVal 　   検証する文字列
    //@return　文字数
    getStringCount = (chkVal: any) => {
        var length;
        if (typeof chkVal === 'string') {
            // 文字数チェック
            // TODO window.encodeURIComponentで良いかは謎？全角文字が3バイトではなく6バイトとかになる。
            //length = window.encodeURIComponent(chkVal).replace(/%../g, '"b').length;
            length = new Blob([chkVal]).size;
        } else {
            length = 0;
        }
        return length;
    }

    //文字数チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@param max         最大文字数
    //@isString          バイト変換しない場合はtrue（指定なしの場合はbyte換算）
    //@return　不具合があった場合はエラーメッセージを返します
    stringCount = (chkVal: any, itemName?: any, max?: number, isString?: boolean) => {
        var msg = '';
        var isStringCount = isString || false;
        if (typeof chkVal === 'string') {
            // 文字数チェック
            if (isStringCount) {
                // バイト変換しない文字数チェック
                if (max && (chkVal.length > max)) {
                    msg = i18n.t('Msg.MaxLengthFailed', {args1: itemName, args2: max});
                }
            } else {
                // バイト変換した文字数チェック
                if (max && (this.getStringCount(chkVal) > max)) {
                    msg = i18n.t('Msg.OverCharacters', {args1: itemName, args2: max});
                }
            }
        }
        return msg;
    }

    //半角数字チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    stringNumeric = (chkVal: string, itemName: any) => {
        var msg = '';
        var chkStr = '^[0-9]';
        var reg = new RegExp(chkStr);
        if (typeof chkVal === 'string') {
            if (!reg.test(chkVal)) {
                msg = i18n.t('Msg.InvalidStringNumeric', {args1: itemName});
            }
        }
        return msg;
    }

    //ログインチェック
    //@param chkUser 　  検証するユーザーID
    //@param chkPw 　    検証するパスワード
    //@return　不具合があった場合はエラーメッセージを返します
    login = (chkUser: string, chkPw: string, max: number) => {
        var chkFlg = true;

        // ユーザーID（必須チェック）
        var msg = this.required(chkUser, i18n.t('Text.User'));
        // パスワード（必須チェック）
        if (msg.length === 0) {
            msg = this.required(chkPw, i18n.t('Text.Password'));
        }

        if (msg.length === 0) {
            if (this.isProhibitString(chkUser)) {
                chkFlg = false;
            }
            // ユーザーID（禁則文字チェック）
            if (this.isProhibitString(chkUser)) {
                chkFlg = false;
            }
            // ユーザーID（文字数チェック）
            // 文字数チェック
            if (this.getStringCount(chkUser) > max) {
                chkFlg = false;
            }
            // パスワード（パスワードチェック）
            if (!this.isHalfstringnumericSingleByte(chkPw, _PASSWORD_LENGTH)) {
                chkFlg = false;
            }
            // メッセージ設定
            if (chkFlg === false) {
                msg = i18n.t('Msg.LoginFailed');
            }
        }
        return msg;
    }

    //数値チェック
    //@param chkVal 　   検証する文字列
    //@param isDecimal 　小数点含む：true　小数点含まない：false
    //@return　true:数値　false:数値以外
    isNumeric = (chkNum: any, isDecimal: any) => {
        if (typeof chkNum === 'number') {
            chkNum = chkNum.toString();
        }
        if (isDecimal) {
            // 符号付き小数点を判断
            if (chkNum.match(/^-[0-9]+\.[0-9]+$/)) {
                if (chkNum.match(/^[0-9]+\.[0-9]+$/)) {
                    return false;
                }
            }
            // １桁の場合は数字入力かどうか
            if (chkNum.length === 1) {
                if (!chkNum.match(/[0-9]/)) {
                    return false;
                }
            }
        } else {
            // 整数を判断
            if (!chkNum.match(/^-?[0-9]+$/)) {
                return false;
            }
        }
        if (chkNum.length < 1) {
            return false;
        }
        return true;
    }

    //数値変換
    //@param chkVal 　   検証する文字列
    //@param isDecimal　 小数点含む：true　小数点含まない：false
    //@param numericCnt　整数値の桁数
    //@param decimalCnt　小数点の桁数
    //@return　true:数値　false:数値以外
    changeNumeric = (chkNum: number | undefined, isDecimal: boolean | undefined, numericCnt: number | undefined, decimalCnt: number | undefined) => {
        numericCnt = numericCnt === undefined ? 19 : numericCnt;
        decimalCnt = decimalCnt === undefined ? 0 : decimalCnt;
        // １桁目を取得
        var chkNumber = String(chkNum).charAt(0);
        // マイナス確認
        var minus = chkNumber === '-';
        var intNum = '';
        var decNum = '';
        var priod = false;
        var pt = minus ? 1 : 0;

        for (; pt < String(chkNum).length; pt++) {
            // 文字切り抜き
            chkNumber = String(chkNum).charAt(pt);

            // ピリオドはスキップする(複数ある場合はエラー)
            if (chkNumber === '.') {
                if (priod) {
                    return 'E';
                }
                priod = true;
                continue;
            }

            // 数値以外はエラー
            if (!this.isNumeric(chkNumber, false)) {
                return 'E';
            }

            // マイナスの場合は数値を置換する
            var ad = minus ? this.toMinus(chkNumber) : chkNumber;

            // 整数・小数でセットする変数を変更
            if (!priod) {
                intNum += ad;
            } else {
                decNum += ad;
            }
        }
        // プラスとマイナスでヘッダー記号を付加
        var head = minus ? 'M' : 'P';

        // マイナスの場合は「９」パディング、整数は「０」パディング
        var padChar = minus ? '9' : '0';
        var s1_zero = this.padding(numericCnt - intNum.length, padChar);
        var s2_zero = this.padding(decimalCnt - decNum.length, padChar);

        return head + s1_zero + intNum + decNum + s2_zero;
    }

    //パディング
    //@param count 　   パディングする個数
    //@param padChar 　 パディングする文字列
    //@return　パディング文字列
    padding = (count: number, padChar: string) => {
        var padStr = '';
        for (var i = 0; i < count; i++) {
            padStr += padChar;
        }
        return padStr;
    }

    //マイナス数値変更
    //@param chkVal 　変換する文字列
    //@return　変換後文字列
    toMinus = (chkVal: any) => {
        switch (chkVal) {
            case '0':
                return '9';
            case '1':
                return '8';
            case '2':
                return '7';
            case '3':
                return '6';
            case '4':
                return '5';
            case '5':
                return '4';
            case '6':
                return '3';
            case '7':
                return '2';
            case '8':
                return '1';
            case '9':
                return '0';
            default:
                return '0';
        }
    }

    //数値チェック(範囲チェック)//数値チェック(範囲チェック)
    //@param chkVal 　   検証する文字列
    //@param min 　      最小値
    //@param max 　      最大値
    //@param isDecimal　 小数点含む：true　小数点含まない：false
    //@param numericCnt　整数値の桁数
    //@param decimalCnt　小数点の桁数
    //@return　不具合があった場合はエラーメッセージを返します
    rangeNumeric = (chkVal: any, min: any, max: any, itemName: any, isDecimal: any, numericCnt?: number, decimalCnt?: number) => {
        var msg = '';
        var msgType = '';

        var msgMin = String(min).replace(/^(-?[0-9]+)(?=\.|$)/, function (s) {
            return s.replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
        });

        var msgMax = String(max).replace(/^(-?[0-9]+)(?=\.|$)/, function (s) {
            return s.replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
        });

        if (isDecimal) {
            msgType = 'Msg.InvalidNumeric';
        } else {
            msgType = 'Msg.InvalidNumber';
        }

        // 数値チェック
        if (this.isNumeric(String(chkVal), isDecimal) === false) {
            msg = i18n.t(msgType, {
                args1: itemName,
                args2: msgMin,
                args3: msgMax
            });
        }
        if (msg.length < 1) {
            // 数値変換
            var chgVal = this.changeNumeric(chkVal, isDecimal, numericCnt, decimalCnt);
            var chgMax = this.changeNumeric(max, isDecimal, numericCnt, decimalCnt);
            var chgMin = this.changeNumeric(min, isDecimal, numericCnt, decimalCnt);
            if (chgVal.charAt(0) === 'E') {
                msg = i18n.t(msgType, {
                    args1: itemName,
                    args2: msgMin,
                    args3: msgMax
                });
            }

            // 範囲チェック
            if (chgMin > chgVal || chgVal > chgMax || (chgVal.length > chgMin.length) || (chgVal.length > chgMax.length)) {
                msg = i18n.t(msgType, {
                    args1: itemName,
                    args2: msgMin,
                    args3: msgMax
                });
            }
        }
        return msg;
    }

    //数値チェック(大小チェック)
    //@param min 　      最小値
    //@param max 　      最大値
    //@return　不具合があった場合はエラーメッセージを返します
    rangeRelationNumeric = (chkMin: number, chkMax: number, itemName: any) => {
        var msg = '';
        // 数値変換
        // @ts-ignore
        var chgMax = this.changeNumeric(chkMax);
        // @ts-ignore
        var chgMin = this.changeNumeric(chkMin);
        if (chgMax.charAt(0) === 'E') {
            msg = i18n.t('Msg.InvalidNumberRange', {
                args1: itemName,
                args2: String(chkMin).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                args3: String(chkMax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            });
        }
        if (msg.length < 1) {
            if (chgMin.charAt(0) === 'E') {
                msg = i18n.t('Msg.InvalidNumberRange', {
                    args1: itemName,
                    args2: String(chkMin).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                    args3: String(chkMax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                });
            }
        }
        // 範囲指定条件チェック(最小値 > 最大値)
        if (msg.length < 1) {
            if (chgMin > chgMax) {
                msg = i18n.t('Msg.InvalidNumberRange', {
                    args1: itemName,
                    args2: String(chkMin).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                    args3: String(chkMax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                });
            }
        }
        return msg;
    }

    //数値チェック(下限チェック)
    //@param chkVal 　   検証する文字列
    //@param min 　      最小値
    //@return　不具合があった場合はエラーメッセージを返します
    overNumeric = (chkVal: any, min: any, itemName: any) => {
        var msg = '';
        // 数値変換
        // @ts-ignore
        var chgVal = this.changeNumeric(chkVal);
        // @ts-ignore
        var chgMin = this.changeNumeric(min);
        if (chgVal.charAt(0) === 'E') {
            msg = i18n.t('Msg.InvalidNumberOver', {
                args1: itemName,
                args2: String(min).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            });
        }
        if (msg.length < 1) {
            // 範囲チェック
            if (chgMin > chgVal) {
                msg = i18n.t('Msg.InvalidNumberOver', {
                    args1: itemName,
                    args2: String(min).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                });
            }
        }
        return msg;
    }

    //数値チェック(上限チェック)
    //@param chkVal 　   検証する文字列
    //@param min 　      最大値
    //@return　不具合があった場合はエラーメッセージを返します
    inNumeric = (chkVal: any, max: any, itemName: any) => {
        var msg = '';
        // 数値変換
        // @ts-ignore
        var chgVal = this.changeNumeric(chkVal);
        // @ts-ignore
        var chgMax = this.changeNumeric(max);
        if (chgVal.charAt(0) === 'E') {
            msg = i18n.t('Msg.InvalidNumberIn', {
                args1: itemName,
                args2: String(max).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            });
        }
        if (msg.length < 1) {
            // 範囲チェック
            if (chgMax < chgVal) {
                msg = i18n.t('Msg.InvalidNumberIn', {
                    args1: itemName,
                    args2: String(max).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                });
            }
        }
        return msg;
    }

    //先頭文字が指定の文字から開始されているかチェック
    //@param chkVal 　   　検証する文字列
    //@param targetString  指定文字列
    //@return　true:先頭文字が指定文字から開始されている　false:先頭文字が指定文字から開始されていない
    isTargetStart = (chkVal: any, targetString: string) => {
        //先頭文字がピリオドから開始
        if (String(chkVal).substr(0, 1) === targetString) {
            return true;
        } else {
            return false;
        }
    }

    //最終文字が指定の文字で終了しているかチェック
    //@param chkVal 　   検証する文字列
    //@param targetString  指定文字列
    //@return　true:最終文字がピリオドで終了している　false:最終文字がピリオドで終了していない
    isTargetEnd = (chkVal: string, targetString: any) => {
        //最終文字がピリオドで終わっている
        if (chkVal.substr(String(chkVal).length - 1) === targetString) {
            return true;
        } else {
            return false;
        }
    }

    //頭文字がピリオドから開始されているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    periodStart = (chkVal: any, itemName: any) => {
        var msg = '';
        //頭文字がピリオドから開始されている
        if (this.isTargetStart(String(chkVal), '.')) {
            msg = i18n.t('Msg.InvalidValueNamePeriodStart', {args1: itemName});
        }
        return msg;
    }

    //最終文字がピリオドで終了しているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    periodEnd = (chkVal: any, itemName: any) => {
        var msg = '';
        if (this.isTargetEnd(String(chkVal), '.')) {
            msg = i18n.t('Msg.InvalidValueNamePeriodEnd', {args1: itemName});
        }
        return msg;
    }

    //頭文字がスペースから開始されているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    spaceStart = (chkVal: any, itemName: any) => {
        var msg = '';
        if (this.isTargetStart(String(chkVal), ' ')) {
            msg = i18n.t('Msg.InvalidValueNameSpaceCharacterStart', {args1: i18n.t('Text.Name')});
        }
        return msg;
    }

    //最終文字がスペースで終了しているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    spaceEnd = (chkVal: any, itemName: any) => {
        var msg = '';
        if (this.isTargetEnd(String(chkVal), ' ')) {
            msg = i18n.t('Msg.InvalidValueNameSpaceCharacterEnd', {args1: i18n.t('Text.Name')});
        }
        return msg;
    }

    //最終文字がセパレートで終了しているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    separateEnd = (chkVal: any, itemName: any, isFolder: any) => {
        var msg = '';
        if (this.isTargetEnd(String(chkVal), '/')) {
            if (isFolder) {
                msg = i18n.t('Msg.InvalidValuePathNoFolderName', {args1: itemName});
            } else {
                msg = i18n.t('Msg.InvalidValuePathNoFileName', {args1: itemName});
            }
        }
        return msg;
    }

    //ピリオドが連続しているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    periodRow = (chkVal: any, itemName: any) => {
        var msg = '';
        if (String(chkVal).indexOf('..') > -1) {
            msg = i18n.t('Msg.InvalidValueNamePeriodRow', {args1: itemName});
        }
        return msg;
    }

    //セパレートが連続しているかチェック
    //@param chkVal 　   検証する文字列
    //@return　不具合があった場合はエラーメッセージを返します
    separateRow = (chkVal: any, itemName: any) => {
        var msg = '';
        if (String(chkVal).indexOf('//') > -1) {
            msg = i18n.t('Msg.InvalidSeparator', {args1: itemName});
        }
        return msg;
    }

    //フォルダ名またはファイル名の禁則文字（Unix系OS｛WindowsOS以外｝）の使用判定
    //@param chkVal 　   検証する文字列
    //@param isFileName  ファイル名かフォルダ名の判定（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    folderFileNameUseDeviceOs = (chkVal: any, itemName: any, isFileName?: any) => {
        //必須チェック
        var msg = this.required(String(chkVal), itemName);
        if (String(chkVal).match(/[/]/)) {
            if (isFileName) {
                msg = i18n.t('Msg.InvalidValueFileName', {args1: ' / '});
            } else {
                msg = i18n.t('Msg.InvalidValueFolderName', {args1: ' / '});
            }
        }
        return msg;
    }

    //フォルダ名またはファイル名チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    項目名（メッセージ生成時に利用）
    //@param befNam    　変更前のフォルダ名またはファイル名
    //@return　不具合があった場合はエラーメッセージを返します
    folderFolderFileNameUseDeviceWindowsOs = (chkVal: any, itemName: any, befNam: any) => {
        //必須チェック
        var msg = this.required(String(chkVal), itemName);
        //文字数チェック
        if (msg.length < 1) {
            msg = this.stringCount(String(chkVal), itemName, 250);
        }
        //先頭文字がピリオドから開始
        if (msg.length < 1) {
            msg = this.periodStart(String(chkVal), itemName);
        }
        //禁則文字列チェック
        if (msg.length < 1) {
            msg = this.prohibitString(chkVal, itemName);
        }
        //同一ファイル名チェック
        if (msg.length < 1) {
            if ((String(befNam)).length > 0) {
                if (chkVal === befNam) {
                    msg = i18n.t('Msg.SameNameFailed');
                }
            }
        }
        return msg;
    }

    //WindowsOSファイル名チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    項目名（メッセージ生成時に利用）
    //@param befNam    　変更前のフォルダ名またはファイル名
    //@return　不具合があった場合はエラーメッセージを返します
    fileNameUseDeviceWindowsOs = (chkVal: string, itemName: any, befNam: any) => {
        //必須チェック
        var msg = this.required(String(chkVal), itemName);
        //文字数チェック
        if (msg.length < 1) {
            msg = this.stringCount(String(chkVal), itemName, 250);
        }
        //先頭文字がピリオドから開始
        if (msg.length < 1) {
            msg = this.periodStart(String(chkVal), itemName);
        }
        //禁則文字列チェック
        if (msg.length < 1) {
            msg = this.prohibitString(chkVal, itemName);
        }
        //禁則文字列チェック(フォルダ名・ファイル名デバイス文字チェック)
        if (msg.length < 1) {
            if (chkVal.match(/^(CON|PRN|AUX|NUL|COM[0-9]|LPT[0-9]) *(\.|$)/i)) {
                msg = i18n.t('Msg.InvalidID', {
                    args1: itemName,
                    args2: 'CON PRN AUX NUL COM0-9 LPT0-9'
                });
            }
        }
        //同一ファイル名チェック
        if (msg.length < 1) {
            if ((String(befNam)).length > 0) {
                if (chkVal === befNam) {
                    msg = i18n.t('Msg.SameNameFailed');
                }
            }
        }
        return msg;
    }

    //ファイルパスのバリデーションを行う
    //@param chkVal 　   検証するファイルパス
    //@param itemName    画面上の項目名
    //@param isWindowsOs 検証対象となるサーバーのオペレーティングシステムがWindowsかどうか
    //@param isFolder    フォルダ名のパスチェックの場合のみtrue
    //@return　不具合があった場合はエラーメッセージを返します
    filePath = (chkVal: string, itemName: any, isWindowsOs: boolean, isFolder: any) => {

        //WindowsOS限定：ドライブ指定しているかどうか
        var isDrv = false;
        chkVal = String(chkVal);

        // 必須チェック
        var msg = this.required((String(chkVal) + ''), itemName);

        //WindowsOS
        if (msg.length < 1 && isWindowsOs) {
            if (chkVal.indexOf(':') > -1) {
                var intDrvM = (chkVal.length - chkVal.replace(/:/g, '').length) / ':'.length;
                if (intDrvM !== 1 || chkVal.indexOf(':') !== 1) {
                    //ドライブ指定なしエラー
                    msg = i18n.t('Msg.InvalidValueOutputFileNameDriveLetter', {args1: itemName});
                } else {
                    var strDrv = chkVal.split(':');
                    if (strDrv[0].match(/[^a-zA-Z]/)) {
                        //不正なドライブ指定エラー
                        msg = i18n.t('Msg.InvalidValueOutputFileNameDriveLetter', {args1: itemName});
                    }
                }
                isDrv = true;
            } else {
                //ドライブ指定なしエラー
                msg = i18n.t('Msg.InvalidValueOutputFileNameDriveLetter', {args1: itemName});
            }
            //\マークを/に置き換えてからバリデーション処理に入る
            chkVal = chkVal.replace(/\\/g, '/');
        }

        // ファイルパスチェック
        //セパレートが連続している
        if (msg.length < 1) {
            msg = this.separateRow(String(chkVal), itemName);
        }
        //最後の文字がセパレートで終わっている
        if (msg.length < 1) {
            msg = this.separateEnd(String(chkVal), itemName, isFolder);
        }
        //文字数チェック
        if (msg.length < 1) {
            msg = this.stringCount(String(chkVal), itemName, 256);
        }

        if (msg.length < 1) {
            var wkPath = chkVal.split('/');
            //ファイル名、フォルダ名妥当性チェック
            for (var i = 0; i < wkPath.length; i++) {

                //ドライブ指定がされてる場合、および Unix系OSの場合で/から始まっている場合、チェックは配列1から
                if ((i === 0 && isDrv === true) || (i === 0) && (isWindowsOs === false) && (chkVal.substr(0, 1) === '/')) {
                    if (isDrv) {
                        // ドライブ名 + ":"以外に文字が設定されている場合は不正なドライブ指定エラーとする。
                        if (wkPath[0].length !== 2) {
                            msg = i18n.t('Msg.InvalidValueOutputFileNameDriveLetter', {args1: itemName});
                        }
                    }
                    continue;
                }

                // 空白チェック
                if (msg.length < 1) {
                    msg = this.required(String(wkPath[i]) + '', itemName);
                }
                //先頭文字が空白文字から開始
                if (msg.length < 1) {
                    msg = this.spaceStart(String(wkPath[i]) + '', itemName);
                }
                //最後の文字が空白文字で終わっている
                if (msg.length < 1) {
                    msg = this.spaceEnd(String(wkPath[i]) + '', itemName);
                }

                if (isWindowsOs) {
                    // WINDOWSのファイルチェック
                    //先頭文字がピリオドから開始
                    if (msg.length < 1) {
                        msg = this.periodStart(String(wkPath[i]) + '', itemName);
                    }
                    //ピリオドが連続している
                    if (msg.length < 1) {
                        msg = this.periodRow(String(wkPath[i]), itemName);
                    }
                    //最後の文字がピリオドで終わっている
                    if (msg.length < 1) {
                        msg = this.periodEnd(String(wkPath[i]) + '', itemName);
                    }
                    //禁則文字列チェック
                    if (msg.length < 1) {
                        if (wkPath[i].match(/[|*|?|\"|<|>||]/)) {
                            msg = i18n.t('Msg.InvalidID', {args1: itemName, args2: ' * ? \" < > |  '});
                        }
                    }
                    //禁則文字列チェック(フォルダ名・ファイル名デバイス文字チェック)
                    if (msg.length < 1) {
                        if (wkPath[i].match(/^(CON|PRN|AUX|NUL|COM[0-9]|LPT[0-9]) *(\.|$)/i)) {
                            msg = i18n.t('Msg.InvalidID', {
                                args1: itemName,
                                args2: 'CON PRN AUX NUL COM0-9 LPT0-9'
                            });
                        }
                    }
                } else {
                    if (msg.length < 1) {
                        // WINDOWS以外のOSのファイル名チェック
                        msg = this.folderFileNameUseDeviceOs((String(wkPath[i]) + ''), itemName);
                    }
                }

                if (msg.length > 0) {
                    break;
                }
            }
        }
        return msg;
    }

    //日付チェック
    //@param chkVal 　   検証する文字列
    //@param itemName    項目名（メッセージ生成時に利用）
    //@param dateType    日付型
    //@param dateFormat  フォーマットタイプ
    //@return　不具合があった場合はエラーメッセージを返します
    formatDate = (chkVal: string, itemName: any, dateType: string, dateFormat: string) => {
        var msg = '';

        // 日付を書式にフォーマット
        var chgDate = '';
        var chgDateType = '';
        // AM・PM表記の場合は変換を行う
        if (dateType.substr(dateType.length - 1, 1) === 'a') {
            if (chkVal.substr(chkVal.length - 2) === 'PM') {
                // 午後の場合は１２時間プラス
                chgDate = chkVal.substr(0, 11) + String(parseInt(chkVal.substr(11, 2)) + 12) + chkVal.substr(13, 6);
            } else {
                // 午前の場合はそのまま使用
                chgDate = chkVal.substr(0, 19);
            }
            chgDateType = dateType.substr(0, dateType.length - 2).replace('hh', 'HH');
        } else {
            chgDate = chkVal;
            chgDateType = dateType;
        }

        var vChkDate = true;

        // 年月日チェック、時分秒のlengthチェック（時分秒の値のチェックは以降の処理で実施）
        var dts = [];
        chkVal = chkVal.trim();
        switch (dateFormat) {
            case DATE_TYPE[1]://'yyyyMMddHHmmss':
                if (chkVal.length !== 19) {
                    vChkDate = false;
                } else {
                    dts = chkVal.split(' ');
                    if (dts.length === 2) {
                        vChkDate = this.isYYYYMMSS(dts[0], dateType);
                        if (vChkDate) {
                            vChkDate = this.isHHMMSS(dts[1]);
                        }
                    } else {
                        vChkDate = false;
                    }
                }
                break;
            case DATE_TYPE[2]://'yyyyMMddHHmm':
                if (chkVal.length !== 16) {
                    vChkDate = false;
                } else {
                    dts = chkVal.split(' ');
                    if (dts.length === 2) {
                        vChkDate = this.isYYYYMMSS(dts[0], dateType);
                        if (vChkDate) {
                            vChkDate = this.isHHMM(dts[1]);
                        }
                    } else {
                        vChkDate = false;
                    }
                }
                break;
            case DATE_TYPE[3]://'yyyyMMdd':
                if (chkVal.length !== 10) {
                    vChkDate = false;
                } else {
                    vChkDate = this.isYYYYMMSS(chkVal, dateType);
                }
                break;
            case DATE_TYPE[4]://'MMddHHmm':
                if (chkVal.length !== 11) {
                    vChkDate = false;
                } else {
                    dts = chkVal.split(' ');
                    if (dts.length === 2) {
                        vChkDate = this.isHHMM(dts[1]);
                    } else {
                        vChkDate = false;
                    }
                }
                break;
            case DATE_TYPE[5]://'MMdd':
                if (chkVal.length !== 5) {
                    vChkDate = false;
                }
                break;
            case 'HHmmss':
                vChkDate = this.isHHMMSS(chkVal);
                break;
            case 'HHmm':
                vChkDate = this.isHHMM(chkVal);
                break;
        }

        var vYear = '';
        var vMonth = '';
        var vDay = '';
        var vHour = '';
        var vMinute = '';
        var vSecond = '';

        var formatDate = CustomDateFilter(chgDate, chgDateType, dateFormat);

        // Formatに合わせて日時取得
        if (dateFormat === DATE_TYPE[1]) {//:'yyyyMMddHHmmss'
            vYear = formatDate.substr(0, 4);
            vMonth = formatDate.substr(4, 2);
            vDay = formatDate.substr(6, 2);
            vHour = formatDate.substr(8, 2);
            vMinute = formatDate.substr(10, 2);
            vSecond = formatDate.substr(12, 2);
        } else if (dateFormat === DATE_TYPE[2]) {//:'yyyyMMddHHmm'
            vYear = formatDate.substr(0, 4);
            vMonth = formatDate.substr(4, 2);
            vDay = formatDate.substr(6, 2);
            vHour = formatDate.substr(8, 2);
            vMinute = formatDate.substr(10, 2);
        } else if (dateFormat === DATE_TYPE[3]) {//'yyyyMMdd'
            vYear = formatDate.substr(0, 4);
            vMonth = formatDate.substr(4, 2);
            vDay = formatDate.substr(6, 2);
        } else if (dateFormat === DATE_TYPE[4]) {//'MMddHHmm'
            vMonth = formatDate.substr(0, 2);
            vDay = formatDate.substr(2, 2);
            vHour = formatDate.substr(4, 2);
            vMinute = formatDate.substr(6, 2);
        } else if (dateFormat === DATE_TYPE[5]) {//'MMdd')
            vMonth = formatDate.substr(0, 2);
            vDay = formatDate.substr(2, 2);
        } else if (dateFormat === 'HHmmss') {
            vHour = formatDate.substr(0, 2);
            vMinute = formatDate.substr(2, 2);
            vSecond = formatDate.substr(4, 2);
        } else if (dateFormat === 'HHmm') {
            vHour = formatDate.substr(0, 2);
            vMinute = formatDate.substr(2, 2);
        }

        // 年月日時分秒が数値でなければエラー
        if ((dateFormat.indexOf('yyyy') > -1) && (!this.isNumeric(vYear, false))) {
            vChkDate = false;
        }
        if ((dateFormat.indexOf('MM') > -1) && (!this.isNumeric(vMonth, false))) {
            vChkDate = false;
        }
        if ((dateFormat.indexOf('DD') > -1) && (!this.isNumeric(vDay, false))) {
            vChkDate = false;
        }
        if ((dateFormat.indexOf('HH') > -1) && (!this.isNumeric(vHour, false))) {
            vChkDate = false;
        }
        if ((dateFormat.indexOf('mm') > -1) && (!this.isNumeric(vMinute, false))) {
            vChkDate = false;
        }
        if ((dateFormat.indexOf('ss') > -1) && (!this.isNumeric(vSecond, false))) {
            vChkDate = false;
        }
        if (vChkDate) {
            // 月,日の妥当性チェック
            if (String(vMonth).length > 0 && String(vDay).length > 0) {
                // @ts-ignore
                if (1 <= vMonth && vMonth <= 12 && 1 <= vDay && vDay <= 31) {
                    if (String(vYear).length > 0) {
                        // 年,月,日の妥当性チェック
                        // @ts-ignore
                        var dt = new Date(Number(vYear), vMonth - 1, vDay);
                        // @ts-ignore
                        if (isNaN(dt)) {
                            vChkDate = false;
                        } else {
                            if (dt.getFullYear() !== parseInt(vYear) || dt.getMonth() !== (parseInt(vMonth) - 1) || dt.getDate() !== parseInt(vDay)) {
                                vChkDate = false;
                            }
                        }
                    } else {
                        // 月,日の妥当性チェック
                        if (parseInt(vMonth) === 2) {
                            if (parseInt(vDay) > 29) {
                                vChkDate = false;
                            }
                        } else if (parseInt(vMonth) === 4 || parseInt(vMonth) === 6 || parseInt(vMonth) === 9 || parseInt(vMonth) === 11) {
                            if (parseInt(vDay) > 30) {
                                vChkDate = false;
                            }
                        }
                    }
                } else {
                    vChkDate = false;
                }
            }
            // 時,分,秒の妥当性チェック
            if (String(vHour).length > 0) {
                // @ts-ignore
                if (vHour < 0 || vHour > 23) {
                    vChkDate = false;
                }
            }
            if (String(vMinute).length > 0) {
                // @ts-ignore
                if (vMinute < 0 || vMinute > 59) {
                    vChkDate = false;
                }
            }
            if (String(vSecond).length > 0) {
                // @ts-ignore
                if (vSecond < 0 || vSecond > 59) {
                    vChkDate = false;
                }
            }
        }

        if (vChkDate === false) {
            msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
        }

        return msg;
    }

    //日付範囲チェック
    //@param chkValFrom  検証する文字列(開始日時)
    //@param chkValTo 　 検証する文字列(終了日時)
    //@param itemName    項目名（メッセージ生成時に利用）
    //@param DateType    日付型
    //@param DateFormat  フォーマットタイプ
    //@return　不具合があった場合はエラーメッセージを返します
    rangeDate = (chkValFrom: string, chkValTo: string, itemName: any, dateType: string, dateFormat: any) => {
        var msg = '';

        // 日付を書式にフォーマット
        var chgFromDate = '';
        var chgToDate = '';
        var chgDateType = '';
        // AM・PM表記の場合は変換を行う
        if (dateType.substr(dateType.length - 1, 1) === 'a') {
            // FROM
            if (chkValFrom.substr(chkValFrom.length - 2) === 'PM') {
                // 午後の場合は１２時間プラス
                chgFromDate = chkValFrom.substr(0, 11) + String(parseInt(chkValFrom.substr(11, 2)) + 12) + chkValFrom.substr(13, 6);
            } else {
                // 午前の場合はそのまま使用
                chgFromDate = chkValFrom.substr(0, 19);
            }
            // TO
            if (chkValTo.substr(chkValTo.length - 2) === 'PM') {
                // 午後の場合は１２時間プラス
                chgToDate = chkValTo.substr(0, 11) + String(parseInt(chkValTo.substr(11, 2)) + 12) + chkValTo.substr(13, 6);
            } else {
                // 午前の場合はそのまま使用
                chgToDate = chkValTo.substr(0, 19);
            }
            chgDateType = dateType.substr(0, dateType.length - 2).replace('hh', 'HH');
        } else {
            chgFromDate = chkValFrom;
            chgToDate = chkValTo;
            chgDateType = dateType;
        }
        var fromDate = CustomDateFilter(chgFromDate, chgDateType, dateFormat);
        var toDate = CustomDateFilter(chgToDate, chgDateType, dateFormat);

        if (fromDate > toDate) {
            msg = i18n.t('Msg.InvalidDateRange', {args1: itemName});
        }

        return msg;
    }

    //正規表現を用いた16進6桁チェック
    //@param chkHex  　検証する文字列
    //@param itemName  項目名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    regHexCheck = (chkHex: string, itemName: any) => {
        var msg = '';
        if (chkHex.match(/[^0-9a-fA-F]/g)) {
            msg = i18n.t('Msg.HighlightColorFailed', {args1: itemName});
        }
        if (msg.length === 0) {
            if (chkHex.length !== 6) {
                msg = i18n.t('Msg.HighlightColorFailed', {args1: itemName});
            }
        }
        return msg;
    }

    //フリーワードチェック
    //@param chkVal  　検証する文字列
    //@param itemName  項目名（メッセージ生成時に利用）
    //@param enableSearchMethod 「全角と半角を区別する」のON/OFF（true：全角半角を区別する, false：全角半角を区別しない）
    //@return　不具合があった場合はエラーメッセージを返します
    freeWord = (chkVal: string, itemName: any, enableSearchMethod: any) => {
        var condArray = [];
        var subCondArray = [];
        var type = '';
        var quote = false;
        var paren = 0;
        var prevChar = _CHAR_SPACE;
        var condStr = '';
        var nextCondStr = '';
        var escCondStr: number | string = '';
        var escapeCharasExpr;

        // 文字数チェック（テキスト項目）
        var msg = this.stringCount(chkVal, itemName, 1024);

        // 「全角と半角を区別する」がON/OFFによって、判定する文字を切り替える
        if (msg.length === 0 && enableSearchMethod) {
            escapeCharasExpr = '\\\"()';
        } else {
            escapeCharasExpr = '\\*\"()';
        }

        // 文字チェック
        for (var i = 0; i < chkVal.length; i++) {
            if (msg.length === 0) {
                // 文字切り抜き
                var checkStr = chkVal.charAt(i);
                // 空白
                if (checkStr === ' ' || checkStr === '　') {

                    // 連続する場合はスキップ
                    if (prevChar === _CHAR_SPACE) {
                        continue;
                    }
                    if (quote) {
                        // ダブルクォーテーション内は無視
                        condStr += checkStr;
                    } else {
                        // トークンを取得
                        if (condStr.length > 0) {
                            type = this.getTokenType(condStr) + '';
                            subCondArray = [condStr, type];
                            condArray.push(subCondArray);
                            condStr = '';
                        }
                    }
                    prevChar = _CHAR_SPACE;
                }
                // ダブルクォーテーション
                else if (checkStr === '\"') {
                    // 左ダブルクォーテーション
                    if (!quote) {
                        // トークンを取得
                        if (condStr.length > 0) {
                            type = this.getTokenType(condStr) + '';
                            subCondArray = [condStr, type];
                            condArray.push(subCondArray);
                            condStr = '';
                        }
                        condStr += checkStr;
                        // クォーテーションを開く
                        quote = true;
                        // 直前が空白か左括弧でない場合はエラー
                        if (i - 1 >= 0) {
                            nextCondStr = chkVal.charAt(i - 1);
                            if (!((nextCondStr === ' ' || nextCondStr === '　') || nextCondStr === '(')) {
                                msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Quote')});
                            }
                        }
                    }
                    // 右ダブルクォーテーション
                    else {
                        condStr += checkStr;
                        // クォーテーションを閉じる
                        quote = false;
                        // AND,OR,NOT以外を""で囲った場合はエラーとする
                        if (!this.checkPhrase(condStr)) {
                            msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Quote')});
                        } else {
                            // トークンを取得
                            if (condStr.length > 0) {
                                type = this.getTokenType(condStr) + '';
                                subCondArray = [condStr, type];
                                condArray.push(subCondArray);
                                condStr = '';
                            }
                            // 直後が空白か右括弧でない場合はエラー
                            if (i + 1 < chkVal.length) {
                                nextCondStr = chkVal.charAt(i + 1);
                                if (!((nextCondStr === ' ' || nextCondStr === '　') || nextCondStr === ')')) {
                                    msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Quote')});
                                }
                            }
                        }
                    }
                    prevChar = _CHAR_RQUOTE;
                }
                // 左括弧
                else if (checkStr === '(') {
                    // ダブルクォーテーション内はエラー
                    if (quote) {
                        msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Paren')});
                    }
                    paren++;
                    // トークンを取得
                    if (condStr.length > 0) {
                        type = this.getTokenType(condStr) + '';
                        subCondArray = [condStr, type];
                        condArray.push(subCondArray);
                        condStr = '';
                    }
                    subCondArray = [checkStr, _TYPE_LPAREN];
                    condArray.push(subCondArray);
                    // 直前が空白か左括弧でない場合はエラー
                    if (i - 1 >= 0) {
                        nextCondStr = chkVal.charAt(i - 1);
                        if (!((nextCondStr === ' ' || nextCondStr === '　') || nextCondStr === '(')) {
                            msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Paren')});
                        }
                    }
                    prevChar = _CHAR_LPAREN;
                }
                // 右括弧
                else if (checkStr === ')') {
                    // ダブルクォーテーション内はエラー
                    if (quote) {
                        msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Paren')});
                    }
                    paren--;
                    // トークンを取得
                    if (condStr.length > 0) {
                        type = this.getTokenType(condStr) + '';
                        subCondArray = [condStr, type];
                        condArray.push(subCondArray);
                        condStr = '';
                    }
                    subCondArray = [checkStr, _TYPE_RPAREN];
                    condArray.push(subCondArray);
                    // 直後が空白か右括弧でない場合はエラー
                    if (i + 1 < chkVal.length) {
                        // 直前が空白か左括弧でない場合はエラー
                        if (i + 1 >= 0) {
                            nextCondStr = chkVal.charAt(i + 1);
                            if (!((nextCondStr === ' ' || nextCondStr === '　') || nextCondStr === ')')) {
                                msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Paren')});
                            }
                        }
                    }
                    prevChar = _CHAR_RPAREN;
                }
                // エスケープ
                else if (checkStr === '\\') {
                    escCondStr = (i + 1 === chkVal.length ? -1 : chkVal.charAt(++i));
                    // @ts-ignore
                    if (escapeCharasExpr.indexOf(escCondStr) < 0) {
                        msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.EscapeString')});
                    }
                    // 後で除去するのでそのまま
                    condStr += checkStr;
                    prevChar = _CHAR_CHAR;
                } else {
                    // その他の文字
                    condStr += checkStr;
                    prevChar = _CHAR_CHAR;
                }
            }
        }
        if (msg.length === 0) {
            // トークンを取得
            if (condStr.length > 0) {
                type = this.getTokenType(condStr) + '';
                subCondArray = [condStr, type];
                condArray.push(subCondArray);
                condStr = '';
            }
            // ダブルクォーテーションが閉じてない場合はエラー
            if (quote) {
                msg = i18n.t('Msg.SearchInvalidQuote');
            }
            // 括弧の数が合わない場合はエラー
            if (msg.length === 0) {
                if (paren !== 0) {
                    msg = i18n.t('Msg.SearchInvalidParen');
                }
            }
            // 括弧内をサブリスト化
            if (msg.length === 0) {
                msg = this.sublistTokenList(condArray);
            }
            //// 検索条件個数チェック
            //if (msg.length === 0) {
            //    var condCnt = 0;
            //    for (var j = 0; j < condArray.length; j++) {
            //        if (condArray[j][1] === _TYPE_WORD || condArray[j][1] === _TYPE_LIST) {
            //            condCnt++;
            //        }
            //    }
            //    // 条件個数が10個以上の場合はエラー
            //    if (condCnt > 10) {
            //        msg = i18n.t('Msg.SearchInvalidConditionCount', {args1: itemName});
            //    }
            //}
        }

        return msg;
    }

    //トークン取得
    //@param chkVal  検証する文字列
    //@return　トークンのタイプ
    getTokenType = (chkVal: string) => {
        var type = _TYPE_WORD;
        var upVal = chkVal.toUpperCase();
        if (upVal === 'AND') {
            type = _TYPE_AND;
        } else if (upVal === 'OR') {
            type = _TYPE_OR;
        } else if (upVal === 'NOT') {
            type = _TYPE_NOT;
        }
        return type;
    }

    //ダブルクォートに囲まれている文字がAND,OR,NOTであるかどうかのチェック
    //@param chkVal  検証する文字列
    //@return　トークンのタイプ
    checkPhrase = (chkVal: string) => {
        var valueLen = chkVal.length;
        if (valueLen >= 2 && (chkVal.charAt(0) === '\"' && chkVal.charAt(valueLen - 1) === '\"')) {
            chkVal = chkVal.substring(1, valueLen - 1);
        }
        if (!((this.getTokenType(chkVal) >= _TYPE_AND) && (this.getTokenType(chkVal) <= _TYPE_NOT))) {
            return false;
        }
        return true;
    }

    //括弧内をサブリスト化
    //@param chkVal  検証する文字列
    //@return　トークンのタイプ
    sublistTokenList = (tokenList: any[]) => {
        var msg = '';
        var token = [];
        var subToken = [];
        var subTokenList = [];

        var type = '';
        var subType = '';
        var paren = 0;
        var start = 0;
        for (var i = 0; i < tokenList.length; i++) {
            // トークン取得
            token = tokenList[i];
            // トークンタイプ取得
            type = token[1];
            // 左括弧
            // @ts-ignore
            if (type === _TYPE_LPAREN) {
                // 対応する右括弧までのトークンを取得
                paren = 1;
                start = ++i;
                for (; i < tokenList.length; i++) {
                    subToken = tokenList[i];
                    subType = subToken[1];
                    // 左括弧
                    if (subType === _TYPE_LPAREN) {
                        paren++;
                    }
                    // 右括弧
                    else { // @ts-ignore
                        if (subType === _TYPE_RPAREN) {
                            paren--;
                            if (paren === 0) {
                                // 再帰的にサブリスト化
                                subTokenList = tokenList.slice(start, i);
                                msg = this.sublistTokenList(subTokenList);
                                tokenList.splice(start - 1, ((i + 1) - (start - 1)));
                                //（）の中にトークンがない場合はエラー
                                if (subTokenList.length === 0) {
                                    msg = i18n.t('Msg.FreeWordInvalidCharacter', {args1: i18n.t('Text.Paren')});
                                } else {
                                    token = [subTokenList, _TYPE_LIST];
                                    tokenList.splice(start - 1, 0, token);
                                }
                                i = start - 1;
                                break;
                            }
                        }
                    }
                }
                // 括弧の数が合わない場合はエラー
                if (paren !== 0) {
                    msg = i18n.t('Msg.SearchInvalidParen');
                }
            }
        }
        // オペレータチェック
        var opeToken = [];
        var prevOpeToken = [];
        var tokenIsOperator = false;
        var prevTokenIsOperator = false;
        for (var j = 0; j < tokenList.length; j++) {
            opeToken = tokenList[j];
            tokenIsOperator = (opeToken[1] === _TYPE_AND || opeToken[1] === _TYPE_OR || opeToken[1] === _TYPE_NOT);
            // 先頭でオペレータはエラー
            if (j === 0 && tokenIsOperator) {
                msg = i18n.t('Msg.SearchInvalidOperator');
            }
            // 最後でオペレータはエラー
            if (j + 1 === tokenList.length && tokenIsOperator) {
                msg = i18n.t('Msg.SearchInvalidOperator');
            }
            if (j > 0) {
                prevOpeToken = tokenList[j - 1];
                prevTokenIsOperator = (prevOpeToken[1] === _TYPE_AND || prevOpeToken[1] === _TYPE_OR || prevOpeToken[1] === _TYPE_NOT);
                // オペレータが連続する場合はエラー
                if (prevTokenIsOperator && tokenIsOperator) {
                    msg = i18n.t('Msg.SearchInvalidOperator');
                }
            }
        }

        return msg;
    }
    //URLの形式チェック
    //@param chkVal  　検証する文字列
    //@param itemName  項目名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    urlFormat = (chkVal: string | null, itemName: any) => {
        var msg = '';
        // apiから返却される値でnullの場合が存在するのでnullチェックを行う
        // また空白の時は正規表現のチェックを行わない
        if (chkVal !== null && chkVal !== '') {
            if (!chkVal.match(/^(https?):\/\//)) {
                msg = i18n.t('Msg.UrlFormatFailed', {args1: itemName});
            }
        }
        return msg;
    }

    //接続文字列の形式チェック
    //@param chkVal    検証する文字列
    //@param itemName  項目名（メッセージ生成時に利用）
    //@param dbType    データベース種別
    //@return 不具合があった場合はエラーメッセージを返します
    connectionStringFormat = (chkVal: string | any[] | null, itemName: any, dbType: any) => {
        var msg = '';
        // apiから返却される値でnullの場合が存在するのでnullチェックを行う
        // また空白の時は正規表現のチェックを行わない
        if (chkVal !== null && chkVal !== '') {
            if (dbType === Constants.dbType.POSTGRESQL) {
                if (chkVal.indexOf(Constants.connectionStringPrefix.POSTGRESQL) !== 0) {
                    msg = i18n.t('Msg.PrefixFormatFailed', {
                        args1: itemName,
                        args2: Constants.connectionStringPrefix.POSTGRESQL
                    });
                }
            } else if (dbType === Constants.dbType.ORACLE) {
                if (chkVal.indexOf(Constants.connectionStringPrefix.ORACLE) !== 0) {
                    msg = i18n.t('Msg.PrefixFormatFailed', {
                        args1: itemName,
                        args2: Constants.connectionStringPrefix.ORACLE
                    });
                }
            }
        }
        return msg;
    }

    //サーバー名/IPアドレスの形式チェック
    //@param chkVal    検証する文字列
    //@param itemName  項目名（メッセージ生成時に利用）
    //@param max       最大文字数
    //@return 不具合があった場合はエラーメッセージを返します
    serverIpFormat = (chkVal: string, itemName: any, max: number) => {
        var msg = '';
        // 空白チェック
        if (msg.length < 1) {
            msg = this.required(chkVal, itemName);
        }
        // 文字数チェック
        if (msg.length < 1) {
            msg = this.stringCount(chkVal, itemName, max);
        }
        // 禁則文字列チェック
        if (msg.length < 1) {
            if (chkVal.match(/[^a-z|A-Z|0-9|\-|.]/)) {
                msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
            }
        }
        // 先頭と末尾の文字をピリオドとすることは不可
        if (msg.length < 1) {
            if (this.isTargetStart(String(chkVal), '.') || this.isTargetEnd(String(chkVal), '.')) {
                msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
            }
        }
        // ピリオド、ハイフンが連続している
        if (msg.length < 1) {
            if (String(chkVal).indexOf('..') > -1 || String(chkVal).indexOf('--') > -1) {
                msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
            }
        }
        // ラベルの文字数チェック
        var chkValAry = chkVal.split('.');
        for (var i = 0; i < chkValAry.length; i++) {
            if (this.getStringCount(chkValAry[i]) > 63) {
                msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
            } else if (this.isTargetStart(chkValAry[i], '-')) {
                msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
            } else if (this.isTargetEnd(chkValAry[i], '-')) {
                msg = i18n.t('Msg.InvalidFormat', {args1: itemName});
            }
        }
        return msg;
    }

    //タイムスタンプ（SEIKO形式）のパスワードの形式チェック
    //@param chkVal  　検証する文字列
    //@param max       最大文字数
    //@param itemName  項目名（メッセージ生成時に利用）
    //@return　不具合があった場合はエラーメッセージを返します
    seikoPasswordFormat = (chkVal: any, itemName: any, max: number) => {
        var msg = '';
        // @文字数チェック
        if (this.getStringCount(chkVal) > max || this.getStringCount(chkVal) === 0) {
            msg = i18n.t('Msg.SeikoStringFailed', {args1: itemName, args2: max});
        }

        // @禁則文字チェック
        if (chkVal?.match(/[^a-z|A-Z|0-9|!|#|$|%|&|'|*|+|\-|/|=|?|^|_|`|{|}|||~|@|.]/)) {
            msg = i18n.t('Msg.SeikoStringFailed', {args1: itemName, args2: max});
        }
        return msg;
    }

    //パスワードの形式チェック
    //@param chkVal  　検証する文字列
    //@param max       最大文字数
    //@param itemName  項目名（メッセージ生成時に利用）
    //@param isDisp    項目名を表示するかどうか
    //@return　不具合があった場合はエラーメッセージを返します
    passwordFormat = (chkVal: any, itemName: any, max: string | number, isDisp: any) => {
        var msg = '';
        // @文字数チェック
        if (this.getStringCount(chkVal) > (max as number) || this.getStringCount(chkVal) === 0) {
            if (isDisp) {
                msg = i18n.t('Msg.MailStringFailed', {args1: itemName, args2: max});
            } else {
                msg = i18n.t('Msg.InvalidPassword', {args1: max});
            }
        }

        // @禁則文字チェック
        if (!this.isHalfstringnumericSingleByte(chkVal, max)) {
            if (isDisp) {
                msg = i18n.t('Msg.MailStringFailed', {args1: itemName, args2: max});
            } else {
                msg = i18n.t('Msg.InvalidPassword', {args1: max});
            }
        }
        return msg;
    }

    //メールアドレスの形式チェック
    //@param chkVal  　検証する文字列
    //@param itemName  項目名（メッセージ生成時に利用）
    //@param max       最大文字数
    //@return　不具合があった場合はエラーメッセージを返します
    mailAddressFormat = (chkVal: any, itemName: any, max: number) => {
        var msg = '';
        // @前後チェック
        if (chkVal !== null && chkVal !== '') {
            if (!chkVal.match(/^.+@.+/)) {
                msg = i18n.t('Msg.MailAddressFormatFailed', {args1: itemName});
            }
        }

        // @2個以上使用チェック
        if (chkVal !== null && chkVal !== '' && msg.length === 0) {
            // 正常の@が一つの場合はlengthは2となる
            if (chkVal.split('@').length > 2) {
                msg = i18n.t('Msg.MailAddressFormatFailed', {args1: itemName});
            }
        }

        // @文字数チェック
        if (this.getStringCount(chkVal) > max) {
            msg = i18n.t('Msg.SeikoStringFailed', {args1: itemName, args2: max});
        }

        // @禁則文字チェック
        if (chkVal.match(/[^a-z|A-Z|0-9|!|#|$|%|&|'|*|+|\-|/|=|?|^|_|`|{|}|||~|@|.]/)) {
            msg = i18n.t('Msg.SeikoStringFailed', {args1: itemName, args2: max});
        }
        return msg;
    }

    //ページ指定の値チェック
    //※downloadCtrl.jsにあったチェック処理を共通で仕様するので移行
    //@param maxPageCnt 最大ページ数
    //@param val        検証する値(ページ指定の値)
    //@param params     返却値(参照渡しを利用するのでオブジェクトで渡してsetPrintPagesに設定します)
    //@param itemName   項目名
    //@return　不具合があった場合はエラーメッセージを返します
    checkPageDesignation = (maxPageCnt: number, val: string, params: { setPrintPages: string | number; }, itemName: undefined) => {
        var msg = '';

        //ページ番号が指定されていない場合はエラー
        if (val.length < 1) {
            if (itemName !== undefined) {
                msg = i18n.t('Msg.RequiredField', {args1: itemName});
            } else {
                msg = i18n.t('Msg.InvalidSelectPages');
            }
        }

        //カンマ、ハイフンが連続する場合一つに補正
        if (msg.length < 1) {
            while (val.indexOf('--') !== -1) {
                val = val.replace(/--/g, '-');
            }
            while (val.indexOf(',,') !== -1) {
                val = val.replace(/,,/g, ',');
            }
            if (val === ',') {
                //ページ番号が指定されていない
                if (itemName !== undefined) {
                    msg = i18n.t('Msg.InvalidSelectPagesFailedItem', {args1: itemName});
                } else {
                    msg = i18n.t('Msg.InvalidSelectPagesFailed');
                }
            } else {
                if (val === '-') {
                    //ページ番号が指定されていない
                    if (itemName !== undefined) {
                        msg = i18n.t('Msg.InvalidSelectPagesRangeNoneSpecifiedItem', {args1: itemName});
                    } else {
                        msg = i18n.t('Msg.InvalidSelectPagesRangeNoneSpecified');
                    }
                }
            }
        }

        if (msg.length < 1) {

            //先頭または最後尾にカンマがある場合除去
            while (val.charAt(0) === ',') {
                val = val.trim();
                val = val.substring(1, val.length);
            }

            while (val.charAt(val.length - 1) === ',') {
                val = val.trim();
                val = val.substring(0, val.length - 1);
            }

            //文字列をカンマ区切りで分割し、ハイフンの前後文字列を取得し、カンマ区切りに変換
            var valAry = val.split(',');
            for (var i = 0, len1 = valAry.length; i < len1; i++) {
                if (valAry[i].indexOf('-') !== -1) {
                    //範囲ページ指定の場合
                    var sPage = 0;
                    var ePage = 0;
                    var pageAry = valAry[i].split('-');
                    if (pageAry.length === 0) {
                        //ページ番号が指定されていない
                        if (itemName !== undefined) {
                            msg = i18n.t('Msg.InvalidSelectPagesRangeNoneSpecifiedItem', {args1: itemName});
                        } else {
                            msg = i18n.t('Msg.InvalidSelectPagesRangeNoneSpecified');
                        }
                        break;
                    } else {
                        //----------------------------------------------------------------------------------
                        //開始ページ
                        //----------------------------------------------------------------------------------
                        if (pageAry[0] === '') {
                            //開始ページが省略されている場合は1ページ目を指定
                            sPage = 1;
                        } else {
                            if (this.isRegNumCheck(pageAry[0]) === false) {
                                //数値ではないエラー
                                if (itemName !== undefined) {
                                    msg = i18n.t('Msg.InvalidSelectPagesFailedItem', {args1: itemName});
                                } else {
                                    msg = i18n.t('Msg.InvalidSelectPagesFailed');
                                }
                                break;
                            }
                            sPage = this.numZeroShift(pageAry[0]);
                            if (sPage < 1 || sPage > maxPageCnt) {
                                //開始ページが小さすぎるエラーもしくは大きすぎるエラー
                                if (itemName !== undefined) {
                                    msg = i18n.t('Msg.InvalidSelectPagesRangeNonExistentItem', {args1: itemName});
                                } else {
                                    msg = i18n.t('Msg.InvalidSelectPagesRangeNonExistent');
                                }
                                break;
                            }
                        }
                        //----------------------------------------------------------------------------------
                        //終了ページ
                        //----------------------------------------------------------------------------------
                        if (pageAry.length === 2) {
                            if (pageAry[1] === '') {
                                //終了ページが省略されている場合は最大ページ番号をセット
                                ePage = maxPageCnt;
                            } else {
                                if (this.isRegNumCheck(pageAry[1]) === false) {
                                    //数値ではないエラー
                                    if (itemName !== undefined) {
                                        msg = i18n.t('Msg.InvalidSelectPagesFailedItem', {args1: itemName});
                                    } else {
                                        msg = i18n.t('Msg.InvalidSelectPagesFailed');
                                    }
                                    break;
                                }
                                ePage = this.numZeroShift(pageAry[1]);
                                if (ePage < 1 || ePage > maxPageCnt) {
                                    //終了ページが小さすぎるエラーもしくは大きすぎるエラー
                                    if (itemName !== undefined) {
                                        msg = i18n.t('Msg.InvalidSelectPagesRangeNonExistentItem', {args1: itemName});
                                    } else {
                                        msg = i18n.t('Msg.InvalidSelectPagesRangeNonExistent');
                                    }
                                    break;
                                }
                            }
                        } else {
                            //終了ページが省略されている場合は最大ページ番号をセット
                            ePage = maxPageCnt;
                        }

                        if (params.setPrintPages !== '') {
                            params.setPrintPages = params.setPrintPages + ',';
                        }
                        params.setPrintPages = params.setPrintPages + sPage + '-' + ePage;
                    }
                } else {
                    //単ページ指定の場合
                    if (this.isRegNumCheck(valAry[i]) === false) {
                        //数値ではないエラー
                        if (itemName !== undefined) {
                            msg = i18n.t('Msg.InvalidSelectPagesFailedItem', {args1: itemName});
                        } else {
                            msg = i18n.t('Msg.InvalidSelectPagesFailed');
                        }
                        break;
                    }
                    var page = this.numZeroShift(valAry[i]);
                    if (page < 1 || page > maxPageCnt) {
                        //開始ページが小さすぎるまたは終了ページが大きすぎるエラー
                        if (itemName !== undefined) {
                            msg = i18n.t('Msg.InvalidSelectPagesRangeNonExistentItem', {args1: itemName});
                        } else {
                            msg = i18n.t('Msg.InvalidSelectPagesRangeNonExistent');
                        }
                        break;
                    }

                    if (params.setPrintPages !== '') {
                        params.setPrintPages = params.setPrintPages + ',';
                    }
                    params.setPrintPages = params.setPrintPages + page;

                }
            }
        }

        return msg;
    }

    //年月日チェック
    //@param val 検証する文字列
    //@param dateType 日付書式
    //@return
    isYYYYMMSS = (val: string, dateType: string) => {
        var yyyy = '';
        var MM = '';
        var dd = '';
        var dtchk = null;
        switch (dateType.substr(0, 10)) {
            case 'yyyy/MM/DD'://'yyyy/MM/dd':
                yyyy = val.substr(0, 4);
                MM = val.substr(5, 2);
                dd = val.substr(8, 2);
                if (!this.yearOfSimpleDateFormat(yyyy)) {
                    return false;
                }
                dtchk = new Date(Number(yyyy), Number(MM) - 1, Number(dd));
                // @ts-ignore
                if (isNaN(dtchk)) {
                    return false;
                }
                break;
            case 'MM/DD/yyyy'://'MM/dd/yyyy':
                MM = val.substr(0, 2);
                dd = val.substr(3, 2);
                yyyy = val.substr(6, 4);
                if (!this.yearOfSimpleDateFormat(yyyy)) {
                    return false;
                }
                dtchk = new Date(Number(yyyy), Number(MM) - 1, Number(dd));
                // @ts-ignore
                if (isNaN(dtchk)) {
                    return false;
                }
                break;
            case 'DD/MM/yyyy'://'dd/MM/yyyy':
                dd = val.substr(0, 2);
                MM = val.substr(3, 2);
                yyyy = val.substr(6, 4);
                if (!this.yearOfSimpleDateFormat(yyyy)) {
                    return false;
                }
                dtchk = new Date(Number(yyyy), Number(MM) - 1, Number(dd));
                // @ts-ignore
                if (isNaN(dtchk)) {
                    return false;
                }
                break;
            default:
                return false;
        }
        return true;
    }

    //時分秒チェック
    //@param val 検証する文字列
    //@return
    isHHMMSS = (val: string) => {
        if (!val.match(/^\d{2}\:\d{2}:\d{2}$/)) {
            return false;
        }
        return true;
    }

    //時分チェック
    //@param val 検証する文字列
    //@return
    isHHMM = (val: string) => {
        if (!val.match(/^\d{2}\:\d{2}$/)) {
            return false;
        }
        return true;
    }

    //西暦の範囲チェック(100年～9100年)
    //@param val 検証する文字列（西暦）
    //@return
    yearOfSimpleDateFormat = (val: any) => {
        var yychk = val - 0;
        if (isNaN(yychk)) {
            return false;
        } else {
            // 100から9100範囲チェック
            if (yychk < 100 || 9900 < yychk) {
                return false;
            }
        }
        return true;
    }
    //外部リクエスト送信パラメーターキーチェック
    //@param chkVal 　   検証する文字列
    //@param itemName    ファイル名（メッセージ生成時に利用）
    //@param max         最大文字数
    //@return　不具合があった場合はエラーメッセージを返します
    checkWebhookParamKey = (chkVal: any, itemName: any, max: number) => {
        var msg = '';
        // @文字数チェック
        if (this.getStringCount(chkVal) > max || this.getStringCount(chkVal) === 0) {
            msg = i18n.t('Msg.WebhookParamKeyFailed', {args1: itemName, args2: max});
        }
        // @禁則文字チェック
        if (chkVal.match(/[^a-z|A-Z|0-9|\-|_]/)) {
            msg = i18n.t('Msg.WebhookParamKeyFailed', {args1: itemName, args2: max});
        }
        return msg;
    }

    //重複チェック
    //@param chkVal 　    検証する文字列
    //@param compareVal   比較する文字列
    //@param itemName     アイテム名
    //@return　不具合があった場合はエラーメッセージを返します
    checkDuplicate = (chkVal: any, compareVal: any, itemName: any) => {
        var msg = '';
        if (chkVal === compareVal) {
            msg = i18n.t('Msg.InvalidDuplicate', {args1: itemName});
        }
        return msg;
    }

}


export default new ValidatorService();
