import React, { useEffect } from 'react';
import '../App.css';
import UserContainer from '../container/UserContainer';
import DocContainer from "../container/DocContainer";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import PersonOutline from '@material-ui/icons/PersonOutline';
import DocList from './DocList';
import SearchBar from "./SearchBar";
import { useTranslation } from "react-i18next";
import Preview from "./Preview";
import TantouDialog from "./dialog/TantouDialog";
import { AleartDialog } from "./dialog/AlertDialog";
import { MSG_PIPE_STR } from "../constants/Constants";
import * as WebConstants from "../constants/WebConstants";

import {
    Backdrop,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    FormGroup,
    Grow,
    MenuItem,
    MenuList,
    Popper
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import SearchExecDialog from "./dialog/SearchExecDialog";
import CommonContainer from '../container/CommonContainer';
import DocMappingManage from './DocMappingManage';
import ManagePage from './ManagePage';
import SendTable from './SendTable';
import ErrorContainer from '../container/ErrorContainer';
import ServiceContainer from '../container/AxiosServiceContainer';
import ReceiveDocMappingManage from './ReceiveDocMappingManage';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 10,
    },
    toolbar: {
        paddingRight: 12, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: "#4c4a4a",
        backgroundColor: "#b3cbee"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 0,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


const drawerWidth = 240;

const MainPage = () => {
    const userState = UserContainer.useContainer();
    const docState = DocContainer.useContainer();
    const errorState = ErrorContainer.useContainer();
    const commonState = CommonContainer.useContainer();
    const { t, i18n } = useTranslation();
    const [tantouDlgOpen, setTantouDlgOpen] = React.useState(false);
    const [alertDlgOpen, setAlertDlgOpen] = React.useState(false);
    const [rightMenuOpen, setRightMenuOpen] = React.useState(false);
    const [execDlgOpen, setExecDlgOpen] = React.useState(false);
    const [page, setPage] = React.useState<string>(localStorage.getItem("page") ?? "mainPage");
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const anchorRef = React.useRef(null);
    const { resetAuthToken } = ServiceContainer.useContainer()

    useEffect(() => {
        console.log('==== init ====')
        userState.currentAuthorities();
        if (page === "mainPage") {
            docState.loadList();
        }
    }, [])

    useEffect(() => {
        console.log('==== error Dialog ====')
        if (errorState.error != null) {
            handleAlertOpen();
        } else {
            setAlertDlgOpen(false);
        }
    }, [errorState.error])

    useEffect(() => {
        console.log('==== exec Dialog ====')
        if (docState.selectCondition != null) {
            handleExecOpen();
        }
    }, [docState.selectCondition])

    /**
     * 担当者ダイアログの表示
     */
    const handleTantouOpen = () => {
        setTantouDlgOpen(true);
    };
    /**
     * 担当者ダイアログを閉じる
     */
    const handleTantouClose = () => {
        setTantouDlgOpen(false);
    };
    /**
     * 実行時指定ダイアログの表示
     */
    const handleExecOpen = () => {
        setExecDlgOpen(true);
    };
    /**
     * 実行時指定ダイアログを閉じる
     */
    const handleExecClose = () => {
        setExecDlgOpen(false);
    };

    /**
     * エラーダイアログを表示
     */
    const handleAlertOpen = () => {
        if (docState && errorState.error && errorState.error.msg.length === 0) {
            errorState.error.msg = t('Msg.Error');
        }
        setAlertDlgOpen(true);
    };
    /**
     * エラーダイアログを閉じる
     */
    const handleAlertClose = () => {
        if (errorState.error) {
            if (errorState.error.code === '401' || errorState.error.code === '-20013' || errorState.error.code === '403') {
                //リセット
                resetPage();
            }
            setAlertDlgOpen(false);
            errorState.setError(null);
        }
    }
    /**
     * ログアウト
     */
    const signOut = () => {
        userState.signOut();
        //リセット
        resetPage();
    }
    /**
     * ページのリセット
     */
    const resetPage = () => {
        //ログイン画面に戻す
        userState.setUser(null);
        //選択情報のリセット
        docState.resetUI();
        // リセットトークン
        resetAuthToken()
    }
    /**
     * ツールバー右上のメニューを閉じる
     * @param event
     */
    const handleRightMenuClose = (event: { target: any; }) => {
        // @ts-ignore
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setRightMenuOpen(false);
    };

    /**
     * タブキーで右上のメニューを表示
     * @param event
     */
    const handleListKeyDown = (event: { key: string; preventDefault: () => void; }) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setRightMenuOpen(false);
        }
    }

    /**
     * ツールバー右上のメニューを開閉
     */
    const handleRightMenuToggle = () => {
        setRightMenuOpen((prevOpen) => !prevOpen);
    };

    /**
     * エラーメッセージの文言作成
     */
    const getAlertMsg = () => {
        let ret = '';
        if (errorState.error) {
            ret = errorState.error.msg;
            if (errorState.error.code.length > 0) {
                ret = errorState.error.code + MSG_PIPE_STR + ret;
            }
        }
        return ret;
    }

    function swithPage() {
        localStorage.setItem("page", page)
        switch (page) {
            case 'mainPage':
                return (
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <SearchBar />
                        <Container maxWidth="xl" className={classes.container}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <DocList />
                                </Grid>
                            </Grid>
                            {!docState.previewOpen && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Preview />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )}
                        </Container>
                    </main>
                )
            case 'automaticDelivery':
                return (
                    <SendTable />
                )
            case 'resumeManagement':
                return (
                    <ManagePage />
                )
            case 'docMap':
                return (
                    <DocMappingManage />
                )
            case 'receiptDocMap':
                return (
                    <ReceiveDocMappingManage />
                )
        }
    }

    const switchFlag = (pageName: string) => {
        if (pageName === "mainPage") {
            docState.loadList();
        }
        setPage(pageName);
        setRightMenuOpen(false);
    }

    return <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar)}>
            <Toolbar className={classes.toolbar}>
                {/*製品名*/}
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    <span style={{ cursor: 'pointer' }} onClick={() => switchFlag("mainPage")}>{t('Text.ApplicationName')}</span>
                </Typography>
                {page === "mainPage" ?
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={commonState.previewFullScreen}
                                onChange={(e, checked) => commonState.setPreviewFullScreen(checked)}
                                name="checkedA" />
                            }
                            label="フルスクリーンでプレビューする"
                        />
                    </FormGroup>
                    :
                    null
                }

                <div className={classes.grow} />
                {/*担当者設定*/}
                <IconButton color="inherit" onClick={handleTantouOpen}>
                    <Badge badgeContent="!" color="secondary" invisible={userState.tantou.length > 0}>
                        <PersonOutline />
                    </Badge>
                </IconButton>
                {/*担当者名*/}
                <Typography variant={'body2'} color="inherit" noWrap className={classes.title}>
                    {userState.tantou}
                </Typography>
                {/*ログインユーザー名*/}
                <Typography variant={'body2'} color="inherit" noWrap >
                    {userState.user !== null ? userState.user.displayName : ''}
                </Typography>

                {/*右サイドメニュー　プルダウン*/}
                <IconButton
                    ref={anchorRef}
                    aria-controls={rightMenuOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleRightMenuToggle}
                >
                    <MoreVert />
                </IconButton>
                <Popper open={rightMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleRightMenuClose}>
                                    <MenuList autoFocusItem={rightMenuOpen} id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}>
                                        {/*SPAリンク*/}
                                        <MenuItem onClick={handleRightMenuClose}> <Link href={WebConstants.SPA_PATH(userState.subdomain)} target={'spaTab'}
                                            className={classes.title}>
                                            {t('Text.SPA')}
                                        </Link></MenuItem>
                                        <MenuItem onClick={() => switchFlag("automaticDelivery")}>{t('Text.SendHistory')}
                                        </MenuItem>
                                        {
                                            (userState.userAuthorities?.includes(28) &&
                                                userState.userAuthorities?.includes(35) &&
                                                userState.userAuthorities?.includes(51)) ?
                                                <div>
                                                    <MenuItem onClick={() => switchFlag("resumeManagement")}>{t('Text.InformationManagement')}</MenuItem>
                                                    <MenuItem onClick={() => switchFlag("docMap")}>{t('Text.DeliveryDocumentMappingName')}</MenuItem>
                                                    <MenuItem onClick={() => switchFlag("receiptDocMap")}>{t('Text.ReceiptDocumentMappingName')}</MenuItem>
                                                </div>
                                                : null
                                        }
                                        {/*ログアウト*/}
                                        <MenuItem onClick={() => signOut()}>{t('Text.Logout')}</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>

        {swithPage()}

        {/*担当者ダイアログ*/}
        <TantouDialog id='tantouDialog'
            title={t('Text.TantouSet')}
            isOpen={tantouDlgOpen}
            content={""}
            ButtonText={t('Text.Ok')}
            doSubmit={() => {
            }}
            doClose={() => handleTantouClose()}
        />
        {/*実行時指定ダイアログ*/}
        <SearchExecDialog id='searchExecDialog'
            title={t('Text.ExecutionTimeSpecified')}
            isOpen={execDlgOpen}
            content={""}
            ButtonText={t('Text.Ok')}
            doSubmit={() => handleExecClose()}
            doClose={() => handleExecClose()}
        />
        {/*エラーダイアログ*/}
        <AleartDialog key='errorDialog'
            title={t('Text.Error')}
            isOpen={alertDlgOpen}
            content={getAlertMsg()}
            doClose={() => handleAlertClose()}
        />

        {/*待機画面*/}
        <Backdrop style={{ color: "#fff", zIndex: 35001 }} open={docState.previewBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>;
}
export default MainPage;
