import {Fields} from "./Fields";
import {Records} from "./Records";

export class PageFieldList {

    private _pageNum: number | undefined;
    private _form: string | undefined;
    private _dpi: number | undefined;
    private _scale: number | undefined;
    private _timezoneFields: string[] | undefined;
    private _values: { fieldList: [{ id: number; fields: Fields[] }]; recordList: [{ name: string; records: Records[] }] } | undefined
    private _origin: any | undefined;

    constructor(init?: Partial<PageFieldList>) {
        if (init) {
            this._origin = JSON.parse(JSON.stringify(init));
            this.pageNum = init.pageNum
            this.timezoneFields = init.timezoneFields;
            this.scale = init.scale;
            this.dpi = init.dpi
            this.form = init.form;
            let fieldsList: any[] = new Array();
            if (init.values?.fieldList) {
                var fieldL: { id: number; fields: Fields[] };
                for (fieldL of init.values?.fieldList) {
                    const ret: Fields[] = this.createFieldList(fieldL.fields);
                    fieldsList.push({id: fieldL.id, fields: ret});
                }
            }
            let recordList: any[] = new Array();
            let res: any[] = new Array();
            if (init.values?.recordList) {
                var recordL: { name: string; records: Records[] };
                for (recordL of init.values?.recordList) {
                    recordList.push(this.createRecordList(recordL));
                    let tempMeisai: any = {name: recordL.name, records: recordList};
                    res.push(tempMeisai);
                }
            }
            //値をセット
            this.values = {fieldList: fieldsList, recordList: res};
        }
    }


    get pageNum(): number | undefined {
        return <number>this._pageNum;
    }

    get timezoneFields(): string[] | undefined {
        return this._timezoneFields
    }

    get values(): { fieldList: any, recordList: any } | undefined {
        return this._values;
    }

    get scale(): number | undefined {
        return this._scale;
    }

    set scale(value: number | undefined) {
        this._scale = value;
    }

    get dpi(): number | undefined {
        return this._dpi;
    }

    set dpi(value: number | undefined) {
        this._dpi = value;
    }

    get form(): string | undefined {
        return this._form;
    }

    set form(value: string | undefined) {
        this._form = value;
    }

    set pageNum(value: number | undefined) {
        this._pageNum = value;
    }

    set timezoneFields(value: string[] | undefined) {
        this._timezoneFields = value;
    }

    set values(value: { fieldList: any, recordList: any } | undefined) {
        this._values = value;
    }

    /**
     * 表示用SVF検索フィールドリストを生成します。
     */
    createFieldList = (fieldList: Fields[]) => {
        let fieldFields: any[] = new Array();
        var field: Fields;
        for (field of fieldList) {
            if (field.updated) {
                field.editValue = field.value
            }
            fieldFields.push(new Fields(field));
        }
        return fieldFields;
    }
    /**
     * 明細データから、表示様SVF検索フィールドリストを生成します。
     */
    createRecordList = (meisaiSet: any) => {
        var datas: any[] = [];
        //明細行セットから明細行を抽出
        for (var meisai of meisaiSet.records) {
            //明細行からデータを抽出
            let temp: any = new Records();
            temp.x = meisai.x;
            temp.y = meisai.y;
            temp.row = meisai.row;
            temp.width = meisai.width;
            temp.height = meisai.height;
            temp.fieldList = this.createFieldList(meisai.fieldList);
            //表示様SVF検索フィールドリストを生成
            datas.push(temp);
        }
        return datas;
    }

    /**
     * 値更新したJSONを取得する
     */
    getEditList(isCheck?: { checked: boolean }) {
        // 単フィールド
        this._fieldListUpdate(this._origin.values.fieldList, this.values, isCheck);
        // レコード
        var recordName = '';
        for (var list of this._origin.values.recordList) {
            recordName = list.name;
            const recordList = this.values?.recordList.find((el: { name: string; }) => el.name === recordName);
            for (var origin_record of list.records) {
                for (let records of recordList.records) {
                    const record = records.find((el: Records) => el.row === origin_record.row);
                    this._fieldListUpdate(origin_record.fieldList, record, isCheck);
                }
            }
        }
        return this._origin;
    }

    /**
     * 値をオリジナルのJSONに反映させる
     * @param originFieldList
     * @param updateFieldList
     */
    _fieldListUpdate = (originFieldList: any, record: any, isCheck?: { checked: boolean }) => {
        let updateFieldList = record.fieldList;
        //該当するページのフィールドリスト内から一致する項目を見つけてValueの値を更新する
        for (var j = 0; j < originFieldList.length; j++) {
            for (var k = 0; k < originFieldList[j].fields.length; k++) {
                var originField = originFieldList[j].fields[k];
                for (var m = 0; m < updateFieldList.length; m++) {
                    var fid = updateFieldList[m].id;
                    for(  var editField of updateFieldList[m].fields) {
                        //確認済み更新（全フィールド）
                        if (isCheck) {
                            originField.checked = isCheck.checked;
                        }
                        //値の更新(変更対象のみ)
                        if (
                            fid === originFieldList[j].id &&
                            editField.x === originField.x &&
                            editField.y === originField.y &&
                            editField.width === originField.width &&
                            editField.height === originField.height) {
                            //値更新
                            if (editField.editValue) {
                                if (originField.value !== editField.editValue) {
                                    originField.value = editField.editValue;
                                    console.log('Updated ID:  ' + originFieldList[j].id);
                                    originField.checked = editField.isEditDataCheck;
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }
    }


}

