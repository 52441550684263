import { Switch, SwitchClassKey, SwitchProps, Theme, createStyles, withStyles } from "@material-ui/core";
import { createMuiTheme, makeStyles, } from '@material-ui/core/styles';

interface Props extends SwitchProps {
    classes: Styles;
}
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

export const table = () => createMuiTheme({
    typography: {
        body2: {
            fontSize: "0.875rem",
        },
    },
    overrides: {
        MuiTableCell: {
            head: {
                height: "69px", padding: "0px", margin: "0px"
            },
        },
        MuiTableRow: {
            root: {
                height: "20px", padding: "0px", margin: "0px", whiteSpace: "nowrap"
            }
        },
        MuiTableFooter: {
            root: {
                '& .MuiToolbar-root': {
                    backgroundColor: 'white',
                },
            },
        }
    }
});

export const dialog = () => createMuiTheme({
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth: '680px'
            }
        }
    }
})

export const listItem = () => createMuiTheme({
    overrides: {
        MuiListItem: {
            root: {
                '&.Mui-selected': {
                    backgroundColor: '#e2eef4 !important'
                },
                '&.MuiListItem-button:hover': {
                    backgroundColor: '#e2eef4'
                }
            },
        }
    }
})

export const IOSSwitch = withStyles((theme) =>
    createStyles({
        root: {
            width: 50,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(25px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#52d869',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 440,
        flexGrow: 1,
        minWidth: 400,
    },
    title: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        width: 200,
    },
    tabTitle: {
        ...theme.typography.button,
        textAlign: 'left',
        fontWeight: 800,
        marginLeft: 0,
        backgroundColor: '#e9eef0',
        padding: theme.spacing(1),
    },
    addButtonActived: {
        color: '#87d068',
        fontSize: 23
    },
    addButtonDisabled: {
        color: 'disable',
        fontSize: 23
    },
    editButtonActived: {
        color: '#faad14',
        fontSize: 23
    },
    editButtonDisabled: {
        color: 'disable',
        fontSize: 23
    },
    deleteButtonActived: {
        color: '#FF0000'
    },
    deleteButtonDisabled: {
        color: 'disable'
    },
    iconStyle: {
        textAlign: 'left',
        paddingLeft: '10px',
        backgroundColor: '#e9eef0'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: '#ffb74d'
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        margin: "3px 0px 0px 5px"
    },
    saveButtonActived: {
        color: '#1181d7',
        fontSize: 27
    },
    saveButtonDisabled: {
        color: 'disable',
        fontSize: 27
    },
    docDefList: {
        borderLeft: 'solid 1px #d4d4d4',
        paddingBottom: '0',
        height: 'auto',
        overflowY: 'auto',
        textWrap: 'nowrap',
        display: 'inline-block',
        paddingTop: '0'
    },
    lastDocDefList: {
        borderLeft: 'solid 1px #d4d4d4',
        borderRight: 'solid 1px #d4d4d4',
        paddingBottom: '0',
        height: 'auto',
        overflowY: 'auto',
        textWrap: 'nowrap',
        display: 'inline-block',
        paddingTop: '0'
    },
    docDefHeader: {
        minWidth: '200px',
        backgroundColor: '#eaeaea',
        color: '#333333',
        fontWeight: 'bold',
        height: '30px',
        lineHeight: '2.3em',
        overflow: 'hidden',
        borderTop: 'solid 1px #d4d4d4',
        borderBottom: 'solid 1px #d4d4d4'
    },
    docDefItem: {
        minWidth: '200px',
        '&:nth-child(odd)': {
            backgroundColor: '#f3f3f3',
        },
        '&:nth-child(even)': {
            backgroundColor: '#fdfdfd',
        }
    }
}))

export const textFieldStyle = () => createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {
                '& .MuiOutlinedInput-inputMarginDense': {
                    paddingTop: '5.5px',
                    paddingBottom: '5.5px'
                },
                '& .MuiInputBase-input.Mui-disabled': {
                    cursor: 'not-allowed',
                    backgroundColor: '#eee'
                }
            }
        }
    }
});

