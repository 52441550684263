import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { IOSSwitch } from "../manage/css/Style";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import { ShippingData } from "../../models/ShippingData";
import { GetTimeZoneDate, TransformDateToNumber } from "../../common/TransFormTimeZone";
import UserContainer from "../../container/UserContainer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StyledDatePicker, parseDateToMoment } from "../datepicker/StyledDatePicker";
import moment, { Moment } from "moment";
import { DEFAULT_TIMEZONE } from "../../constants/Constants";

type Props = {
    title: string;
    isOpen: boolean;
    data: ShippingData;
    estiDateDttmDateChoose: boolean;
    vouDateIssDttmDateChoose: boolean;
    docTypeKey: string;
    doClose: () => void;
    getDocDefList: () => void;
    doClick: (data: any) => void;
};

// 最大フィールド長
const MAX_LENGTHS = {
    shipToPrivateCdId: 13,
    cnsgPrivateCdId: 13,
    shipFromPrivateCdId: 13,
    trspCliPrivateCdId: 13,
    cnnPrivateCdId: 13,
    delSlipNumId: 13
};

const getStatusMap = {
    "未取得のみ": "0",
    "取得済のみ": "1",
    "すべて取得": "2",
}

export const DeliveryDialog: React.FC<Props> = ({ title, isOpen, data, doClose, getDocDefList, doClick, estiDateDttmDateChoose, vouDateIssDttmDateChoose, docTypeKey }) => {
    const userState = UserContainer.useContainer();

    const [open, setOpen] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<ShippingData>({});
    const [formEstiDateDttmDateChoose, setFormEstiDateDttmDateChoose] = React.useState<boolean>(false);
    const [formVouDateIssDttmDateChoose, setFormVouDateIssDttmDateChoose] = React.useState<boolean>(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setOpen(isOpen);
        setFormEstiDateDttmDateChoose(estiDateDttmDateChoose);
        setFormVouDateIssDttmDateChoose(vouDateIssDttmDateChoose);
        setFormData(data);
    }, [isOpen])

    useEffect(() => {
        setFormData({ ...formData, docTypeKey: docTypeKey });
    }, [docTypeKey])

    /**
     * 入力ボックスのデータ変更に対応
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any }) => {
        const { target: { value, name } } = e;
        if (name === "shipToPrivateCdId" && value[0] === " ") {
            return;
        }
        const maxLength = MAX_LENGTHS[name as keyof typeof MAX_LENGTHS];
        let newValue = value.length > maxLength ? value.substring(0, maxLength) : value;
        setFormData({ ...formData, [name]: newValue });
    }

    /**
     * 日付から相対日付への変換を指定します
     * @param event 
     */
    const handleDateChooseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked, name } } = event;
        if (name === "checkEstiDateDttmDate") {
            setFormEstiDateDttmDateChoose(checked);
            if (checked) {
                let relativeDate = Number.parseInt(formData.estiDelDateDttmRelative ?? "")
                let absoluteDate = formData.estiDelDateDttm
                if (relativeDate === null || Number.isNaN(relativeDate)) {
                    absoluteDate = ""
                } else {
                    absoluteDate = GetTimeZoneDate(new Date(), DEFAULT_TIMEZONE, relativeDate);
                }
                setFormData({ ...formData, estiDelDateDttm: absoluteDate, estiDelDateDttmRelative: "" })
            } else {
                let absoluteDate = formData.estiDelDateDttm
                let relativeDate = ""
                if (!absoluteDate) {
                    relativeDate = ""
                } else {
                    relativeDate = TransformDateToNumber(absoluteDate);
                }
                setFormData({ ...formData, estiDelDateDttm: "", estiDelDateDttmRelative: relativeDate })
            }
        } else if (name === "checkVouDateIssDttmDate") {
            setFormVouDateIssDttmDateChoose(checked);
            if (checked) {
                let relativeDate = Number.parseInt(formData.vouDateIssDttmRelative ?? "")
                let absoluteDate = formData.vouDateIssDttm
                if (relativeDate === null || Number.isNaN(relativeDate)) {
                    absoluteDate = ""
                } else {
                    absoluteDate = GetTimeZoneDate(new Date(), DEFAULT_TIMEZONE, relativeDate);
                }
                setFormData({ ...formData, vouDateIssDttm: absoluteDate, vouDateIssDttmRelative: "" })
            } else {
                let absoluteDate = formData.vouDateIssDttm
                let relativeDate = ""
                if (!absoluteDate) {
                    relativeDate = ""
                } else {
                    relativeDate = TransformDateToNumber(absoluteDate);
                }
                setFormData({ ...formData, vouDateIssDttm: "", vouDateIssDttmRelative: relativeDate })
            }
        }
    }

    /**
     * ラジオボックスのステータスを変更する
     */
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { name } } = event;
        setFormData({ ...formData, getStatus: getStatusMap[name as keyof typeof getStatusMap] });
    }

    /**
     * コントロールスイッチ
     * @param event
     */
    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, shippingFetchFlag: event.target.checked === true ? "1" : "0" });
    }

    /**
     * 日本のタイムゾーン時間への変換とフォーマット
     * @param date 日付型の変数
     * @param timeZone タイムゾーン
     * @param changeDayNum 変更日数
     * @returns フォーマットされた文字列
     */
    const numberToTimeZoneDate = (date: Date, timeZone: string, changeDayNum: number, isEstiDateDttm: boolean): string => {
        if (date === null || Number.isNaN(changeDayNum)) {
            return "";
        }
        const datePartFormat = GetTimeZoneDate(date, timeZone, changeDayNum);
        if (isEstiDateDttm) {
            setFormData({ ...formData, estiDelDateDttm: datePartFormat, estiDelDateDttmRelative: "" });
        } else {
            setFormData({ ...formData, vouDateIssDttm: datePartFormat, vouDateIssDttmRelative: "" });
        }

        return datePartFormat;
    }

    /**
     * 日付型データの差分を数値型に変換
     * @param date 変換が行われる時間
     * @returns 時差
     */
    const dateToNumber = (date: string | undefined, isEstiDateDttm: boolean): string => {
        if (date == undefined || date === "") {
            return "";
        }
        const day = TransformDateToNumber(date);
        if (isEstiDateDttm) {
            setFormData({ ...formData, estiDelDateDttmRelative: day, estiDelDateDttm: "" });
        } else {
            setFormData({ ...formData, vouDateIssDttmRelative: day, vouDateIssDttm: "" });
        }

        return day;
    }

    const handleClick = () => {
        const data = { tenantId: userState.tenantId, ...formData }
        doClick(data);
    }

    /**
     * 新規ダイアログの確認ボタンのdisable状態を制御する
     * @returns コンポーネントのdisable状態を返す
     */
    const disableButton = () => {
        if ((formData.estiDelDateDttm || formData.estiDelDateDttmRelative) && formData.shipToPrivateCdId && formData.docTypeKey) {
            return false;
        }
        return true;
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{title}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* 納入予定日 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '125px' }}>
                            <span style={{ color: 'red', lineHeight: '10px' }}>*</span>
                            {formEstiDateDttmDateChoose ? t('Text.SubmitDatetime') : t('Text.DelDateDttmRelative')}
                        </Typography>
                        <div style={{ marginLeft: '120px', display: 'flex', alignItems: 'center' }}>
                            {
                                formEstiDateDttmDateChoose ?
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <StyledDatePicker
                                                name="estiDelDateDttm"
                                                value={formData.estiDelDateDttm ? moment(formData.estiDelDateDttm) : null}
                                                sx={{ width: '170px' }}
                                                format={"YYYY-MM-DD"}
                                                onChange={(valueNew) => {
                                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                                        handleTextFieldOnChange({ target: { value: "", name: "estiDelDateDttm" } })
                                                    } else {
                                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DD"), name: "estiDelDateDttm" } })
                                                    }
                                                }} />
                                        </LocalizationProvider>
                                    </> :
                                    <>
                                        <TextField
                                            name="estiDelDateDttmRelative"
                                            type="number"
                                            size="small"
                                            style={{ width: '150px' }}
                                            value={formData.estiDelDateDttmRelative}
                                            onChange={handleTextFieldOnChange}
                                            variant="outlined"
                                        />
                                        <span style={{ marginLeft: '10px' }}>
                                            {formData.estiDelDateDttmRelative?.length === 0 ? "" : (parseInt(formData.estiDelDateDttmRelative!) === 0 ? i18n.t('Text.TheDay') :
                                                (parseInt(formData.estiDelDateDttmRelative!) > 0 ? i18n.t('Text.DaysAfter') : i18n.t('Text.DaysBefore')))}
                                        </span>
                                    </>
                            }

                        </div>
                        <div style={{ marginLeft: '30px' }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkEstiDateDttmDate"
                                            color="primary"
                                            checked={formEstiDateDttmDateChoose}
                                            onChange={handleDateChooseChange} />
                                    }
                                    label={i18n.t('Text.SpecifiedDate')}
                                />
                            </FormGroup>
                        </div>
                    </div> <br />
                    {/* 荷届先コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>
                            <span style={{ color: 'red' }}>*</span>
                            {t('Text.ShipToPrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name='shipToPrivateCdId'
                            size="small"
                            style={{ marginLeft: '95px', width: '385px' }}
                            value={formData.shipToPrivateCdId}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 荷送人コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>
                            {t('Text.CnsgPrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name='cnsgPrivateCdId'
                            size="small"
                            style={{ marginLeft: '95px', width: '385px' }}
                            value={formData.cnsgPrivateCdId}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 出荷場所コード(ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>
                            {t('Text.ShipFromPrivateCdId')}<br />
                            ({t('Text.StorageTypeLocal')})
                        </Typography>
                        <TextField
                            name='shipFromPrivateCdId'
                            size="small"
                            style={{ marginLeft: '95px', width: '385px' }}
                            value={formData.shipFromPrivateCdId}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 運送事業者コード(自社ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>
                            {t('Text.TrspCliPrivateCdId')}<br />
                            ({t('Text.InternalLocal')})
                        </Typography>
                        <TextField
                            name='trspCliPrivateCdId'
                            size="small"
                            style={{ marginLeft: '95px', width: '385px' }}
                            value={formData.trspCliPrivateCdId}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 中継拠点コード(自社ローカル) */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>
                            {t('Text.CnnPrivateCdId')}<br />
                            ({t('Text.InternalLocal')})
                        </Typography>
                        <TextField
                            name='cnnPrivateCdId'
                            size="small"
                            style={{ marginLeft: '95px', width: '385px' }}
                            value={formData.cnnPrivateCdId}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 納品伝票番号 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '150px' }}>{t('Text.SubmitNo')}</Typography>
                        <TextField
                            name='delSlipNumId'
                            size="small"
                            style={{ marginLeft: '95px', width: '385px' }}
                            value={formData.delSlipNumId}
                            onChange={handleTextFieldOnChange}
                            variant="outlined"
                        />
                    </div><br />
                    {/* 納品伝票データ作成日付 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '180px' }}>
                            {formVouDateIssDttmDateChoose ? t('Text.VouDateIssDttm') : t('Text.VouDateIssDttmRelative')}
                        </Typography>
                        <div style={{ marginLeft: '65px', display: 'flex', alignItems: 'center' }}>
                            {
                                formVouDateIssDttmDateChoose ?
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <StyledDatePicker
                                                name="vouDateIssDttm"
                                                value={formData.vouDateIssDttm ? moment(formData.vouDateIssDttm) : null}
                                                sx={{ width: '170px' }}
                                                format={"YYYY-MM-DD"}
                                                onChange={(valueNew) => {
                                                    if (!valueNew || !(valueNew as Moment).isValid()) {
                                                        handleTextFieldOnChange({ target: { value: "", name: "vouDateIssDttm" } })
                                                    } else {
                                                        handleTextFieldOnChange({ target: { value: (valueNew as Moment).format("yyyy-MM-DD"), name: "vouDateIssDttm" } })
                                                    }
                                                }} />
                                        </LocalizationProvider>
                                    </> :
                                    <>
                                        <TextField
                                            name="vouDateIssDttmRelative"
                                            type="number"
                                            size="small"
                                            style={{ width: '150px' }}
                                            value={formData.vouDateIssDttmRelative}
                                            onChange={handleTextFieldOnChange}
                                            variant="outlined"
                                        />
                                        <span style={{ marginLeft: '10px' }}>
                                            {formData.vouDateIssDttmRelative?.length === 0 ? "" : (parseInt(formData.vouDateIssDttmRelative!) === 0 ? i18n.t('Text.TheDay') :
                                                (parseInt(formData.vouDateIssDttmRelative!) > 0 ? i18n.t('Text.DaysAfter') : i18n.t('Text.DaysBefore')))}
                                        </span>
                                    </>
                            }
                        </div>
                        <div style={{ marginLeft: '30px' }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkVouDateIssDttmDate"
                                            color="primary"
                                            checked={formVouDateIssDttmDateChoose}
                                            onChange={handleDateChooseChange} />
                                    }
                                    label={i18n.t('Text.SpecifiedDate')}
                                />
                            </FormGroup>
                        </div>
                    </div><br />
                    {/* 文書定義管理ID */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: 'red', lineHeight: '10px' }}>*</span>
                        <Typography variant="body1" gutterBottom style={{ width: '223px' }}>{t('Text.DocTypeKey')}</Typography>
                        <Button style={{ backgroundColor: "#e2e2e2", marginLeft: '17px' }} size='small' onClick={() => getDocDefList()}>
                            {t('Text.Select')}
                        </Button>
                        <span style={{ marginLeft: '20px' }}>{formData.docTypeKey}</span>
                    </div><br />
                    {/* 納品書取得 */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom style={{ width: '223px' }}>{t('Text.ShippingFetchFlag')}</Typography>
                        <FormControlLabel
                            style={{ width: '78px', marginLeft: '15px' }}
                            control={
                                <IOSSwitch
                                    checked={formData.shippingFetchFlag === "1" ? true : false}
                                    onChange={handleSwitch} />
                            }
                            label=""
                        />
                    </div><br />
                    {/* 取得フラグ */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: 'red', lineHeight: '10px' }}>*</span>
                        <Typography variant="body1" gutterBottom style={{ width: '240px' }}>{t('Text.DeliveryGetStatus')}</Typography>
                        <FormControlLabel
                            control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={formData.getStatus === "0"} onChange={handleCheckboxChange} name="未取得のみ" color="primary" />}
                            label={i18n.t('Text.OnlyUnavailable')}
                        />
                        <FormControlLabel
                            control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={formData.getStatus === "1"} onChange={handleCheckboxChange} name="取得済のみ" color="primary" />}
                            label={i18n.t('Text.OnlyAvailable')}
                        />
                        <FormControlLabel
                            control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={formData.getStatus === "2"} onChange={handleCheckboxChange} name="すべて取得" color="primary" />}
                            label={i18n.t('Text.GetAll')}
                        />
                    </div><br />
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" onClick={() => handleClick()} disabled={disableButton()}>
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => doClose()}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
