import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { TreeItem, TreeView } from "@material-ui/lab";
import { Folder } from "@material-ui/icons";
import { useStyles } from "../manage/css/Style";
import { RenderTree } from "../../models/RenderTree";

type Props = {
    treeView: RenderTree;
    isOpen: boolean;
    selectFolder: (folder: RenderTree) => void;
    doClose: () => void;
};

export const TreeViewDialog: React.FC<Props> = ({ treeView, isOpen, selectFolder, doClose }) => {
    // css
    const classes = useStyles();

    const { i18n, t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const [folder, setFolder] = React.useState<RenderTree>();

    useEffect(() => {
        setOpen(isOpen);
        setFolder({});
    }, [isOpen])

    /**
     * フォルダーツリー
     */
    const renderTree = (nodes: RenderTree) => (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id!}
            label={
                <div className={classes.labelRoot}>
                    <Folder className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>{nodes.name}</Typography>
                </div>
            }
            onClick={() => setFolder(nodes)}
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    )

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Text.FolderTree')}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TreeView
                        className={classes.root}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {renderTree(treeView)}
                    </TreeView>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" onClick={() => selectFolder(folder!)} disabled={!!!folder?.id}>
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => doClose()}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
