import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Close } from "@material-ui/icons";
import Preview from "../Preview";
import { AppBar, Box, createStyles, IconButton, makeStyles, Slide, Switch, Theme, Toolbar, Typography } from "@material-ui/core";
import DocContainer from "../../container/DocContainer";
import { TransitionProps } from "@material-ui/core/transitions";
import { useTranslation } from "react-i18next";

type Props = {
    isOpen: boolean;
    doClose: () => void;
};
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
})
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative'
        },
        title: {
            marginLeft: 2,
            flex: 1,
        }
    })
)
export const PreviewDialog: React.FC<Props> = (
    {
        isOpen,
        doClose
    }) => {
    const { t } = useTranslation();
    const { currentDoc, selectRow, switchedItem, isDisableCheck, switchConfirm, isVisibleCheck } = DocContainer.useContainer();
    const classes = useStyles();
    const rowIndex: number = selectRow?.length ? parseInt(selectRow[0].toString()) : -1;
    const [svffieldDataChecked, setSvffieldDataChecked] = React.useState<boolean>(false);
    const handleClose = () => {
        doClose();
    };

    React.useEffect(() => {
        // @ts-ignore
        const checked = currentDoc ? JSON.parse(currentDoc['properties.svffield_data_checked']) : true
        setSvffieldDataChecked(checked);
    }, [currentDoc, switchedItem, isOpen]);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
                fullScreen
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {
                                // @ts-ignore
                                currentDoc ? currentDoc['properties.name'] : ''
                            }
                        </Typography>
                        {rowIndex >= 0 && isVisibleCheck(rowIndex) && (
                            <Box>
                                {t('Text.ToMakeInto', { args1: t('Text.SvffieldDataChecked') })}
                                <Switch
                                    disabled={isDisableCheck(rowIndex)}
                                    checked={svffieldDataChecked}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => switchConfirm(rowIndex, checked)}
                                />
                            </Box>
                        )}
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Preview />
                </DialogContent>
            </Dialog>
        </>
    );
};
