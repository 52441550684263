import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import CachedIcon from '@material-ui/icons/Cached';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect } from "react";
import { History } from "../../models/History";
import { useManageService } from "../../service/ManageService";
import CommonContainer from "../../container/CommonContainer";
import { useTranslation } from 'react-i18next';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import UserContainer from "../../container/UserContainer";
import { useStyles } from "./css/Style";

export default function HistoryDay(props: { setCallBackMessage: (arg0: { status: number; message: string; open: boolean }) => void; }) {

    const classes = useStyles();

    const commonState = CommonContainer.useContainer();
    const userState = UserContainer.useContainer();

    const { historyGetDataApi, historyAddDataApi, historyEditDataApi } = useManageService()

    const { t, i18n } = useTranslation();
    const [history, setHistory] = React.useState<History>({
        id: undefined,
        day: "0",
        tenantId: userState.tenantId
    });
    const [daysFormat, setDaysFormat] = React.useState<boolean>(false); 

    useEffect(() => {
        getData()
    }, [])

    /**
     * 伝票送受信の履歴管理取得
     */
    const getData = () => {
        commonState.setProgress(true);
        historyGetDataApi(history).then((data) => {
            if (data.length !== 0) {
                setHistory({ ...history, id: data[0].id, day: data[0].day });
            }
        }).catch((error) => {
            console.log("****** error is ******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
        })
    }

    /**
     * 伝票送受信の履歴情報のセーブ
     */
    const handleSave = () => {
        let rule = /^(0|\+?[1-9][0-9]*)$/g;
        if (!rule.test(history.day!)) {
            setDaysFormat(true);
            return;
        }

        if (!!history.id) {
            historyEditDataApi(history).then((data) => {
                props.setCallBackMessage({ status: 200, message: i18n.t('Msg.SaveSuccessful'), open: true });
            }).catch((error) => {
                props.setCallBackMessage({ status: 500, message: i18n.t('Msg.SaveFailed'), open: true });
                console.log("****** error is ******", error.response);
            }).finally(() => {
                getData();
            });
        } else {
            historyAddDataApi(history).then((data) => {
                props.setCallBackMessage({ status: 200, message: i18n.t('Msg.SaveSuccessful'), open: true });
            }).catch((error) => {
                props.setCallBackMessage({ status: 500, message: i18n.t('Msg.SaveFailed'), open: true });
                console.log("****** error is ******", error.response);
            }).finally(() => {
                getData();
            });
        }
    }

    /**
    * 伝票送受信の履歴情報のリフレッシュ
    */
    const handleRefresh = () => {
        getData();
    }

    /**
     * 入力ボックスのデータ変更に対応
     * @param e コンポーネント・イベント
     */
    const handleTextFieldOnChange = (e: { target: any }) => {
        setHistory({ ...history, day: e.target.value });
    }

    return (
        <>
            <div className={classes.tabTitle}>{t('Text.HistoryManagement')}</div>
            <Divider />
            <div className={classes.iconStyle}>
                <IconButton onClick={handleSave} disabled={history.day === ""}>
                    <SaveIcon className={history.day !== "" ? classes.saveButtonActived : classes.saveButtonDisabled} />
                </IconButton>
                <IconButton onClick={handleRefresh}>
                    <CachedIcon style={{ color: '#1181d7', fontSize: 27 }} />
                </IconButton>
            </div>
            <Divider />
            <div style={{ textAlign: 'left', padding: '20px 0 0 20px', lineHeight: '40px' }}>
                <span>{t('Text.Saveday')}</span>
                <TextField
                    size="small"
                    style={{ marginLeft: '200px' }}
                    value={history.day || ""}
                    onChange={handleTextFieldOnChange}
                    variant="outlined"
                /> {t('Text.CalendarSunday')}
            </div>

            {/* 保存日の形式が正しくない */}
            <Dialog
                open={daysFormat}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" align="center" gutterBottom={true}>{t('Text.Warning')}</Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ReportProblemRoundedIcon color='error' fontSize='large' style={{ marginRight: "20px" }} />
                        {t('Msg.DaysFormatConfirm')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" onClick={() => setDaysFormat(false)} color="primary">
                        {t('Text.Ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}