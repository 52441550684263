import {
 FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import React, {Ref} from "react";
import {useTranslation} from "react-i18next";
import SearchOptionService from "../../service/SearchOptionService";
import DocContainer from "../../container/DocContainer";


/**
 * 範囲内・範囲外　ドロップダウンボックス
 * @param props
 * @constructor
 */
const RangeSelect = (props: { templateIdx: number, detailIdx: number, conditionType: string }) => {
    const {templateIdx, detailIdx, conditionType} = props;
    const docState = DocContainer.useContainer();
    const {t, i18n} = useTranslation();
    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
    const [inRange, setInRange] = React.useState(detailCondition.inRange);
    const templateOption = SearchOptionService.getTemplateOption();
    const DUMMY_NONE = '__none__';

    const handleChange = (event: object) => {
        // @ts-ignore
        const value = event.target.value === DUMMY_NONE ? '' : event.target.value
        let temp = detailCondition;
        temp.inRange = value;
        setDetailCondition(temp);
        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
        // @ts-ignore
        setInRange(event.target.value);
    }
    const getValue =()=>{
        return  inRange === '' ? DUMMY_NONE : inRange;
    }

    {/*<!-- 条件ドロップダウン 範囲 -->*/
    }
    return (
            <FormControl  variant="outlined" margin='dense'>
                <InputLabel shrink>{ }</InputLabel>
                <Select key={'select' + detailIdx}
                        value={getValue()}
                        style={{width:'120px'}}
                        onChange={handleChange}
                        displayEmpty
                >
                    {templateOption.inRangeOptions.map(({label, value}: any) => {
                        value=value === '' ? DUMMY_NONE :value;
                        return (<MenuItem key={value} value={value}>{t(label)}</MenuItem>);
                    })
                    }

                </Select>
            </FormControl>
    );
};

export default RangeSelect;
