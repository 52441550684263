import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * 検索条件値（マスター） API
 */
export const useSearchValuesMasterService = () => {
    const axios = ServiceContainer.useContainer()

    const listSearchValuesMaster = () => {
        return axios.request(
            WebConstants.SEARCH_VALUES_MASTER_LIST_URL,
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {
            console.log("****** SearchValuesMasterService is *******", response);
            return response.data.searchValues;
        }
        );
    }

    return {
        listSearchValuesMaster
    }
}