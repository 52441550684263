import { Chip } from "@material-ui/core";
import { getFilterData } from "../../service/ColumnService";
import i18n from "../../i18n";

// @ts-ignore
const CustomTableFilterChip = ({ label, onDelete, className, filterProps }) => {
    const [colNameFrom, filterLabel] = getFilterData(label)

    function lableTransform() {
        if (colNameFrom === i18n.t('Text.Comfirmed')) {
            switch (filterLabel) {
                case "true":
                    return i18n.t('Text.Comfirmed') + ": " + i18n.t('Text.Comfirmed')
                case "false":
                    return i18n.t('Text.Comfirmed') + ": " + i18n.t('Text.Unconfirmed')
                case "All":
                    return i18n.t('Text.Comfirmed') + ": " + i18n.t('Text.All')
            }
        } else {
            return label;
        }
    }

    return (
        !!filterLabel
            ?
            <Chip
                className={className}
                variant="outlined"
                label={lableTransform()}
                onDelete={onDelete}
            />
            :
            ""
    );
};

export default CustomTableFilterChip