import React from 'react';
import { createContainer } from "unstated-next";

const useCommonState = () => {
    const [fullCheck, setFullCheck] = React.useState<boolean>(false);
    const [checkList, setCheckList] = React.useState<any[]>([]);
    const [rowCheckStatuslist, setRowCheckStatuslist] = React.useState<any[]>([]);
    const [rowClickAction, setRowClickAction] = React.useState<String>("");

    return { checkList, setCheckList, rowCheckStatuslist, setRowCheckStatuslist, fullCheck, setFullCheck,rowClickAction, setRowClickAction }
}

const CustomContainer = createContainer(useCommonState);

export default CustomContainer;
