import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * SendService API
 */
export const useSendService = () => {
    const serviceState = ServiceContainer.useContainer()

    const getFilterDataApi = (sendData: any) => {
        return serviceState.request(
            WebConstants.SEND_FILTER_DATA,
            WebConstants.METHOD_POST,
            sendData
        ).then((response) => {
            return response.data;
        })
    }

    return {
        getFilterDataApi
    }
}