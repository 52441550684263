import * as WebConstants from "../constants/WebConstants";
import { SipInfo } from "../models/SipInfo";
import { Info } from "../models/Info";
import ServiceContainer from "../container/AxiosServiceContainer";

export const useManageService = () => {
    const serviceState = ServiceContainer.useContainer()

    // sip get
    const sipGetDataApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.MANAGE_SIP_INFO_GET,
            WebConstants.METHOD_POST,
            { "tenantId": tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // sip add
    const sipAddDataApi = (addData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_SIP_INFO_ADD,
            WebConstants.METHOD_POST,
            addData
        ).then((response) => {
            return response.data;
        })
    }

    // sip edit
    const sipEditDataApi = (editData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_SIP_INFO_EDIT,
            WebConstants.METHOD_POST,
            editData
        ).then((response) => {
            return response.data;
        })
    }

    // sip delete
    const sipDeleteDataApi = (rowData: SipInfo) => {
        return serviceState.request(
            WebConstants.MANAGE_SIP_INFO_DELETE,
            WebConstants.METHOD_POST,
            rowData
        ).then((response) => {
            return response.data;
        })
    }

    // ia get
    const iaGetDataApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.MANAGE_IA_GET,
            WebConstants.METHOD_POST,
            { "tenantId": tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // ia add
    const iaAddDataApi = (addData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_IA_ADD,
            WebConstants.METHOD_POST,
            addData
        ).then((response) => {
            return response.data;
        })
    }

    // ia edit
    const iaEditDataApi = (editData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_IA_EDIT,
            WebConstants.METHOD_POST,
            editData
        ).then((response) => {
            return response.data;
        })
    }

    // ia delete
    const iaDeleteDataApi = (rowData: Info) => {
        return serviceState.request(
            WebConstants.MANAGE_IA_DELETE,
            WebConstants.METHOD_POST,
            rowData
        ).then((response) => {
            return response.data;
        })
    }

    // history get
    const historyGetDataApi = (history: any) => {
        return serviceState.request(
            WebConstants.MANAGE_HISTORY_GET,
            WebConstants.METHOD_POST,
            history
        ).then((response) => {
            return response.data;
        })
    }

    // history add
    const historyAddDataApi = (addData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_HISTORY_ADD,
            WebConstants.METHOD_POST,
            addData
        ).then((response) => {
            return response.data;
        })
    }

    // history edit
    const historyEditDataApi = (editData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_HISTORY_EDIT,
            WebConstants.METHOD_POST,
            editData
        ).then((response) => {
            return response.data;
        })
    }

    // get folderList
    const folderListApi = () => {
        return serviceState.request(
            WebConstants.FOLDERS_LIST,
            WebConstants.METHOD_GET,
            null
        ).then((response) => {
            return response.data;
        })
    }

    // receipt get
    const receiptGetDataApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.MANAGE_RECEIPT_GET,
            WebConstants.METHOD_POST,
            { tenantId: tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // receipt add
    const receiptAddDataApi = (addData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_RECEIPT_ADD,
            WebConstants.METHOD_POST,
            addData
        ).then((response) => {
            return response.data;
        })
    }

    // receipt edit
    const receiptEditDataApi = (editData: any) => {
        return serviceState.request(
            WebConstants.MANAGE_RECEIPT_EDIT,
            WebConstants.METHOD_POST,
            editData
        ).then((response) => {
            return response.data;
        })
    }

    // receipt delete
    const receiptDeleteDataApi = (rowId: any) => {
        return serviceState.request(
            WebConstants.MANAGE_RECEIPT_DELETE,
            WebConstants.METHOD_POST,
            rowId
        ).then((response) => {
            return response.data;
        })
    }

    // insert folder
    const insertFolderApi = (params: any) => {
        return serviceState.request(
            WebConstants.RECEIPT_FOLDER_INSERT,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // update folder
    const updateFolderApi = (params: any) => {
        return serviceState.request(
            WebConstants.RECEIPT_FOLDER_UPDATE,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // getFolderName
    const getFolderNameApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.RECEIPT_FOLDER_LIST,
            WebConstants.METHOD_POST,
            { tenantId: tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // insert delivery folder
    const insertDeliveryFolderApi = (params: any) => {
        return serviceState.request(
            WebConstants.DELIVERY_FOLDER_INSERT,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // update delivery folder
    const updateDeliveryFolderApi = (params: any) => {
        return serviceState.request(
            WebConstants.DELIVERY_FOLDER_UPDATE,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }
    // get Delivery FolderName
    const getDeliveryFolderNameApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.DELIVERY_FOLDER_LIST,
            WebConstants.METHOD_POST,
            { tenantId: tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // delivery get
    const deliveryGetDataApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.MANAGE_DELIVERY_GET,
            WebConstants.METHOD_POST,
            { tenantId: tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // delivery add
    const deliveryAddDataApi = (params: any) => {
        return serviceState.request(
            WebConstants.MANAGE_DELIVERY_ADD,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // delivery update
    const deliveryUpdatetataApi = (params: any) => {
        return serviceState.request(
            WebConstants.MANAGE_DELIVERY_EDIT,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // delivery delete
    const deliveryDeleteDataApi = (params: any) => {
        return serviceState.request(
            WebConstants.MANAGE_DELIVERY_DELETE,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // consignor get
    const consignorGetDataApi = (tenantId: string) => {
        return serviceState.request(
            WebConstants.MANAGE_CONSIGNOR_GET,
            WebConstants.METHOD_POST,
            { "tenantId": tenantId }
        ).then((response) => {
            return response.data;
        })
    }

    // consignor add
    const consignorAddDataApi = (params: any) => {
        return serviceState.request(
            WebConstants.MANAGE_CONSIGNOR_ADD,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // consignor update
    const consignorUpdatetDataApi = (params: any) => {
        return serviceState.request(
            WebConstants.MANAGE_CONSIGNOR_EDIT,
            WebConstants.METHOD_PUT,
            params
        ).then((response) => {
            return response.data;
        })
    }

    // consignor delete
    const consignorDeleteDataApi = (params: any) => {
        return serviceState.request(
            WebConstants.MANAGE_CONSIGNOR_DELETE,
            WebConstants.METHOD_POST,
            params
        ).then((response) => {
            return response.data;
        })
    }

    return {
        sipGetDataApi, sipAddDataApi, sipEditDataApi, sipDeleteDataApi,
        iaGetDataApi, iaAddDataApi, iaEditDataApi, iaDeleteDataApi,
        historyGetDataApi, historyAddDataApi, historyEditDataApi,
        folderListApi, insertFolderApi, getFolderNameApi, updateFolderApi,
        receiptAddDataApi, receiptDeleteDataApi, receiptEditDataApi, receiptGetDataApi,
        consignorAddDataApi, consignorDeleteDataApi, consignorUpdatetDataApi, consignorGetDataApi,
        deliveryGetDataApi, deliveryAddDataApi, deliveryUpdatetataApi, deliveryDeleteDataApi,
        insertDeliveryFolderApi, updateDeliveryFolderApi, getDeliveryFolderNameApi
    }
}


