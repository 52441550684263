import {DEFAULT_DATE_FORMAT, SEARCH_CONDITION_TYPE} from "../constants/Constants";
import {DATE_TYPE, EXTENSION_COLUMNS, FORMAT_DATE} from "../constants/PropertiesConstants";
import CustomDateFilter from "../common/CustomDateFilter";
import UTCTimeFilter from "../common/UTCTimeFilter";
import moment from "moment";
import ValidatorService from "./ValidatorService";
import i18n from "i18next";

class DocumentOptionService {
    private browser_offset = -new Date().getTimezoneOffset();
    // 文書プロパティのリソースキー
    getDocumentPropertyResource=(key:string)=> {
        const resource:any = {
            // PDF以外
            name: 'Text.FileName',                     // ファイル名
            type: 'Text.DocType',                      // 文書種類
            filetype: 'Text.Kind',                     // 種類
            // PDF
            id: 'Text.ID',                             // ID
            title: 'Text.PDFTitle',                    // タイトル
            subject: 'Text.PDFSubject',                // サブタイトル
            keywords: 'Text.PDFKeywords',              // キーワード
            author: 'Text.PDFAuthor',                  // PDF作成者
            creator: 'Text.Creator',                   // 作成アプリケーション
            producer: 'Text.PDFProducer',              // PDF変換
            createdate: 'Text.PDFCreateDate',          // PDF作成日時
            modifydate: 'Text.PDFModifyDate',          // PDF更新日時
            size: 'Text.Size',                          // ファイルサイズ
            sizeKB: 'Text.SizeKB',                     // ファイルサイズ(KB)
            pagecount: 'Text.PageCount',               // ページ数
            // 作成
            adduser: 'Text.FirstAddUser',              // 作成ユーザー
            adduserdomain: 'Text.FirstAddDomain',      // 作成ユーザードメイン
            adddate: 'Text.FirstAddDate',              // 作成日
            // アーカイブ
            updateuser: 'Text.AddFileUser',            // アーカイブユーザー
            updateuserdomain: 'Text.AddFileDomain',    // アーカイブユーザードメイン
            updatedate: 'Text.AddFileDate',            // アーカイブ日時
            // 文書
            doctype_id: 'Text.DocTypeId',              // 文書定義ID
            doctype_dispname: 'Text.DocTypeName',      // 文書定義名
            version: 'Text.Version',                   // バージョン
            // 最終閲覧
            viewdate: 'Text.LastViewDate',             // 最終閲覧日時
            viewuser: 'Text.LastViewUser',             // 最終閲覧ユーザー
            viewuserdomain: 'Text.LastViewDomain',     // 最終閲覧ユーザードメイン
            // 最終印刷
            printdate: 'Text.LastPrintDate',           // 最終印刷日時
            printuser: 'Text.LastPrintUser',           // 最終印刷ユーザー
            printuserdomain: 'Text.LastPrintDomain',   // 最終印刷ユーザードメイン
            // 最終ダウンロード
            downloaddate: 'Text.LastDLDate',           // 最終ダウンロード日時
            downloaduser: 'Text.LastDLUser',           // 最終ダウンロードユーザー
            downloaduserdomain: 'Text.LastDLDomain',   // 最終ダウンロードユーザードメイン
            // 追加
            docpath: 'Text.FolderPath',                // フォルダパス
            linkpath: 'Text.LinkedPath',               // リンク元パス
            docstatus: 'Text.DocStatus',               // 文書ステータス（旧）
            parsed_status: 'Text.DocStatus',           // 文書ステータス
            svffield_editable_status: 'Text.ExistenceSvfField', // SVF検索フィールドの有無（SVF検索フィールドの編集）
            svffield_data_checked: 'Text.SvffieldDataChecked', // 確認済み
            annotation_existence: 'Text.AnnotationExistence', // 注釈の有無
            hitpages: 'Text.HitPageCount',             // ヒットページ数
            stamp: 'Text.Timestamp',                   // タイムスタンプ
            stamped_image_info: 'Text.ImageInfo',      // タイムスタンプのイメージ情報
            stamped_expiration_date: 'Text.TimestampExpirationDate', // タイムスタンプの有効期限
            doctype_key: 'Text.DocTypeKey',            // 文書定義管理ID
            fileId: 'Text.FileId',                     // ファイルID
            comment: 'Text.Comment',                   // 文書のコメント
            review_status: 'Text.Review',              // レビュー
            stage_name: 'Text.ReviewStageName' ,       //レビューの経路名
            review_complete_date: 'Text.ReviewCompleteDate' , //レビュー完了日時
            direct_url: 'Text.UrlLink',                // URLリンク
            document_version: 'Text.Version',          // 文書バージョン
            convert_doc_status: 'Text.DocumentConvertStatus', // Document Converter変換ステータス
            doc_convert_error: 'Text.DocumentConvertError', // Document Converter変換エラー
            checkout: 'Text.Checkout',                       // チェックアウト
            checkoutdate: 'Text.CheckoutDate',              // チェックアウト日時
            checkoutuser: 'Text.CheckoutUser',              // チェックアウトユーザー
            checkoutuserdomain: 'Text.CheckoutUserDomain', // チェックアウトユーザーのドメイン
            //ページコンテンツ
            annotation: 'Text.Annotation',             // 注釈
            annotation_text: 'Text.AnnotationText',   // 注釈のテキスト
            private_annotation_text: 'Text.PrivateAnnotationText', // 注釈(個人)のテキスト
            pagememo: 'Text.PageMemo',                 // ページメモ
            formname: 'Text.FormFileName',             // 様式名
            pagenumber: 'Text.PageNumber',              // ページ番号
            loader_result_code: 'Text.LoaderResultCode', // Loaderの実行結果コード
            output_svffields_createdate: 'Text.OutputSvfFieldsCreateDate', // CSVファイル出力の作成日時
            output_svffields_downloaddate: 'Text.OutputSvfFieldsDownloadDate', // CSVファイル出力のダウンロード日時
            relate_info: 'Text.RelateInfo'//関連文書
        };
        return resource[key];
    }

    // dataTypeのリソースキー
    getDataTypeResource=()=> {
        var resource = {
            string: 'Text.String',
            number: 'Text.Integer',
            date: 'Text.Date',
            boolean: 'Text.Boolean',
            hyperLink: 'Text.Hyperlink',
            dropdown: '-'
        };
        return resource;
    }

    // PDF項目の取得
    getPdfColumns=()=> {
        var columns = ['title', 'subject', 'keywords', 'author', 'creator', 'producer', 'createdate', 'modifydate'];
        return columns;
    }
    // ページコンテンツ項目の取得
    getPageContentsColumns=()=> {
        var columns = ['annotation', 'loginUserAnnotation', 'pagememo', 'formname', 'pagenumber'];
        return columns;
    }
    // カスタムプロパティのうちの拡張項目の取得
/*    getExtensionColumns=()=> {

        var extensionColumns = [
            commonConstant.extensionColumns.EXT_START_DATE,
            commonConstant.extensionColumns.EXT_LOCK,
            commonConstant.extensionColumns.EXT_TIMESTAMP,
            commonConstant.extensionColumns.EXT_MASK,
            commonConstant.extensionColumns.EXT_MASK_TYPE,
            commonConstant.extensionColumns.EXT_REVIEW_TEMPLATE,
            commonConstant.extensionColumns.EXT_OVERWRITE_FORBIDDEN,
            commonConstant.extensionColumns.EXT_REVIEW_ISSUE_USER_ID
        ];

        return extensionColumns;
    }*/

}

export default new DocumentOptionService()
