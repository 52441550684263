import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, List, ListItem, ListSubheader, MuiThemeProvider, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Search } from "@material-ui/icons";
import { listItem, useStyles } from "../manage/css/Style";

type Props = {
    isOpen: boolean;
    docDefData: any;
    selectRowId: string;
    doClose: (isOk: boolean, currentRowId: string) => void;
};

export const DocDefListDialog: React.FC<Props> = ({ isOpen, docDefData, selectRowId, doClose }) => {
    // css
    const classes = useStyles();

    const { i18n, t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const [filterValue, setFilterValue] = React.useState<any>("");
    const [filteredList, setFilteredList] = React.useState<any>([]);
    const [currentRowId, setCurrentRowId] = React.useState<any>("");

    // 文書定義リスト
    const docDefArray = [{
        id: "0",
        title: t('Text.DocTypeKey'),
        value: "id"
    }, {
        id: "1",
        title: t('Text.DocTypeId'),
        value: "dispId"
    }, {
        id: "2",
        title: t('Text.DocTypeName'),
        value: "name"
    }]

    useEffect(() => {
        setOpen(isOpen);
        setCurrentRowId(selectRowId)
    }, [isOpen])

    /**
     * 文書定義のタイトル
     */
    const docDefHeader = (title: string, value: string, key: string) => {
        const subheaderId = `nested-list-subheader-${key}`;
        return (
            <div key={subheaderId} style={{ display: 'inline-block' }}>
                <List className={value === "name" ? classes.lastDocDefList : classes.docDefList}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader className={classes.docDefHeader} component="div" id={subheaderId}>
                            {title}
                        </ListSubheader>
                    }
                >
                </List>
            </div>
        )
    }

    /**
     * 文書定義リスト
     */
    const docDefList = (title: string, value: string, key: string) => {
        const id = `nested-list-${key}`;
        return (
            <div key={id} style={{ display: 'inline-block' }}>
                <MuiThemeProvider theme={listItem()}>
                    <List className={value === "name" ? classes.lastDocDefList : classes.docDefList}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {
                            (filterValue !== "" ? filteredList : docDefData).map((row: any, index: number) => {
                                let name = value === "id" ? row.id : value === "dispId" ? row.dispId : row.name;
                                return (
                                    <ListItem key={index} button divider
                                        selected={row.id == currentRowId}
                                        className={classes.docDefItem}
                                        onClick={() => setCurrentRowId(row.id)}>
                                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', wordBreak: 'break-all', whiteSpace: 'nowrap', width: '160px' }}>
                                            <span title={name}>{name}</span>
                                        </div>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </MuiThemeProvider>
            </div>
        )
    }

    /**
     * 文書定義検索
     */
    const handleFilterOnchange = (e: { target: any }) => {
        const value = e.target.value;
        setFilterValue(value);
        setFilteredList(docDefData.filter((item: any) => item.dispId.includes(value) || item.id.includes(value) || item.name.includes(value)))
    }

    const handleClose = (isOk: boolean) => {
        setFilterValue("");
        doClose(isOk, currentRowId)
    }

    return (
        <>
            {/* 文書定義リスト */}
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Text.SelectDocDef')}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            size="small"
                            style={{ width: '600px' }}
                            onChange={handleFilterOnchange}
                            variant="outlined"
                            placeholder={t('Text.ViewFilter')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div> <br />
                    <div style={{ height: '30px', display: 'flex', flexDirection: 'row' }}>
                        {
                            docDefArray.map((val, index) => docDefHeader(val.title, val.value, val.id))
                        }
                    </div>
                    <div style={{ height: "500px", overflowY: 'auto', display: 'flex', flexDirection: 'row' }}>
                        {
                            docDefArray.map((val, index) => docDefList(val.title, val.value, val.id))
                        }
                    </div >
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#e9eef0" }}>
                    <Button variant="contained" color="primary" disabled={currentRowId === ""} onClick={() => handleClose(true)} >
                        {t('Text.Ok')}
                    </Button>
                    <Button variant="contained" color='default' onClick={() => handleClose(false)}>
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
