import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CustomInfoSnackBar from './dialog/CustomInfoSnackBar';
import { useTranslation } from 'react-i18next';
import ReceiptList from './manage/ReceiptList';
import HistoryDay from './manage/HistoryDay';
import IaList from './manage/IaList';
import SipList from './manage/SipList';
import ConsignorList from './manage/ConsignorList';
import DeliveryList from './manage/DeliveryList';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: 'calc(100% - 200px)', overflowX: 'auto' }}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
};

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
};

const ManagePage = () => {
    const [value, setValue] = React.useState(0);
    const [openSnackBar, setOpenSnackBar] = React.useState<boolean>(false);
    const [dataCallbackMessage, setDataCallbackMessage] = React.useState<any>({ status: "", message: "" });
    const { t, i18n } = useTranslation();

    const useStyles = makeStyles((theme: Theme) => ({
        rootTab: {
            flexGrow: 1,
            backgroundColor: '#fff',
            display: 'flex',
            height: 'auto',
            minHeight: 600,
            marginTop: '100px',
            width: '100%'
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        }
    }));

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    /**
     * アラートの取得
     * @param info 
     */
    const getCallBackMessage = (info: any) => {
        let params = {
            message: info.message,
            status: info.status
        }
        setDataCallbackMessage(params)
        setOpenSnackBar(info.open)
    }
    return (
        <>
            <div className={classes.rootTab}>
                <div style={{ width: '200px', backgroundColor: '#e9eef0', borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <div style={{ fontWeight: 900, fontSize: 14.4, marginLeft: 20, textAlign: 'left', backgroundColor: '#e9eef0', padding: 8 }}>
                        {t('Text.InformationManagement')}
                    </div>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                    >
                        <Tab label={t('Text.IaDocumentManagement')} {...a11yProps(0)} />
                        <Tab label={t('Text.ContinuousManagement')} {...a11yProps(1)} />
                        <Tab label={t('Text.HistoryManagement')} {...a11yProps(2)} />
                        <Tab label={t('Text.ReceiptInformationManagement')} {...a11yProps(3)} />
                        <Tab label={t('Text.ShippingInformationManagement')} {...a11yProps(4)} />
                        <Tab label={t('Text.CnsgInfo')} {...a11yProps(5)} />
                    </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                    <IaList setCallBackMessage={getCallBackMessage} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SipList setCallBackMessage={getCallBackMessage} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <HistoryDay setCallBackMessage={getCallBackMessage} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ReceiptList setCallBackMessage={getCallBackMessage} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <DeliveryList setCallBackMessage={getCallBackMessage} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <ConsignorList setCallBackMessage={getCallBackMessage} />
                </TabPanel>
            </div>

            {/* プロンプトフレーム */}
            <CustomInfoSnackBar
                isOpen={openSnackBar}
                content={dataCallbackMessage.message}
                statusCode={dataCallbackMessage.status}
                doClose={() => { setOpenSnackBar(false) }}
            />

        </>
    );
}

export default ManagePage;
