import { styled } from "@mui/material/styles";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";

const StyledDatePicker = styled(DatePicker)(() => ({
    "&>.MuiInputBase-root": {
        "&>input": {
            "padding": "7.5px 0 7.5px 10px",
        }
    }
}))

const StyledDateTimePicker = styled(DateTimePicker)(() => ({
    "&>.MuiInputBase-root": {
        "&>input": {
            "padding": "7.5px 0 7.5px 10px",
        }
    }
}))

const parseDateToMoment = (value: string | undefined | null): Moment | null => {
    if (!!value) {
        return null;
    }
    let date = moment(value)
    if (!date.isValid()) {
        return null;
    }
    return date;
}

export {
    parseDateToMoment,
    StyledDatePicker,
    StyledDateTimePicker
}