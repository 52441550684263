import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DocContainer from "../../container/DocContainer";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../constants/Constants";
import { Box, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { AddComment } from "@material-ui/icons";
import { useDocumentCommentService } from "../../service/DocumentCommentService";

/**
 * 文書コメントダイアログ
 */
type Props = {
    docId: string,
    isOpen: boolean;
    doClose: () => void;
};
const DocCommentDialog: React.FC<Props> = ({
    docId,
    isOpen,
    doClose,
}) => {
    const { getDocumentComment, addDocumentComment } = useDocumentCommentService()

    const docState = DocContainer.useContainer();
    const [open, setOpen] = useState(false)
    const [commentList, setCommentList] = useState([])
    const [expand, setExpand] = useState<Array<string>>([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newComment, setNewComment] = React.useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setCommentList([]);
            setExpand([]);
            documentCommentsGet();
        }
        setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        doClose();
    };
    const handlePopClick = (event: any) => {
        setNewComment('');
        setAnchorEl(event.currentTarget);
    };
    const handlePopClose = (event: any) => {
        setAnchorEl(null);
    };

    const handleCommentChange = (comment: string) => {
        setNewComment(comment)
    }
    const handleAddComment = () => {
        handlePopClose(null);
        addComment();
    }
    /**
     * コメント追加
     */
    const addComment = async () => {
        addDocumentComment(docState.getCurrentDocId(), newComment).then((response) => {
            setNewComment('');
            documentCommentsGet();
        }).catch((error) => {
            docState.handleError(error);
        });
    }

    const useTreeItemStyles = makeStyles((theme) => ({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
            minWidth: 400,
            borderWidth: 1,
            borderColor: "gray"
        },
        content: {
            color: theme.palette.text.secondary,
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium as any,
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            marginLeft: 0,
            '& $content': {
                paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
        },
        typography: {
            padding: theme.spacing(2),
        },
    }));

    /**
     * 指定したファイルの文書コメントを取得する
     * Documents Comments Get (v2)
     */
    const documentCommentsGet = async () => {
        // @ts-ignore
        getDocumentComment(docState.getCurrentDocId()).then((response) => {
            setCommentList(response.commentList);
        }).catch((error) => {
            docState.handleError(error);
        });
    }
    const classes = useTreeItemStyles();
    /**
     * コメントをTree形式で返す
     */
    const TreeRender = (list: any) => {
        let ret = null;
        if (Array.isArray(list) && list.length > 0) {
            ret = new Array();
            for (let data of list) {
                expand.push(data.id);
                ret.push(createNode(data));
            }
            return ret;
        } else {
            return null;
        }

    };
    /**
     * コメントのツリーノードの生成
     * @param data
     * @param items
     */
    const createNode = (data: any) => {
        if (Array.isArray(data.children) && data.children.length > 0) {
            return (
                <TreeItem key={data.id} nodeId={data.id} label={getNodeLabel(data)}>
                    {data.children.map((node: any, idx: any) => createNode(node))}
                </TreeItem>
            );
        }
        return (<TreeItem key={data.id} nodeId={data.id} label={
            getNodeLabel(data)
        } />);
    }

    const getNodeLabel = (item: any) => {
        return (<div className={classes.labelRoot}>

            <Typography variant="h6" className={classes.labelText}>
                {item.comment}
            </Typography>
            <Typography variant="body2" color="inherit">
                {moment(item.addDate).format(DEFAULT_DATE_FORMAT)}
            </Typography>
        </div>)
    }

    return (<Dialog
        id={'CommentDialog'}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"

    >
        <DialogTitle
            id="form-dialog-title"> {t('Text.DocComment') + ' : ' + docState.currentDoc?.documentId}


        </DialogTitle>
        <Box component="span" m={1}
            width={400} height={400}>
            <Box height={30}>
                <TextField id={"textComment"} value={newComment} variant="outlined" style={{ width: 350, height: 20 }}
                    onChange={(e) => handleCommentChange(e.target.value)} />
                <IconButton color={'primary'} disabled={newComment.length === 0} onClick={() => handleAddComment()}>
                    <AddComment />
                </IconButton>
            </Box>
            <Box height={30} />
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={expand}
            >
                {TreeRender(commentList)}
            </TreeView>
        </Box>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                {t('Text.Close')}
            </Button>
        </DialogActions>
    </Dialog>);
}
export default DocCommentDialog;
