import React from 'react';
import { createContainer } from "unstated-next";
import { SendTableData } from '../models/SendTableData';
import moment from 'moment';

const useSendState = () => {
    // コントロール変数
    const [open, setOpen] = React.useState<boolean>(false);
    const [warningDialogStatus, setWarningDialogStatus] = React.useState<boolean>(false);

    // データ
    const [filteData, setFilteData] = React.useState<any[]>([]);
    const [currentTimeDay, setCurrentTimeDay] = React.useState<string>(formatDateTime(new Date()));

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [statusCode, setStatusCode] = React.useState<number>();
    function formatDateTime(date: Date): string {
        const yyyy = date.getFullYear();
        const MM = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        return `${yyyy}-${MM}-${dd}`;
    }
    const [searchData, setSearchData] = React.useState<SendTableData>({
        fileName: "",
        folderName: "",
        dataNo: "",
        submitNo: "",
        sendDatetimeStart: currentTimeDay + " 00:00",
        sendDatetimeEnd: currentTimeDay + " 23:59",
        submitDatetimeStart: "",
        submitDatetimeEnd: "",
        userName: "",
        sendMethod: "",
        sendResult: "",
        sendErrorDetail: ""
    })

    /**
     * SearchDataのデータをリセットする
     */
    const resetData = () => {
        setCurrentTimeDay(formatDateTime(new Date()));
        setSearchData(
            {
                fileName: "",
                folderName: "",
                dataNo: "",
                submitNo: "",
                sendDatetimeStart: currentTimeDay + " 00:00",
                sendDatetimeEnd: currentTimeDay + " 23:59",
                submitDatetimeStart: "",
                submitDatetimeEnd: "",
                userName: "",
                sendMethod: "",
                sendResult: "",
                sendErrorDetail: ""
            }
        )
    }

    return {
        open,
        setOpen,
        currentTimeDay,
        setCurrentTimeDay,
        searchData,
        setSearchData,
        warningDialogStatus,
        setWarningDialogStatus,
        filteData,
        setFilteData,
        resetData,
        isOpen, 
        setIsOpen,
        statusCode,
        setStatusCode
    }
}

const SendContainer = createContainer(useSendState);

export default SendContainer;