/**
 * SVF Field 編集 様式情報
 */
export class FormDefs{
    "formDefs":
        {
            id: string;
            name: string;
        }
    constructor(init?: Partial<FormDefs>) {
        Object.assign(this, init);
    }
}
