/**
 * SVF Field 編集 フィールド情報
 */
export class Fields {
    private _x: number | undefined;
    private _y: number | undefined;
    private _width: number | undefined;
    private _height: number | undefined;
    accuracy?: number [];
    private _formatFailed?: boolean | undefined;
    private _updated: boolean | undefined;
    private _checked: boolean | undefined;
    private _value: string | undefined;
    private _editValue: string | undefined;

    constructor(init?: Partial<Fields>) {
        Object.assign(this, init);
    }

    get updated(): boolean {
        return <boolean>this._updated;
    }

    set updated(value: boolean) {
        this._updated = value;
    }

    get formatFailed(): boolean {
        return <boolean>this._formatFailed;
    }

    set formatFailed(value: boolean) {
        this._formatFailed = value;
    }

    get height(): number {
        return <number>this._height;
    }

    set height(value: number) {
        this._height = value;
    }

    get width(): number {
        return <number>this._width;
    }

    set width(value: number) {
        this._width = value;
    }

    get y(): number {
        return <number>this._y;
    }

    set y(value: number) {
        this._y = value;
    }

    get x(): number {
        return <number>this._x;
    }

    set x(value: number) {
        this._x = value;
    }

    get value(): string {
        return <string>this._value;
    }

    set value(value: string) {
        this._value = value;
    }
    get checked(): boolean {
        return <boolean>this._checked;
    }

    set checked(value: boolean) {
        this._checked = value;
    }
    get editValue(): string | undefined {
        return this._editValue;
    }

    set editValue(value: string | undefined) {
        this._editValue = value;
    }
}
