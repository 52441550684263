import { Box, Checkbox, FormControlLabel, TextField, Typography } from "@material-ui/core";
import { styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { SEARCH_CONDITION_TYPE } from "../../constants/Constants";
import DocContainer from "../../container/DocContainer";
import RangeSelect from "./RangeSelect";
import ValueCheckBox from "./ValueCheckBox";
import { DATE_FORMAT, DATE_FORMAT_HTML } from "../../constants/PropertiesConstants";
import PropRenderService from "../../service/PropRenderService";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment, { Moment } from "moment";

const StyledDatePicker = styled(DatePicker)(() => ({
    "&>.MuiInputBase-root": {
        "&>input": {
            "padding": "7.5px 0 7.5px 10px",
        }
    }
}))

const StyledDateTimePicker = styled(DateTimePicker)(() => ({
    "&>.MuiInputBase-root": {
        "&>input": {
            "padding": "7.5px 0 7.5px 10px",
        }
    }
}))

/**
 * 日付型検索条件
 * @param props
 * @constructor
 */
const DateCondition = (props: { templateIdx: number, detailIdx: number, conditionType: string, dateTypeIndex: string }) => {
    const { templateIdx, detailIdx, conditionType, dateTypeIndex } = props;
    const docState = DocContainer.useContainer();
    const { t, i18n } = useTranslation();
    const [detailCondition, setDetailCondition] = React.useState(docState.selectCondition.templates[templateIdx].details[detailIdx]);
    const [specify, setSpecify] = React.useState(Boolean(detailCondition.specified));
    const [range, setRange] = React.useState(Boolean(detailCondition.range));
    const propService = PropRenderService;
    const useStyles = makeStyles((theme) => ({
        specifyTextField: { minWidth: '180px', fontSize: 12 },
        diffRangeTextField: { textAlign: 'center' }
    }));
    const classes = useStyles();
    /**
     * 日付型によるフォーマット文字列の取得
     */
    const getDateFormatStr = (reverse: boolean | undefined) => {
        let formatList = (reverse) ? DATE_FORMAT_HTML : DATE_FORMAT;
        let ret = formatList[1];
        if (conditionType === SEARCH_CONDITION_TYPE.CUSTOM || conditionType === SEARCH_CONDITION_TYPE.SVF_FIELD || conditionType === SEARCH_CONDITION_TYPE.DETAIL) {
            // @ts-ignore
            ret = formatList[Number(dateTypeIndex)];
        }
        return ret;
    }
    /**
     * フォーマット文字列変換
     * @param value
     * @param formatStr
     */
    const getFormattedDate = (value: string, reverse: boolean | undefined) => {
        let formatStr = getDateFormatStr(reverse);
        return propService.getFormattedDate(value, formatStr);
    }
    const [specifyFrom, setSpecifyFrom] = React.useState<Moment | null | string>(specify ? (!!detailCondition.from ? moment(getFormattedDate(detailCondition.from, true)) : null) : '');
    const [specifyTo, setSpecifyTo] = React.useState<Moment | null | string>(specify ? (detailCondition.to ? moment(getFormattedDate(detailCondition.to, true)) : null) : '');
    const [diffFrom, setDiffFrom] = React.useState(specify ? '' : detailCondition.from);
    const [diffTo, setDiffTo] = React.useState(specify ? detailCondition.to : '');
    const [day, setDay] = React.useState(detailCondition.day);
    //入力非活性フラグ
    const [disable, setDisable] = React.useState(false);
    //値なし・ありのチェックボックスが変わった時
    const valueCheckBoxChanged = (checked: boolean) => {
        setDisable(checked);
    }
    //Boxの表示切り替え
    const isVisible = (disable: boolean) => {
        return disable ? "hidden" : 'visible';
    }
    /**
     * 日にち差分入力時の文字ラベルのリソース名取得
     * @param num
     */
    const getDayText = (num: number) => {
        let ret = 'Text.TheDay';
        if (num > 0) {
            ret = 'Text.DaysAfter';
        } else if (num < 0) {
            ret = 'Text.DaysBefore';
        }
        return ret;
    }

    /**
     * 項目ごとのキー名取得
     * @param pre
     */
    const getKey = (pre: string) => {
        console.log(pre + '_' + templateIdx + '_' + detailIdx)
        return pre + '_' + templateIdx + '_' + detailIdx;
    }
    /**
     * 条件のrender
     */
    const renderCondition = () => {
        let ret: JSX.Element[] = [];
        let inputType = (dateTypeIndex === '3') ? 'date' : 'datetime-local';
        const dateSpecifyRange = (specify) ? (
            <Box display="flex" flexDirection="row" alignItems="center" key={'range' + detailIdx} mr={2}
                visibility={isVisible(disable)}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {dateTypeIndex === '3' ?
                        <StyledDatePicker
                            value={specifyFrom}
                            key={getKey('specifyFrom')}
                            className={classes.specifyTextField}
                            onChange={(event: any) => {
                                setSpecifyFrom(event);
                                let val = '';
                                if (!!event && (event as Moment).isValid()) {
                                    val = (event as Moment).format("yyyy-MM-DD")
                                }
                                let temp = detailCondition;
                                temp.from = getFormattedDate(val, false);
                                setDetailCondition(temp);
                                docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                console.log(detailCondition.from);
                            }}
                            format={"YYYY/MM/DD"}
                        />
                        :
                        <StyledDateTimePicker
                            value={specifyFrom}
                            key={getKey('specifyFrom')}
                            className={classes.specifyTextField}
                            onChange={(event: any) => {
                                setSpecifyFrom(event);
                                let val = '';
                                if (!!event && (event as Moment).isValid()) {
                                    val = (event as Moment).format("yyyy-MM-DDTHH:mm")
                                }
                                let temp = detailCondition;
                                temp.from = getFormattedDate(val, false);
                                setDetailCondition(temp);
                                docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                console.log(detailCondition.from);
                            }}
                            format={"YYYY/MM/DD HH:mm"}
                        />}
                    <span>{t('Text.FromToDash')}</span>
                    {dateTypeIndex === '3' ?
                        <StyledDatePicker
                            value={specifyTo}
                            key={getKey('specifyTo')}
                            className={classes.specifyTextField}
                            onChange={(event: any) => {
                                setSpecifyTo(event);
                                let val = "";
                                if (!!event && (event as Moment).isValid()) {
                                    val = (event as Moment).format("yyyy-MM-DD")
                                }
                                let temp = detailCondition;
                                temp.to = getFormattedDate(val, false);
                                ;
                                setDetailCondition(temp);
                                docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                console.log(detailCondition.to);
                            }}
                            format={"YYYY/MM/DD"}
                        />
                        :
                        <StyledDateTimePicker
                            value={specifyTo}
                            key={getKey('specifyTo')}
                            className={classes.specifyTextField}
                            onChange={(event: any) => {
                                setSpecifyTo(event);
                                let val = "";
                                if (!!event && (event as Moment).isValid()) {
                                    val = (event as Moment).format("yyyy-MM-DDTHH:mm")
                                }
                                let temp = detailCondition;
                                //　1: yyyyMMddHHmmss　　（年月日時分秒）の時は59秒を指定する
                                if (dateTypeIndex === '1') {
                                    val += ':59';
                                }
                                temp.to = getFormattedDate(val, false);
                                setDetailCondition(temp);
                                docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                console.log(detailCondition.to);
                            }}
                            format={"YYYY/MM/DD HH:mm"}
                        />}
                </LocalizationProvider>
                {/*<!-- 条件ドロップダウン 範囲 -->*/}
                <RangeSelect key={getKey('rangeSelect')} templateIdx={templateIdx} detailIdx={detailIdx}
                    conditionType={conditionType} />
            </Box>) : null;
        const dateDiffRange = (!specify && range) ? (
            <Box display="flex" flexDirection="row" alignItems="center" mr={2}
                visibility={isVisible(disable)}>
                {/* <!-- from -->*/}
                <TextField type={'number'}
                    value={diffFrom}
                    key={getKey('fromNum')}
                    id={getKey('fromNum')}
                    inputProps={{ className: classes.diffRangeTextField }}
                    onChange={(event: any) => {
                        let val = event.target.value;
                        setDiffFrom(val);
                        let temp = detailCondition;
                        temp.from = val;
                        setDetailCondition(temp);
                        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                    }}
                    ng-disabled="$execTemplate.details[$index].empty || $execTemplate.details[$index].anyValue || conditionsExecTemplateCtrl.isDisabled($execTemplate.conditionType)" />
                <span className="fromToDash">{t('Text.FromToDash')}</span>
                {/*  <!-- to -->*/}
                <TextField type={'number'} className="form-control num"
                    value={diffTo}
                    key={getKey('toNum')}
                    id={getKey('toNum')}
                    inputProps={{ className: classes.diffRangeTextField }}
                    onChange={(event: any) => {
                        let val = event.target.value;
                        setDiffTo(val);
                        let temp = detailCondition;
                        temp.to = val;
                        setDetailCondition(temp);
                        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                    }}
                />
            </Box>) : null;

        const dateDiff = (!specify && !range) ? (
            <Box key={'diff' + detailIdx} mr={2} display="flex" flexDirection="row" alignItems="center"
                visibility={isVisible(disable)}>
                <TextField type="number"
                    value={day}
                    key={getKey('diff')}
                    id={getKey('diff')}
                    inputProps={{ className: classes.diffRangeTextField }}
                    onChange={(event: any) => {
                        let val = event.target.value;
                        setDay(val);
                        let temp = detailCondition;
                        temp.day = val;
                        temp.dateTypes = getDayText(val);
                        setDetailCondition(temp);
                        docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                    }}
                />
                <Typography variant="body1" display="block" align='center'>{'   ' + t(getDayText(day))}</Typography>
            </Box>) : null;

        const diffRangeCheck = (!specify) ? (
            <Box visibility={isVisible(disable)}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={range}
                            color={'primary'}
                            key={getKey('diffRangeCheck')}
                            id={getKey('diffRangeCheck')}
                            onChange={(event) => {
                                let val = event.target.checked;
                                setRange(val);
                                let temp = detailCondition;
                                temp.range = val;
                                setDetailCondition(temp);
                                docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                console.log(detailCondition.range);
                            }}
                        />
                    }
                    label={t('Text.RangeCheck')}
                />
            </Box>) : null;
        ret.push(
            <>
                {dateSpecifyRange}
                {dateDiffRange}
                {/*<!-- 数値 -->*/}
                {dateDiff}
                {/* <!--期間-->*/}
                {diffRangeCheck}

                {/* <!-- 日時指定 -->*/}
                <Box visibility={isVisible(disable)}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={'primary'}
                                checked={specify}
                                key={getKey('specifyCheck')}
                                id={getKey('specifyCheck')}
                                onChange={(event) => {
                                    let val = event.target.checked;
                                    setSpecify(val);
                                    setRange(!val);
                                    let temp = detailCondition;
                                    temp.specified = val;
                                    temp.range = !val;
                                    setDetailCondition(temp);
                                    docState.applySearchExecDetailCondition(templateIdx, detailIdx, temp);
                                }}
                            />
                        }
                        label={t('Text.SpecifiedDate')}
                    />
                </Box>
                {/* <!-- 値なし  値あり-->*/}
                <ValueCheckBox key={getKey('valueCheck')} templateIdx={templateIdx} detailIdx={detailIdx}
                    conditionType={conditionType}
                    valueTypeChanged={(value) => {
                        valueCheckBoxChanged(value);
                    }} />
            </>
            //    }
        );
        return ret;
    }

    return (
        // 詳細条件実行時指定 日付型 テンプレート

        <Box display="flex" flexDirection="row" alignItems="center" key={'date' + detailIdx}
            className="conditionsTemplateDetail" mx={2}>
            {renderCondition}
        </Box>
    );
};

export default DateCondition;
