import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import DocContainer from "../../container/DocContainer";
import SearchExecContent from "../search/SearchExecContent";
import { makeStyles } from "@material-ui/core/styles";
import ValidatorService from "../../service/ValidatorService";
import { DEFAULT_DATE_FORMAT } from "../../constants/Constants";
import SearchOptionService from "../../service/SearchOptionService";
import { DATE_FORMAT_APP_INFO } from "../../constants/PropertiesConstants";
import { ErrorInfo } from "../../models/ErrorInfo";
import ErrorContainer from "../../container/ErrorContainer";
import CommonContainer from "../../container/CommonContainer";

type Props = {
    id: string,
    title: string;
    isOpen: boolean;
    content: string
    ButtonText: string;
    doSubmit: (user?: any) => void;
    doClose: () => void;
};
const SearchExecDialog: React.FC<Props> = ({
    id,
    title,
    isOpen,
    content,
    ButtonText,
    doSubmit,
    doClose,
}) => {
    const docState = DocContainer.useContainer();
    const errorState = ErrorContainer.useContainer();
    const commonState = CommonContainer.useContainer();
    
    const inputRef = React.useRef(null);
    const [open, setOpen] = useState(false)
    const { t, i18n } = useTranslation();
    const useStyles = makeStyles(() => ({
        paper: { minWidth: "800px" },
    }));
    const classes = useStyles();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        doClose();
    };

    const handleSubmit = async () => {
        // フリーワードが不正、もしくはフォルダーが選択されていない場合はメッセージを表示
        var msg = '';
        // フリーワードチェック
        if (msg.length === 0 && (docState.selectCondition.freeword !== undefined
            && docState.selectCondition.freeword.execSet === true
            && docState.selectCondition.freeword.val !== undefined)) {
            msg = ValidatorService.freeWord(docState.selectCondition.freeword.val, i18n.t('Text.FreeWord'), true);
        }

        if (msg.length > 0) {
            // dialogService().message(options);

            return false;
        }
        // 実行時指定のチェック
        let ignoreRowIndexes = {};
        // 実行時に入力した項目のみチェック ※ループを途中で抜けたいので$.eachを使用
        let emptyRow = [];
        let templates = docState.selectCondition.templates;
        let condition: any = {};
        let options: any = {
            dateFormat: DATE_FORMAT_APP_INFO,
            ignoreEmpty: true
        };
        for (let idx = 0; idx < templates.length; idx++) {
            let row = templates[idx];
            if (row.execSet === true) {
                row.templateIndex = idx;
                var validateResult = SearchOptionService.validate(row, options);
                // @ts-ignore
                emptyRow = validateResult.emptyRow;
                if (typeof validateResult !== "boolean") {
                    if (validateResult?.msg.length > 0) {
                        errorState.setError(new ErrorInfo('', validateResult.msg));
                        return;
                    }
                    // @ts-ignore
                    ignoreRowIndexes[row.templateIndex] = validateResult.ignoreRowIndexes;
                }
                if (emptyRow.length > 0) {
                    return false;
                }
            }
        }
        commonState.setProgress(true);
        handleClose();
        await docState.searchFromTemplate(docState.selectCondition, ignoreRowIndexes, null, null);
        commonState.setProgress(false);
    };
    const disableOK = () => {
        if (inputRef !== null) {
            // @ts-ignore
            if (inputRef.current !== null && inputRef.current.value !== null) {
                return false;
            }
        }
        return true;
    };
    return (
        <>
            <Dialog
                id={id}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.paper }}
                fullScreen={true}
                disableBackdropClick={true}
            >
                <Box m={1}>
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <SearchExecContent content={content} />

                    <DialogActions>
                        <Button onClick={handleSubmit} color="primary">
                            {ButtonText}
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            {t('Text.Cancel')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
export default SearchExecDialog;
