import React from 'react';
import { createContainer } from "unstated-next";
import * as WebConstants from "../constants/WebConstants";

const KEY_FULLSCREEN_PREVIEW = 'isFullScreenPreview';

const useCommonState = () => {
    const [status, setStatus] = React.useState({
        open: false,
        type: "success",
        message: "成功しました。"
    });
    const [progress, setProgress] = React.useState<boolean>(false)

    const packageJson = require("../../package.json");
    const version = packageJson.version;
    const buildNo = packageJson.build;
    const versionStr = 'Ver.' + version + '(' + buildNo + ')';
    const [tantouDlgOpen, setTantouDlgOpen] = React.useState<boolean>(false);
    const [previewFullScreen, _setPreviewFullScreen] = React.useState<boolean>(
        localStorage.getItem(KEY_FULLSCREEN_PREVIEW) === 'true'
    );
    const [error, setError] = React.useState<boolean>(false);
    const [statusCode, setStatusCode] = React.useState<number>();
    const isPC = () => {
        return !(navigator.userAgent.indexOf('iPhone') > 0
            || navigator.userAgent.indexOf('Android') > 0
            || navigator.userAgent.indexOf('Mobile') > 0
            || navigator.userAgent.indexOf('iPad') > 0
            || navigator.userAgent.indexOf('Android') > 0)
    }

    const setPreviewFullScreen = (fullScreen: boolean) => {
        _setPreviewFullScreen(fullScreen);
        localStorage.setItem(KEY_FULLSCREEN_PREVIEW, String(fullScreen));
    }

    return { status, setStatus, tantouDlgOpen, setTantouDlgOpen, versionStr, isPC, previewFullScreen, setPreviewFullScreen, error, setError, statusCode, setStatusCode, progress, setProgress }
}

const CommonContainer = createContainer(useCommonState);

export default CommonContainer;
