import moment from "moment";

/**
* カスタムプロパティの文字列を日付に変換するフィルター
*/
export default function CustomDateFilter(val: string | undefined, format1: string, format2: string){
        var STR_YEAR = 'yyyy';
        var STR_MON = 'MM';
        var STR_DAY = 'dd';
        var STR_HOUR = 'HH';
        var STR_MIN = 'mm';
        var STR_SEC = 'ss';

        var STR_DATE_KEYS = [STR_YEAR, STR_MON, STR_DAY, STR_HOUR, STR_MIN, STR_SEC];

        var SEP_SLA = '/';
        var SEP_CLN = ':';
        var SEP_SPA = ' ';
        var hasTimezone = ['yyyyMMddHHmmss', 'yyyyMMddHHmm'];

        return convertValue(val,format1,format2);

    /**
     * 変換文字列取得
     * @param val
     * @param format1
     * @param format2
     */
        function convertValue(val: string | undefined, format1: string, format2: string){
            let ret ='';
            if (val === null || val === '') {
                return　ret;
            }
            var timeType = hasTimezone.indexOf(format1);
            //タイムゾーンありでフォーマット文字列変換
            if (timeType > -1) {
                var date;
                if (typeof val === "string") {
                    var dateMap = getDateStringMap(val, format1);
                    switch (timeType) {
                        case 0:
                            // @ts-ignore
                            date = new Date(dateMap[STR_YEAR], parseInt(dateMap[STR_MON]) - 1, dateMap[STR_DAY], dateMap[STR_HOUR], dateMap[STR_MIN], dateMap[STR_SEC]);
                            break;
                        case 1:
                            // @ts-ignore
                            date = new Date(dateMap[STR_YEAR], parseInt(dateMap[STR_MON]) - 1, dateMap[STR_DAY], dateMap[STR_HOUR], dateMap[STR_MIN]);
                            break;
                    }
                } else {
                    date = val;
                }
                ret =  moment(date).format(format2);
            } else {
                //タイムゾーンなし
                ret = replaceDateString(val, format1, format2);
            }
            return ret;
        }

        /**
         * 文字列から日付時刻を切り出してMapに保管
         * @param value
         * @param format
         * @return
         */
        function getDateStringMap(value:any, format:any) {
            var map = {};

            //フォーマットが無い場合は処理しない
            if (format === undefined) {
                return map;
            }

            //-------------------------------------------------
            //入力値補正
            //-------------------------------------------------
            //全角スペースを半角スペースに置き換え
            value = value.replace(/'　'/g, SEP_SPA);

            //両端のスペースを除去
            value = value.trim();

            //2つ以上続くセパレータ文字列（半角スペース、スラッシュ、コロン）を除去
            value = value.replace(new RegExp(SEP_SPA + '{2,}', 'g'), '');
            value = value.replace(new RegExp(SEP_SLA + '{2,}', 'g'), '');
            value = value.replace(new RegExp(SEP_CLN + '{2,}', 'g'), '');

            //入力値が無くなったら入力エラー
            if (value.length <= 0) {
                return map;
            }

            //入力値の文字間の半角スペース出現回数がフォーマット文字列の出現回数を超えてるか2回以上出現していたら入力エラー
            var fmtSpaCnt = (format.length - format.replace(new RegExp(SEP_SPA, 'g'), '').length) / SEP_SPA.length;
            var valSpaCnt = (value.length - value.replace(new RegExp(SEP_SPA, 'g'), '').length) / SEP_SPA.length;
            if (fmtSpaCnt < valSpaCnt || valSpaCnt >= 2) {
                return map;
            }

            //-------------------------------------------------
            //値セット
            //-------------------------------------------------
            //セパレータが存在しておらず、値が数値の場合は文字列切り出しでMapを作成する
            if ((format.indexOf(SEP_SPA) === -1 && format.indexOf(SEP_SLA) === -1 && format.indexOf(SEP_CLN) === -1) && (value.indexOf(SEP_SPA) === -1 && value.indexOf(SEP_SLA) === -1 && value.indexOf(SEP_CLN) === -1)) {
                STR_DATE_KEYS.map( (key, index)=> {
                    var idx = format.indexOf(key);
                    if (idx > -1) {
                        var str = null;
                        if (idx + key.length < value.length + 1) {
                            str = value.substring(idx, idx + key.length);
                        }
                        if (str != null) {
                            // @ts-ignore
                            map[key] = str;
                        }
                    }
                });
            } else {
                var formats = format.trim().split(SEP_SPA);
                var values = value.trim().split(SEP_SPA);

                var formatHHmmss;
                var valueHHmmss;
                if (values.length === 2) {
                    //要素2は時分秒として扱う
                    if (formats.length === 2) {
                        formatHHmmss = formats[1].split(SEP_CLN);
                        valueHHmmss = values[1].split(SEP_CLN);
                        for (var i = 0; i < formatHHmmss.length; i++) {
                            var setVal = '00';
                            if (valueHHmmss.length - 1 >= i) {
                                // setVal = String.format('%1$02d', parseInt(valueHHmmss[i]));
                                setVal = ('00' + valueHHmmss[i]).slice(-2);
                            }
                            // @ts-ignore
                            map[formatHHmmss[i]] = setVal;
                        }
                    }
                }
                //要素1は年月日か時分秒かを判断しセパレータ文字列を決める
                var sSep = SEP_SLA;
                var idx = values[0].indexOf(SEP_CLN);
                if (idx > -1) {
                    sSep = SEP_CLN;
                }
                formatHHmmss = formats[0].split(sSep);
                valueHHmmss = values[0].split(sSep);

                for (var j = 0; j < formatHHmmss.length; j++) {
                    var setValue = '';
                    //  var setFmt = '%1$02d';
                    var setFmt = '00';
                    if (formatHHmmss[j] === STR_YEAR) {
                        setValue = '0001';
                        //setFmt = '%1$04d';
                        setFmt = '0000';
                    }
                    else if (formatHHmmss[j] === STR_MON || formatHHmmss[j] === STR_DAY) {
                        setValue = '01';
                    }
                    else {
                        setValue = '00';
                    }

                    if (valueHHmmss.length - 1 >= j) {
                        // setValue = String.format(setFmt, parseInt(valueHHmmss[j], 10));
                        setValue = (setFmt + valueHHmmss[j]).slice(-setFmt.length);
                    }
                    if (setValue !== '') {
                        // @ts-ignore
                        map[formatHHmmss[j]] = setValue;
                    }
                }
            }

            return map;
        }

        /**
         * タイムゾーンのない日付の文字列型のフォーマット変換を行います
         * @param value
         * @param fromFormat
         * @param toFormat
         * @return
         */
        function replaceDateString(value: any, fromFormat: any, toFormat: string) {
            var ret = '';
            var map = getDateStringMap(value, fromFormat);
            let itMap = Object.entries(map);
            //ターゲットの文字列に置き換える
            // if (map.length > 0) {
            ret = toFormat;
            for (let item of itMap){
                ret = ret.replace(new RegExp(item[0], 'g'), <string>item[1]);
            }
            // }
            return ret;
        }

    };
