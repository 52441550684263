import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import {Checkbox} from "@material-ui/core";

export default function CellCheckbox(props: { value: boolean; }) {
    return (
        <Checkbox size="small"
                  color="primary"
                  checked={props.value}/>
    );
}


