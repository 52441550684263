import { Divider, IconButton, InputBase, MuiThemeProvider } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from "mui-datatables";
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Info } from "../../models/Info";
import Word from "../../common/Word";
import { useManageService } from "../../service/ManageService";
import CommonContainer from "../../container/CommonContainer";
import UserContainer from "../../container/UserContainer";
import { table, useStyles } from "./css/Style";
import { ConfirmPasswordDialog } from "../dialog/ConfirmPasswordDialog";
import { ManagementDeleteDialog } from "../dialog/ManagementDeleteDialog";
import { IaDialog } from "../dialog/IaDialog";

export default function IaList(props: { setCallBackMessage: (arg0: { status: number; message: string; open: boolean }) => void; }) {
    // css
    const classes = useStyles();

    const commonState = CommonContainer.useContainer();
    const useState = UserContainer.useContainer();

    const { iaGetDataApi, iaAddDataApi, iaEditDataApi, iaDeleteDataApi } = useManageService()

    const { t, i18n } = useTranslation();

    const [openAdd, setOpenAdd] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [addData, setAddData] = React.useState<Info>({});
    const [editData, setEditData] = React.useState<Info>({});
    const [tableData, setTableData] = React.useState<Info[]>([]);
    const [hidePassword, setHidePassword] = React.useState<string>("");
    const [rowSelected, setRowSelected] = React.useState<any[]>([]);
    const [selectedRowData, setSelectedRowData] = React.useState<Info>({});
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [openPassword, setOpenPassword] = React.useState(false);
    const [password, setPassword] = React.useState<string>("");

    const [tableColumns, setTableColumns] = React.useState(
        [
            {
                label: i18n.t('Text.Name'),
                name: "name"
            },
            {
                label: i18n.t('Text.UserName'),
                name: "userName"
            },
            {
                label: i18n.t('Text.Password'),
                name: "password",
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        return (
                            <InputBase type='password' value={"**********"} inputProps={{ readOnly: true }} />
                        )
                    }
                }
            },
            {
                label: i18n.t('Text.Token'),
                name: "id"
            },
            {
                label: i18n.t('Text.LatestLoginTime'),
                name: "latestLoginTime"
            }
        ]
    );

    const options: any = {
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        serverSide: false,
        pagination: false,
        search: false,
        sort: true,
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        rowHover: false,
        rowsPerPageOptions: [10, 50, 100],
        onRowSelectionChange: (currentRowsSelected: any[] | undefined, allRowsSelected: any[] | undefined, rowsSelected: any[] | undefined) => {
            if (allRowsSelected !== undefined) {
                setRowSelected(rowsSelected as any[]);
            }
        },
        textLabels: {
            body: {
                noMatch: Word('Text.NoHit'),
                toolTip: '',
                columnHeaderTooltip: '',
            }
        }
    };

    useEffect(() => {
        if (!!useState.tenantId) {
            getListData();
        }
    }, [useState.tenantId])

    useEffect(() => {
        if (rowSelected.length === 1) {
            setSelectedRowData({ ...tableData[rowSelected[0]], password: "" });
        }
    }, [rowSelected])

    /**
     * IA文書管理の情報取得
     */
    const getListData = () => {
        commonState.setProgress(true);
        iaGetDataApi(useState.tenantId as string).then((data) => {
            setTableData(data);
            setRowSelected([]);
        }).catch((error) => {
            console.log("****** error is *******", error.response);
        }).finally(() => {
            commonState.setProgress(false);
            resetData();
        })
    }

    /**
     * IA文書管理の接続の新規ダイアログを開く
     */
    const addOpen = () => {
        setOpenAdd(true)
        setHidePassword("");
    }

    /**
     * IA文書管理の編集ダイアログを開く
     */
    const editOpen = () => {
        setOpenEdit(true);
        setHidePassword("**********");
        setEditData(Object.assign({}, selectedRowData));
    }

    /**
     * IA文書管理の情報新規
     */
    const handelAddButtonOnClick = (addData: Info) => {
        let param = addData!;
        param.tenantId = useState.tenantId;
        iaAddDataApi(param).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.AddSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.AddFailed'), open: true });
        }).finally(() => {
            setOpenAdd(false);
            setAddData({});
            setHidePassword("");
            getListData();
        })
    }

    /**
     * IA文書管理の情報編集
     */
    const handleEditButtonOnClick = (editData: Info) => {
        iaEditDataApi(editData).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.EditSuccessful'), open: true });
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.EditFailed'), open: true });
        }).finally(() => {
            setOpenEdit(false);
            getListData();
        })
    }

    /**
     * 削除操作の実行
     */
    const handleDeleteButtonOnClick = () => {
        iaDeleteDataApi(tableData[rowSelected[0]]).then((data) => {
            props.setCallBackMessage({ status: 200, message: i18n.t('Msg.DeleteSuccessful'), open: true });
            setOpenDeleteAlert(false);
        }).catch((error) => {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.DeleteFailed'), open: true });
            console.log("****** error is ******", error.response);
        }).finally(() => {
            getListData();
        });
    }

    /**
     * 追加ダイアログを閉じる
     */
    const handleAddClose = () => {
        setOpenAdd(false);
        setPassword("");
        setHidePassword("");
        resetData();
    }

    const resetData = () => {
        setAddData({
            name: "",
            userName: "",
            password: ""
        })
    }

    /**
     * パスワードの確認
     */
    const confirmPassword = (passwordList: any) => {
        if (passwordList.password !== passwordList.confirmPassword) {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.ConfirmPassword'), open: true });
            return;
        }
        if ((!passwordList.password && !passwordList.confirmPassword) || passwordList.password!.length > 32) {
            props.setCallBackMessage({ status: 500, message: i18n.t('Msg.CheckPasswordFormat'), open: true });
            return;
        }
        setPassword(passwordList.password);
        setHidePassword("**********");
        setOpenPassword(false);
    }

    return (
        <>
            <div className="TabPanel" style={{ height: 'auto', width: '100%' }}>
                <div className={classes.tabTitle}>{t('Text.IaDocumentManagement')}</div>
                <Divider />
                <div className={classes.iconStyle}>
                    <IconButton onClick={() => addOpen()} disabled={tableData.length !== 0}>
                        <AddIcon className={tableData.length === 0 ? classes.addButtonActived : classes.addButtonDisabled} />
                    </IconButton>
                    <IconButton onClick={editOpen} disabled={rowSelected.length === 0}>
                        <EditIcon className={rowSelected.length !== 0 ? classes.editButtonActived : classes.editButtonDisabled} />
                    </IconButton>
                    <IconButton onClick={() => setOpenDeleteAlert(true)} disabled={rowSelected.length === 0}>
                        <CloseIcon className={rowSelected.length !== 0 ? classes.deleteButtonActived : classes.deleteButtonDisabled} />
                    </IconButton>
                </div>
                <Divider />
                <div style={{ textAlign: 'left', padding: '10px 10px 0 20px' }}>{t('Text.IaPagePrompt')}</div>
                <div style={{ width: '100%', backgroundColor: 'white', padding: '20px 10px 20px 10px' }}>
                    <MuiThemeProvider theme={table()}>
                        <MUIDataTable
                            columns={tableColumns}
                            data={tableData}
                            title={undefined}
                            options={options}>
                        </MUIDataTable>
                    </MuiThemeProvider>
                </div>
            </div>

            {/* IA文書管理の情報管理の新規 */}
            <IaDialog
                title={t('Text.New')}
                action="add"
                isOpen={openAdd}
                data={addData}
                password={password}
                hidePassword={hidePassword}
                doClose={() => handleAddClose()}
                doClick={(addData) => handelAddButtonOnClick(addData)}
                openConfirmPassword={() => setOpenPassword(true)}
            />

            {/* IA文書管理の情報管理の編集 */}
            <IaDialog
                title={t('Text.Edit')}
                action="edit"
                isOpen={openEdit}
                data={editData}
                password={password}
                hidePassword={hidePassword}
                doClose={() => setOpenEdit(false)}
                doClick={(editData) => handleEditButtonOnClick(editData)}
                openConfirmPassword={() => setOpenPassword(true)}
            />

            {/* 削除のヒント */}
            <ManagementDeleteDialog
                isOpen={openDeleteAlert}
                doClose={() => setOpenDeleteAlert(false)}
                deleteButtonOnClick={() => handleDeleteButtonOnClick()}
            />

            {/* パスワード入力ボックス */}
            <ConfirmPasswordDialog
                title={t('Text.Password')}
                isOpen={openPassword}
                confirmPassword={(passwordList) => confirmPassword(passwordList)}
                doClose={() => setOpenPassword(false)}
            />
        </>
    )
}