import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * プレビュー API
 */
export const usePreviewService = () => {
    const axios = ServiceContainer.useContainer();

    const getPreview = (id: number | undefined, pageNo: number) => {
        return axios.request(
            WebConstants.PREVIEW_GET_URL(id, pageNo),
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {
            console.log("****** previewGet is *******", response);
            return response.data;
            //         }
            //    ).catch((error) => {
            //         console.log("****** error is *******", error.response);
        })
    }

    const getAttributes = (id: number) => {
        return axios.request(
            WebConstants.PREVIEW_ATTRIBUTE_GET_URL(id),
            WebConstants.METHOD_GET,
            null,
            WebConstants.HEADER_ACCEPT_JSON
        ).then((response) => {
            return response.data;
        })
    }

    return {
        getPreview, getAttributes
    }
}