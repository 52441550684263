import React, {FC, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import UserContainer from "../../container/UserContainer";
import TantouInputContent from "./TantouInputContent";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";


type Props = {
    id: string,
    title: string;
    isOpen: boolean;
    content: string
    ButtonText: string;
    doSubmit: (user?: any) => void;
    doClose: () => void;
};
const TantouDialog: React.FC<Props> = ({
                                           id,
                                           title,
                                           isOpen,
                                           content,
                                           ButtonText,
    doSubmit,
                                           doClose,
                                       }) => {
    const userState = UserContainer.useContainer();
    const inputRef = React.useRef(null);
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        doClose();
    };

    const handleSubmit = () => {
        if (inputRef !== null) {
            // @ts-ignore
            if (inputRef.current !== null && inputRef.current.value.length> 0) {
                // @ts-ignore
                let user = inputRef.current.value;
                userState.setTantou(user);
                doSubmit(user);
                handleClose();
            }
        }

    };
    const disableOK= () => {
        if (inputRef !== null) {
            // @ts-ignore
            if (inputRef.current !== null && inputRef.current.value !== null) {
                 return false;
            }
        }
            return true;
    };
    return (
        <>
            <Dialog
                id={id}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <Box component="span" m={1}
                     width={400}>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>

                <TantouInputContent content={content} defaultValue={userState.tantou}
                                    inputRef={inputRef}/>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        {ButtonText}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {t('Text.Cancel')}
                    </Button>
                </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
export default TantouDialog;
