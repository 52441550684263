import * as WebConstants from "../constants/WebConstants";
import ServiceContainer from "../container/AxiosServiceContainer";

/**
 * プレビュー API
 */
export const useSearchService = () => {
    const searchState = ServiceContainer.useContainer();

    const searchFolder = (conditions: any) => {
        return searchState.request(
            WebConstants.SEARCH_URL,
            WebConstants.METHOD_POST,
            conditions,
        ).then((response) => {
            console.log("****** search is *******", response);
            let ret = [];
            if (response.data.resultList.length > 0) {
                ret = response.data.resultList;
            }
            return ret;
        })
    }

    return {
        searchFolder
    }
}