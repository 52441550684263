import React from 'react';
import LoginPage from "./components/LoginPage";
import {GeneralContainer} from "./container/GeneralContainer";

const App: React.FC = () => {
  return (
      <GeneralContainer>
          <LoginPage />
      </GeneralContainer>
  );
}

export default App;
